export default {
  login: 'LOGIN',
  password: 'Password',
  slogan: 'See your growth and get consulting support!',
  akaName: 'OPTIMIZED WAREHOUSE MANAGEMENT',
  akaDescription: 'By Digital Transformation',
  placeholderPassword: 'Password',
  messagePassword: 'Please input your Password!',
  placeholderUsername: 'Username',
  messageUsername: 'Please enter your Username',
  loading: 'Loading',
  inbound: 'Inbound',
  receipt: 'PO',
  temporaryInbound: 'Temporary Inbound',
  storing: 'Storing',
  inboundControl: 'Inbound Control',
  inventory: 'Inventory',
  warehouseState: 'Warehouse State',
  reallocation: 'Reallocation',
  productManagement: 'Product Management',
  outbound: 'Outbound',
  pickingOrder: 'Sale order',
  packaging: 'Packaging',
  shippingOutboundControl: 'Delivery order',
  configuration: 'Configuration',
  supplier: 'Supplier',
  product: 'Product',
  productCategory: 'Product category',
  location: 'Location',
  replenishment: 'Replenishment',
  saleOrder: 'Sale Order',
  reporting: 'Reporting',
  auditOrder: 'Audit Order',
  inventoryWarning: 'Inventory Warning',
  performance: 'Performance',
  changePassword: 'Change Password',
  logout: 'Logout',
  confirm: 'Confirm',
  confirmLogout: 'Do you want to log out?',
  warehouse: 'Warehouse',
  user: 'User',
  admin: 'Admin',
  receiptId: 'Receipt Id',
  receiptCode: 'Receipt code',
  inboundKind: 'Inbound kind',
  status: 'Status',
  receiptDate: 'Receipt date',
  receiveDate: 'Receive date',
  from: 'From',
  to: 'To',
  creator: 'Creator',
  keyword: 'Keyword',
  search: 'Search',
  receiptList: 'Receipt List',
  doYouWantToDeleteTheseItems: 'Do you want to delete these items?',
  deleteReceiptsSuccessfully: 'Delete receipts successfully',
  receiptIdsHasBeenDeleted: 'Receipt IDs has been deleted:',
  productCode: 'Product code',
  productName: 'Product name',
  expectedQuantity: 'Expected quantity',
  cost: 'Purchase price',
  salePrice: 'Sale price',
  expireDate: 'Expiration date',
  createReceipt: 'Create Receipt',
  createReceiptSuccessfully: 'Create receipt successfully',
  pleaseSelectAInboundKind: 'Please select a inbound kind',
  messageReceiptDate: 'Please enter your Receipt date',
  messageReceiveDate: 'Please enter Receive date',
  productAdded: 'Product added',
  importCSV: 'Import Excel',
  pleaseSelecteAProduct: 'Please select a product',
  messageExpectedQuantity: 'Please input a expected quantity',
  messageCost: 'Please input a purchase price',
  totalQuantity: 'Total quantity',
  zone: 'Zone',
  block: 'Block',
  shelf: 'Shelf',
  rack: 'Rack',
  confirmReceiptSuccessfully: 'Confirm receipt successfully',
  receiptDetail: 'Receipt detail',
  productList: 'Product list',
  updateLocationSuccessfully: 'Update location successfully',
  updateLocation: 'Update location',
  print: 'Print',
  messageTotalQuantity: 'Please input a total quantity',
  importDate: 'Import date',
  report: 'Report',
  scanReturnProduct: 'Scan return product',
  reportErrorProduct: 'Report error product',
  reportSuccessfully: 'Report successfully',
  reportContent: 'Report content',
  messageReportContent: 'Please input a report content',
  btnSubmit: 'Submit',
  inputCode: 'Input code',
  packageCode: 'Package code',
  locationCode: 'Location code',
  completeScanReturnProductSuccessfully: 'Complete scan return product successfully',
  scanReturnProductComplete: 'Scan return product complete',
  scanMore: 'Scan more',
  scan: 'Scan',
  storingDate: 'Storing date',
  scanProduct: 'Scan product',
  storingScanProduct: 'Storing scan product',
  completeScanStoringSuccessfully: 'Complete scan storing successfully',
  storingScanProductComplete: 'Scan product list',
  inboundControlDate: 'Inbound control date',
  inboundControlScanProduct: 'Inbound control scan product',
  completeInboundControlScanSuccessfully: 'Complete inbound control scan successfully',
  inboundControlScanProductComplete: 'Scan product list',
  username: 'Username',
  name: 'Name',
  role: 'Role',
  users: 'Users',
  requiredUsername: 'Username is required',
  requiredPassword: 'Password is required',
  requiredName: 'Name is required',
  requiredRole: 'Role is required',
  requiredWarehouse: 'Warehouse is required',
  warehouseCode: 'Warehouse code',
  warehouseName: 'Warehouse name',
  address: 'Address',
  warehouses: 'Warehouses',
  acreage: 'Acreage',
  btnCancel: 'Cancel',
  btnComplete: 'Complete',
  btnUpdate: 'Update',
  btnCreate: 'Create',
  btnSearch: 'Search',
  btnAdd: 'Add',
  btnEdit: 'Edit',
  btnConfirm: 'Confirm',
  btnDelete: 'Delete',
  btnSave: 'Save',
  confirmQuestion: 'The content has been modified, your changes will be discarded. Do you want to proceed?',
  confirmContent: 'If you cancel, the edited contents will be discarded.',
  yes: 'Yes',
  addZone: 'Add Zone',
  addShelf: 'Add Shelf',
  addBlock: 'Add Block',
  zoneDetail: 'Zone Detail',
  zoneCreate: 'Create Zone',
  zoneName: 'Zone name',
  zoneAreDeletedSuccessfully: 'Zone are deleted successfully',
  blockAreDeletedSuccessfully: 'Block are deleted successfully',
  shelfAreDeletedSuccessfully: 'Shelf is deleted successfully',
  areYouSureToDeleteThisZone: 'Are you sure to delete this zone?',
  areYouSureToDeleteThisBlock: 'Are you sure to delete this block?',
  areYouSureToDeleteThisShelf: 'Are you sure to delete this shelf?',
  placeholderName: 'Please input name',
  placeholderSelectMethod: 'Please select method',
  requiredCategoryName: 'Product category name is required',
  requiredMethod: 'Method export is required',
  placeholderSelectProductCate: 'Please select product category',
  placeholderAddress: 'Please input address',
  placeholderPhoneNumber: 'Please input phone number',
  requiredCategory: 'Product category is required',
  requiredSupplierName: 'Supplier name is required',
  requiredEmail: 'Email is required',
  requiredAddress: 'Address is required',
  requiredPhoneNumber: 'Phone number is required',
  placeholderSelectSupplier: 'Please select supplier',
  placeholderCapacity: 'Please input capacity',
  requiredImage: 'Image is required',
  requiredProductName: 'Product name is required',
  requiredSupplier: 'Supplier is required',
  requiredCapacity: 'Capacity is required',
  requiredStoreTime: 'Store time in inventory is required',
  placeholderMin: 'Please input min',
  placeHolderMax: 'Please input max',
  requiredMin: 'Min quantity is required',
  requiredMax: 'Max quantity is required',
  placeholderSelectProduct: 'Please select product',
  requiredProduct: 'Product is required',
  requiredZoneName: 'Zone name is required',
  requiredBlockName: 'Block name is required',
  placeholderTotalshelf: 'Please input total shelf',
  requiredTotalshelf: 'Total shelf is required',
  requiredShelfName: 'Shelf name is required',
  placeholderTotalRack: 'Please input total rack',
  requiredTotalRack: 'Total rack is required',
  placeholderLevelPercent: 'Please input level percent',
  requiredLevelPercent: 'Level percent is required',
  placeholderQuantity: 'Please input quantity',
  requiredQuantity: 'Quantity is required',
  capacity: 'Capacity',
  success: 'Success',
  blockDetail: 'Block Detail',
  blockCreate: 'Create Block',
  blockName: 'Block name',
  totalShelf: 'Total shelf',
  updateShelf: 'Update Shelf',
  createShelf: 'Create Shelf',
  update: 'Update',
  complete: 'Complete',
  shelfName: 'Shelf name',
  capacityRack: 'Capacity rack',
  levelPercent: 'Level percent',
  medium: 'medium',
  high: 'high',
  code: 'Code',
  createSuccessfully: 'Create successfully',
  updateSuccessfully: 'Update successfully',
  deleteSuccessfully: 'Delete successfully',
  idsHasBeenDeleted: 'IDs has been deleted:',
  email: 'Email',
  orderCode: 'Order code',
  userName: 'User name',
  orderDate: 'Order date',
  orderQuantity: 'Order quantity',
  pickingquantity: 'Picking quantity',
  placeholderSelectOrderCode: 'Select order code',
  titlePicking: 'Order and picking information',
  suggestLocation: 'Suggest location',
  quantity: 'Quantity',
  createPicking: 'Create pick up list',
  labelBooking: 'Booking order',
  placeholderSelectOrder: 'Select order',
  okText: 'Ok',
  cancelText: 'Cancel',
  titleModalBooking: 'Select booking order item',
  scanCode: 'Scan code',
  confirmPickupList: 'Confirm pick up list',
  titleModalPslitProduct: 'Split Product',
  placeholderSelectPackageCode: 'Select package code',
  btnDone: 'Done',
  btnClose: 'Close',
  titleModalSuccessSuggestLocation: 'Suggest location are printed successfully',
  pickupList: 'Pick up list',
  generatePackageCode: 'Generate package code',
  orderNumber: 'Order number',
  productOrderCode: 'Product order code',
  pickingList: 'Picking list',
  packagingCode: 'Package code',
  tempOutLot: 'Temp out lot',
  orderandpackagingInfo: 'Order and packaging information',
  packagingDetail: 'Package detail',
  orderandshippingInfo: 'Order and shipping information',
  confirmPackageList: 'Confirm package list',
  outQuantity: 'Out quantity',
  startDate: 'Start date',
  endDate: 'End date',
  orderInfo: 'Order information',
  inStock: 'In stock',
  expirationDate: 'Expiration date',
  storageExpirationDate: 'Storage expiration date',
  exportCSV: 'Export Excel',
  agingStockAlert: 'Aging Stock Alert',
  dotRestock: 'Please restock for the next delivery',
  productDetail: 'Product Detail',
  total: 'Total',
  inventorySummary: 'Inventory Summary',
  inboundsummary: 'Inbound Summary',
  productSummary: 'Product Summary',
  action: 'Action',
  warehouseBlockDetail: 'Warehouse Block Detail',
  requiredReportContent: 'Report content is required',
  editLocation: 'Edit location',
  dotEditLocation: 'Please select the products that you want to reallocate to edit',
  onlyEditLocation: 'Only one line can be edited at a time',
  pendingState: 'Pending state',
  inboundState: 'Inbound state',
  lostState: 'Lost state',
  updateProduct: 'Update product',
  requiredZone: 'Zone is required',
  requiredBlock: 'Block is required',
  requiredShelf: 'Shelf is required',
  requiredRack: 'Rack is required',
  requiredExpirationDate: 'Expiration date is required',
  requiredTotalQauntity: 'Total quantity is required',
  requiredCost: 'Purchase price is required',
  requiredSalePrice: 'Sale price is required',
  requiredStatus: 'Status is required',
  requiredLostDate: 'Lost date is required',

  totalRackbetween: 'Total rack (between 1 ~ 100)',
  backtoProductList: 'Back to Product List',
  productInfo: 'Product Info',
  storageTime: 'Storage time in inventory (day)',
  productImage: 'Product Image',
  imageExtra: 'You can upload up to an image of 5MB',
  upload: 'Upload',
  methodExport: 'Method export product',
  createDate: 'Create date',
  createProduct: 'Create product',
  productCategoryName: 'Product category name',
  productCategoryCode: 'Product category code',
  productCategoryList: 'Product category list',
  updateProductCategoty: 'Update product category',
  createProductCategoty: 'Create product category',
  minQuantity: 'Min quantity',
  maxQuantity: 'Max quantity',
  notification: 'Notification',
  onHand: 'Onhand',
  toOrder: 'To order',
  replenishmentList: 'Replenishment list',
  createReplenishment: 'Create replenishment',
  updateReplenishment: 'Update replenishment',
  backtoSupplierList: 'Back to Supplier List',
  supplierInfo: 'Supplier Info',
  phone: 'Phone number',
  cooperationDate: 'Cooperation date',
  supplierName: 'Supplier name',
  requiredCooperationDate: 'Cooperation date is required',
  supplierCode: 'Supplier code',
  supplierList: 'Supplier list',
  createSupplier: 'Create supplier',
  updateSupplier: 'Update supplier',
  createSaleOrder: 'Create Sale Order',
  addProduct: 'Add product',
  editProduct: 'Edit product',
  saleOrderDetail: 'Sale Order detail',
  orderList: 'Order list',
  reportDate: 'Report date',
  firstName: 'First name',
  lastName: 'Last name',
  lostDate: 'Lost date',
  phoneLengthMessage: 'Phone number cannot be longer than 11 characters',
  phoneMessage: 'Phone number must be numbers',
  full: 'Full',
  empty: 'Empty',
  Medium: 'Medium',
  resetPassword: 'Reset password',
  passwordNew: 'New password',
  currentPassword: 'Current password',
  passwordConfirm: 'Confirm password',
  zoneNameExist: 'Zone name is existed',
  blockNameExist: 'Block name is existed',
  shelfNameExist: 'Shelf name is existed',
  subTitleInboundControl: 'Please scan product location before placing on the shelf',
  subTitleOutboundCreatePickUpNotify: 'Please select booking order item',
  subTitleStoringNotify: 'Please scan temporary inbound QR code before moving',
  subTitleInboundTemporaryNotify: 'Please scan Temporary inbound QR code before moving',
  no: 'No',
  passwordRegex: 'Password must be at least 8 characters',
  updateImageLess: 'Please upload photos from 5MB or less',
  creatorName: 'Creator',
  invalidCode: 'Invalid code',
  tryAgain: 'Please try again',
  orderStatus: 'Order status',
  packageStatus: 'Package status',
  noRecordsFound: 'No records found!',
  emailRequirePlaceholder: 'Please input email',
  messeageExpiration: 'Expiration date is required',
  unlockAccount: 'Unlock account successfully',
  January: 'January',
  February: 'February',
  March: 'March',
  April: 'April',
  May: 'May',
  June: 'June',
  July: 'July',
  August: 'August',
  September: 'September',
  October: 'October',
  November: 'November',
  December: 'December',
  splitproduct: 'Split Product',
  splitProductList: 'Split product list',
  vans: 'Vans',
  addVans: 'Add Vans',
  createVans: 'Create vans',
  updateVans: 'Update vans',
  vansName: 'Vans name',
  vansLicensePlate: 'License plate',
  placeholderLicensePlate: 'Please input license plate',
  requiredLicensePlate: 'License plate is required',
  requiredVansName: 'Vans name is required',
  vansState: 'Vans State',
  vansDetail: 'Vans Detail',
  defaultCode: 'Barcode',
  requiredDefaultCode: 'Default code is required',
  placeHolderDefaultCode: 'Please input default code',
  placeholderSalePrice: 'Please input sale price',
  retailPrice: 'Retail price',
  placeholderRetailPrice: 'Please input sale price',
  lot: 'Lot',
  requiredLot: 'Lot is required',
  VAT: 'VAT',
  Divison: 'Divison',
  Category: 'Category',
  divisonCode: 'Divison code',
  divisonName: 'Divison name',
  createDivison: 'Create Divison',
  updateDivison: 'Update divison',
  placeholderDivisonName: 'Please input divison name',
  requiredDivisonName: 'Divison name is required',
  categoryCode: 'Category code',
  categoryName: 'Category name',
  createCategory: 'Create category',
  updateCategory: 'Update category',
  placeholderCategoryName: 'Please input category name',
  requiredParentCategoryName: 'Category name is requried',
  requiredDivison: 'Divison is requried',
  requiredParentCategory: 'Category is requried',
  categoryList: 'Category list',
  divisonList: 'Divison list',
  DVT: 'Unit',
  isActive: 'Is active',
  contractNumber: 'Contract number',
  packingRule: 'Packing rule',
  NEW: 'NEW',
  ERROR: 'ERROR',
  TEMPORARY: 'TEMPORARY',
  STORED: 'STORED',
  MOVING: 'MOVING',
  TEMPORARY_OUT: 'TEMPORARY_OUT',
  LOST: 'LOST',
  REALLOCATE: 'REALLOCATE',
  ENABLE: 'ENABLE',
  DISABLE: 'DISABLE',
  requestReceipt: 'Request receipt',
  note: 'Note',
  itemCode: 'Item code',
  length: 'Length',
  width: 'Width',
  height: 'Height',
  RECEIVE: 'RECEIVE',
  dowloadTemplate: 'Dowload template',
  importSuccess: 'Import Successfully',
  exportPDF: 'ExportPDF',
  discount: 'Discount',
  isResources: 'Resources',
  vansCode: 'Vans Code',
  createPO: 'Create PO',
  taxCode: 'Tax code',
  requiredPackingRule: 'Packing rule is required',
  CANCELLED: 'CANCELLED',
  PICKING: 'PICKING',
  PACKAGED: 'PACKAGED',
  deliveryTime: 'Delivery time',
  messeagePicking: 'Orders are picking by another user',
  weAre: 'We are',
  fptCloudWms: 'FPT CloudWMS',
  fptCloudWmsDescription: 'is a optimized warehouse management solution, that aims to enable digital transformation by integrationg flexible systemthat ofer various benefits in processes and techniques. It improves and convertinal methoeds of warehouse management and boosts the effectiveness and expertise of warehouse operations.',
  totalSaleOrderItem: 'Total sale order',
  finishedGoods: 'Finished Goods',
  rawMaterials: 'Raw Materials',
};
 