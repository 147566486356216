import {
  SCAN_RETURN_FOR_PRODUCT_CODE_ADD,
  SCAN_RETURN_FOR_PACKAGE_CODE_ADD,
  SCAN_RETURN_COMPLETED,

  SCAN_STORING_FOR_PRODUCT_CODE_ADD,
  SCAN_STORING_FOR_PACKAGE_CODE_ADD,
  SCAN_STORING_COMPLETED,

  SCAN_INBOUND_CONTROL_FOR_LOCATION_CODE_ADD,
  SCAN_INBOUND_CONTROL_FOR_PRODUCT_CODE_ADD,
  SCAN_INBOUND_CONTROL_COMPLETED,
} from './types';

const INITIAL_STATE = {
  //Temporary Inbound - Scan return
  scanReturnForPackageCodes: [],
  scanReturnForProductCodes: [],
  //Storing - Scan storing
  scanStoringForPackageCodes: [],
  scanStoringForProductCodes: [],
  //Inbound Control - Scan inbound control
  scanInboundControlForLocationCode: [],
  scanInboundControlForProductCodes: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    //Temporary Inbound - Scan return
    case SCAN_RETURN_FOR_PRODUCT_CODE_ADD:
      return {
        ...state,
        scanReturnForProductCodes: action.payload,
    };
    case SCAN_RETURN_FOR_PACKAGE_CODE_ADD:
      return {
        ...state,
        scanReturnForPackageCodes: action.payload,
    };
    case SCAN_RETURN_COMPLETED:
      return {
        ...state,
        scanReturnForPackageCodes: [],
        scanReturnForProductCodes: [],
      };

    //Storing - Scan storing
    case SCAN_STORING_FOR_PRODUCT_CODE_ADD:
      return {
        ...state,
        scanStoringForProductCodes: action.payload,
    };
    case SCAN_STORING_FOR_PACKAGE_CODE_ADD:
      return {
        ...state,
        scanStoringForPackageCodes: action.payload,
    };
    case SCAN_STORING_COMPLETED:
      return {
        ...state,
        scanStoringForPackageCodes: [],
        scanStoringForProductCodes: [],
      };

    //Inbound Control - Scan inbound control
    case SCAN_INBOUND_CONTROL_FOR_PRODUCT_CODE_ADD:
      return {
        ...state,
        scanInboundControlForProductCodes: action.payload,
    };
    case SCAN_INBOUND_CONTROL_FOR_LOCATION_CODE_ADD:
      return {
        ...state,
        scanInboundControlForLocationCode: action.payload,
    };
    case SCAN_INBOUND_CONTROL_COMPLETED:
      return {
        ...state,
        scanInboundControlForProductCodes: [],
        scanInboundControlForLocationCode: [],
      };

    default:
      return state;
  }
};
