import React, { useState, useEffect } from 'react';
import { Form, Col, Row, Button, Select, Switch, InputNumber, message as Alert, Modal } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import { replenishmentCreate, replenishmentUpdate } from './replenishment.service';
import { productList } from '../product/product.service';

import './style.less';
import { useSelector } from 'react-redux';
import { productCategoryList } from '../product-category/product-category.service';
import Helper from 'utils/helpers';

const { Option } = Select;

const ReplenishmentEditForm = ({ item, onCallback, setIsEditModal, isEditModal }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);

  const [products, setProducts] = useState([]);
  const { currentWarehouse } = useSelector((state) => state.auth);
  const [productCategorys, setProductCategorys] = useState();
  const onchangeProduct = ({ value }) => {
    const productFind = products?.find(product => product.id === value);
    form.setFieldsValue({ productCategoryId: productFind?.productCategory?.id });
  };
  useEffect(() => {
    productCategoryList({ warehouseId: currentWarehouse.id, status: 'ENABLE' }).then(result => {
      setProductCategorys(result.data);
    });
  }, [currentWarehouse]);

  useEffect(() => {
    setLoading(true);
    productList({ warehouseId: currentWarehouse.id, status: 'ENABLE', isNoneReplenishment: true })
      .then(result => {
        if (item) {
          item.masterProduct.productCategory = item?.productCategory;
          setProducts([...result.data, item?.masterProduct]);
        } else {
          setProducts(result.data);
        }
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  }, [currentWarehouse, item]);

  useEffect(() => {
    return () => {
      form.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditModal]);

  const initValues = {
    masterProductId: item ? { value: item?.masterProduct?.id, label: item?.masterProduct?.name, key: item?.masterProduct?.id } : undefined,
    productCategoryId: item ? item?.productCategory.id : undefined,
    status: item  ? item?.status === 'ENABLE': undefined,
    min: item ? item?.min : null,
    max: item ? item?.max : null,
  };
  const onCancel = () => {
    const valueForm = form.getFieldsValue();
    const isDefined = Object.values(valueForm).some(value => value !== undefined);
    const isUnChange = JSON.stringify(initValues) === JSON.stringify(valueForm);
    if (!isDefined || isUnChange) {
      setIsEditModal(false);
      return true;
    }
    Helper.conFirm(setIsEditModal);
  };

  return (
    <Modal
      visible={isEditModal}
      title={item ? lang.updateReplenishment : lang.createReplenishment}
      onCancel={onCancel}
      width={570}
      centered
      footer={null}
      wrapClassName="replenishment-modal"
      afterClose={() => {
        form.resetFields();
      }}
      forceRender={true}
    >
      <Form
        colon={false}
        form={form}
        layout="vertical"
        requiredMark={false}
        name="replenishmentEditForm"
        onFinish={async (values) => {
          const { masterProductId } = values;
          values.productName = masterProductId.label;
          Object.assign(values, { masterProductId: masterProductId.value });
          setLoading(true);
          if (!item) { // Create
            setLoading(true);
            replenishmentCreate({ ...values, warehouseId: currentWarehouse.id })
              .then(result => {
                Alert.success(lang.createSuccessfully);
                setLoading(false);
                onCallback();
              })
              .catch((e) => setLoading(false));
          } else { // Update
            setLoading(true);
            if (values.status === true) {
              values.status = 'ENABLE';
            } else { values.status = 'DISABLE'; }
            replenishmentUpdate(values, item)
              .then(result => {
                Alert.success(lang.updateSuccessfully);
                onCallback();
                setLoading(false);
              })
              .catch((e) => setLoading(false));
          }
        }}
        initialValues={initValues}
      >
        <Row gutter={24} type="flex">
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item
              name="masterProductId"
              label={lang.productName}
              rules={[
                {
                  required: true,
                  message: lang.requiredProduct,
                },
              ]}
            >
              <Select placeholder={lang.placeholderSelectProduct} labelInValue onChange={onchangeProduct} showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      return option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    }>
                {products?.map(item => (
                  <Option key={item.id} value={item.id}>{item.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item name="productCategoryId" label={lang.productCategory}
              rules={[
                {
                  required: true,
                  message: lang.requiredCategory,
                },
              ]}>
              <Select placeholder={lang.placeholderSelectProductCate} disabled={true}>
                {productCategorys?.map(category => <Option key={category.id} value={category.id}>{category.name}</Option>)}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} type="flex">
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item
              noStyle
              shouldUpdate
            >
              {({ getFieldValue }) => <Form.Item
                name="min"
                label={lang.minQuantity}
                rules={[
                  {
                    required: true,
                    message: lang.requiredMin,
                  },
                ]}
              >
                <InputNumber min={0} style={{ width: '100%' }} placeholder={lang.placeholderMin} max={getFieldValue('max') && getFieldValue('max') - 1} />
              </Form.Item>}
            </Form.Item>
          </Col>
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item
              noStyle
              shouldUpdate
            >
              {({ getFieldValue }) =>
                <Form.Item
                  name="max"
                  label={lang.maxQuantity}
                  rules={[
                    {
                      required: true,
                      message: lang.requiredMax,
                    },
                  ]}
                >
                  <InputNumber style={{ width: '100%' }} placeholder={lang.placeHolderMax} min={getFieldValue('min') + 1 || 1} />
                </Form.Item>
              }
            </Form.Item>
          </Col>
        </Row>
        {item && (
          <Row gutter={24} type="flex">
            <Col lg={{ span: 12 }} xs={{ span: 24 }}>
              <Form.Item
                name="status"
                label={lang.notification}
                valuePropName="checked"
              >
                <Switch checkedChildren="ENABLE" unCheckedChildren="DISABLE" />
              </Form.Item>
            </Col>
          </Row>
        )}
        <div
          className="ant-modal-footer"
          style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
        >
          <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
            <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
              <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
                {lang.btnCancel}
              </Button>
              {loading === false ? (
                <Button type="primary" htmlType="submit" style={{ fontWeight: 'bold' }}>
                  {lang.btnSave}
                </Button>
              ) : (
                <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                  {lang.loading}
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </Form>
    </Modal>
  );
};

ReplenishmentEditForm.propTypes = {
  item: PropTypes.object,
  onCallback: PropTypes.func,
  setIsEditModal: PropTypes.func,
  isEditModal: PropTypes.bool,
};

export default ReplenishmentEditForm;
