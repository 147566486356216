import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Card, Row, Col, DatePicker, Input, Form } from 'antd';

import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { saleorderList } from './sale-order.service';

import './style.less';
import { defaultPage, formatDate, formatDateFull, formatDatetime } from 'utils/constants';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { pickBy } from 'lodash';
import TableCustom from 'components/TableCustom/table';

const defaultSort = {
  sortDirection: 'desc',
  sortBy: 'createDate',
};

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const ReceiptList = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [items, setItems] = useState([]);

  //Pagination
  const [params, setParams] = useState({ ...defaultPage });
  const [sortedInfo] = useState(defaultSort);
  const [totalItems, setTotalItems] = useState();

  const { currentWarehouse } = useSelector((state) => state.auth);

  const [form] = Form.useForm();

  //

  useEffect(() => {
    setLoading(true);
    saleorderList({ ...params, ...sortedInfo })
      .then(result => {
        setItems(result.data);
        setTotalItems(result.totalItem);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  }, [params, sortedInfo, currentWarehouse]);

  const columns = [
    // {
    //   title: 'Seq',
    //   dataIndex: 'id',
    //   key: 'id',
    //   width: '6%',
    //   fixed: 'left',
    // },
    {
      title: lang.orderCode,
      key: 'receiptId',
      dataIndex: 'code',
    },
    {
      title: lang.vansCode,
      key: 'vansCode',
      dataIndex: 'boothCode',
    },
    {
      title: lang.userName,
      dataIndex: 'nameCustomer',
      key: 'nameCustomer',
    },
    {
      title: lang.createDate,
      dataIndex: 'createDate',
      key: 'createDate',
      render: (record) => moment(record, formatDateFull).format(formatDatetime),
    },
  ];

  const extraButton = [
    <Button
      key="btn-complete"
      type="primary"
      onClick={() => {
        navigate('create');
      }}
    >
      {lang.btnCreate}
      <PlusOutlined />
    </Button>,
  ];

  const routes = [
    {
      path: '',
      breadcrumbName: 'Home',
    },
    {
      path: '',
      breadcrumbName: lang.configuration,
    },
    {
      path: '/sale-order',
      breadcrumbName: lang.saleOrder,
    },
  ];

  return (
    <Layout className="layoutContent">
      <PageHeader
        ghost={false}
        title={lang.saleOrder}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        //onBack={onBack}
        className="customPageHeader"
      />
      <Layout.Content>
        <Card size="small" className="cardSearch">
          <Form form={form} className="customFormSearch" onFinish={(value) => {
            const cleanValue = pickBy(value, v => v !== undefined && v !== '');

            if (cleanValue?.date) {
              cleanValue.startDate = cleanValue.date[0].startOf('date').format(formatDateFull);
              cleanValue.endDate = cleanValue.date[1].endOf('date').format(formatDateFull);
              delete cleanValue.date;
            }
            setParams({ ...cleanValue, page: 1, limit: params.limit });
          }}>
            <Row gutter={16}>
              <Col xxl={{ span: 6 }} md={8} sm={12} xs={24}>
                <Form.Item label={lang.createDate} name="date" className="customItem">
                  <DatePicker.RangePicker
                    placeholder={[lang.from, lang.to]}
                    style={{ width: '100%' }}
                    format={formatDate}
                  />
                </Form.Item>
              </Col>
              <Col xxl={{ span: 4 }} sm={6} xs={12}>
              <Form.Item name="orderCode">
                  <Input placeholder={lang.keyword} allowClear={true} />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item>
                  <Button
                    type={'primary'}
                    ghost
                    icon={<SearchOutlined />}
                    htmlType="submit"
                  >{lang.btnSearch}</Button>
                </Form.Item>

              </Col>
            </Row>
          </Form>
        </Card>
        <TableCustom
          title={() => <Row>
            <Col span={12}><h3>{lang.orderList}</h3></Col>
          </Row>}
          rowKey="id"
          loading={loading}
          bordered
          columns={columns}
          dataSource={items}
          onChange={(pagination, filters, sorter) => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            if (pagination.pageSize !== params.limit) {
              params.page = 1;
            } else {
              params.page = pagination.current;
            }
            params.limit = pagination.pageSize;
            setParams({ ...params });
          }}
          onRow={(record) => {
            return {
              onClick: () => navigate(`${record.id}`),
              style: { cursor: 'pointer' },
            };
          }}
          pagination={{
            showSizeChanger: true,
            total: totalItems,
            pageSize: params.limit,
            current: params.current,
          }}
        />
      </Layout.Content>

    </Layout>
  );
};

export default ReceiptList;
