import React, { useState } from 'react';
import { Form, Input, Col, Row, Button, Select, InputNumber, DatePicker, Modal, message as Alert } from 'antd';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';

import ProductPrint from './product-print';

import './style.less';
import moment from 'moment-timezone';
import { productSplit } from './picking.service';
import { formatDate, formatDateFull } from 'utils/constants';

const { Option } = Select;

const ProductEditForm = ({ item, onCancel, onCallback, isOnlyQuantityAllowForUpdate = false }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [isPrintModal, setIsPrintModal] = useState(false);
  const [itemNew, setItemNew] = useState();

  return (
    <Form
      colon={false}
      form={form}
      requiredMark={false}
      layout="vertical"
      name="receiptProductEditForm"
      onFinish={async (values) => {
        try {
          setLoading(true);
          const result = await productSplit({ id: item.id, quantity: values.totalQuantity });
          setItemNew(Object.assign(result, { orderPicking: item.orderPicking }));
          setIsPrintModal(true);
          setLoading(false);
        } catch (error) {
          Alert.error(error.message);
          setLoading(false);
        }
      }}
      initialValues={{
        packageCode: item ? item?.packageCode : '',
        productCode: item ? item?.code : '',
        productName: item ? item?.masterProduct?.name : '',
        // totalQuantity: item ? item?.totalQuantity : undefined,
        supplier: item ? item?.supplier?.name : undefined,
        productCategory: item ? item?.masterProduct?.productCategory?.name : undefined,
        cost: item ? item?.masterProduct.purchasePrice : undefined,
        salePrice: item ? item?.masterProduct.salePrice : undefined,
        expirationDate: item ? moment(item?.expireDate, formatDateFull) : moment.now(),
      }}
    >
      <Row gutter={24} type="flex">
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item name="packageCode" label={lang.packageCode}>
            <Select placeholder={lang.placeholderSelectPackageCode} disabled={isOnlyQuantityAllowForUpdate}>
              <Option value="package1">Package 1</Option>
              <Option value="package2">Package 2</Option>
              <Option value="package3">Package 3</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item name="productCode" label={lang.productCode}>
            <Input placeholder={lang.messageGender} disabled={isOnlyQuantityAllowForUpdate} />
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item
            name="productName"
            label={lang.productName}
          >
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24} type="flex">
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item
            name="totalQuantity"
            label={lang.totalQuantity}
            rules={[
              {
                required: true,
                message: lang.messageInput,
              },
              {
                type: 'number',
                max: item?.totalQuantity - 1,
                message: `${lang.totalQuantity} <${item?.totalQuantity}`,
              },
              {
                type: 'number',
                min: 1,
                message: `${lang.totalQuantity} > 1`,
              },
            ]}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item name="supplier" label={lang.supplier}>
            <Input disabled />
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item name="productCategory" label={lang.productCategory}>
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24} type="flex">
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item
            name="cost"
            label={lang.cost}
          >
            <InputNumber disabled={isOnlyQuantityAllowForUpdate} style={{ width: '100%' }} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} />
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item name="salePrice" label={lang.salePrice}>
            <InputNumber disabled={isOnlyQuantityAllowForUpdate} style={{ width: '100%' }} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} />
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item name="expirationDate" label={lang.expireDate}>
            <DatePicker disabled={isOnlyQuantityAllowForUpdate} style={{ width: '100%' }} format={formatDate} />
          </Form.Item>
        </Col>
      </Row>
      <div
        className="ant-modal-footer"
        style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
      >
        <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
          <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
            <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
              {lang.btnCancel}
            </Button>
            {loading === false ? (
              <Button type="primary" htmlType="submit" style={{ fontWeight: 'bold' }}>
                {lang.btnSave}
              </Button>
            ) : (
              <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                {lang.loading}
              </Button>
            )}
          </Col>
        </Row>
      </div>
      <Modal
        visible={isPrintModal}
        title={lang.print}
        onCancel={() => {
          setIsPrintModal(false);
          onCallback();
        }}
        destroyOnClose={true}
        width={320}
        centered
        footer={null}
        wrapClassName="picking-products-print-modal"
      >
        <ProductPrint
          item={itemNew}
          onCallback={(value) => {
            setIsPrintModal(false);
            onCallback(itemNew);
          }}
          onCancel={() => {
            setIsPrintModal(false);
            onCallback(itemNew);
          }}
        />
      </Modal>
    </Form>
  );
};

ProductEditForm.propTypes = {
  item: PropTypes.object,
  onCancel: PropTypes.func,
  onCallback: PropTypes.func,
  isOnlyQuantityAllowForUpdate: PropTypes.bool,
};

export default ProductEditForm;
