import React, { useEffect, useState } from 'react';
import { Form, Input, Col, Row, Button, Select, message, Modal } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import { roleList, userCreate, userUpdate } from './user.service';

import './style.less';
import Helper from 'utils/helpers';

const { Option } = Select;

const UserEditForm = ({ item, warehouses, setIsEditModal, onCallback, isEditModal }) => {
  const [form] = Form.useForm();
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  useEffect(() => {
    roleList()
      .then(result => {
        setRoles(result);
      });
  }, []);
  useEffect(() => {
    return () => {
      form.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditModal]);

  const initValues = {
    username: item ? item?.username : '',
    password: item ? item?.password : undefined,
    firstName: item ? item?.firstName : '',
    lastName: item ? item?.lastName : '',
    realmRoles: item?.roles ? item?.roles[0]?.name : [],
    email: item ? item?.email : '',
    warehouseIds: item ? item?.warehouseIds : [],
  };
  const onCancel = () => {
    const valueForm = form.getFieldsValue();
    const isDefined = Object.values(valueForm).some(value => value !== undefined);
    const isUnChange = JSON.stringify(initValues) === JSON.stringify(valueForm);
    if (!isDefined || isUnChange) {
      setIsEditModal(false);
      return true;
    }
    Helper.conFirm(setIsEditModal);
  };

  return (
    <Modal
      visible={isEditModal}
      title={`${item ? `${lang.btnUpdate}` : `${lang.btnCreate}`} ${lang.user.toLowerCase()}`}
      onCancel={onCancel}
      width={980}
      centered
      footer={null}
      wrapClassName="user-modal"
      afterClose={() => {
        form.resetFields();
      }}
      forceRender={true}
    >
      <Form
        colon={false}
        form={form}
        layout="vertical"
        requiredMark={false}
        name="userEditForm"
        onFinish={values => {
          values.realmRoles = [roles.find(x => x.name === values.realmRoles)];
          if (!item) { // Create
            setLoading(true);
            userCreate(values)
              .then(result => {
                message.success(lang.createSuccessfully);
                setLoading(false);
                onCallback();
              })
              .catch((e) => {
                message.error(e.message);
                setLoading(false);
              });
          } else { // Update
            setLoading(true);
            values.realmRoles = [...values.realmRoles ,roles.find(x => x.name === "default-roles-akawms-dev")];
            values.id = item.id;
            userUpdate(values)
              .then(result => {
                message.success(lang.updateSuccessfully);
                onCallback();
                setLoading(false);
              })
              .catch((e) => {
                message.error(e.message);
                setLoading(false);
              });
          }
        }}
        initialValues={initValues}
      >
        <Row gutter={24} type="flex">
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item
              name="username"
              label={lang.username}
              rules={[
                {
                  required: true,
                  message: lang.requiredSupplierName,
                },
              ]}
            >
              <Input placeholder={lang.username} disabled={item} autoComplete="off" />
            </Form.Item>
          </Col>

          {!item && <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item
              name="password"
              label={lang.password}
              rules={[
                {
                  required: true,
                  message: lang.requiredPassword,
                },
                {
                  // pattern: new RegExp(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/g),
                  min: 8,
                  message: lang.passwordRegex,
                },
              ]}
            >
              <Input.Password
                placeholder={lang.password}
                iconRender={(visible) =>
                  visible ? (
                    <EyeOutlined style={{ color: 'black' }} />
                  ) : (
                    <EyeInvisibleOutlined style={{ color: 'black' }} />
                  )
                }
                autoComplete="new-password"
              />
            </Form.Item>
          </Col>
          }
        </Row>
        <Row gutter={24} type="flex">
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item
              name="firstName"
              label={lang.firstName}
              rules={[
                {
                  required: true,
                  message: lang.requiredName,
                },
              ]}
            >
              <Input placeholder={lang.name} />
            </Form.Item>
          </Col>
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item
              name="lastName"
              label={lang.lastName}
              rules={[
                {
                  required: true,
                  message: lang.requiredName,
                },
              ]}
            >
              <Input placeholder={lang.name} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} type="flex">
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item
              name="realmRoles"
              label={lang.role}
              rules={[
                {
                  required: true,
                  message: lang.requiredRole,
                }]}>
              <Select placeholder={lang.role}>
                <Option key={'admin'} value={'admin'}>Admin</Option>
                <Option key={'manager'} value={'manager'}>Manager</Option>
                <Option key={'supervisor'} value={'supervisor'}>Supervisor</Option>
                <Option key={'staff'} value={'staff'}>Staff</Option>
                <Option key={'kcu'} value={'kcu'}>Kcu</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item
              name="email"
              label={lang.email}
            >
              <Input placeholder={lang.email} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} type="flex">
          <Col lg={{ span: 24 }} xs={{ span: 24 }}>
            <Form.Item
              name="warehouseIds"
              label={lang.warehouse}
              rules={
                [
                  {
                    required: true,
                    message: lang.requiredWarehouse,
                  },
                ]}
            >
              <Select
                placeholder={lang.warehouse}
                mode="multiple"
              >
                {warehouses.filter(x => x.status !== 'DISABLE').map(item => (
                  <Option key={item.id} value={item.id}>{item.name} - {item.code}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <div
          className="ant-modal-footer"
          style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
        >
          <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
            <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
              <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
                {lang.btnCancel}
              </Button>
              {loading === false ? (
                <Button type="primary" htmlType="submit" style={{ fontWeight: 'bold' }}>
                  {lang.btnSave}
                </Button>
              ) : (
                <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                  {lang.loading}
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </Form>
    </Modal>
  );
};

UserEditForm.propTypes = {
  item: PropTypes.object,
  warehouses: PropTypes.arrayOf(PropTypes.object),
  setIsEditModal: PropTypes.func,
  onCallback: PropTypes.func,
  isEditModal: PropTypes.bool,
};

export default UserEditForm;
