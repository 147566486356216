import React, { useEffect, useState } from 'react';
import { Form, Input, Col, Row, Button, InputNumber, Select, message, Modal } from 'antd';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';

import './style.less';
import { warehouseCreate, warehouseUpdate } from './warehouse.service';
import Helper from 'utils/helpers';

const { Option } = Select;

const WarehouseEditForm = ({ item, setIsEditModal, onCallback, isEditModal }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);

  useEffect(() => {
    return () => {
      form.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditModal]);

  const initValues = {
    name: item ? item?.name : '',
    acreage: item ? item?.acreage : '',
    address: item ? item?.address : '',
    status: item ? item?.status : undefined,
  };
  const onCancel = () => {
    const valueForm = form.getFieldsValue();
    const isDefined = Object.values(valueForm).some(value => value !== undefined);
    const isUnChange = JSON.stringify(initValues) === JSON.stringify(valueForm);
    if (!isDefined || isUnChange) {
      setIsEditModal(false);
      return true;
    }
    Helper.conFirm(setIsEditModal);
  };
  return (
    <Modal
      visible={isEditModal}
      title={`${item ? `${lang.btnUpdate}` : `${lang.btnCreate}`} ${lang.warehouse.toLowerCase()}`}
      onCancel={onCancel}
      width={570}
      centered
      footer={null}
      wrapClassName="user-modal"
      afterClose={() => {
        form.resetFields();
      }}
      forceRender={true}
    >
      <Form
        colon={false}
        form={form}
        layout="vertical"
        requiredMark={false}
        name="warehouseEditForm"
        onFinish={values => {
          if (!item) { // Create
            setLoading(true);
            warehouseCreate(values)
              .then(result => {
                message.success(lang.createSuccessfully);
                setLoading(false);
                onCallback();
              })
              .catch((e) => {
                message.error(e.message);
                setLoading(false);
              });
          } else { // Update
            setLoading(true);
            values.id = item.id;
            warehouseUpdate(values)
              .then(result => {
                message.success(lang.updateSuccessfully);
                onCallback();
                setLoading(false);
              })
              .catch((e) => {
                message.error(e.message);
                setLoading(false);
              });
          }
        }}
        initialValues={initValues}
      >
        <Row gutter={24} type="flex">
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item
              name="name"
              label={lang.warehouseName}
              rules={[
                {
                  required: true,
                  message: lang.requiredWarehouse,
                },
              ]}
            >
              <Input placeholder={lang.warehouseName} />
            </Form.Item>
          </Col>

          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item
              name="acreage"
              label={lang.acreage}
            >
              <InputNumber style={{ width: '100%' }} placeholder={lang.acreage} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} type="flex">
          <Col lg={{ span: item ? 12 : 24 }} xs={{ span: 24 }}>
            <Form.Item
              name="address"
              label={lang.address}
            >
              <Input placeholder={lang.address} />
            </Form.Item>
          </Col>
          {item && <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item
              name="status"
              label={lang.status}
              rules={[
                {
                  required: true,
                }]}>
              <Select placeholder={lang.status}>
                <Option key={'ENABLE'} value={'ENABLE'}>ENABLE</Option>
                <Option key={'DISABLE'} value={'DISABLE'}>DISABLE</Option>
              </Select>
            </Form.Item>
          </Col>}
        </Row>
        <div
          className="ant-modal-footer"
          style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
        >
          <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
            <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
              <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
                {lang.btnCancel}
              </Button>
              {loading === false ? (
                <Button type="primary" htmlType="submit" style={{ fontWeight: 'bold' }}>
                  {lang.btnSave}
                </Button>
              ) : (
                <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                  {lang.loading}
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </Form>
    </Modal>
  );
};

WarehouseEditForm.propTypes = {
  item: PropTypes.object,
  setIsEditModal: PropTypes.func,
  onCallback: PropTypes.func,
  isEditModal: PropTypes.bool,
};

export default WarehouseEditForm;
