import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Card, Row, Col, DatePicker, Input, Tag, Form, Modal, message } from 'antd';

import { PlusOutlined, EditOutlined, SearchOutlined, InboxOutlined } from '@ant-design/icons';

import { importExel, productList } from './product.service';
import ProductEditForm from './product-edit.form';

import './style.less';
import { useSelector } from 'react-redux';
import { defaultPage, formatDate, formatDateFull, formatDatetime } from 'utils/constants';
import moment from 'moment-timezone';
import { pickBy } from 'lodash';
import TableCustom from 'components/TableCustom/table';
import Helper from 'utils/helpers';
import Dragger from 'antd/lib/upload/Dragger';

const defaultSort = {
  sortDirection: 'desc',
  sortBy: 'createDate',
};

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const ProductList = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [isEditModal, setIsEditModal] = useState(false);

  const [item, setItem] = useState();
  const [items, setItems] = useState([]);
  const [file, setFile] = useState();
  //Pagination
  const [params, setParams] = useState({ ...defaultPage });
  const [sortedInfo] = useState(defaultSort);
  const [totalItems, setTotalItems] = useState();
  const [loadingBtn, setLoadingBtn] = useState(false);
  const { currentWarehouse } = useSelector((state) => state.auth);
  const [uploadModal, setUploadModal] = useState(false);
  //
  const [form] = Form.useForm();
  const fetchProduct = (params, sortedInfo, warehouseId) => {
    setLoading(true);
    productList({ ...params, ...sortedInfo, warehouseId })
      .then(result => {
        setItems([...result.data]);
        setTotalItems(result.totalItem);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  };
  useEffect(() => {
    fetchProduct(params, sortedInfo, currentWarehouse.id);
  }, [params, sortedInfo, currentWarehouse]);

  const columns = [
    // {
    //   title: 'Seq',
    //   dataIndex: 'id',
    //   key: 'id',
    //   align: 'center',
    //   width: '8%',
    // },
    {
      title: lang.code,
      key: 'code',
      width: '12%',
      render: (record) => {
        return (
          <Button
            size="small"
            type="link"
            onClick={() => navigate(`${record.id}`)}>
            {record?.code}
          </Button>
        );
      },
    },
    {
      title: lang.productName,
      dataIndex: 'name',
      key: 'name',
      width: '15%',
    },
    {
      title: lang.productCategory,
      dataIndex: 'productCategory',
      key: 'productCategory',
      width: '18%',
      render: value => value?.name,
    },
    {
      title: lang.createDate,
      dataIndex: 'createDate',
      sortOrder: sortedInfo.columnKey === 'createDate' && sortedInfo.order,
      key: 'createDate',
      width: '10%',
      render: value => moment(value, formatDateFull).format(formatDatetime),
    },
    {
      title: lang.supplier,
      dataIndex: 'suppliers',
      key: 'suppliers',
      width: '25%',
      render: value => value?.map(sup => sup.name).toString(),
    },
    {
      title: lang.status,
      key: 'status',
      dataIndex: 'status',
      width: '10%',
      render: (record) => {
        return (
          <Tag>{lang[record]}</Tag>
        );
      },
    },
    {
      title: lang.action,
      align: 'center',
      width: '8%',
      fixed: 'right',
      render: (record) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            type="link"
            size="small"
            icon={<EditOutlined />}
            onClick={() => {
              const supplierDetail = items.find((item) => item.id === record.id);
              setItem(supplierDetail);
              setIsEditModal(true);
            }}
          />
        </div>
      ),
    },
  ];

  const extraButton = [
    <Button
      key="btn-export"
      type="primary"
      loading={loadingBtn}
      onClick={async () => {
        try {
          setLoadingBtn(true);
          const response = await productList({ warehouseId: currentWarehouse.id });
          const dataRemp = response?.data?.map(product => {
            delete product.replenishments;
            delete product.suppliers;

            const { id: idProductCategory, code: codeProductCategory, name: nameProductCategory } = product.productCategory;
            delete product.productCategory;
            delete product.parentProductCategory;
            return {
              ...product,
              idProductCategory,
              codeProductCategory,
              nameProductCategory
            }
          })
          Helper.dowloadXLSX(dataRemp, 'MasterProduct');
          setLoadingBtn(false);
        } catch (error) {
          setLoadingBtn(false);
          return false;
        }
      }}
    >
      {lang.exportCSV}
    </Button>,
    <Button
    key="btn-import"
    type="primary"
    loading={loadingBtn}
    onClick={() => setUploadModal(true)}
  >
    {lang.importCSV}
  </Button>,
    <Button
      key="btn-complete"
      type="primary"
      onClick={() => {
        setIsEditModal(true);
        setItem();
      }}
    >
      {lang.btnCreate}
      <PlusOutlined />
    </Button>,
  ];

  const routes = [
    {
      path: '',
      breadcrumbName: 'Home',
    },
    {
      path: '',
      breadcrumbName: lang.configuration,
    },
    {
      path: '/product',
      breadcrumbName: lang.product,
    },
  ];

  return (
    <Layout className="layoutContent">
      <PageHeader
        ghost={false}
        title={lang.product}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        //onBack={onBack}
        className="customPageHeader"
      />
      <Layout.Content>
        <Card size="small" className="cardSearch">
          <Form form={form} layout='horizontal' className="customFormSearch" onFinish={(value) => {
            const cleanValue = pickBy(value, v => v !== undefined && v !== '');

            if (cleanValue?.date) {
              cleanValue.startDate = cleanValue.date[0].startOf('date').format(formatDateFull);
              cleanValue.endDate = cleanValue.date[1].endOf('date').format(formatDateFull);
              delete cleanValue.date;
            }
            setParams({ ...cleanValue, page: 1, limit: params.limit });
          }}>
            <Row gutter={16}>
              <Col xxl={{ span: 6 }} md={8} sm={12} xs={24}>
                <Form.Item label={lang.createDate} name="date" className="customItem">
                  <DatePicker.RangePicker
                    placeholder={[lang.from, lang.to]}
                    format={formatDate}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col xxl={{ span: 4 }} sm={6} xs={12}>
                <Form.Item name="masterProductName">
                  <Input placeholder={lang.keyword} allowClear={true} />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item>
                  <Button
                    type={'primary'}
                    ghost
                    icon={<SearchOutlined />}
                    htmlType="submit"
                  >{lang.btnSearch}</Button>
                </Form.Item>

              </Col>
            </Row>
          </Form>
        </Card>
        <TableCustom
          title={() => <Row>
            <Col span={12}><h3>{lang.productList}</h3></Col>
          </Row>}
          rowKey="id"
          loading={loading}
          bordered
          columns={columns}
          dataSource={items}
          onChange={(pagination, filters, sorter) => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            if (pagination.pageSize !== params.limit) {
              params.page = 1;
            } else {
              params.page = pagination.current;
            }
            params.limit = pagination.pageSize;
            setParams({ ...params });
          }}
          pagination={{
            total: totalItems,
            showSizeChanger: true,
            pageSize: params.limit,
            current: params.page,
          }}
          scroll={{ x: 1200 }}
        />
      </Layout.Content>
      <ProductEditForm
        item={item}
        onCallback={(value) => {
          setParams({ ...defaultPage });
          setIsEditModal(false);
        }}
        isEditModal={isEditModal}
        setIsEditModal={setIsEditModal}
      />
      <Modal visible={uploadModal} onCancel={() => { setUploadModal(false); setFile(); }}
        bodyStyle={{ minHeight: 340 }} onOk={async () => {
          try {
            if (file) {
              const formData = new FormData();
              formData.append('excel', new Blob([file?.originFileObj], { type: file.type }));
              await importExel(currentWarehouse.id, formData);
              setFile();
            }
            setParams({ ...defaultPage });
            message.success(lang.importSuccess);
            setUploadModal(false);
          } catch (error) {
            message.error(error.message);
            setUploadModal(false);
            setFile();
          }
        }}
        okText={lang.okText} cancelText={lang.cancelText}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Button onClick={() => Helper.dowloadXLSX([{ 'Item Code': '1010101', 'Barcode': '1212', 'Tên Sản Phẩm': 'Hành lá VietGap 100gr', 'Mã Nhóm Hàng': 1, 'Nhóm Hàng': 'Rau VietGap',
            'Sức Chứa': 1, 'Thời Gian Lưu Kho': 100, 'Đơn Vị Tính': 'Goi', 'VAT': '2%', 'Giá Mua': 1000, 'Giá Bán Lẻ': 1500, 'Giá Bán Sỉ': 1600, 'Mã Nhà Cung Cấp': ' ', 'Tên Nhà Cung Cấp': 'CÔNG TY TNHH TRƯƠNG NGUYỄN',
            'Số Lượng Tối Đa': 2, 'Số Lượng Tối Thiểu': 1, 'Quy Cách Đóng Gói': ' ', 'Dài': 10, 'Rộng': 10, 'Cao': 10, 'Active': 'X' }], 'TemplateMasterProduct')}>{lang.dowloadTemplate}</Button>
          </Col>
          <Col span={24}>
            <Dragger onChange={(info) => {
              setFile(info.file);
            }}
              maxCount={1}
              // defaultFileList={file}
              showUploadList={true}
              customRequest={async (options) => {
                const { onSuccess } = options;
                onSuccess('OK');
              }}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                band files
              </p>
            </Dragger>
          </Col>
        </Row>
      </Modal>
    </Layout>
  );
};

export default ProductList;
