import React, { useState } from 'react';
import { Col, Row, Button, Card } from 'antd';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import './style.less';
import Helper from 'utils/helpers';

const PackagingPrint = ({ item, onCancel, onCallback, onEXportCSV }) => {
  const [loading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  return (
    <>
      <Row gutter={16}>
        <Col key={item.id} span={24}>
          <Card bodyStyle={{ padding: 0, textAlign: 'center' }} id="print">
            {Helper.genarateQRcode([item.code])}
            {Helper.genarateBarcode([item.code])}
            <div style={{ textAlign: 'center' }}>{`${lang.code}: ${item.code}`}</div>
          </Card>
        </Col>
      </Row>
      <div
        className="ant-modal-footer"
        style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
      >
        <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
          <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
            <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
              {lang.btnClose}
            </Button>
            {loading === false ? (
              <>
                <Button type="primary" onClick={onCallback} style={{ fontWeight: 'bold' }}>
                  {lang.print}
                </Button>
                <Button type="primary" onClick={()=>onEXportCSV([{ packageCode: item?.code }])} style={{ fontWeight: 'bold' }}>
                  {lang.exportCSV}
                </Button>
              </>
            ) : (
              <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                {lang.loading}
              </Button>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

PackagingPrint.propTypes = {
  item: PropTypes.object,
  onCancel: PropTypes.func,
  onCallback: PropTypes.func,
  onEXportCSV: PropTypes.func,
};

export default PackagingPrint;
