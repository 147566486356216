
import {
  PICKING_ORDER_CREATE,
  BOOKING_ORDER_SET,
  BOOKING_ORDER_SET_SUCCESS,
  BOOKING_ORDER_SET_FAIL,
  PICKING_LIST_SET,
  PICKING_LIST_SET_SUCCESS,
  PICKING_LIST_SET_FAIL,
  PACKAGING_ORDER_SET,

  //Scan
  SCAN_PICKING_FOR_PRODUCT_CODE_ADD,
  SCAN_PACKAGING_FOR_PRODUCT_CODE_ADD,
  SCAN_SHIPPING_FOR_PACKAGE_CODE_ADD,
  SCAN_SPLIT_PRODUCT,
} from './types';

export function pickingOrderCreate(payload = {}) {
  return { type: PICKING_ORDER_CREATE, payload };
}

export function packagingOrderSet(payload = {}) {
  return { type: PACKAGING_ORDER_SET, payload };
}

export function bookingOrderSet(payload = {}) {
  return { type: BOOKING_ORDER_SET, payload, meta: { thunk: true } };
}
export function bookingOrderSetSuccess(payload = {}, meta) {
  return { type: BOOKING_ORDER_SET_SUCCESS, payload, meta };
}
export function bookingOrderSetFail(payload = {}, meta) {
  return { type: BOOKING_ORDER_SET_FAIL, payload, meta };
}

export function pickingListSet(payload = {}) {
  return { type: PICKING_LIST_SET, payload, meta: { thunk: true } };
}
export function pickingListSetSuccess(payload = {}, meta) {
  return { type: PICKING_LIST_SET_SUCCESS, payload, meta };
}
export function pickingListSetFail(payload = {}, meta) {
  return { type: PICKING_LIST_SET_FAIL, payload, meta };
}

//Scan
export function scanPickingProductCodeAdd(payload = {}) {
  return { type: SCAN_PICKING_FOR_PRODUCT_CODE_ADD, payload };
}
export function scanPackagingProductCodeAdd(payload = {}) {
  return { type: SCAN_PACKAGING_FOR_PRODUCT_CODE_ADD, payload };
}
export function scanShippingPackageCodeAdd(payload = {}) {
  return { type: SCAN_SHIPPING_FOR_PACKAGE_CODE_ADD, payload };
}

export function scanSplitProduct(payload = {}) {
  return { type: SCAN_SPLIT_PRODUCT, payload };
}
