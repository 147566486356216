import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Row, Col, message as Alert } from 'antd';
import { LoginOutlined } from '@ant-design/icons';

import ScanProductForm from 'components/ScanProductForm/scan-product.form';

import './style.less';
import { productScan } from '../picking/picking.service';
import { useSelector } from 'react-redux';
import ProTableCusTom from 'components/TableCustom/Protable';

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const PackagingCreateScan = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const { scanPackagingForProductCodes, packagingOrder, pickingProducts } = useSelector((state) => state.outbound);
  const { currentWarehouse } = useSelector((state) => state.auth);
  const [selectedOrder, setSelectedOrder] = useState();
  const columnsPickingList = [
    {
      title: lang.defaultCode,
      key: 'code',
      width: '15%',
      dataIndex: 'masterProduct',
      render: value => value?.barCode,
    },
    {
      title: lang.productCode,
      key: 'code',
      width: '15%',
      dataIndex: 'code',
    },
    {
      title: lang.productCategory,
      dataIndex: 'masterProduct',
      key: 'productCategory',
      width: '15%',
      render: value => value?.productCategory?.name,
    },
    {
      title: lang.productName,
      key: 'name',
      width: '20%',
      render: record => record?.masterProduct?.name,
    },
    {
      title: lang.quantity,
      key: 'totalQuantity',
      dataIndex: 'totalQuantity',
      width: '15%',
    },
    {
      title: lang.block,
      key: 'block',
      dataIndex: 'block',
      width: '10%',
      render: value => value?.name,
    },
    {
      title: lang.shelf,
      key: 'shelf',
      dataIndex: 'rack',
      width: '10%',
      render: value => value?.shelf?.name,
    },
    {
      title: lang.rack,
      key: 'rack',
      dataIndex: 'rack',
      width: '10%',
      render: value => value?.code,
    },
  ];
  const columnsBookingOrders = [
    {
      title: lang.defaultCode,
      render: record => record?.masterProduct?.barCode,
      key: 'barCode',
      // width: '15%',
    },
    {
      title: lang.productCategory,
      key: 'productCategory',
      // width: '20%',
      render: record => record?.masterProduct?.productCategory?.name,
    },
    {
      title: lang.productName,
      key: 'name',
      // width: '20%',
      render: record => record?.masterProduct?.name,
    },
    {
      title: lang.orderQuantity,
      dataIndex: 'total',
      key: 'total',
      // width: '15%',
    },
  ];
  const extraButton = [
    <Button
      key="btn-cancle"
      type="primary"
      ghost
      onClick={() => {
        navigate('/dashboard/outbound/packaging/create');
      }}
    >
      {lang.btnCancel}
    </Button>,
    <Button
      key="btn-complete"
      type="primary"
      onClick={async () => {
        try {
          const errList = [];
          const result = await productScan({ barCodes: scanPackagingForProductCodes?.map(value => value.code), type: 'PACKING', warehouseId: currentWarehouse.id });
          if (result?.successList) {
            const productGroup = result?.successList?.filter(product => product.group === packagingOrder.group);
            const productTemp = scanPackagingForProductCodes.reduce((prev, curr) => {
              const productCode = productGroup.find(value => curr.code === value.masterProduct.barCode && curr.count === value.totalQuantity && !prev.finded?.some(find => find.code === value.code));
              if (productCode) {
                prev.finded = [...prev.finded, productCode]
              }
              else {
                const productCodesmall = productGroup.find(value => curr.code === value.masterProduct.barCode && curr.count < value.totalQuantity && !prev.finded?.some(find => find.code === value.code));
                if (productCodesmall) {
                  productCodesmall.split = curr.count
                  prev.split = [...prev.split, productCodesmall];
                }
                else{
                  const productgroupfill = productGroup?.filter(product=>curr?.code === product?.masterProduct?.barCode);
                  if(curr.count === productgroupfill?.reduce((prev,curr) =>prev+curr?.totalQuantity,0)){
                    prev.finded = [...prev.finded, ...productgroupfill]
                  }
                }
              }
              return prev;
            }, { finded: [], split: [] });
            result.successList = [...productTemp.split, ...productTemp.finded];
          }
          result.errList = errList;
          navigate('complete', { state: { ...result, selectedOrder } });
          // console.log(result);
          // console.log(scanPackagingForProductCodes)
        } catch (error) {
          Alert.error(error.message);
        }
      }}
      disabled={!(scanPackagingForProductCodes?.length > 0)}
    >
      {lang.btnSubmit}
      <LoginOutlined />
    </Button>,
  ];

  const routes = [
    {
      path: '',
      breadcrumbName: 'Home',
    },
    {
      path: '',
      breadcrumbName: lang.outbound,
    },
    {
      path: '/packaging',
      breadcrumbName: lang.packaging,
    },
    {
      path: '/create',
      breadcrumbName: lang.pickupList,
    },
    {
      path: '',
      breadcrumbName: lang.scanCode,
    },
  ];

  return (
    <Layout className="storing-scan">
      <PageHeader
        ghost={false}
        title={<div>
          {lang.scanCode}<br />
          <span style={{ fontSize: 15 }}>{`${lang.orderNumber}: `}<strong>{packagingOrder?.orders?.map(order => order.code).toString()}</strong></span>
        </div>}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      //onBack={onBack}
      />
      <Layout.Content style={{ margin: '12px 24px' }}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <ScanProductForm type={'PACKAGING'} pickingOrder={{ orders: packagingOrder?.orders, pickingProducts: pickingProducts }} setSelectedOrder={setSelectedOrder} selectedOrder={selectedOrder} />
          </Col>
          <Col span={24}>
            <ProTableCusTom
              headerTitle="Sản phẩm đơn hàng"
              rowKey="id"
              size="small"
              className="tableCustom"
              bordered
              columns={columnsBookingOrders}
              // loading={loading}
              params={{ selectedOrder }}
              request={async (params) => {
                const { selectedOrder } = params;
                return Promise.resolve({
                  data: selectedOrder?.productOrders?.map(product => { return { ...product, orderCode: selectedOrder?.code } })?.sort((a, b)=>a.masterProduct.id-b.masterProduct.id),
                  total: selectedOrder?.productOrders?.length,
                  success: true
                })
              }}
              search={false}
              options={{
                search: false,
                reload: false,
                density: false,
              }}
              pagination={{
                showTitle: false,
                showTotal: false,
                pageSize: 10,
              }}
              scroll={{ x: 800 }}
            />
          </Col>
          <Col span={24}>
            <ProTableCusTom
              headerTitle="Sản phẩm đã lấy"
              rowKey="id"
              size="small"
              className="tableCustom"
              bordered
              columns={columnsPickingList}
              // loading={loading}
              // params={{ selectedOrder }}
              request={async (params) => {
                return Promise.resolve({
                  data: pickingProducts,
                  total: pickingProducts.length,
                  success: true
                })
              }}
              search={false}
              options={{
                search: false,
                reload: false,
                density: false,
              }}
              pagination={{
                showTitle: false,
                showTotal: false,
                pageSize: 10,
              }}
              scroll={{ x: 800 }}
            />
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  );
};

export default PackagingCreateScan;
