import ProTable from '@ant-design/pro-table';
import React from 'react';
import PropTypes from 'prop-types';

const ProTableCusTom = ({
    columns,
    pagination,
    ...props
}) => {
    return (
            <ProTable
                // scroll={{ y: 550, x: 'auto' }}
                size="small"
                columns={columns}
                options={false}
                pagination={{
                    ...pagination,
                }}
                {...props}
            />
    );
};
ProTableCusTom.propTypes = {
    columns: PropTypes.array.isRequired,
    pagination: PropTypes.object,
};
export default ProTableCusTom;
