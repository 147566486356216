export default {
  login: 'ログイン',
  password: 'パスワード',
  slogan: 'ビジネスの成長を常に見守り、コンサルティングサポートを受け取る！',
  akaName: '最適化された倉庫管理システム',
  akaDescription: 'デジタル変革',
  placeholderPassword: 'パスワード',
  messagePassword: 'パスワードを入力してください！',
  placeholderUsername: 'ユーザー名',
  messageUsername: 'ユーザー名を入力してください',
  loading: '読み込み中',
  inbound: '入庫',
  receipt: 'PO',
  temporaryInbound: '一時入庫',
  storing: '保管中',
  inboundControl: '入庫管理',
  inventory: '在庫',
  warehouseState: '倉庫の状態',
  reallocation: '再配分',
  productManagement: '製品管理',
  outbound: '出庫',
  pickingOrder: '販売発注',
  packaging: '包装',
  shippingOutboundControl: '配送発注',
  configuration: '設定',
  supplier: 'サプライヤ',
  product: '製品',
  productCategory: '製品カテゴリー',
  location: '位置',
  replenishment: '補充',
  saleOrder: '販売発注',
  reporting: 'レポート',
  auditOrder: '監査発注',
  inventoryWarning: '在庫警告',
  performance: 'パフォーマンス',
  changePassword: 'パスワード変更',
  logout: 'ログアウト',
  confirm: '確認',
  confirmLogout: 'ログアウトしてよろしいですか？',
  warehouse: '倉庫',
  user: 'ユーザー',
  admin: '管理者',
  receiptId: '受領ID',
  receiptCode: '受領コード',
  inboundKind: '入庫種類',
  status: '状態',
  receiptDate: '受領日',
  receiveDate: '受付日',
  from: '自',
  to: '至',
  creator: '作成者',
  keyword: 'キーワード',
  search: '検索',
  receiptList: '受領一覧',
  doYouWantToDeleteTheseItems: 'これらのアイテムを削除してよろしいですか？',
  deleteReceiptsSuccessfully: '受領が正常に削除されました',
  receiptIdsHasBeenDeleted: '受領IDが削除されました：',
  productCode: '製品コード',
  productName: '製品名',
  expectedQuantity: '予想数量',
  cost: '購入価格',
  salePrice: '販売価格',
  expireDate: '有効期限',
  createReceipt: '受領を作成する',
  createReceiptSuccessfully: '受領が正常に作成されました',
  pleaseSelectAInboundKind: '入庫種類を選択してください',
  messageReceiptDate: '受領日を入力してください',
  messageReceiveDate: '受付日を入力してください',
  productAdded: '製品が追加されました',
  importCSV: 'Excel をインポート',
  pleaseSelecteAProduct: '製品を選択してください',
  messageExpectedQuantity: '予想数量を入力してください',
  messageCost: '購入価格を入力してください',
  totalQuantity: '合計数量',
  zone: 'ゾーン',
  block: 'ブロック',
  shelf: '棚',
  rack: 'ラック',
  confirmReceiptSuccessfully: '受領を確認しました',
  receiptDetail: '受領詳細',
  productList: '製品一覧',
  updateLocationSuccessfully: '位置が正常に更新されました',
  updateLocation: '更新位置',
  print: '印刷',
  messageTotalQuantity: '合計数量を入力してください',
  importDate: '導入日',
  report: 'レポート',
  scanReturnProduct: '返品製品をスキャンする',
  reportErrorProduct: '疵物を報告する',
  reportSuccessfully: '正常に報告されました',
  reportContent: '報告内容',
  messageReportContent: '報告内容を入力してください',
  btnSubmit: '提出',
  inputCode: 'コードを入力する',
  packageCode: 'パッケージコード',
  locationCode: '位置コード',
  completeScanReturnProductSuccessfully: '正常に製品を返品スキャンします',
  scanReturnProductComplete: '返品製品のスキャンが完了しました',
  scanMore: 'さらにスキャン',
  scan: 'スキャン',
  storingDate: '保管日',
  scanProduct: '製品をスキャンする',
  storingScanProduct: '製品の保管スキャン',
  completeScanStoringSuccessfully: '製品の保管スキャンが正常に完了しました',
  storingScanProductComplete: 'スキャンされた製品一覧',
  inboundControlDate: '入荷日',
  inboundControlScanProduct: '入荷製品のスキャン',
  completeInboundControlScanSuccessfully: '入荷製品のスキャンが正常に完了しました',
  inboundControlScanProductComplete: 'スキャンされた製品一覧',
  username: 'ユーザー名',
  name: '名前',
  role: '役割',
  users: 'ユーザー',
  requiredUsername: 'ユーザー名が必要です',
  requiredPassword: 'パスワードが必要です',
  requiredName: '名前が必要です',
  requiredRole: '役割が必要です',
  requiredWarehouse: '倉庫が必要です',
  warehouseCode: '倉庫コード',
  warehouseName: '倉庫名',
  address: '住所',
  warehouses: '倉庫',
  acreage: '面積',
  btnCancel: 'キャンセル',
  btnComplete: '完了',
  btnUpdate: '更新',
  btnCreate: '作成',
  btnSearch: '検索',
  btnAdd: '追加',
  btnEdit: '編集',
  btnConfirm: '確認',
  btnDelete: '削除',
  btnSave: '保存',
  confirmQuestion: '内容が変更されました。変更は破棄されます。続行しますか？',
  confirmContent: 'キャンセルすると、編集した情報は破棄されます',
  yes: 'はい',
  addZone: 'ゾーンを追加する',
  addShelf: '棚を追加する',
  addBlock: 'ブロックを追加する',
  zoneDetail: 'ゾーン明細',
  zoneCreate: 'ゾーンを作成する',
  zoneName: 'ゾーン名',
  zoneAreDeletedSuccessfully: 'ゾーンが正常に削除されました',
  blockAreDeletedSuccessfully: 'ブロックが正常に削除されました',
  shelfAreDeletedSuccessfully: '棚が正常に削除されました',
  areYouSureToDeleteThisZone: 'このゾーンを削除してよろしいですか？',
  areYouSureToDeleteThisBlock: 'このブロックを削除してよろしいですか？',
  areYouSureToDeleteThisShelf: 'この棚を削除してよろしいですか？',
  placeholderName: '名前を入力してください',
  placeholderSelectMethod: '配送方法を選択してください',
  requiredCategoryName: '製品カテゴリ名が必要です',
  requiredMethod: 'エクスポート方法が必要です',
  placeholderSelectProductCate: '製品カテゴリを選択してください',
  placeholderAddress: '住所を入力してください',
  placeholderPhoneNumber: '電話番号を入力してください',
  requiredCategory: '製品カテゴリが必要です',
  requiredSupplierName: 'サプライヤ名が必要です',
  requiredEmail: 'メールが必要です',
  requiredAddress: '住所が必要です',
  requiredPhoneNumber: '電話番号が必要です',
  placeholderSelectSupplier: 'サプライヤを選択してください',
  placeholderCapacity: '収容を入力してください',
  requiredImage: '画像が必要です',
  requiredProductName: '製品名が必要です',
  requiredSupplier: 'サプライヤが必要です',
  requiredCapacity: '収容が必要です',
  requiredStoreTime: '在庫の保管時間が必要です',
  placeholderMin: '最小数量を入力してください',
  placeHolderMax: '最大数量を入力してください',
  requiredMin: '最小数量が必要です',
  requiredMax: '最大数量が必要です',
  placeholderSelectProduct: '製品を選択してください',
  requiredProduct: '製品が必要です',
  requiredZoneName: 'ゾーン名が必要です',
  requiredBlockName: 'ブロック名が必要です',
  placeholderTotalshelf: '棚の合計数を入力してください',
  requiredTotalshelf: '棚の合計数が必要です',
  requiredShelfName: '棚名が必要です',
  placeholderTotalRack: 'ラックの合計数を入力してください',
  requiredTotalRack: 'ラックの合計数が必要です',
  placeholderLevelPercent: 'レベルパーセントを入力してください',
  requiredLevelPercent: 'レベルパーセントが必要です',
  placeholderQuantity: '数量を入力してください',
  requiredQuantity: '数量が必要です',
  capacity: '収容',
  success: '成功',
  blockDetail: 'ブロック明細',
  blockCreate: 'ブロックを作成する',
  blockName: 'ブロック名',
  totalShelf: '棚の合計',
  updateShelf: '棚を更新する',
  createShelf: '棚を作成する',
  update: '更新',
  complete: '完了',
  shelfName: '棚名',
  capacityRack: 'ラックの収容',
  levelPercent: 'レベルパーセント',
  medium: '中',
  high: '高',
  code: 'コード',
  createSuccessfully: '作成が成功しました',
  updateSuccessfully: '更新が成功しました',
  deleteSuccessfully: '削除が成功しました',
  idsHasBeenDeleted: 'IDが削除されました：',
  email: 'メール',
  orderCode: '発注コード',
  userName: 'ユーザー名',
  orderDate: '発注日',
  orderQuantity: '発注数量',
  pickingquantity: 'ピッキング数量',
  placeholderSelectOrderCode: '発注コードを選択',
  titlePicking: '発注とピッキング情報',
  suggestLocation: '推奨位置',
  quantity: '数量',
  createPicking: '"ピックアップ一覧を作成',
  labelBooking: '発注予約',
  placeholderSelectOrder: '発注を選択',
  okText: '同意',
  cancelText: 'キャンセル',
  titleModalBooking: '予約発注アイテムを選択',
  scanCode: 'コードをスキャン',
  confirmPickupList: 'ピックアップ一覧を確認',
  titleModalPslitProduct: '製品を分割',
  placeholderSelectPackageCode: 'パッケージコードを選択',
  btnDone: '完了',
  btnClose: '閉じる',
  titleModalSuccessSuggestLocation: '推奨場所が正常に印刷されました',
  pickupList: 'ピックアップ一覧',
  generatePackageCode: 'パッケージコードを生成する',
  orderNumber: '発注番号',
  productOrderCode: '製品発注コード',
  pickingList: 'ピッキング一覧',
  packagingCode: 'パッケージコード',
  tempOutLot: '一時的な出荷ロット',
  orderandpackagingInfo: '発注と包装情報',
  packagingDetail: 'パッケージ明細',
  orderandshippingInfo: '発注と出荷情報',
  confirmPackageList: 'パッケージ一覧を確認',
  outQuantity: '出荷数量',
  startDate: '開始日',
  endDate: '終了日',
  orderInfo: '発注情報',
  inStock: '在庫中',
  expirationDate: '賞味期限',
  storageExpirationDate: '保管期限日',
  exportCSV: 'Excel をエクスポート',
  agingStockAlert: '保管期限に警告',
  dotRestock: '次回の配送のために再補充してください',
  productDetail: '製品明細',
  total: '合計',
  inventorySummary: '在庫概要',
  inboundsummary: '入庫概要',
  productSummary: '発送概要',
  action: '行為',
  warehouseBlockDetail: '倉庫ブロック明細',
  requiredReportContent: 'レポート内容が必要です',
  editLocation: '位置編集',
  dotEditLocation: '位置を変更したい製品を選択してください',
  onlyEditLocation: '一度に編集できるのは1行のみです',
  pendingState: '保留中状態',
  inboundState: '入庫状態',
  lostState: '紛失状態',
  updateProduct: '更新製品',
  requiredZone: 'ゾーンが必要です',
  requiredBlock: 'ブロックが必要です',
  requiredShelf: '棚が必要です',
  requiredRack: 'ラックが必要です',
  requiredExpirationDate: '有効期限が必要です',
  requiredTotalQauntity: '総数量が必要です',
  requiredCost: '購入価格が必要です',
  requiredSalePrice: '販売価格が必要です',
  requiredStatus: '状態が必要です',
  requiredLostDate: '紛失日が必要です',
 
  totalRackbetween: '合計ラック（1〜100）',
  backtoProductList: '製品一覧に戻る',
  productInfo: '製品情報',
  storageTime: '在庫保管期間（日）',
  productImage: '製品画像',
  imageExtra: '最大5MBの画像をアップロードできます',
  upload: 'アップロード',
  methodExport: '製品エクスポート方法',
  createDate: '作成日',
  createProduct: '製品を作成する',
  productCategoryName: '製品カテゴリ名',
  productCategoryCode: '製品カテゴリコード',
  productCategoryList: '製品カテゴリ一覧',
  updateProductCategoty: '製品カテゴリを更新する',
  createProductCategoty: '製品カテゴリを作成する',
  minQuantity: '最小数量',
  maxQuantity: '最大数量',
  notification: '通知',
  onHand: '在庫"',
  toOrder: '発注する',
  replenishmentList: '補充一覧',
  createReplenishment: '補充を作成する',
  updateReplenishment: '補充を更新する',
  backtoSupplierList: 'サプライヤ一覧に戻る',
  supplierInfo: 'サプライヤ情報',
  phone: '電話番号',
  cooperationDate: '提携日',
  supplierName: 'サプライヤ名',
  requiredCooperationDate: '提携日が必要です',
  supplierCode: 'サプライヤコード',
  supplierList: 'サプライヤ一覧',
  createSupplier: 'サプライヤを作成する',
  updateSupplier: 'サプライヤを更新する',
  createSaleOrder: '販売発注を作成する',
  addProduct: '製品を追加する',
  editProduct: '製品を編集する',
  saleOrderDetail: '販売発注明細',
  orderList: '発注一覧',
  reportDate: 'レポート日',
  firstName: '名',
  lastName: '姓',
  lostDate: '紛失日',
  phoneLengthMessage: '電話番号は11文字を超えることはできません',
  phoneMessage: '電話番号は数字でなければなりません',
  full: '完全',
  empty: '空',
  Medium: '中',
  resetPassword: 'パスワードを回復する',
  passwordNew: '新しいパスワード',
  currentPassword: '現在のパスワード',
  passwordConfirm: 'パスワードを確認する',
  zoneNameExist: 'ゾーン名が既に存在します',
  blockNameExist: 'ブロック名が既に存在します',
  shelfNameExist: '棚名が既に存在します',
  subTitleInboundControl: '棚に置く前に商品の位置をスキャンしてください',
  subTitleOutboundCreatePickUpNotify: '予約発注アイテムを選択してください',
  subTitleStoringNotify: '移動する前に一時的な入庫QRコードをスキャンしてください',
  subTitleInboundTemporaryNotify: '移動する前に一時的な入庫QRコードをスキャンしてください',
  no: 'いいえ',
  passwordRegex: 'パスワードは少なくとも8文字必要があります',
  updateImageLess: '5MB以下の写真をアップロードしてください',
  creatorName: '作成者',
  invalidCode: '無効なコード',
  tryAgain: 'もう一度お試しください',
  orderStatus: '発注状況',
  packageStatus: 'パッケージの状態',
  noRecordsFound: 'レコードが見つかりませんでした！',
  emailRequirePlaceholder: 'メールアドレスを入力してください',
  messeageExpiration: '有効期限が必要です',
  unlockAccount: 'アカウントのロックが解除されました',
  January: '1月',
  February: '2月',
  March: '3月',
  April: '4月',
  May: '5月',
  June: '6月',
  July: '7月',
  August: '8月',
  September: '9月',
  October: '10月',
  November: '11月',
  December: '12月',
  splitproduct: '製品を分割する',
  splitProductList: '製品分割一覧',
  vans: 'バン',
  addVans: 'バンを追加する',
  createVans: 'バンを作成する',
  updateVans: 'バンを更新する',
  vansName: 'バン名',
  vansLicensePlate: 'ナンバープレート',
  placeholderLicensePlate: 'ナンバープレートを入力してください',
  requiredLicensePlate: 'ナンバープレートが必要です',
  requiredVansName: 'バン名が必要です',
  vansState: 'バンの状態',
  vansDetail: 'バンの明細',
  defaultCode: 'バーコード',
  requiredDefaultCode: '既定コードが必要です',
  placeHolderDefaultCode: '既定コードを入力してください',
  placeholderSalePrice: '販売価格を入力してください',
  retailPrice: '小売価格',
  placeholderRetailPrice: '販売価格を入力してください',
  lot: 'ロット',
  requiredLot: 'ロットが必要です',
  VAT: '消費税',
  Divison: '部門',
  Category: 'カテゴリ',
  divisonCode: '部門コード',
  divisonName: '部門名',
  createDivison: '部門を作成する',
  updateDivison: '部門を更新する',
  placeholderDivisonName: '部門名を入力してください',
  requiredDivisonName: '部門名が必要です',
  categoryCode: 'カテゴリーコード',
  categoryName: 'カテゴリー名',
  createCategory: 'カテゴリーを作成する',
  updateCategory: 'カテゴリーを更新する',
  placeholderCategoryName: 'カテゴリー名を入力してください',
  requiredParentCategoryName: 'カテゴリー名が必要です',
  requiredDivison: '部門が必要です',
  requiredParentCategory: 'カテゴリーが必要です',
  categoryList: 'カテゴリー一覧',
  divisonList: '部門一覧',
  DVT: '単位',
  isActive: '活性',
  contractNumber: '契約番号',
  packingRule: '梱包ルール',
  NEW: '新しい',
  ERROR: 'エラー',
  TEMPORARY: '一時的',
  STORED: '保管済み',
  MOVING: '移動中',
  TEMPORARY_OUT: '一時的な外出',
  LOST: '紛失',
  REALLOCATE: '再割り当て',
  ENABLE: '有効',
  DISABLE: '無効',
  requestReceipt: '領収書をリクエストする',
  note: 'メモ',
  itemCode: '製品コード',
  length: '長さ',
  width: '幅',
  height: '高さ',
  RECEIVE: '受け取る',
  dowloadTemplate: 'テンプレートをダウンロード',
  importSuccess: 'インポートが成功しました',
  exportPDF: 'PDFをエクスポート',
  discount: '割引',
  isResources: 'リソース',
  vansCode: 'バンコード',
  createPO: '発注書を作成する',
  taxCode: '税コード',
  requiredPackingRule: '梱包ルールが必要です',
  CANCELLED: 'キャンセルされました',
  PICKING: 'ピッキング中',
  PACKAGED: '"梱包済み',
  deliveryTime: '配達時間',
  messeagePicking: '他のユーザーがピッキングしています',
  weAre: '私たちは',
  fptCloudWms: 'FPT CloudWMS',
  fptCloudWmsDescription: 'は、最適化された倉庫管理ソリューションであり、柔軟なシステムを統合することで、さまざまなプロセスと技術に関する様々な利点を提供し、デジタル変革をサポートすることを目指しています。このソリューションは、従来の倉庫管理や店舗管理方法を改善し、倉庫業務の効率と専門知識を向上させます。',
  totalSaleOrderItem: '発注総数',
  finishedGoods: '製品',
  rawMaterials: '原料',
};
