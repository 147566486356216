import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Descriptions, Card, Row, Col, Modal, message, Form } from 'antd';

import { TableOutlined, EditOutlined, CheckOutlined } from '@ant-design/icons';
import { productUpdateLocation } from '../product.service';
import { receiptDetail, receiptConfirm } from './receipt.service';
import { productUpdate, zoneList } from 'pages/dashboard/inventory/product-management/product-management.service';
import { ProFormSelect } from '@ant-design/pro-form';
import jsPdf from "jspdf";
import moment from 'moment';
import { formatDate, formatDateFull, formatDatetime } from 'utils/constants';
import html2canvas from "html2canvas";
import ReceiptDetailProductEditForm from './receipt-detail-product-edit.form';
import ReceiptProductsPrint from './receipt-products-print';

import './style.less';
import TableCustom from 'components/TableCustom/table';
import Helper from 'utils/helpers';
import numeral from 'numeral';
//const tableScrollHeightArea = window.innerHeight - 360; // px

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const ReceiptDetail = () => {
  const { receiptId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [isEditModal, setIsEditModal] = useState(false);
  const { currentWarehouse } = useSelector((state) => state.auth);
  const [form] = Form.useForm();
  const [isPrintModal, setIsPrintModal] = useState(false);
  const [printProducts, setPrintProducts] = useState([]);
  const [item, setItem] = useState();
  const [items, setItems] = useState([]);
  const [receipt, setReceipt] = useState();
  const [loadingExport, setLoadingExport] = useState(false);

  useEffect(() => {
    setLoading(true);
    receiptDetail({ receiptId: parseInt(receiptId) })
      .then(result => {
        setReceipt(result);
        setItems(result?.products);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  }, [receiptId]);

  const columns = [
    {
      title: lang.defaultCode,
      key: 'barcode',
      fixed: 'left',
      render: (record) => {
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        return <Button
          type="link"
          size="small"
          onClick={() => {
            setItem(record);
            setIsEditModal(true);
          }}>{record?.masterProduct?.barCode}
        </Button>;
      },
      width: '10%',
    },
    {
      title: lang.productCode,
      key: 'code',
      width: '10%',
      dataIndex: 'code',
    },
    {
      title: lang.productCategory,
      dataIndex: 'masterProduct',
      key: 'productCategory',
      width: '10%',
      render: (value) => value?.productCategory?.name,
    },
    {
      title: lang.productName,
      dataIndex: 'masterProduct',
      key: 'productName',
      width: '12%',
      render: (value) => value?.name,
    },
    {
      title: lang.expectedQuantity,
      key: 'expectedQuantity',
      dataIndex: 'expectedQuantity',
      width: '6%',
    },
    {
      title: lang.totalQuantity,
      key: 'totalQuantity',
      dataIndex: 'totalQuantity',
      width: '6%',
    },
    {
      title: lang.DVT,
      key: 'DVT',
      dataIndex: 'masterProduct',
      width: '6%',
      render: (value) => value?.DVT,
    },
    {
      title: lang.zone,
      key: 'zone',
      dataIndex: 'zone',
      width: '6%',
    },
    {
      title: lang.block,
      key: 'block',
      dataIndex: 'block',
      width: '6%',
      render: value => value?.name,
    },
    {
      title: lang.shelf,
      key: 'shelf',
      dataIndex: 'rack',
      width: '6%',
      render: value => value?.shelf?.name,
    },
    {
      title: lang.rack,
      key: 'rack',
      dataIndex: 'rack',
      width: '6%',
      render: value => value?.code,
    },
    {
      title: lang.supplier,
      key: 'supplier',
      dataIndex: 'supplier',
      width: '18%',
      render: (value) => value?.name,
    },
    {
      title: lang.cost,
      dataIndex: 'masterProduct',
      key: 'cost',
      width: '8%',
      render: value => value?.purchasePrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    },
    {
      title: lang.salePrice,
      dataIndex: 'masterProduct',
      key: 'salePrice',
      width: '8%',
      render: value => value?.salePrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    },
    {
      title: lang.retailPrice,
      dataIndex: 'masterProduct',
      key: 'retailPrice',
      width: '8%',
      render: value => value?.retailPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    },
    {
      title: lang.expireDate,
      dataIndex: 'expireDate',
      key: 'expireDate',
      width: '8%',
      render: value => value ? moment(value, formatDateFull).format(formatDatetime) : undefined,
    },
    {
      title: lang.action,
      align: 'center',
      width: '8%',
      fixed: 'right',
      render: (record) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            type="link"
            size="small"
            icon={<EditOutlined />}
            onClick={() => {
              setItem(record);
              setIsEditModal(true);
            }}
          />
        </div>
      ),
    },
  ];

  const extraButton = [
    <Button
      key="btn-cancel"
      type="primary"
      ghost
      onClick={() => {
        navigate('/dashboard/inbound/receipt');
      }}
    >
      {lang.btnCancel}
    </Button>,
    <Button
      key="btn-export"
      type="primary"
      ghost
      loading={loadingExport}
      onClick={async () => {
        setLoadingExport(true);
        const supplier = receipt?.products?.reduce((prev, curr) => {
          if (curr?.supplier === null || prev?.find(prev => prev?.id === curr?.supplier?.id)) {
            return prev;
          }
          return [...prev, curr.supplier];
        }, []);
        const content = `
        <div style="display: grid ;line-height: 0.5;width: 950px; font-size: 16px; font-family: "Times New Roman", Times, serif;">
          <div style="display: flex; height: 120px;">
            <div style="text-align:center; width: 20%; border-top: 2px solid black; border-bottom: 2px solid black; border-left: 2px solid black; border-right: none; border-image: initial; padding: 10px">
              <img src="${window.location.origin}/static/media/PinNow-07.0abe70b8.png" style=" height: 100%; width: 100%" />
            </div>
            <div style="width: 60%; text-align: center; border: 2px solid black; padding-top: 7vh; ">
              <h2>ĐƠN ĐẶT HÀNG</h2>
            </div>
            <div style="width: 20%; border: 2px solid black; display: grid; border-left: none; border-top: 2px solid black; border-bottom: 2px solid black; border-right: 2px solid black; border-image: initial;">
              <div style=" padding: 20px 0; ">
                <h4>Số PO:</h4>
                <p>${receipt.code}</p>
              </div>
              <div style=" border-top: 2px solid black; padding: 15px 0; ">
                <p>Ngày giao: ${moment(receipt.receiptDate, formatDateFull).format(formatDate)} </p>
              </div>
            </div>
          </div>
          <div style=" margin-top: 20px; border: 2px solid black; padding: 10px 0; ">
            <p>
              <b>Công Ty Cổ Phần Pinnow Việt Nam</b>
            </p>
            <p>
              <b>Địa chỉ : 134 Bạch Đằng,P2,Q Tân Bình,TPHCM</b>
            </p>
            <p>
              <b>Mã số thuế : 0316954732</b>
            </p>
            <p>
              <b>Điện thoại : 028 3740 2222</b>
            </p>
            <br />
            <p>
              <b>Thông tin giao hàng :</b>
            </p>
            <h2>CÔNG TY CỔ PHẦN PINNOW VIỆT NAM</h2>
            <p>
              <b>Địa chỉ giao hàng: </b>Lô 1-5 Khu C1 & Lô 3-5 Khu B1, KCN Tân Thới
              Hiệp, Phường Hiệp Thành,Quận 12, TPHCM
            </p>
          </div>
          <div style=" margin-top: 20px; border:2px solid black; padding: 10px 0; line-height: 1; ">
            <div style=" display: flex; ">
              <div style=" width: 80%;">
                <p>
                  <b>Tên nhà cung cấp: ${supplier?.map(supp => supp?.name)?.join()}</b>
                </p>
              </div>
              <div style=" width: 30%; ">
                <p>
                  <b>Mã số thuế: ${supplier?.map(supp => supp?.taxCode)?.join()}</b>
                </p>
              </div>
            </div>
            <div style=" width: 100%; ">
              <p>
                <b>Địa chỉ nhà cung cấp: ${supplier?.map(supp => supp?.address)?.join()}</b>
              </p>
            </div>
          </div>
          <div style=" margin-top: 50px; ">
            Chúng tôi xác nhận đặt hàng theo chi tiết sau:
          </div>
          <br />
          <div>
            <table border="1" style="width: 100%; border-collapse: collapse; line-height: 1;">
              <thead>
                <tr style=" background-color: yellow; ">
                  <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">STT</div></th>
                  <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">Mã hàng</div></th>
                  <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">Tên hàng hóa</div></th>
                  <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">ĐVT</div></th>
                  <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">Số lượng</div></th>
                  <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">Đơn giá chưa bao gồm VAT</div></th>
                  <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">Chiết khấu</div></th>
                  <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">VAT</div></th>
                  <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">Thành Tiền bao gồm VAT</div></th>
                </tr>
              </thead>
              <tbody>
                ${receipt?.products?.map((product, index) => {
          return `<tr style="text-align: center;;">
                      <td><div style="min-height: 30px; display: flex; align-items: center;">${index + 1}</div></td>
                      <td><div style="min-height: 30px; display: flex; align-items: center;">${product.masterProduct.barCode}</div></td>
                      <td><div style="min-height: 30px; display: flex; align-items: center;">${product.masterProduct.name}</div></td>
                      <td><div style="min-height: 30px; display: flex; align-items: center;">${product.masterProduct.DVT}</div></td>
                      <td><div style="min-height: 30px; display: flex; align-items: center;">${product.totalQuantity}</div></td>
                      <td><div style="min-height: 30px; display: flex; align-items: center;">${numeral(product.masterProduct.purchasePrice).format('0,0')}</div></td>
                      <td><div style="min-height: 30px; display: flex; align-items: center;">${product.masterProduct?.discount || ''}</div></td>
                      <td><div style="min-height: 30px; display: flex; align-items: center;">${product.masterProduct?.VAT || ''}</div></td>
                      <td><div style="min-height: 30px; display: flex; align-items: center;">${numeral((product.totalQuantity * product.masterProduct.purchasePrice * (product.masterProduct?.discount ? 100 - product.masterProduct?.discount : 100) / 100) * (product.masterProduct.VAT ? 100 + product.masterProduct.VAT : 100) / 100).format('0,0')}</div></td>
                  </tr>`
        }).join(' \n')}
                <tr">
                  <td colSpan="7">
                    <b style="min-height: 30px; display: flex; align-items: center;">Tổng giá trị đơn hàng bao gồm VAT</b>
                  </td>
                  <td style=" text-align: center; font-weight: bold; " colSpan="2">${numeral(receipt.products.reduce((prev, cur) => prev += (cur.totalQuantity * cur.masterProduct.purchasePrice * (cur.masterProduct?.discount ? 100 - cur.masterProduct?.discount : 100) / 100) * (cur.masterProduct.VAT ? 100 + cur.masterProduct.VAT : 100) / 100, 0)).format('0,0')}</td>
                </tr>
              </tbody>
            </table>
            <br />
            <div>
              <p>
                <b>Số tiền bằng chữ:</b>
              </p>
            </div>
          </div>
          <div style=" line-height: 1; ">
            <p>
              <b>Ghi chú</b>
            </p>
            <p>
              1/ Giá trị đơn hàng đã bao gồm thuế giá trị gia tăng. Giá trị đơn hàng
              là cố định và không thay đổi trong suốt thời gian hiệu lực của đơn đặt
              hàng.
            </p>
            <p>
              2/ Vui lòng kiểm tra thông tin chi tiết đơn hàng, nếu có khác biệt
              Pinnow có quyền từ chối nhận hàng
            </p>
            <p>
              3/ Vui lòng xác nhận đơn hàng này bằng e-mail theo thời gian đã thống
              nhất như hợp đồng.
            </p>
            <p>
              4/ Vui lòng mang theo đơn đặt hàng, phiếu giao hàng ( 3 liên, NCC giữ
              1 liên, Pinnow giữ 2 liên ) khi giao hàng
            </p>
            <p>
              5/Pinnow sẽ từ chối nhận hàng nếu thiếu 1 trong các chứng từ mục 4 .
            </p>
          </div>
          <table border="1" style=" width: 100%; border-collapse: collapse; ">
            <thead style=" height: 30px; ">
              <tr>
                <th width="20%">Xác nhận từ NCC</th>
                <th width="60%">Phê Duyệt</th>
                <th width="20%">Người đặt hàng</th>
              </tr>
            </thead>
            <tbody>
              <tr style=" height: 100px; ">
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr style=" height: 30px; ">
                <td>Ngày ../../....</td>
                <td>Ngày ../../....</td>
                <td>Ngày ../../....</td>
              </tr>
            </tbody>
          </table>
        </div>`;
        var template = document.createElement('div');
        template.innerHTML = content;
        document.body.appendChild(template);
        await html2canvas(template, { width: 1100, scale: 3, }).then((canvas) => {
          let imgWidth = 208;
          let imgHeight = canvas.height * imgWidth / canvas.width;
          var imgData = canvas.toDataURL("image/png");
          const pdf = new jsPdf("p", "mm", "a4");
          pdf.addImage(imgData, "PNG", 15, 10, imgWidth, imgHeight);
          pdf.save(`Receipt${moment().format('DDMMYYYY')}.pdf`);
        });
        document.body.removeChild(template);
        setLoadingExport(false);
      }}
    >
      {lang.exportPDF}
    </Button>,
    <Button
      key="btn-complete"
      type="primary"
      disabled={receipt?.status !== 'NEW'}
      onClick={async () => {
        try {
          const zoneId = await form.validateFields().then(values => values.zone)
            .catch(() => { throw new Error(undefined); });
          receiptConfirm({
            id: parseInt(receiptId),
            zoneId,
            products: items.map(item => {
              return {
                id: item.id,
                quantity: item.totalQuantity,
              };
            }),
          })
            .then(result => {
              Modal.success({
                title: lang.confirmReceiptSuccessfully,
                content: `${lang.receiptId}: ${result?.code}`,
                icon: <CheckOutlined />,
                onOk() {
                  setPrintProducts(result?.products?.map(product => {
                    product.receiptDate = result.receiptDate;
                    return product;
                  }));
                  setIsPrintModal(true);
                },
              });
            })
            .catch((e) => { throw new Error(e.message); });
          // setPrintProducts([{
          //   code:'45456456465',
          //   masterProduct: {name: 'sdfsdflhjsdkdsf'},
          //   receiptDate: '2022-03-16T04:44:29.951Z'
          // },
          // {
          //   code:'45435432',
          //   masterProduct: {name: 'ca chua bni'},
          //   receiptDate: '2022-03-16T04:44:29.951Z'
          // }]);
          // setIsPrintModal(true);
        } catch (error) {
          if (error?.message) {
            message.error(error.message);
          }
        }
      }}
    >
      {lang.btnConfirm}
      <CheckOutlined />
    </Button>,
  ];

  const routes = [
    {
      path: '/dashboard/inbound',
      breadcrumbName: lang.inbound,
    },
    {
      path: '/dashboard/inbound/receipt',
      breadcrumbName: lang.receipt,
    },
    {
      path: '',
      breadcrumbName: lang.receiptDetail,
    },
  ];

  return (
    <Layout className="receipt-detail layoutContent">
      <PageHeader
        ghost={false}
        title={lang.receiptDetail}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      />
      <Layout.Content>
        <Card size="small" className="cardSearch">
          <Descriptions className="descriptions-class" column={4}>
            <Descriptions.Item label={lang.receiptId}>{receipt?.id}</Descriptions.Item>
            <Descriptions.Item label={lang.inboundKind}>{receipt?.inboundkind}</Descriptions.Item>
            <Descriptions.Item label={lang.vansCode}>{receipt?.boothCode}</Descriptions.Item>
            <Descriptions.Item label={lang.receiveDate}>{moment(receipt?.receiptDate, formatDateFull).format(formatDate)}</Descriptions.Item>
            <Descriptions.Item label={lang.status}>{receipt?.status}</Descriptions.Item>
            <Descriptions.Item label={lang.creatorName}>{receipt?.creatorName}</Descriptions.Item>
            <Descriptions.Item >
              {
                <Form form={form}>
                  <ProFormSelect style={{ fontWeight: 600 }}
                    name="zone" label={lang.zone} rules={[
                      {
                        required: true,
                        message: lang.requiredZone,
                      },
                    ]}
                    placeholder={lang.zone}
                    params={{ warehouseId: currentWarehouse.id }}
                    request={async (params) => {
                      const response = await zoneList(params);
                      return response?.data?.map(zone => {
                        return {
                          value: zone.id,
                          label: zone.name,
                        };
                      });
                    }}
                  />
                </Form>
              }
            </Descriptions.Item>
          </Descriptions>
        </Card>
        <TableCustom
          title={() => <Row>
            <Col span={12}><h3>{lang.productList}</h3></Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <Button
                type="primary"
                onClick={() => {
                  const productIds = items.map(item => item.id);
                  productUpdateLocation({ productIds })
                    .then(result => {
                      Modal.success({
                        title: lang.updateLocationSuccessfully,
                        icon: <CheckOutlined />,
                        onOk() {
                          setLoading(true);

                          receiptDetail({ receiptId: parseInt(receiptId) })
                            .then(result => {
                              setItems(result?.products.map(product => {
                                if (items.map(item => item.id).includes(product.id)) {
                                  const find = items.find(x => x.id === product.id);
                                  return {
                                    ...product,
                                    totalQuantity: find?.totalQuantity,
                                  };
                                } else {
                                  return product;
                                }
                              }));
                              setLoading(false);
                            })
                            .catch((e) => setLoading(false));
                        },
                      });
                    })
                    .catch((e) => message.error(e.message));
                }}
              >
                {lang.updateLocation}
                <TableOutlined />
              </Button>
            </Col>
          </Row>}
          rowKey="id"
          loading={loading}
          bordered
          columns={columns}
          dataSource={items}
          pagination={false}
          scroll={{ x: 2500 }}
        />
      </Layout.Content>
      <Modal
        visible={isEditModal}
        title={`${item ? lang.btnEdit : lang.btnAdd} ${lang.product}`}
        onCancel={() => setIsEditModal(false)}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
        wrapClassName="receipt-product-edit-modal"
      >
        <ReceiptDetailProductEditForm
          item={item}
          onCallback={async (value) => {
            try {
              await productUpdate({ id: value.id, totalQuantity: value.totalQuantity, expireDate: value.expireDate, note: value.note })
              setLoading(true);
              receiptDetail({ receiptId: parseInt(receiptId) })
                .then(result => {
                  setReceipt(result);
                  setItems(result?.products);
                  setLoading(false);
                })
                .catch((e) => setLoading(false));
              setIsEditModal(false);
            } catch (error) {
              message.error(error.message)
            }

          }}
          onCancel={() => setIsEditModal(false)}
        />
      </Modal>
      <Modal
        visible={isPrintModal}
        title={lang.print}
        onCancel={() => setIsPrintModal(false)}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
      >
        <ReceiptProductsPrint
          items={printProducts}
          onCallback={(values) => {
            Helper.dowloadXLSX(values?.map(item => {
              return {
                code: item.code,
                name: item.masterProduct.name,
                receiptDate: moment(item?.receiptDate, formatDateFull).format(formatDate),
              }
            }), 'productCode');
            setIsPrintModal(false);
            navigate('/dashboard/inbound/receipt');
          }}
          onPrint={()=>{
            var content = document.getElementById("print");
            var myWindow = window.open('', '');
            myWindow.document.write(content.innerHTML);
            myWindow.document.close(); //missing code
            myWindow.focus();
            myWindow.print();
            setIsPrintModal(false);
            navigate('/dashboard/inbound/receipt');
          }}
          onCancel={() => {
            setIsPrintModal(false);
            navigate('/dashboard/inbound/receipt');
          }}
        />
      </Modal>
    </Layout>
  );
};

export default ReceiptDetail;
