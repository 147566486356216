import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Modal, Card, Row, Col, Select, Input, Tag, Form, message } from 'antd';

import { ScanOutlined, SearchOutlined } from '@ant-design/icons';

import PackagingDetailForm from './packaging-detail.form';
import { getPackageStatus } from './shipping.service';

import './style.less';
import { bookingList } from '../picking/picking.service';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import _get from 'lodash/get';
import { defaultPage, formatDateFull, formatDatetime } from 'utils/constants';
import { pickBy } from 'lodash';
import TableCustom from 'components/TableCustom/table';

const defaultSort = {
  sortDirection: 'desc',
  sortBy: 'createDate',
};

const { Option } = Select;

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const ShippingList = () => {
  const navigate = useNavigate();

  //Roles
  const { user, currentWarehouse } = useSelector((state) => state.auth);
  const isStaff = _get(user, 'roles', '').includes('staff');

  const [loading, setLoading] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);

  const [item, setItem] = useState();
  const [items, setItems] = useState([]);
  const [itemsDetail, setItemsDetail] = useState([]);
  const [form] = Form.useForm();
  const [isPackagingDetailModal, setIsPackagingDetailModal] = useState(false);

  //Pagination
  const [params, setParams] = useState({ status: 'READY', ...defaultPage });
  const [sortedInfo] = useState(defaultSort);
  const [pageSizeDetail, SetPageSizeDetail] = useState(10);
  const [currentPageDetail, setCurrentPageDetail] = useState(1);
  // const [searchCode, setSearchCode] = useState([]);
  const [orderCode, setOrderCode] = useState();
  const [totalItems, setTotalItems] = useState();

  const fetchDetail = async (id) => {
    try {
      setLoadingDetail(true);
      const pkgrs = await getPackageStatus({ orderId: id });
      setItemsDetail(pkgrs?.data);
      setLoadingDetail(false);
    } catch (error) {
      message.error(error.message);
      setLoadingDetail(false);
    }
  };
  // useEffect(() => {
  //   setLoading(true);
  //   bookingList({ warehouseId: currentWarehouse.id, status: 'PICKING' })
  //     .then(result => {
  //       setSearchCode(result.data);
  //       setLoading(false);
  //     })
  //     .catch((e) => setLoading(false));
  // }, [currentWarehouse]);
  useEffect(() => {
    setLoading(true);
    bookingList({ ...params, ...sortedInfo, warehouseId: currentWarehouse.id })
      .then(result => {
        setItems(result.data);
        setTotalItems(result.totalItem);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  }, [params, sortedInfo, currentWarehouse]);

  const columns = [
    {
      title: lang.orderCode,
      key: 'orderCode',
      dataIndex: 'code',
    },
    {
      title: lang.vansCode,
      key: 'vansCode',
      dataIndex: 'boothCode',
    },
    {
      title: lang.userName,
      key: 'nameCustomer',
      dataIndex: 'nameCustomer',
    },
    {
      title: lang.orderQuantity,
      key: 'orderQuantity',
      render: (record) => {
        const sum = record?.productOrders?.reduce((prev, current) => {
          return prev + current.total;
        }, 0);
        return sum;
      },
    },
    {
      title: lang.orderDate,
      dataIndex: 'createDate',
      key: 'orderDate',
      render: value => moment(value, formatDateFull).format(formatDatetime),
    },
    {
      title: lang.orderStatus,
      key: 'status',
      dataIndex: 'status',
      ellipsis: true,
      render: (record) => {
        return (
          <Tag>{record}</Tag>
        );
      },
    },
  ];

  const columnsDetail = [
    {
      title: lang.packageCode,
      dataIndex: 'code',
      key: 'packageCode',
      width: '50%',
      render: (record) => {
        return (
          <Button
            size="small"
            type="link"
            onClick={() => {
              setItem({ packageCode: record, orderCode: orderCode });
              setIsPackagingDetailModal(true);
            }}
          >{record}</Button>
        );
      },
    },
    {
      title: lang.packageStatus,
      key: 'status',
      dataIndex: 'status',
      width: '50%',
      render: (record) => {
        return (
          <Tag>{record}</Tag>
        );
      },
    },
  ];

  const extraButton = [
    <Button
      key="btn-complete"
      disabled={isStaff}
      type="primary"
      onClick={() => {
        navigate('scan');
      }}
    >
      {lang.scan}
      <ScanOutlined />
    </Button>,
  ];

  const routes = [
    {
      path: '',
      breadcrumbName: 'Home',
    },
    {
      path: '',
      breadcrumbName: lang.outbound,
    },
    {
      path: '/shipping',
      breadcrumbName: lang.shippingOutboundControl,
    },
  ];

  return (
    <Layout className="picking-list">
      <PageHeader
        ghost={false}
        title={lang.shippingOutboundControl}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      //onBack={onBack}
      />
      <Layout.Content style={{ margin: '12px 24px' }}>
        <Card size="small" className="cardSearch">
          <Form form={form} className="customFormSearch" onFinish={(value) => {
            const cleanValue = pickBy(value, v => v !== undefined && v !== '');
            // if (!cleanValue?.boothCode && !cleanValue?.status) {
            //   cleanValue.status = 'READY';
            // }
            // if (cleanValue?.boothCode) {
            //   cleanValue.status = undefined;
            // }
            setParams({ ...cleanValue, page: 1, limit: params.limit });
          }}>
            <Row gutter={[16, 16]}>
              <Col xxl={4} sm={12} md={6} xs={16}>
                <Form.Item label={lang.vansCode} name="boothCode">
                  {/* <Select placeholder={lang.orderCode} showSearch allowClear>
                    {searchCode?.map(item => <Option key={item.id} value={item.code}>{item.code}</Option>)}
                  </Select> */}
                  <Input style={{ width: '100%' }} placeholder={lang.vansCode} />
                </Form.Item>
              </Col>
              <Col xxl={4} md={6} sm={12}>
                <Form.Item name="status">
                  <Select placeholder={lang.status} showSearch allowClear>
                    <Option key={'PACKAGED'} value={'PACKAGED'}>{'PACKAGED'}</Option>
                    <Option key={'READY'} value={'READY'}>{'READY'}</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xxl={4} md={6} sm={12}>
                <Form.Item name="customerName">
                  <Input placeholder={lang.keyword} allowClear={true} />
                </Form.Item>
              </Col>
              <Col sm={3}>
                <Form.Item>
                  <Button
                    type={'primary'}
                    ghost
                    icon={<SearchOutlined />}
                    htmlType="submit"
                  >{lang.btnSearch}</Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
        <Card>
          <Row style={{ marginBottom: 10 }}>
            <Col span={12}><h3>{lang.orderandshippingInfo}</h3></Col>
            {/* <Col span={12} style={{ textAlign: 'right' }}>
              <Button
                type="primary"
                ghost
                // onClick={() => console.log('Import CSV')}
                style={{ marginLeft: 8 }}
              >
                {lang.importCSV}
              </Button>
            </Col> */}
          </Row>
          <Row gutter={20}>
            <Col span={24}>
              <TableCustom
                rowKey="id"
                loading={loading}
                bordered
                className="tableCustom"
                columns={columns}
                dataSource={items}
                onChange={(pagination, filters, sorter) => {
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                  if (pagination.pageSize !== params.limit) {
                    params.page = 1;
                  } else {
                    params.page = pagination.current;
                  }
                  params.limit = pagination.pageSize;
                  setParams({ ...params });
                  setItemsDetail([]);
                }}
                pagination={{
                  showSizeChanger: true,
                  total: totalItems,
                  pageSize: params.limit,
                  current: params.page,
                }}
                scroll={{ x: '800' }}
                onRow={(record) => {
                  return {
                    onClick: async () => {
                      setOrderCode(record.code);
                      fetchDetail(record.id);
                    },
                    style: { cursor: 'pointer' },
                  };
                }}
              />
            </Col>
            <Col span={24}>
              <TableCustom
                rowKey="id"
                loading={loadingDetail}
                bordered
                className="tableCustom"
                columns={columnsDetail}
                dataSource={itemsDetail}
                onChange={(pagination, filters, sorter) => {
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                  setCurrentPageDetail(pagination.current);
                }}
                pagination={{
                  showSizeChanger: true,
                  total: itemsDetail.length,
                  onShowSizeChange: (current, size) => {
                    SetPageSizeDetail(size);
                  },
                  pageSize: pageSizeDetail,
                  current: currentPageDetail,
                }}
                scroll={{ x: 300 }}
              />
            </Col>
          </Row>
        </Card>
      </Layout.Content>
      <Modal
        visible={isPackagingDetailModal}
        title={lang.packagingDetail}
        onCancel={() => setIsPackagingDetailModal(false)}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
        wrapClassName="receipt-product-edit-modal"
      >
        <PackagingDetailForm
          item={item}
          onCallback={(value) => {
            setIsPackagingDetailModal(false);
          }}
          onCancel={() => setIsPackagingDetailModal(false)}
        />
      </Modal>
    </Layout>
  );
};

export default ShippingList;
