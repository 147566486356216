import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Row, Col } from 'antd';
import { LoginOutlined } from '@ant-design/icons';
import './reallocattion-style.less';
import ScanProductForm from 'components/ScanProductForm/scan-product.form';
import { useSelector } from 'react-redux';

const breadcrumbItemRender = (route, params, routes, paths) => {
    const last = routes.indexOf(route) === routes.length - 1;
    return (last || route.path !== '') ? (
        <span>{route.breadcrumbName}</span>
    ) : (
        <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
    );
};

const ReallocationList = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const lang = getLanguages(t);
    const { scanReallocationProductCode } = useSelector(auth => auth.inventory);
    const extraButton = [
        <Button
            key="btn-complete1"
            type="primary"
            onClick={() => {
                navigate('/dashboard/inventory/reallocation/scancode/editlocation');
            }}
            style={{ width: 154, borderRadius: 4 }}
            disabled={!(scanReallocationProductCode?.length > 0)}
        >
            {lang.btnSubmit}
            <LoginOutlined />
        </Button>,
    ];

    const routes = [
        {
            path: '',
            breadcrumbName: 'Home',
        },
        {
            path: '',
            breadcrumbName: lang.inventory,
        },
        {
            path: '/reallocation',
            breadcrumbName: lang.reallocation,
        },
        {
            path: '/scancode',
            breadcrumbName: lang.scanCode,
        },
    ];

    return (
        <Layout className="layoutContent">
            <PageHeader
                ghost={false}
                title={lang.scanCode}
                extra={extraButton}
                breadcrumb={{
                    routes,
                    itemRender: breadcrumbItemRender,
                }}
                className="customPageHeader"
            />
            <Layout.Content>
                <Row gutter={20}>
                    <Col span={24}>
                        <ScanProductForm type={'INVENTORY'} />
                    </Col>
                </Row>
            </Layout.Content>
        </Layout>
    );
};

export default ReallocationList;
