import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Layout, PageHeader, Button, Modal, Card, Row, Col, DatePicker, Input, Tag, message, Select } from 'antd';
import { PlusOutlined, DeleteOutlined, SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import { receiptDelete, receiptList } from './reuqest-receipt.service';
import _get from 'lodash/get';

import './style.less';
import moment from 'moment';
import { defaultPage, formatDate, formatDateFull, formatDatetime } from 'utils/constants';
import TableCustom from 'components/TableCustom/table';
import { Option } from 'antd/lib/mentions';

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const RequestReceiptList = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);

  //Roles
  const { user, currentWarehouse } = useSelector((state) => state.auth);
  const isStaff = _get(user, 'roles', '').includes('staff');

  const [items, setItems] = useState([]);
  const [totalItem, setTotalItem] = useState(0);
  const [status, setStatus] = useState();
  const [params, setParams] = useState({ ...defaultPage, status: 'NEW' });
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [sortBy] = useState('id');
  const [sortDirection] = useState('desc');
  const [keyword, setKeyword] = useState('');
  //
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const getReceipts = (params, sortBy, sortDirection, currentWarehouse) => {
    setLoading(true);

    // const params = {
    //   page,
    //   limit,
    //   sortBy,
    //   sortDirection,
    //   keyword,
    // };
    // if (startDate) {
    //   params.startDate = startDate.startOf('date').format(formatDateFull);
    // }
    // if (endDate) {
    //   params.endDate = endDate.endOf('date').format(formatDateFull);
    // }
    // params.warehouseId = currentWarehouse.id;

    receiptList({ ...params, sortBy, sortDirection, warehouseId: currentWarehouse.id })
      .then(result => {
        setItems(result.data);
        setTotalItem(result.totalItem);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  };
  useEffect(() => {
    getReceipts(params, sortBy, sortDirection, currentWarehouse);
  }, [params, sortBy, sortDirection, currentWarehouse]);

  const columns = [
    // {
    //   title: lang.receiptId,
    //   dataIndex: 'id',
    //   key: 'id',
    //   fixed: 'left',
    //   width: '15%',
    //   render: (record) => {
    //     if (isStaff) {
    //       return (
    //         <div>{record}</div>
    //       );
    //     } else {
    //       return (
    //         <Button type="link" size="small" onClick={() => navigate(`${record}`)}>{record}</Button>
    //       );
    //     }
    //   },
    // },
    {
      title: lang.receiptCode,
      // dataIndex: 'code',
      key: 'code',
      render: (record) => {
        if (isStaff) {
          return (
            <div>{record?.code}</div>
          );
        } else {
          return (
            <Button type="link" size="small" onClick={() => navigate(`${record?.id}`)}>{record?.code}</Button>
          );
        }
      },
    },
    {
      title: lang.inboundKind,
      dataIndex: 'inboundkind',
      key: 'inboundkind',
    },
    {
      title: lang.status,
      key: 'status',
      dataIndex: 'status',
      render: (record) => {
        return (
          <Tag>{lang[record]}</Tag>
        );
      },
    },
    {
      title: lang.receiveDate,
      dataIndex: 'receiptDate',
      //sortOrder: sortedInfo.columnKey === 'createDate' && sortedInfo.order,
      key: 'receiptDate',
      render: value => moment(value, formatDateFull).format(formatDatetime),
      // sorter: true,
      // sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: lang.creator,
      dataIndex: 'creatorName',
      key: 'creatorName',
    },
  ];

  const extraButton = [
    <Button
      key="btn-complete"
      disabled={isStaff}
      type="primary"
      onClick={() => {
        navigate('create');
      }}
    >
      {lang.btnCreate}
      <PlusOutlined />
    </Button>,
  ];

  const routes = [
    {
      path: '/dashboard/inbound',
      breadcrumbName: lang.inbound,
    },
    {
      path: '/request-receipt',
      breadcrumbName: lang.requestReceipt,
    },
  ];

  return (
    <Layout className="layoutContent">
      <PageHeader
        ghost={false}
        title={lang.requestReceipt}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      />
      <Layout.Content>
        <Card size="small" className="cardSearch">
          <Row gutter={[16, 16]}>
            <Col style={{ marginTop: 4 }}>
              <label>{lang.receiveDate}</label>
            </Col>
            <Col xxl={{ span: 4 }} sm={6} xs={16}>
              <DatePicker.RangePicker
                placeholder={[lang.from, lang.to]}
                onChange={(dates) => {
                  if (dates) {
                    setStartDate(dates[0]);
                    setEndDate(dates[1]);
                  } else {
                    setStartDate();
                    setEndDate();
                  }
                }}
                style={{ width: '100%' }}
                format={formatDate}
              />
            </Col>
            <Col xxl={{ span: 4 }} sm={6}>
              <Input placeholder={lang.keyword} onChange={(value) => setKeyword(value.target.value)} />
            </Col>
            <Col xxl={{ span: 4 }} sm={6}>
              <Select placeholder={lang.status} onChange={(value) => setStatus(value)} style={{ width: '100%' }} allowClear>
                <Option value="NEW">{lang.NEW}</Option>
                <Option value="RECEIVE">{lang.RECEIVE}</Option>
              </Select>
            </Col>
            <Col sm={3}>
              <Button
                type={'primary'}
                ghost
                icon={<SearchOutlined />}
                onClick={() => {
                  if (status) {
                    Object.assign(params, { status: status })
                  } else {
                    Object.assign(params, { status: 'NEW' })
                  }
                  if (startDate) {
                    params.startDate = startDate.startOf('date').format(formatDateFull);
                  } else {
                    delete params.startDate;
                  }
                  if (endDate) {
                    params.endDate = endDate.endOf('date').format(formatDateFull);
                  } else {
                    delete params.endDate;
                  }
                  params.page = 1;
                  setParams({ ...params, keyword });
                }}
              >{lang.search}</Button>
            </Col>
          </Row>
        </Card>
        <TableCustom
          title={() => <Row>
            <Col span={12}><h3>{lang.receiptList}</h3></Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              {selectedRowKeys.length > 0 && (
                <Button
                  type="primary"
                  onClick={() => {
                    Modal.confirm({
                      title: lang.doYouWantToDeleteTheseItems,
                      icon: <ExclamationCircleOutlined />,
                      onOk() {
                        receiptDelete({
                          ids: selectedRowKeys,
                        })
                          .then(result => {
                            params.page = 1;
                            setParams({ ...defaultPage });
                            setSelectedRowKeys([]);
                            Modal.success({
                              title: lang.deleteReceiptsSuccessfully,
                              content: `${lang.receiptIdsHasBeenDeleted} ${JSON.stringify(selectedRowKeys)}`,
                            });
                          })
                          .catch((e) => message.error(e.message));
                      },
                      onCancel() {

                      },
                    });
                  }}
                  style={{ marginLeft: 8 }}
                >
                  {lang.btnDelete}
                  <DeleteOutlined />
                </Button>
              )}
            </Col>
          </Row>}
          rowKey="id"
          rowSelection={!isStaff && rowSelection}
          loading={loading}
          bordered
          columns={columns}
          dataSource={items}
          onChange={(pagination, filters, sorter) => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            // if (sorter) {
            //   setSortedInfo({
            //     columnKey: sorter.columnKey,
            //     order: sorter.order,
            //   });
            // }
            if (pagination.pageSize !== params.limit) {
              params.page = 1;
            } else {
              params.page = pagination.current;
            }
            params.limit = pagination.pageSize;
            setParams({ ...params });
          }}
          pagination={{
            total: totalItem,
            showSizeChanger: true,
            pageSize: params.limit,
            current: params.page,
          }}
          scroll={{ x: 526 }}
        />
      </Layout.Content>

    </Layout>
  );
};

export default RequestReceiptList;
