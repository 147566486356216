import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Button, Layout, PageHeader, Tabs, Form, Image, Space, Card, Row, Col } from 'antd';

import { ProFormSelect, ProFormDateRangePicker, ProFormText, ProFormDigit, ModalForm, ProFormDatePicker } from '@ant-design/pro-form';
import TabPane from '@ant-design/pro-card/lib/components/TabPane';
import locale from 'antd/lib/date-picker/locale/en_US';
import { pickBy } from 'lodash';
// import IconExport from 'assets/icons/icon-Export.svg';
import { productCategoryList, productsList, replenishmentList } from './inventoryWarning.service';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { formatDate, formatDateFull, formatDatetime } from 'utils/constants';
import ProTableCusTom from 'components/TableCustom/Protable';

const breadcrumbItemRender = (route, params, routes, paths) => {
    const last = routes.indexOf(route) === routes.length - 1;
    return (last || route.path !== '') ? (
        <span>{route.breadcrumbName}</span>
    ) : (
        <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
    );
};

const InventoryWarning = () => {
    // const dispatch = useDispatch();
    const { t } = useTranslation();
    const lang = getLanguages(t);
    const [tab, setTab] = useState('2');
    const [visible, setVisible] = useState(false);
    const [dataFilter, setDataFilter] = useState();
    const [selectedItem, setSelectedItem] = useState();
    const [form] = Form.useForm();
    const [formModal] = Form.useForm();
    const { currentWarehouse } = useSelector((state) => state.auth);
    const [productCategory, setProductCategory] = useState([]);
    const ref = useRef();

    useEffect(() => {
        productCategoryList({ warehouseId: currentWarehouse.id, status: 'ENABLE' }).then((value) => {
            setProductCategory(value.data);
        });
    }, [currentWarehouse]);

    useEffect(() => {
        return () => {
            formModal.resetFields();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItem]);
    //Pagination
    const remInitValue = (valueItem, tab) => {
        if (tab === '2') {
            return {
                ...selectedItem,
                expirationDate: selectedItem?.expireDate,
                salePrice: selectedItem?.price,
                productCategory: selectedItem?.productCategorys?.name,
            };
        }
        return {
            block: valueItem?.block?.name,
            shelf: valueItem?.rack?.shelf?.name,
            rack: valueItem?.rack?.code,
            code: valueItem?.code,
            name: valueItem?.masterProduct?.name,
            expirationDate: valueItem?.expireDate,
            salePrice: valueItem?.salePrice,
            productCategory: valueItem?.masterProduct?.productCategory?.name,
            totalQuantity: valueItem?.totalQuantity,
            cost: valueItem?.cost,
        };
    };
    const columnsReplenishment = [
        // {
        //     title: 'Seq',
        //     dataIndex: 'id',
        //     key: 'id',
        //     width: '4%',
        //     sorter: true,
        //     sortDirections: ['ascend', 'descend', 'ascend'],
        // },
        {
            title: lang.supplier,
            key: 'supplier',
            width: '10%',
            render: record => record?.masterProduct?.suppliers?.map(supp => supp.name).join(', '),
        },
        {
            title: lang.productCategory,
            key: 'productCategorys',
            width: '10%',
            render: (record) => {
                return record?.productCategory?.name;
            },
        },
        {
            title: lang.defaultCode,
            render: record => record?.masterProduct?.code,
            key: 'productCode',
            width: '10%',
            fixed: true,
        },
        {
            title: lang.productCode,
            key: 'productcode',
            width: '10%',
            render: (record) => record?.masterProduct?.code,
        },
        {
            title: lang.productName,
            dataIndex: 'productName',
            key: 'productname',
            width: '25%',
        },
        {
            title: lang.inStock,
            dataIndex: 'onHand',
            key: 'inStock',
        },
        {
            title: lang.replenishment,
            dataIndex: 'toOrder',
            key: 'replenishment',
            hideInTable: tab === '1',
        },
    ];
    const columnsProduct = [
        {
            title: lang.supplier,
            key: 'supplier',
            width: '10%',
            render: record => record?.supplier?.name,
        },
        {
            title: lang.productCategory,
            key: 'productCategorys',
            width: '10%',
            render: (record) => {
                return record?.masterProduct?.productCategory?.name;
            },
        },
        {
            title: lang.defaultCode,
            render: record => record?.masterProduct?.code,
            key: 'productCode',
            width: '10%',
            fixed: true,
        },
        {
            title: lang.productCode,
            key: 'productcode',
            width: '10%',
            render: (record) => <Button size="small" type="link" onClick={() => { setSelectedItem(record); setVisible(true); }}>{record?.code}</Button>,
        },
        {
            title: lang.productName,
            key: 'productname',
            width: '25%',
            render: record => record?.masterProduct?.name,
        },
        {
            title: lang.inStock,
            dataIndex: 'totalQuantity',
            key: 'inStock',
            width: '8%',
        },
        {
            title: lang.expirationDate,
            dataIndex: 'expireDate',
            key: 'expirationDate',
            render: value => {
                if (value !== '-') {
                    const date = moment(value, formatDateFull).diff(moment(), 'day');
                    return <span style={{ color: date <= 31 ? '#E4595C' : 'black' }}>{moment(value, formatDateFull).format(formatDatetime)} ({Math.abs(date)} days)</span>;
                }
                return undefined;
            },
        },
        {
            title: lang.storageExpirationDate,
            dataIndex: 'storageDate',
            key: 'storageExpirationDate',
            render: value => {
                const date = moment(value, formatDateFull).diff(moment(), 'day');
                return <span style={{ color: date <= 31 ? '#E4595C' : 'black' }} >{moment(value, formatDateFull).format(formatDatetime)} ({Math.abs(date)} days)</span>;
            },
        },

    ];

    const routes = [
        {
            path: '',
            breadcrumbName: 'Home',
        },
        {
            path: '',
            breadcrumbName: lang.reporting,
        },
        {
            path: '/inventorywarning',
            breadcrumbName: lang.inventoryWarning,
        },
    ];
    return (
        <Layout className="layoutContent">
            <PageHeader
                ghost={false}
                title={lang.inventoryWarning}
                breadcrumb={{
                    routes,
                    itemRender: breadcrumbItemRender,
                }}
                // extra={[
                //     <Button
                //         key="btn-complete"
                //         type="primary"
                //         style={{ width: 154, borderRadius: 4 }}
                //         onClick={() => {
                //             // navigate('/dashboard/inventory/reallocation/scancode')
                //         }}
                //     >
                //         {lang.exportCSV} <img alt="" style={{ marginLeft: 5, marginTop: '-2px' }} src={IconExport} />
                //     </Button>,
                // ]}
                footer={
                    <Tabs onTabClick={(value) => {
                        setTab(value);
                        form.resetFields();
                        ref.current.setPageInfo({ current: 1, pageSize: 10 });
                    }}
                        defaultActiveKey={tab}>
                        <TabPane tab={lang.agingStockAlert} key="1" />
                        <TabPane tab={lang.replenishment} key="2" />
                    </Tabs>
                }
                className="customPageHeader"
            />
            <Layout.Content>
                <Card size="small" className="cardSearch">
                    <Form form={form}
                        onFinish={(values) => {
                            const cleanValue = pickBy(values, v => v !== undefined && v !== '');
                            if (cleanValue?.expirationDate) {
                                cleanValue.startExpireDate = cleanValue.expirationDate[0].startOf('date').format(formatDateFull);
                                cleanValue.endExpireDate = cleanValue.expirationDate[1].endOf('date').format(formatDateFull);
                                delete cleanValue.expirationDate;
                            }
                            if (cleanValue?.storageExpirationDate) {
                                cleanValue.startStorageDate = cleanValue.storageExpirationDate[0].startOf('date').format(formatDateFull);
                                cleanValue.endStorageDate = cleanValue.storageExpirationDate[1].endOf('date').format(formatDateFull);
                                delete cleanValue.expirationDate;
                            }
                            setDataFilter(cleanValue);
                            ref.current.setPageInfo({ current: 1 });
                        }}
                        className="customFormSearch"
                    >
                        {tab === '2' ? <Row gutter={16}>
                            <Col xxl={5} xl={8} md={12} sm={13} xs={24} className="customItem">
                                <ProFormSelect label={lang.productCategory} name="productCategoryId" placeholder={lang.productCategory}
                                    // params={{ warehouseId: currentWarehouse.id }}
                                    // request={async (params) => {
                                    //     const response = await productCategoryList({ ...params, status: 'ENABLE' });
                                    //     return response.data?.map((product) => {
                                    //         return {
                                    //             value: product.id,
                                    //             label: product.name,
                                    //         };
                                    //     });
                                    // }}
                                    options={productCategory?.map(cate => {
                                        return {
                                            value: cate.id,
                                            label: cate.name,
                                        };
                                    })}
                                    fieldProps={{
                                        showSearch: true,
                                        optionFilterProp: 'children',
                                        filterOption: (input, option) => {
                                            return option.label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                        },
                                    }}
                                />
                            </Col>
                            <Col xxl={4} xl={5} md={7} sm={5} xs={14}>
                                <ProFormText name="productName" placeholder={lang.keyword} />
                            </Col>
                            <Col >
                                <Button type={'primary'} style={{ borderRadius: '4px', width: '111px' }} htmlType="submit" ghost >{lang.btnSearch}</Button>
                            </Col>
                        </Row> : <Row gutter={16}>
                            <Col xxl={5} md={8} xs={24} className="customItem">
                                <ProFormDateRangePicker label={lang.expirationDate} name="expirationDate" placeholder={[lang.startDate, lang.endDate]}
                                    fieldProps={{
                                        format: formatDate,
                                    }}
                                />
                            </Col>
                            <Col xxl={6} md={9} xs={24} className="customItem">
                                <ProFormDateRangePicker label={lang.storageExpirationDate} name="storageExpirationDate" placeholder={[lang.startDate, lang.endDate]}
                                    fieldProps={{
                                        format: formatDate,
                                    }}
                                />
                            </Col>
                            <Col xxl={4} md={3} xl={4} xs={14}>
                                <ProFormText name="keyword" placeholder={lang.keyword} />
                            </Col>
                            <Col >
                                <Button type={'primary'} style={{ borderRadius: '4px', width: '111px' }} onClick={() => form?.submit()} ghost >{lang.btnSearch}</Button>
                            </Col>
                        </Row>}
                    </Form>
                </Card>
                <ProTableCusTom
                    rowKey="id"
                    actionRef={ref}
                    headerTitle={
                        <Space>
                            <span>Product information</span>
                            {tab === '2' ? <span className="spanCustomHead">{`* ${lang.dotRestock}`}</span> : null}
                        </Space>
                    }
                    columns={tab === '2' ? columnsReplenishment : columnsProduct}
                    scroll={{ x: 900 }}
                    // dataSource={items}
                    params={{ warehouseId: currentWarehouse.id, ...dataFilter, tab: tab }}
                    request={async (params) => {
                        const { current, pageSize, tab } = params;
                        delete params.current;
                        delete params.pageSize;
                        delete params.tab;
                        if (tab === '2') {
                            const reponse = await replenishmentList({ page: current, limit: pageSize, ...params });
                            return Promise.resolve({
                                data: reponse.data,
                                success: true,
                                total: reponse.totalItem,
                            });
                        }
                        const reponse = await productsList({ page: current, limit: pageSize, ...params, multipleStatus: 'STORED,TEPORARY,MOVING,REALOCATE' });
                        return Promise.resolve({
                            data: reponse.data,
                            success: true,
                            total: reponse.totalItem,
                        });
                    }}
                    search={false}
                    options={{
                        search: false,
                        reload: false,
                        density: false,
                        setting: false,
                    }}
                    pagination={{
                        showTitle: false,
                        showTotal: false,
                        pageSize: 10,
                    }}
                    tableAlertRender={false}
                />
                <ModalForm
                    title={lang.productDetail}
                    width="1024px"
                    visible={visible}
                    submitter={{
                        render: (props) => {
                            return [
                                <Button
                                    style={{ width: '130px', height: '32px', borderRadius: 4 }}
                                    danger
                                    key="extra-reset"
                                    onClick={() => {
                                        setVisible(false);
                                    }}
                                >
                                    {lang.btnClose}
                                </Button>,
                            ];
                        },
                    }}
                    initialValues={remInitValue(selectedItem, tab)}
                    onVisibleChange={setVisible}
                    modalProps={{
                        closable: false,
                        centered: true,
                        destroyOnClose: true,
                    }}
                    form={formModal}
                >
                    <Card title={`${lang.capacity}: ${selectedItem?.masterProduct?.capacity}`} bodyStyle={{ padding: '12px 0px' }} bordered={false} headStyle={{ padding: 0, borderBottom: 0 }}>
                        <Row gutter={24} justify="space-around" align="middle">
                            <Col span={6}>
                                <Image src={tab === '2' ? selectedItem?.image : selectedItem?.masterProduct?.image} />
                            </Col>
                            <Col span={18}>
                                <Row gutter={16}>
                                    <Col span={8}>
                                        <ProFormText name="block" label={lang.block} placeholder={lang.block} disabled={true} />
                                    </Col>
                                    <Col span={8}>
                                        <ProFormText name="shelf" label={lang.shelf} placeholder={lang.shelf} disabled={true} />
                                    </Col>
                                    <Col span={8}>
                                        <ProFormText name="rack" label={lang.rack} placeholder={lang.rack} disabled={true} />
                                    </Col>
                                    <Col span={8}>
                                        <ProFormText name="code" label={lang.productCode} placeholder={lang.productCode} disabled={true} />
                                    </Col>
                                    <Col span={8}>
                                        <ProFormText name="name" label={lang.productName} placeholder={lang.productName} disabled={true} />
                                    </Col>
                                    <Col span={8}>
                                        <ProFormText name="productCategory" label={lang.productCategory} placeholder={lang.productCategory} disabled={true} />
                                    </Col>
                                    <Col span={8}>
                                        <ProFormDatePicker name="expirationDate" label={lang.expirationDate} placeholder={lang.expirationDate} width="lg" disabled={true}
                                            fieldProps={{
                                                format: formatDate,
                                                locale: locale,
                                            }} />
                                    </Col>
                                    <Col span={8}>
                                        <ProFormDigit
                                            name="totalQuantity"
                                            label={lang.totalQuantity}
                                            placeholder={lang.totalQuantity}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col span={8} />
                                    <Col span={8}>
                                        <ProFormText name="cost" label={lang.cost} placeholder={lang.cost} disabled={true} />
                                    </Col>
                                    <Col span={8}>
                                        <ProFormText name="salePrice" label={lang.salePrice} placeholder={lang.salePrice} disabled={true} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>

                </ModalForm>
            </Layout.Content>
        </Layout>
    );
};

export default InventoryWarning;
