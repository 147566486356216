import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Row, Col } from 'antd';
import { LoginOutlined } from '@ant-design/icons';

import { useSelector } from 'react-redux';

import ScanPackageForm from 'components/ScanPackageForm/scan-package.form';
import ScanProductForm from 'components/ScanProductForm/scan-product.form';

import './style.less';

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const ScanReturnProduct = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const lang = getLanguages(t);

  const { scanReturnForProductCodes, scanReturnForPackageCodes } = useSelector((state) => state.inbound);

  const extraButton = [
    <Button
      key="btn-cancel"
      type="primary"
      ghost
      onClick={() => {
        navigate('/dashboard/inbound/temporary-inbound/');
      }}
    >
      {lang.btnCancel}
    </Button>,
    <Button
      disabled={scanReturnForProductCodes.length === 0 && scanReturnForPackageCodes.length === 0}
      key="btn-complete"
      type="primary"
      onClick={() => {
        navigate('complete');
      }}
    >
      {lang.btnSubmit}
      <LoginOutlined />
    </Button>,
  ];

  const routes = [
    {
      path: '/dashboard/inbound',
      breadcrumbName: lang.inbound,
    },
    {
      path: '/dashboard/inbound/temporary-inbound',
      breadcrumbName: lang.temporaryInbound,
    },
    {
      path: '/dashboard/inbound/temporary-inbound/scan-return-product',
      breadcrumbName: lang.scanReturnProduct,
    },
  ];

  return (
    <Layout className="scan-return-product-list layoutContent">
      <PageHeader
        ghost={false}
        title={lang.scanReturnProduct}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      />
      <Layout.Content>
        <Row gutter={[16,16]}>
          <Col sm={24} lg={12} xs={24} >
            <ScanPackageForm type='SCAN_RETURN' />
          </Col>
          <Col sm={24} lg={12} xs={24}>
            <ScanProductForm type='SCAN_RETURN' />
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  );
};

export default ScanReturnProduct;
