import React, { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Card, Row, Col, Input, Form, message } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import './reallocattion-style.less';
import IconScanWhite from 'assets/icons/icon-scanwhite.svg';
import { productsList } from '../product-management/product-management.service';
import { useSelector } from 'react-redux';
import { pickBy } from 'lodash';
import ProTableCusTom from '../../../../components/TableCustom/Protable';

const defaultSort = {
  // sortDirection: 'desc',
  // sortBy: 'createDate',
  status: 'REALLOCATE',
};

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const ReallocationList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const lang = getLanguages(t);

  const { currentWarehouse } = useSelector((state) => state.auth);
  const ref = useRef();
  //Pagination
  const [params, setParams] = useState({});

  const columns = [
    {
      title: lang.defaultCode,
      dataIndex: 'masterProduct',
      key: 'barcode',
      width: '12%',
      render: value => value?.barCode
    },
    {
      title: lang.productCode,
      dataIndex: 'code',
      key: 'code',
      width: '12%',
      editable: false,
    },
    {
      title: lang.productCategory,
      key: 'productCategorys',
      width: '15%',
      render: (record) => {
        return record?.masterProduct?.productCategory?.name;
      },
      editable: false,
    },
    {
      title: lang.productName,
      key: 'productname',
      width: '25%',
      render: (record) => {
        return record?.masterProduct?.name;
      },
      editable: false,
    },
    {
      title: lang.block,
      dataIndex: 'block',
      key: 'block',
      valueType: 'select',
      render: (_, row) => row?.block?.name,
    },
    {
      title: lang.shelf,
      key: 'shelf',
      valueType: 'select',
      render: (_, row) => row?.rack?.shelf?.name,
    },
    {
      title: lang.rack,
      dataIndex: 'rack',
      key: 'rack',
      valueType: 'select',
      render: (_, row) => {
        return row?.rack?.code;
      },
    },
  ];

  const extraButton = [
    <Button danger key="btn-import"
      style={{ width: '12vw', borderRadius: 4, minWidth: 100, maxWidth: 154 }}
    >
      {lang.importCSV}
    </Button>,
    <Button
      key="btn-complete"
      type="primary"
      style={{ width: '12vw', borderRadius: 4, minWidth: 100, maxWidth: 154 }}
      onClick={() => {
        navigate('/dashboard/inventory/reallocation/scancode');
      }}
    >
      {lang.scan} <img alt="" style={{ marginLeft: 5, marginTop: '-2px' }} src={IconScanWhite} />
    </Button>,
  ];

  const routes = [
    {
      path: '',
      breadcrumbName: 'Home',
    },
    {
      path: '',
      breadcrumbName: lang.inventory,
    },
    {
      path: '/reallocation',
      breadcrumbName: lang.reallocation,
    },
  ];

  return (
    <Layout className="layoutContent">
      <PageHeader
        ghost={false}
        title={lang.reallocation}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      //onBack={onBack}
      />
      <Layout.Content>
        <Card size="small" className="cardSearch">
          <Form className="customFormSearch" onFinish={async (value) => {
            const cleanValue = pickBy(value, v => v !== undefined && v !== '');
            ref.current.setPageInfo({ current: 1, pageSize: 10 });
            setParams(cleanValue);
          }}>
            <Row gutter={[16, 16]}>
              <Col md={8} sm={12} xs={16}>
                <Form.Item name="keyword">
                  <Input placeholder={lang.keyword} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Button type={'primary'} htmlType="submit" ghost icon={<SearchOutlined />}>{lang.btnSearch}</Button>
              </Col>
            </Row>
          </Form>

        </Card>
        <ProTableCusTom
          rowKey="id"
          actionRef={ref}
          headerTitle={lang.productList}
          columns={columns}
          // dataSource={items}
          params={{ ...defaultSort, ...params, warehouseId: currentWarehouse.id }}
          request={async (params) => {
            try {
              const { current, pageSize } = params;
              params.limit = pageSize;
              params.page = current;
              delete params.current;
              delete params.pageSize;
              const response = await productsList({ ...params });
              return Promise.resolve({
                data: response.data,
                total: response.totalItem,
                success: true,
              });
            } catch (error) {
              message.error(error.message);
              return [];
            }
          }}
          search={false}
          options={{
            search: false,
            reload: false,
            density: false,
          }}
          scroll={{ x: 900 }}
          pagination={{
            showTitle: false,
            showTotal: false,
            pageSize: 10,
          }}
          bordered
          tableAlertRender={false}
        />
      </Layout.Content>
    </Layout>
  );
};

export default ReallocationList;
