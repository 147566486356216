import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Descriptions, Card, Row, Col, Modal, message } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { receiptDetail } from './reuqest-receipt.service';
import { productUpdate } from 'pages/dashboard/inventory/product-management/product-management.service';

import moment from 'moment';
import { formatDate, formatDateFull, formatDatetime } from 'utils/constants';

import ReceiptDetailProductEditForm from './request-receipt-detail-product-edit.form';
import ReceiptProductsPrint from './request-receipt-products-print';

import './style.less';
import TableCustom from 'components/TableCustom/table';
import Helper from 'utils/helpers';
//const tableScrollHeightArea = window.innerHeight - 360; // px

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const RequestReceiptDetail = () => {
  const { receiptId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [isEditModal, setIsEditModal] = useState(false);
  const [isPrintModal, setIsPrintModal] = useState(false);
  const [printProducts] = useState([]);
  const [item, setItem] = useState();
  const [items, setItems] = useState([]);
  const [receipt, setReceipt] = useState();

  useEffect(() => {
    setLoading(true);
    receiptDetail({ receiptId: parseInt(receiptId) })
      .then(result => {
        setReceipt(result);
        setItems(result?.products);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  }, [receiptId]);

  const columns = [
    {
      title: lang.defaultCode,
      key: 'barcode',
      fixed: 'left',
      render: (record) => {
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        return <Button
          type="link"
          size="small"
          onClick={() => {
            setItem(record);
            setIsEditModal(true);
          }}>{record?.masterProduct?.barCode}
        </Button>;
      },
      width: '10%',
    },
    {
      title: lang.productCode,
      key: 'code',
      width: '10%',
      dataIndex: 'code',
    },
    {
      title: lang.productCategory,
      dataIndex: 'masterProduct',
      key: 'productCategory',
      width: '10%',
      render: (value) => value?.productCategory?.name,
    },
    {
      title: lang.productName,
      dataIndex: 'masterProduct',
      key: 'productName',
      width: '12%',
      render: (value) => value?.name,
    },
    {
      title: lang.expectedQuantity,
      key: 'expectedQuantity',
      dataIndex: 'expectedQuantity',
      width: '6%',
    },
    {
      title: lang.totalQuantity,
      key: 'totalQuantity',
      dataIndex: 'totalQuantity',
      width: '6%',
    },
    {
      title: lang.zone,
      key: 'zone',
      dataIndex: 'zone',
      width: '6%',
    },
    {
      title: lang.block,
      key: 'block',
      dataIndex: 'block',
      width: '6%',
      render: value => value?.name,
    },
    {
      title: lang.shelf,
      key: 'shelf',
      dataIndex: 'rack',
      width: '6%',
      render: value => value?.shelf?.name,
    },
    {
      title: lang.rack,
      key: 'rack',
      dataIndex: 'rack',
      width: '6%',
      render: value => value?.code,
    },
    {
      title: lang.supplier,
      key: 'supplier',
      dataIndex: 'supplier',
      width: '18%',
      render: (value) => value?.name,
    },
    {
      title: lang.expireDate,
      dataIndex: 'expireDate',
      key: 'expireDate',
      width: '8%',
      render: value => value ? moment(value, formatDateFull).format(formatDatetime) : undefined,
    },
    {
      title: lang.action,
      align: 'center',
      width: '8%',
      fixed: 'right',
      render: (record) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            type="link"
            size="small"
            icon={<EditOutlined />}
            onClick={() => {
              setItem(record);
              setIsEditModal(true);
            }}
          />
        </div>
      ),
    },
  ];

  const extraButton = [
    <Button
      key="btn-cancel"
      type="primary"
      ghost
      onClick={() => {
        navigate('/dashboard/inbound/request-receipt');
      }}
    >
      {lang.btnCancel}
    </Button>,
  ];

  const routes = [
    {
      path: '/dashboard/inbound',
      breadcrumbName: lang.inbound,
    },
    {
      path: '/dashboard/inbound/receipt',
      breadcrumbName: lang.receipt,
    },
    {
      path: '',
      breadcrumbName: lang.receiptDetail,
    },
  ];

  return (
    <Layout className="receipt-detail layoutContent">
      <PageHeader
        ghost={false}
        title={lang.receiptDetail}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      />
      <Layout.Content>
        <Card size="small" className="cardSearch">
          <Descriptions className="descriptions-class">
            <Descriptions.Item label={lang.receiptId}>{receipt?.id}</Descriptions.Item>
            <Descriptions.Item label={lang.inboundKind}>{receipt?.inboundkind}</Descriptions.Item>
            <Descriptions.Item label={lang.receiveDate}>{moment(receipt?.receiptDate, formatDateFull).format(formatDate)}</Descriptions.Item>
            <Descriptions.Item label={lang.status}>{receipt?.status}</Descriptions.Item>
            <Descriptions.Item label={lang.creatorName}>{receipt?.creatorName}</Descriptions.Item>
          </Descriptions>
        </Card>
        <TableCustom
          title={() => <Row>
            <Col span={12}><h3>{lang.productList}</h3></Col>
          </Row>}
          rowKey="id"
          loading={loading}
          bordered
          columns={columns}
          dataSource={items}
          pagination={false}
          scroll={{ x: 1800 }}
        />
      </Layout.Content>
      <Modal
        visible={isEditModal}
        title={`${item ? lang.btnEdit : lang.btnAdd} ${lang.product}`}
        onCancel={() => setIsEditModal(false)}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
        wrapClassName="receipt-product-edit-modal"
      >
        <ReceiptDetailProductEditForm
          item={item}
          onCallback={async (value) => {
            try {
              await productUpdate({ id: value.id, totalQuantity: value.totalQuantity, expireDate: value.expireDate, note: value.note });
              setLoading(true);
              receiptDetail({ receiptId: parseInt(receiptId) })
                .then(result => {
                  setReceipt(result);
                  setItems(result?.products);
                  setLoading(false);
                })
                .catch((e) => setLoading(false));
              setIsEditModal(false);
            } catch (error) {
              message.error(error.message);
            }
          }}
          onCancel={() => setIsEditModal(false)}
        />
      </Modal>
      <Modal
        visible={isPrintModal}
        title={lang.print}
        onCancel={() => setIsPrintModal(false)}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
      >
        <ReceiptProductsPrint
          items={printProducts}
          onCallback={(values) => {
            Helper.dowloadXLSX(values?.map(item => {
              return {
                code: item.code,
                name: item.masterProduct.name,
                receiptDate: moment(item.receipt.receiptDate, formatDateFull).format(formatDate),
              };
            }), 'productCode');
            setIsPrintModal(false);
            navigate('/dashboard/inbound/receipt');
          }}
          onCancel={() => {
            setIsPrintModal(false);
            navigate('/dashboard/inbound/receipt');
          }}
        />
      </Modal>
    </Layout>
  );
};

export default RequestReceiptDetail;
