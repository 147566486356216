import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Layout, PageHeader, Button, Modal, Form, Select, Card, Row, Col, DatePicker, message as Alert } from 'antd';

import { CheckOutlined, PlusOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { receiptCreate } from './reuqest-receipt.service';
import ReceiptCreateProductEditForm from './request-receipt-create-product-edit.form';
import { formatDate, formatDateFull, formatDatetime, INBOUND_KINDS } from 'utils/constants';

import './style.less';
import { productList } from '../../configuration/product/product.service';
import ProTableCusTom from 'components/TableCustom/Protable';

const { Option } = Select;

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const RequestReceiptCreate = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const { currentWarehouse, user } = useSelector((state) => state.auth);
  const [isEditModal, setIsEditModal] = useState(false);

  const [product, setProduct] = useState();
  const [products, setProducts] = useState([]);
  const [productMasters, setProductMasters] = useState([]);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: lang.productCategory,
      dataIndex: 'productCategory',
      key: 'productCategory',
      width: '10%',
    },
    {
      title: lang.productCode,
      dataIndex: 'productCode',
      key: 'productCode',
      width: '12%',
    },
    {
      title: lang.defaultCode,
      dataIndex: 'barCode',
      key: 'barCode',
      width: '12%',
    },
    {
      title: lang.productName,
      dataIndex: 'productName',
      key: 'productName',
      width: '15%',
    },
    {
      title: lang.expectedQuantity,
      key: 'expectedQuantity',
      dataIndex: 'expectedQuantity',
      width: '10%',
    },
    {
      title: lang.supplier,
      key: 'supplier',
      dataIndex: 'supplier',
      width: '15%',
    },
    {
      title: lang.expireDate,
      dataIndex: 'expireDate',
      key: 'expireDate',
      width: '12%',
      render: (record) => record !== '-' ? record?.format(formatDatetime) : undefined,
    },
    {
      title: lang.action,
      align: 'center',
      width: '8%',
      render: (record) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            type="link"
            icon={<EditOutlined />}
            size="small"
            onClick={() => {
              // const detail = products.find((item) => item.masterProductId === record.masterProductId);
              setProduct(record);
              setIsEditModal(true);
            }}
          />
        </div>
      ),
    },
  ];

  const extraButton = [
    <Button
      key="btn-cancel"
      type="primary"
      ghost
      onClick={() => {
        navigate('/dashboard/inbound/request-receipt');
      }}
    >
      {lang.btnCancel}
    </Button>,
    <Button
      key="btn-complete"
      type="primary"
      disabled={products.length === 0}
      onClick={() => {
        form.submit();
      }}
    >
      {lang.btnComplete}
      <CheckOutlined />
    </Button>,
  ];

  const routes = [
    {
      path: '/dashboard/inbound',
      breadcrumbName: lang.inbound,
    },
    {
      path: '/request-receipt',
      breadcrumbName: lang.requestReceipt,
    },
    {
      path: '',
      breadcrumbName: lang.createReceipt,
    },
  ];

  useEffect(() => {
    setLoading(true);
    productList({ warehouseId: currentWarehouse.id })
      .then(result => {
        setProductMasters(result.data);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  }, [currentWarehouse]);

  return (
    <Layout className="receipt-list layoutContent">
      <PageHeader
        ghost={false}
        title={lang.createReceipt}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      />
      <Layout.Content>
        <Card size="small" className="cardSearch">
          <Form className="customFormSearch"
            colon={false}
            form={form}
            layout="vertical"
            name="receiptCreateForm"
            onFinish={async (values) => {
              try {
                values.creatorId = user?.userInfo?.sub;
                values.creatorName = user?.userInfo?.name;
                values.warehouseId = currentWarehouse?.id;
                values.receiptDate = values.receiptDate.format(formatDateFull);
                values.products = products?.map(product => {
                  delete product.slLo;
                  return product;
                });
                setLoading(true);
                receiptCreate(values)
                  .then(result => {
                    setLoading(false);
                    Modal.success({
                      title: lang.createReceiptSuccessfully,
                      content: `${lang.receiptCode}: ${result?.code}`,
                      icon: <CheckOutlined />,
                      onOk() {
                        navigate('/dashboard/inbound/receipt');
                      },
                    });
                  })
                  .catch((error) => {
                    Alert.error(error?.message);
                    setLoading(false);
                  });
              } catch (error) {
                Alert.error(error?.message);
                setLoading(false);
              }
            }}
            initialValues={{}}
          >
            <Row gutter={24} type="flex">
              <Col lg={{ span: 6 }} xs={{ span: 12 }}>
                <Form.Item
                  name="inboundkind"
                  label={lang.inboundKind}
                  rules={[
                    {
                      required: true,
                      message: lang.pleaseSelectAInboundKind,
                    },
                  ]}
                >
                  <Select placeholder={lang.inboundKind}>
                    {INBOUND_KINDS.map(item => (
                      <Option key={item.value} value={item.value}>{item.label}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={{ span: 6 }} xs={{ span: 12 }}>
                <Form.Item
                  name="receiptDate"
                  label={lang.receiveDate}
                  rules={[
                    {
                      required: true,
                      message: lang.messageReceiveDate,
                    },
                  ]}
                >
                  <DatePicker style={{ width: '100%' }} format={formatDate} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
        <ProTableCusTom
          title={() => <Row>
            <Col sm={12} xs={10}><h3>{lang.productAdded}</h3></Col>
            <Col sm={12} xs={14} style={{ textAlign: 'right' }}>
              <Button
                type="primary"
                onClick={() => {
                  setIsEditModal(true);
                  setProduct();
                }}
              >
                {lang.btnAdd}
                <PlusOutlined />
              </Button>
              <Button
                type="primary"
                ghost
                // onClick={() => console.log('Import CSV')}
                style={{ marginLeft: 8 }}
              >
                {lang.importCSV}
              </Button>
              {selectedRowKeys.length > 0 && (
                <Button
                  type="primary"
                  onClick={() => {
                    Modal.confirm({
                      title: lang.doYouWantToDeleteTheseItems,
                      icon: <ExclamationCircleOutlined />,
                      onOk() {
                        setProducts(products.filter(item => !selectedRowKeys.includes(item.key)));
                        setSelectedRowKeys([]);
                      },
                      onCancel() {
                      },
                    });
                  }}
                  style={{ marginLeft: 8 }}
                >
                  {lang.btnDelete}
                  <DeleteOutlined />
                </Button>
              )}
            </Col>
          </Row>}
          rowKey="key"
          rowSelection={rowSelection}
          loading={loading}
          bordered
          columns={columns}
          dataSource={products}
          scroll={{ x: 743 }}
          search={false}
          options={{
            search: false,
            reload: false,
            density: false,
          }}
          // pagination={false}
        />
      </Layout.Content>
      <ReceiptCreateProductEditForm
        item={product}
        products={productMasters}
        productsSelected={products}
        warehouseId={currentWarehouse?.id}
        onCallback={(values) => {
          // const productsRest = products.filter(item => item.productCode !== value.productCode);
          const productUpdate = products?.find(product => product.key === values[0].key);
          if (productUpdate) {
            productUpdate.expectedQuantity = values[0].expectedQuantity;
            productUpdate.supplier = values[0].supplier;
            productUpdate.supplierId = values[0].supplierId;
            setProducts([...products]);
            setIsEditModal(false);
            return true
          }
          setProducts([...products, ...values]);
          setIsEditModal(false);
        }}
        setIsEditModal={setIsEditModal}
        isEditModal={isEditModal}
      />
    </Layout>
  );
};

export default RequestReceiptCreate;
