import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Row, Col, message as Alert } from 'antd';
import { LoginOutlined } from '@ant-design/icons';
import ScanProductForm from 'components/ScanProductForm/scan-product.form';
import './style.less';
import { productScan, suggestLocationByOrder } from './picking.service';
import { useSelector } from 'react-redux';
import ProTableCusTom from 'components/TableCustom/Protable';

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const PickingCreateScan = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const { scanPickingForProductCodes, pickingProducts } = useSelector((state) => state.outbound);
  const { currentWarehouse } = useSelector((state) => state.auth);
  const [selectedOrder, setSelectedOrder] = useState([]);
  const [loading, setLoading] = useState(false);
  const columnsPickingList = [
    {
      title: lang.defaultCode,
      render: record => record?.masterProduct?.barCode,
      key: 'barcode',
      width: '10%',
    },
    {
      title: lang.productCode,
      key: 'code',
      width: '10%',
      dataIndex: 'code',
    },
    {
      title: lang.productCategory,
      dataIndex: 'masterProduct',
      key: 'productCategory',
      width: '7%',
      render: value => value?.productCategory?.name,
    },
    {
      title: lang.productName,
      key: 'name',
      width: '20%',
      render: record => record?.masterProduct?.name,
    },
    {
      title: lang.quantity,
      key: 'totalQuantity',
      dataIndex: 'totalQuantity',
      width: '10%',
    },
    {
      title: lang.orderQuantity,
      key: 'total',
      dataIndex: 'total',
      width: '10%',
    },
    {
      title: lang.pickingquantity,
      key: 'pickingQuantity',
      dataIndex: 'pickingQuantity',
      width: '10%',
    },
    {
      title: lang.block,
      key: 'block',
      dataIndex: 'block',
      width: '10%',
      render: value => value?.name,
    },
    {
      title: lang.shelf,
      key: 'shelf',
      dataIndex: 'rack',
      width: '10%',
      render: value => value?.shelf?.name,
    },
    {
      title: lang.rack,
      key: 'rack',
      dataIndex: 'rack',
      width: '10%',
      render: value => value?.code,
    },
  ];
  const extraButton = [
    <Button
      key="btn-cancel"
      type="primary"
      ghost
      onClick={() => {
        navigate('/dashboard/outbound/sale-order/create');
      }}
    >
      {lang.btnCancel}
    </Button>,
    <Button
      key="btn-complete"
      type="primary"
      onClick={async () => {
        try {
          const result = await productScan({ productCodes: scanPickingForProductCodes?.map(value => value.code), type: 'PICKING', warehouseId: currentWarehouse.id });
          if (result?.successList) {
            const productTemp = scanPickingForProductCodes.reduce((prev, curr) => {
              const productCode = result?.successList.find(value => curr.code === value.code && curr.count === value.totalQuantity && !prev.finded?.some(find => find.code === value.code));
              if (productCode) {
                prev.finded = [...prev.finded, productCode]
              }
              else {
                const productCodesmall = result?.successList.find(value => curr.code === value.code && curr.count < value.totalQuantity);
                if (productCodesmall) {
                  productCodesmall.splitQuantity = curr.count;
                  prev.split = [...prev.split, productCodesmall];
                }
              }
              return prev;
            }, { finded: [], split: [] });
            // const response = await Promise.all(productTemp?.split?.map(splitProduct => productSplit(splitProduct)));
            result.successList = [...productTemp.split, ...productTemp.finded];
            // const response = await Promise.all(result.successList.reduce((prev, curr) => {
            //   const productCode = scanPickingForProductCodes.find(value => value.code === curr.code && value.count < curr.totalQuantity );
            //   if (productCode)
            //     return [...prev, { id: curr.id, quantity: productCode.count }];
            //   return prev;
            // },[])?.map(splitProduct => productSplit(splitProduct)));
            // const filter = result?.successList?.filter(product =>scanPickingForProductCodes.some(value => value.code === product.code && value.count === product.totalQuantity ) );
            // result.successList = [...response,...filter];
          }
          navigate('complete', { state: result });
        } catch (error) {
          Alert.error(error.message);
        }
      }}
      disabled={!(scanPickingForProductCodes?.length > 0)}
    >
      {lang.btnSubmit}
      <LoginOutlined />
    </Button>,
  ];

  useEffect(() => {
    setLoading(true);
    const remProducts = pickingProducts.reduce((prev, cur) => {
      const find = prev?.find(prev => prev.masterProduct.id === cur.masterProduct.id);
      if (find) {
        find.total = find.total + cur.total;
        return prev
      }
      return [...prev, Object.assign({}, cur)];
    }, []);
    Promise.all(remProducts?.map(async (product) => {
      const { masterProduct, total, warehouseId } = product;
      const reponse = await suggestLocationByOrder({ masterProductId: masterProduct.id, quantity: total, warehouseId });
      return Promise.resolve(reponse.data);
    })).then((results) => {
      const ctx = results?.reduce((prev, current) => {
        if (current.length > 0 && prev?.find(pre => pre.id === current[0].id)) {
          return prev
        }
        return prev.concat(current);
      }, []).sort((first, second) => {
        return first?.block?.id - second?.block?.id
      }).sort((first, second) => {
        return first.rack.id - second.rack.id
      });
      setSelectedOrder([...ctx]);
      setLoading(false);
    }).catch(() => setLoading(false));
  }, [pickingProducts]);
  const routes = [
    {
      path: '',
      breadcrumbName: 'Home',
    },
    {
      path: '',
      breadcrumbName: lang.outbound,
    },
    {
      path: '/sale-order',
      breadcrumbName: lang.saleOrder,
    },
    {
      path: '/create',
      breadcrumbName: lang.createPicking,
    },
    {
      path: '',
      breadcrumbName: lang.scanCode,
    },
  ];

  return (
    <Layout className="storing-scan">
      <PageHeader
        ghost={false}
        title={lang.scanCode}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      //onBack={onBack}
      />
      <Layout.Content style={{ margin: '12px 24px' }}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <ScanProductForm type={'PICKING'} selectOrderProduct={selectedOrder} />
          </Col>
          <Col span={24}>
            <ProTableCusTom
              rowKey="id"
              size="small"
              className="tableCustom"
              bordered
              columns={columnsPickingList}
              loading={loading}
              params={{ selectedOrder, pickingProducts }}
              request={async (params) => {
                const { selectedOrder, pickingProducts } = params;
                const orderProductQuantity = pickingProducts.reduce((prev, cur) => {
                  const find = prev?.find(prev => prev.masterProduct.id === cur.masterProduct.id);
                  if (find) {
                    find.total = find.total + cur.total;
                    return prev
                  }
                  return [...prev, Object.assign({}, cur)];
                }, []);
                return Promise.resolve({
                  data: selectedOrder?.map(product => {
                    const finded = orderProductQuantity?.find(orderProduct => orderProduct?.masterProduct?.barCode === product?.masterProduct?.barCode)
                    if (finded) {
                      if (finded.total > product.totalQuantity) {
                        // eslint-disable-next-line
                        const temp = Object.assign(product, { total: product.totalQuantity, pickingQuantity: finded.pickingQuantity });
                        finded.total -= product.totalQuantity;
                      }else{
                         // eslint-disable-next-line
                        const temp = Object.assign(product, { total: finded.total, pickingQuantity: finded.pickingQuantity })
                      }
                    }
                    return product;
                  }),
                  total: selectedOrder.length,
                  success: true
                })
              }}
              search={false}
              options={{
                search: false,
                reload: false,
                density: false,
              }}
              pagination={{
                showTitle: false,
                showTotal: false,
                pageSize: 10,
              }}
              scroll={{ x: 800 }}
            />
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  );
};

export default PickingCreateScan;
