import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Row, Col } from 'antd';
import { LoginOutlined } from '@ant-design/icons';
import ScanProductForm from 'components/ScanProductForm/scan-product.form';
import { useSelector } from 'react-redux';

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const SplitScanContainer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const { scanSplitProductCode } = useSelector((state) => state.outbound);
  const extraButton = [
    <Button
      key="btn-complete"
      type="primary"
      onClick={async () => {
          navigate('complete');
      }}
      disabled={!(scanSplitProductCode?.length > 0)}
    >
      {lang.btnSubmit}
      <LoginOutlined />
    </Button>,
  ];

  const routes = [
    {
      path: '',
      breadcrumbName: 'Home',
    },
    {
      path: '',
      breadcrumbName: lang.outbound,
    },
    {
      path: '/split-product',
      breadcrumbName: lang.splitproduct,
    },
    {
      path: '/scan',
      breadcrumbName: lang.scan,
    },
  ];

  return (
    <Layout className="storing-scan">
      <PageHeader
        ghost={false}
        title={lang.splitproduct}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      //onBack={onBack}
      />
      <Layout.Content style={{ margin: '12px 24px' }}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <ScanProductForm type={'SPLIT'} />
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  );
};

export default SplitScanContainer;
