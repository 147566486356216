import React from 'react';
import { ProFormSelect } from '@ant-design/pro-form';
import { shelfDetail } from 'pages/dashboard/configuration/location/location.service';

const selectRack = ({ value, onChange, shelfId }) => {
    return (
         <ProFormSelect name="rack"
                fieldProps={{
                    defaultValue: value,
                    onChange: value => onChange?.(value),
                    labelInValue: true,
                }}
                initialValue={value}
                params={{ id: shelfId }}
                request={async (params) => {
                    if (typeof (params?.id) === 'number') {
                        const response = await shelfDetail(params?.id);
                        if (response) {
                            return response?.racks?.map(shelf => {
                                return {
                                    value: shelf.id,
                                    label: shelf.code,
                                };
                            });
                        }
                        return [];
                    }
                    return [];
                }
                } />
    );
  };

  export default selectRack;
