import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Card, Row, Col, message, Form } from 'antd';
import { ProFormText, ModalForm, ProFormTextArea, ProFormDigit } from '@ant-design/pro-form';
import './warehouse-style.less';
import { productOfRack, shelfDetail } from './warehousestate.service';
import { productUpdate } from '../product-management/product-management.service';
import ProTableCusTom from 'components/TableCustom/Protable';
import Helper from 'utils/helpers';

const breadcrumbItemRender = (route, params, routes, paths) => {
    const last = routes.indexOf(route) === routes.length - 1;
    return (last || route.path !== '') ? (
        <span>{route.breadcrumbName}</span>
    ) : (
        <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
    );
};

const WarehouseBlock = (props) => {
    const [visible, setVisible] = useState(false);
    const { t } = useTranslation();
    const lang = getLanguages(t);
    const { state } = useLocation();
    const [block] = useState(state);
    const [item, setItem] = useState(null);
    const [rack, setRack] = useState();
    const [listShelfRacks, setListShelfRacks] = useState([]);
    const actionRef = useRef();
    const formRef = useRef();
    const [form] = Form.useForm();
    const fetchShelf = async (block) => {
        try {
            const listShelfsRacks = [];
            await Promise.all(block?.shelfs?.map(async (shelf) => {
                const data = await shelfDetail(shelf.id);
                return Promise.resolve(data);
            })).then((results) => listShelfsRacks.push(...results));
            setListShelfRacks(listShelfsRacks);
        } catch (error) {
            message.error(error.message);
        }
    };
    useEffect(() => {
        fetchShelf(block);
    }, [block]);

    useEffect(() => {
        return () => {
            form.resetFields();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);
    const bodyStyle = {
        padding: '0 12px 12px',
        overflowX: 'scroll',
    };
    const headStyleBlock = {
        borderBottom: 0,
        padding: '0 12px',
    };
    const headeStyleZone = {
        margin: 0,
        border: 0,
        height: 0,
        minHeight: 0,
    };
    const columns = [
        {
            title: lang.defaultCode,
            dataIndex: 'masterProduct',
            key: 'barcode',
            width: '12%',
            render: value => value?.barCode
        },
        {
            title: lang.productCode,
            dataIndex: 'code',
            key: 'code',
            width: '12%',
        },
        {
            title: lang.productCategory,
            key: 'productCategorys',
            width: '15%',
            render: (record) => {
                return record?.masterProduct?.productCategory.name;
            },
        },
        {
            title: lang.productName,
            key: 'name',
            width: '20%',
            render: record => record?.masterProduct?.name,
        },
        {
            title: lang.totalQuantity,
            key: 'totalQuantity',
            dataIndex: 'totalQuantity',
        },
        {
            title: lang.block,
            key: 'block',
            render: record => record?.block?.name,
        },
        {
            title: lang.shelf,
            key: 'shelf',
            render: record => record?.rack?.shelf?.name,
        },
        {
            title: lang.rack,
            key: 'rackCode',
            render: record => record?.rack?.code,
        },
        {
            title: lang.action,
            width: '8%',
            fixed: 'right',
            render: (record) => {
                return <Button onClick={() => { setItem(record); setVisible(true); }} style={{ width: '110px', borderRadius: '4px' }} type="primary">{lang.report}</Button>;
            },
        },

    ];

    const routes = [
        {
            path: '',
            breadcrumbName: 'Home',
        },
        {
            path: '',
            breadcrumbName: lang.inventory,
        },
        {
            path: '/warehousestate',
            breadcrumbName: lang.warehouseState,
        },
        {
            path: '/warehouseblock/:blockId',
            breadcrumbName: lang.warehouseBlockDetail,
        },
    ];
    const onCancel = () => {
        const valueForm = form.getFieldsValue();
        const isDefined = Object.values(valueForm).some(value => value !== undefined);
        const isUnChange = JSON.stringify(initValues) === JSON.stringify(valueForm);
        if (!isDefined || isUnChange) {
            setVisible(false);
            return true;
        }
        Helper.conFirm(setVisible);
    };
    const initValues = {
        code: item?.code,
        name: item?.masterProduct?.name,
        description: item?.description ? item?.description : '',
    };
    return (
        <Layout className="layoutContent">
            <PageHeader
                ghost={false}
                title={lang.warehouseBlockDetail}
                breadcrumb={{
                    routes,
                    itemRender: breadcrumbItemRender,
                }}
                className="customPageHeader"
            />
            <Layout.Content>
                <Card
                    title={block?.name}
                    headStyle={headStyleBlock}
                    bodyStyle={bodyStyle}
                >
                    <Row gutter={[16, 16]} wrap={false} style={{ minWidth: '400px' }}>
                        {listShelfRacks?.map(self =>
                            <Col flex="400px" key={self.id}>
                                <Card
                                    title={self.name}
                                    headStyle={headStyleBlock}
                                    bodyStyle={{ padding: '12px', overflowY: 'scroll', height: 120 }}
                                    key={self.id}
                                    className="CardScrool"
                                >
                                    <Row gutter={[16, 16]}>
                                        {self?.racks.sort((first, second) => first.id - second.id).map(rack => <Col key={rack.id} span={8}>
                                            <Card
                                                headStyle={headeStyleZone}
                                                className={(rack?.usedCapacity / rack?.capacity) * 100 > self.high ? 'fullClass CardShelf' : (rack?.usedCapacity / rack?.capacity) * 100 < self.medium ? 'CardShelf' : 'mediumClass CardShelf'}
                                                bodyStyle={{ padding: '8px' }}
                                                key={1}
                                                onClick={() => setRack(rack.code)}
                                            >
                                                {rack.code}
                                            </Card>
                                        </Col>)}
                                    </Row>
                                </Card>
                            </Col>,
                        )}

                    </Row>
                </Card>
                <Card size="small" className="cardSearch">
                    <Form
                        className="customFormSearch"
                        onFinish={({ rackCode }) => setRack(rackCode)}
                    >
                        <Row gutter={[16, 16]}>
                            <Col sm={6} >
                                <ProFormText name="rackCode" label='Rack' placeholder={lang.inputCode} />
                            </Col>
                            <Col offset={16}>
                                <Button key="btn-search"
                                    type="primary"
                                    htmlType='submit'
                                >{lang.btnSearch}</Button>
                            </Col>
                        </Row>

                    </Form>

                </Card>
                <ProTableCusTom
                    rowKey="id"
                    actionRef={actionRef}
                    headerTitle={lang.productList}
                    columns={columns}
                    options={{
                        search: false,
                        reload: false,
                        density: false,
                    }}
                    search={false}
                    scroll={{ x: 1500 }}
                    // toolbar={{
                    //     settings:[{
                    //         tooltip:"Settings",
                    //         icon: <SettingOutlined />,
                    //         onClick:()=>{console.log("asd")}
                    //     }],
                    // }}
                    params={{ rackCode: rack }}
                    request={async (params) => {
                        try {
                            if (params?.rackCode) {
                                const { pageSize, current, rackCode } = params;
                                const response = await productOfRack({ page: current, limit: pageSize, rackCode: rackCode, status: 'STORED' });
                                return Promise.resolve({
                                    data: response.data,
                                    total: response.totalItem,
                                    success: true,
                                });
                            }
                            return [];
                        } catch (error) {
                            message.log(error.message);
                        }
                    }}
                    pagination={{
                        pageSize: 10,
                        current: 1,
                        showTotal: false,
                    }}
                />
                <ModalForm
                    visible={visible}
                    width={570}
                    formRef={formRef}
                    form={form}
                    title={lang.reportErrorProduct}
                    onVisibleChange={setVisible}
                    modalProps={{
                        closable: false,
                        destroyOnClose: true,
                        bodyStyle: { padding: '24px 24px 0' },
                    }}
                    initialValues={initValues}
                    onFinish={async (value) => {
                        try {
                            await productUpdate({ id: item?.id, description: value?.description, status: 'ERROR', lostNumber: value.lostNumber });
                            actionRef.current.reload();
                            message.success(lang.reportSuccessfully);
                            return true;
                        } catch (error) {
                            message.error(error.message);
                            return false;
                        }
                    }}
                    submitter={{
                        render: ({ form }) => {
                            return [
                                <Button
                                    style={{ width: '130px', height: '32px', borderRadius: '4px' }}
                                    key="extra-reset"
                                    onClick={onCancel}
                                >
                                    {lang.btnCancel}
                                </Button>,
                                <Button
                                    style={{ width: '130px', height: '32px', borderRadius: '4px' }}
                                    type="primary"
                                    key="extra-submit"
                                    onClick={() => form?.submit?.()}
                                >
                                    {lang.btnSave}
                                </Button>,
                            ];
                        },
                    }}
                >
                    <Row gutter={16}>
                        <Col span={8}>
                            <ProFormText disabled={true} name="code" label={lang.productCode} placeholder={lang.productCode} />
                        </Col>
                        <Col span={8}>
                            <ProFormText disabled={true} name="name" label={lang.productName} placeholder={lang.productName} />
                        </Col>
                        <Col span={8}>
                            <ProFormDigit name="lostNumber" label={`${lang.quantity} (<=${item?.totalQuantity})`} placeholder={lang.placeholderQuantity} min={1} max={item?.totalQuantity || 1}
                                rules={
                                    [{
                                        required: true,
                                        message: lang.requiredQuantity,
                                    }]
                                } />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <ProFormTextArea
                                name="description"
                                label={lang.reportContent}
                                placeholder={lang.messageReportContent}
                                fieldProps={{
                                    rows: 2,
                                }}
                                rules={
                                    [{
                                        required: true,
                                        message: lang.requiredReportContent,
                                    }]
                                }
                            />
                        </Col>
                    </Row>

                </ModalForm>
            </Layout.Content>
        </Layout>
    );
};
export default WarehouseBlock;
