export const getLanguages = (t) => {
  return {
    login: t('login'),
    password: t('password'),
    slogan: t('slogan'),
    akaName: t('akaName'),
    akaDescription: t('akaDescription'),
    messagePassword: t('messagePassword'),
    placeholderPassword: t('placeholderPassword'),
    placeholderUsername: t('placeholderUsername'),
    messageUsername: t('messageUsername'),
    loading: t('loading'),

    //layout
    inbound: t('inbound'),
    receipt: t('receipt'),
    temporaryInbound: t('temporaryInbound'),
    storing: t('storing'),
    inboundControl: t('inboundControl'),
    inventory: t('inventory'),
    warehouseState: t('warehouseState'),
    reallocation: t('reallocation'),
    productManagement: t('productManagement'),
    outbound: t('outbound'),
    pickingOrder: t('pickingOrder'),
    packaging: t('packaging'),
    shippingOutboundControl: t('shippingOutboundControl'),
    configuration: t('configuration'),
    supplier: t('supplier'),
    product: t('product'),
    productCategory: t('productCategory'),
    location: t('location'),
    replenishment: t('replenishment'),
    saleOrder: t('saleOrder'),
    reporting: t('reporting'),
    auditOrder: t('auditOrder'),
    inventoryWarning: t('inventoryWarning'),
    performance: t('performance'),
    changePassword: t('changePassword'),
    logout: t('logout'),
    confirm: t('confirm'),
    confirmLogout: t('confirmLogout'),
    warehouse: t('warehouse'),
    user: t('user'),
    admin: t('admin'),

    //inbound/receipt
    receiptId: t('receiptId'),
    receiptCode: t('receiptCode'),
    inboundKind: t('inboundKind'),
    status: t('status'),
    receiptDate: t('receiptDate'),
    receiveDate: t('receiveDate'),
    from: t('from'),
    to: t('to'),
    creator: t('creator'),
    keyword: t('keyword'),
    search: t('search'),
    receiptList: t('receiptList'),
    doYouWantToDeleteTheseItems: t('doYouWantToDeleteTheseItems'),
    deleteReceiptsSuccessfully: t('deleteReceiptsSuccessfully'),
    receiptIdsHasBeenDeleted: t('receiptIdsHasBeenDeleted'),

    //inbound
    productCode: t('productCode'),
    productName: t('productName'),
    expectedQuantity: t('expectedQuantity'),
    cost: t('cost'),
    salePrice: t('salePrice'),
    expireDate: t('expireDate'),
    createReceipt: t('createReceipt'),
    createReceiptSuccessfully: t('createReceiptSuccessfully'),
    pleaseSelectAInboundKind: t('pleaseSelectAInboundKind'),
    messageReceiptDate: t('messageReceiptDate'),
    messageReceiveDate: t('messageReceiveDate'),
    productAdded: t('productAdded'),
    importCSV: t('importCSV'),
    pleaseSelecteAProduct: t('pleaseSelecteAProduct'),
    messageExpectedQuantity: t('messageExpectedQuantity'),
    messageCost: t('messageCost'),
    totalQuantity: t('totalQuantity'),
    zone: t('zone'),
    block: t('block'),
    shelf: t('shelf'),
    rack: t('rack'),
    confirmReceiptSuccessfully: t('confirmReceiptSuccessfully'),
    receiptDetail: t('receiptDetail'),
    productList: t('productList'),
    updateLocationSuccessfully: t('updateLocationSuccessfully'),
    updateLocation: t('updateLocation'),
    print: t('print'),
    messageTotalQuantity: t('messageTotalQuantity'),
    importDate: t('importDate'),
    report: t('report'),
    scanReturnProduct: t('scanReturnProduct'),
    reportErrorProduct: t('reportErrorProduct'),
    reportSuccessfully: t('reportSuccessfully'),
    reportContent: t('reportContent'),
    messageReportContent: t('messageReportContent'),
    btnSubmit: t('btnSubmit'),
    inputCode: t('inputCode'),
    packageCode: t('packageCode'),
    locationCode: t('locationCode'),
    completeScanReturnProductSuccessfully: t('completeScanReturnProductSuccessfully'),
    scanReturnProductComplete: t('scanReturnProductComplete'),
    scanMore: t('scanMore'),
    scan: t('scan'),
    storingDate: t('storingDate'),
    scanProduct: t('scanProduct'),
    storingScanProduct: t('storingScanProduct'),
    completeScanStoringSuccessfully: t('completeScanStoringSuccessfully'),
    storingScanProductComplete: t('storingScanProductComplete'),
    inboundControlDate: t('inboundControlDate'),
    inboundControlScanProduct: t('inboundControlScanProduct'),
    completeInboundControlScanSuccessfully: t('completeInboundControlScanSuccessfully'),
    inboundControlScanProductComplete: t('inboundControlScanProductComplete'),

    //admin
    username: t('username'),
    name: t('name'),
    role: t('role'),
    users: t('users'),
    requiredUsername: t('requiredUsername'),
    requiredPassword: t('requiredPassword'),
    requiredName: t('requiredName'),
    requiredRole: t('requiredRole'),
    requiredWarehouse: t('requiredWarehouse'),
    warehouseCode: t('warehouseCode'),
    warehouseName: t('warehouseName'),
    address: t('address'),
    warehouses: t('warehouses'),
    acreage: t('acreage'),

    btnCancel: t('btnCancel'),
    btnComplete: t('btnComplete'),
    btnAdd: t('btnAdd'),
    btnEdit: t('btnEdit'),
    btnConfirm: t('btnConfirm'),
    btnDelete: t('btnDelete'),
    btnSave: t('btnSave'),
    confirmQuestion: t('confirmQuestion'),
    confirmContent: t('confirmContent'),

    addZone: t('addZone'),
    addShelf: t('addShelf'),
    addBlock: t('addBlock'),
    zoneDetail: t('zoneDetail'),
    zoneCreate: t('zoneCreate'),
    zoneName: t('zoneName'),
    zoneAreDeletedSuccessfully: t('zoneAreDeletedSuccessfully'),
    blockAreDeletedSuccessfully: t('blockAreDeletedSuccessfully'),
    shelfAreDeletedSuccessfully: t('shelfAreDeletedSuccessfully'),
    areYouSureToDeleteThisZone: t('areYouSureToDeleteThisZone'),
    areYouSureToDeleteThisBlock: t('areYouSureToDeleteThisBlock'),
    areYouSureToDeleteThisShelf: t('areYouSureToDeleteThisShelf'),

    //Product category
    placeholderName: t('placeholderName'),
    placeholderSelectMethod: t('placeholderSelectMethod'),
    requiredCategoryName: t('requiredCategoryName'),
    requiredMethod: t('requiredMethod'),

    //Supplier
    placeholderSelectProductCate: t('placeholderSelectProductCate'),
    placeholderAddress: t('placeholderAddress'),
    placeholderPhoneNumber: t('placeholderPhoneNumber'),
    requiredCategory: t('requiredCategory'),
    requiredSupplierName: t('requiredSupplierName'),
    requiredEmail: t('requiredEmail'),
    requiredAddress: t('requiredAddress'),
    requiredPhoneNumber: t('requiredPhoneNumber'),

    //Product
    placeholderSelectSupplier: t('placeholderSelectSupplier'),
    placeholderCapacity: t('placeholderCapacity'),
    requiredImage: t('requiredImage'),
    requiredProductName: t('requiredProductName'),
    requiredSupplier: t('requiredSupplier'),
    requiredCapacity: t('requiredCapacity'),
    requiredStoreTime: t('requiredStoreTime'),

    //Replenishment
    placeholderMin: t('placeholderMin'),
    placeHolderMax: t('placeHolderMax'),
    requiredMin: t('requiredMin'),
    requiredMax: t('requiredMax'),
    placeholderSelectProduct: t('placeholderSelectProduct'),
    requiredProduct: t('requiredProduct'),

    //Location
    requiredZoneName: t('requiredZoneName'),
    requiredBlockName: t('requiredBlockName'),
    placeholderTotalshelf: t('placeholderTotalshelf'),
    requiredTotalshelf: t('requiredTotalshelf'),
    requiredShelfName: t('requiredShelfName'),
    placeholderTotalRack: t('placeholderTotalRack'),
    placeholderLevelPercent: t('placeholderLevelPercent'),
    requiredLevelPercent: t('requiredLevelPercent'),
    requiredTotalRack: t('requiredTotalRack'),

    //Sale order
    placeholderQuantity: t('placeholderQuantity'),
    requiredQuantity: t('requiredQuantity'),
    capacity: t('capacity'),
    success: t('success'),
    blockDetail: t('blockDetail'),
    blockCreate: t('blockCreate'),
    btnUpdate: t('btnUpdate'),
    btnCreate: t('btnCreate'),
    btnSearch: t('btnSearch'),
    blockName: t('blockName'),
    totalShelf: t('totalShelf'),
    updateShelf: t('updateShelf'),
    createShelf: t('createShelf'),
    update: t('update'),
    complete: t('complete'),
    shelfName: t('shelfName'),
    capacityRack: t('capacityRack'),
    levelPercent: t('levelPercent'),
    medium: t('medium'),
    high: t('high'),
    code: t('code'),
    createSuccessfully: t('createSuccessfully'),
    updateSuccessfully: t('updateSuccessfully'),
    deleteSuccessfully: t('deleteSuccessfully'),
    idsHasBeenDeleted: t('idsHasBeenDeleted'),
    email: t('email'),
    orderCode: t('orderCode'),
    userName: t('userName'),
    orderDate: t('orderDate'),
    orderQuantity: t('orderQuantity'),
    pickingquantity: t('pickingquantity'),
    placeholderSelectOrderCode: t('placeholderSelectOrderCode'),
    titlePicking: t('titlePicking'),
    suggestLocation: t('suggestLocation'),
    quantity: t('quantity'),
    createPicking: t('createPicking'),
    labelBooking: t('labelBooking'),
    placeholderSelectOrder: t('placeholderSelectOrder'),
    okText: t('okText'),
    cancelText: t('cancelText'),
    titleModalBooking: t('titleModalBooking'),
    scanCode: t('scanCode'),
    confirmPickupList: t('confirmPickupList'),
    titleModalPslitProduct: t('titleModalPslitProduct'),
    placeholderSelectPackageCode: t('placeholderSelectPackageCode'),
    btnDone: t('btnDone'),
    btnClose: t('btnClose'),
    titleModalSuccessSuggestLocation: t('titleModalSuccessSuggestLocation'),
    pickupList: t('pickupList'),
    generatePackageCode: t('generatePackageCode'),
    orderNumber: t('orderNumber'),
    productOrderCode: t('productOrderCode'),
    pickingList: t('pickingList'),
    packagingCode: t('packagingCode'),
    tempOutLot: t('tempOutLot'),
    orderandpackagingInfo: t('orderandpackagingInfo'),
    packagingDetail: t('packagingDetail'),
    orderandshippingInfo: t('orderandshippingInfo'),
    confirmPackageList: t('confirmPackageList'),
    outQuantity: t('outQuantity'),
    startDate: t('startDate'),
    endDate: t('endDate'),
    orderInfo: t('orderInfo'),
    inStock: t('inStock'),
    expirationDate: t('expirationDate'),
    storageExpirationDate: t('storageExpirationDate'),
    exportCSV: t('exportCSV'),
    agingStockAlert: t('agingStockAlert'),
    dotRestock: t('dotRestock'),
    productDetail: t('productDetail'),
    total: t('total'),
    inventorySummary: t('inventorySummary'),
    inboundsummary: t('inboundsummary'),
    productSummary: t('productSummary'),
    action: t('action'),
    warehouseBlockDetail: t('warehouseBlockDetail'),
    requiredReportContent: t('requiredReportContent'),
    editLocation: t('editLocation'),
    dotEditLocation: t('dotEditLocation'),
    onlyEditLocation: t('onlyEditLocation'),
    pendingState: t('pendingState'),
    inboundState: t('inboundState'),
    lostState: t('lostState'),
    updateProduct: t('updateProduct'),
    requiredZone: t('requiredZone'),
    requiredBlock: t('requiredBlock'),
    requiredShelf: t('requiredShelf'),
    requiredRack: t('requiredRack'),
    requiredExpirationDate: t('requiredExpirationDate'),
    requiredTotalQauntity: t('requiredTotalQauntity'),
    requiredCost: t('requiredCost'),
    requiredSalePrice: t('requiredSalePrice'),
    requiredStatus: t('requiredStatus'),
    requiredLostDate: t('requiredLostDate'),

    totalRackbetween: t('totalRackbetween'),
    backtoProductList: t('backtoProductList'),
    productInfo: t('productInfo'),
    storageTime: t('storageTime'),
    productImage: t('productImage'),
    imageExtra: t('imageExtra'),
    upload: t('upload'),
    methodExport: t('methodExport'),
    createDate: t('createDate'),
    createProduct: t('createProduct'),
    productCategoryName: t('productCategoryName'),
    productCategoryCode: t('productCategoryCode'),
    productCategoryList: t('productCategoryList'),
    updateProductCategoty: t('updateProductCategoty'),
    createProductCategoty: t('createProductCategoty'),
    minQuantity: t('minQuantity'),
    maxQuantity: t('maxQuantity'),
    notification: t('notification'),
    onHand: t('onHand'),
    toOrder: t('toOrder'),
    replenishmentList: t('replenishmentList'),
    createReplenishment: t('createReplenishment'),
    updateReplenishment: t('updateReplenishment'),
    backtoSupplierList: t('backtoSupplierList'),
    supplierInfo: t('supplierInfo'),
    phone: t('phone'),
    cooperationDate: t('cooperationDate'),
    supplierName: t('supplierName'),
    requiredCooperationDate: t('requiredCooperationDate'),
    supplierCode: t('supplierCode'),
    supplierList: t('supplierList'),
    createSupplier: t('createSupplier'),
    updateSupplier: t('updateSupplier'),
    createSaleOrder: t('createSaleOrder'),
    addProduct: t('addProduct'),
    editProduct: t('editProduct'),
    saleOrderDetail: t('saleOrderDetail'),
    orderList: t('orderList'),
    reportDate: t('reportDate'),
    firstName: t('firstName'),
    lastName: t('lastName'),
    lostDate: t('lostDate'),
    phoneLengthMessage: t('phoneLengthMessage'),
    phoneMessage: t('phoneMessage'),
    full: t('full'),
    empty: t('empty'),
    Medium: t('Medium'),
    resetPassword: t('resetPassword'),
    passwordNew: t('passwordNew'),
    currentPassword: t('currentPassword'),
    passwordConfirm: t('passwordConfirm'),
    zoneNameExist: t('zoneNameExist'),
    blockNameExist: t('blockNameExist'),
    shelfNameExist: t('shelfNameExist'),
    subTitleInboundControl: t('subTitleInboundControl'),
    subTitleOutboundCreatePickUpNotify: t('subTitleOutboundCreatePickUpNotify'),
    subTitleStoringNotify: t('subTitleStoringNotify'),
    subTitleInboundTemporaryNotify: t('subTitleInboundTemporaryNotify'),
    yes: t('yes'),
    no: t('no'),
    passwordRegex: t('passwordRegex'),
    updateImageLess: t('updateImageLess'),
    creatorName: t('creatorName'),
    invalidCode: t('invalidCode'),
    tryAgain: t('tryAgain'),
    orderStatus: t('orderStatus'),
    packageStatus: t('packageStatus'),
    noRecordsFound: t('noRecordsFound'),
    emailRequirePlaceholder: t('emailRequirePlaceholder'),
    messeageExpiration: t('messeageExpiration'),
    unlockAccount: t('unlockAccount'),
    January: t('January'),
    February: t('February'),
    March: t('March'),
    April: t('April'),
    May: t('May'),
    June: t('June'),
    July: t('July'),
    August: t('August'),
    September: t('September'),
    October: t('October'),
    November: t('November'),
    December: t('December'),
    splitproduct: t('splitproduct'),
    splitProductList: t('splitProductList'),
    vans: t('vans'),
    addVans: t('addVans'),
    createVans: t('createVans'),
    updateVans: t('updateVans'),
    vansName: t('vansName'),
    vansLicensePlate: t('vansLicensePlate'),
    placeholderLicensePlate: t('placeholderLicensePlate'),
    requiredLicensePlate: t('requiredLicensePlate'),
    requiredVansName: t('requiredVansName'),
    vansState: t('vansState'),
    vansDetail: t('vansDetail'),
    defaultCode: t('defaultCode'),
    requiredDefaultCode: t('requiredDefaultCode'),
    placeHolderDefaultCode: t('placeHolderDefaultCode'),
    placeholderSalePrice: t('placeholderSalePrice'),
    retailPrice: t('retailPrice'),
    placeholderRetailPrice: t('placeholderRetailPrice'),
    lot: t('lot'),
    requiredLot: t('requiredLot'),
    VAT: t('VAT'),
    Divison: t('Divison'),
    Category: t('Category'),
    divisonCode: t('divisonCode'),
    divisonName: t('divisonName'),
    createDivison: t('createDivison'),
    updateDivison: t('updateDivison'),
    placeholderDivisonName: t('placeholderDivisonName'),
    requiredDivisonName: t('requiredDivisonName'),
    categoryCode: t('categoryCode'),
    categoryName: t('categoryName'),
    createCategory: t('createCategory'),
    updateCategory: t('updateCategory'),
    placeholderCategoryName: t('placeholderCategoryName'),
    requiredParentCategoryName: t('requiredCategoryName'),
    requiredDivison: t('requiredDivison'),
    requiredParentCategory: t('requiredCategory'),
    categoryList: t('categoryList'),
    divisonList: t('divisonList'),
    DVT: t('DVT'),
    isActive: t('isActive'),
    contractNumber: t('contractNumber'),
    packingRule: t('packingRule'),
    NEW: t('NEW'),
    ERROR: t('ERROR'),
    TEMPORARY: t('TEMPORARY'),
    STORED: t('STORED'),
    MOVING: t('MOVING'),
    TEMPORARY_OUT: t('TEMPORARY_OUT'),
    LOST: t('LOST'),
    REALLOCATE: t('REALLOCATE'),
    ENABLE: t('ENABLE'),
    DISABLE: t('DISABLE'),
    requestReceipt: t('requestReceipt'),
    note: t('note'),
    itemCode: t('itemCode'),
    length: t('length'),
    width:  t('width'),
    height: t('height'),
    RECEIVE: t('RECEIVE'),
    dowloadTemplate: t('dowloadTemplate'),
    importSuccess: t('importSuccess'),
    exportPDF: t('exportPDF'),
    discount: t('discount'),
    isResources: t('isResources'),
    vansCode: t('vansCode'),
    createPO: t('createPO'),
    taxCode: t('taxCode'),
    requiredPackingRule: t('requiredPackingRule'),
    CANCELLED: t('CANCELLED'),
    PICKING: t('PICKING'),
    PACKAGED: t('PACKAGED'),
    deliveryTime: t('deliveryTime'),
    messeagePicking: t('messeagePicking'),
    weAre: t('weAre'),    
    fptCloudWms: t('fptCloudWms'),
    fptCloudWmsDescription: t('fptCloudWmsDescription'),
    totalSaleOrderItem: t('totalSaleOrderItem'),
    finishedGoods: t('finishedGoods'),
    rawMaterials: t('rawMaterials'),
  };
};
