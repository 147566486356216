import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Row, Col } from 'antd';
import { LoginOutlined } from '@ant-design/icons';

import ScanPackageForm from 'components/ScanPackageForm/scan-package.form';

import './style.less';
import { useSelector } from 'react-redux';

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const ShippingScan = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const { scanShippingForPackageCodes } = useSelector((state) => state.outbound);

  const extraButton = [
    <Button
      key="btn-cancle"
      type="primary"
      ghost
      onClick={() => {
        navigate('/dashboard/outbound/shipping');
      }}
    >
      {lang.btnCancel}
    </Button>,
    <Button
      key="btn-complete"
      type="primary"
      onClick={() => {
          navigate('complete');
          return true;
      }}
      disabled={!(scanShippingForPackageCodes?.length > 0)}
    >
      {lang.btnSubmit}
      <LoginOutlined />
    </Button>,
  ];

  const routes = [
    {
      path: '',
      breadcrumbName: 'Home',
    },
    {
      path: '',
      breadcrumbName: lang.outbound,
    },
    {
      path: '/shipping',
      breadcrumbName: lang.shippingOutboundControl,
    },
    {
      path: '',
      breadcrumbName: lang.scanCode,
    },
  ];

  return (
    <Layout className="storing-scan">
      <PageHeader
        ghost={false}
        title={lang.scanCode}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
        //onBack={onBack}
      />
      <Layout.Content style={{ margin: '12px 24px' }}>
        <Row gutter={20}>
          <Col span={24}>
            <ScanPackageForm type={'SHIPPING'} />
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  );
};

export default ShippingScan;
