import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Descriptions, Card, message } from 'antd';

import { supplierDetail } from './supplier.service';

import './style.less';
import { formatDate, formatDateFull } from 'utils/constants';
import moment from 'moment-timezone';

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const SupplierDetail = () => {
  const { supplierId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [supplier, setSupplier] = useState();

  useEffect(() => {
    supplierDetail(supplierId)
      .then(result => {
        setSupplier(result);
      })
      .catch((e) => message.error(e.message));
  }, [supplierId]);

  const extraButton = [
    <Button
      key="btn-complete"
      type="primary"
      onClick={() => {
        navigate('/dashboard/configuration/supplier');
      }}
    >
      {lang.backtoSupplierList}
    </Button>,
  ];

  const routes = [
    {
      path: '',
      breadcrumbName: 'Home',
    },
    {
      path: '',
      breadcrumbName: lang.configuration,
    },
    {
      path: '/supplier',
      breadcrumbName: lang.supplier,
    },
  ];
  return (
    <Layout className="supplier-detail layoutContent">
      <PageHeader
        ghost={false}
        title={lang.supplier}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        //onBack={onBack}
        className="customPageHeader"
      />
      <Layout.Content>
        <Card size="small" className="cardSearch">
          <Descriptions title={lang.supplierInfo}>
            <Descriptions.Item label={lang.code}>{supplier?.code}</Descriptions.Item>
            <Descriptions.Item label={lang.name}>{supplier?.name}</Descriptions.Item>
            <Descriptions.Item label={lang.productCategory}>{supplier?.productCategorys.map(productCate => productCate.name).toString()}</Descriptions.Item>
            <Descriptions.Item label={lang.phone}>{supplier?.phoneNumber}</Descriptions.Item>
            <Descriptions.Item label={lang.email}>{supplier?.email}</Descriptions.Item>
            <Descriptions.Item label={lang.address}>{supplier?.address}</Descriptions.Item>
            <Descriptions.Item label={lang.cooperationDate}>{moment(supplier?.cooperationDay, formatDateFull).format(formatDate)}</Descriptions.Item>
            <Descriptions.Item label={lang.status}>{lang[supplier?.status]}</Descriptions.Item>
          </Descriptions>
        </Card>

      </Layout.Content>

    </Layout>
  );
};

export default SupplierDetail;
