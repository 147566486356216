import environment from 'environments/environment';
import request from 'utils/request';

const zones = [
    {
        id: 1,
        code: 'Z-1',
        name: 'Zone A',
        capcity: 3,
        createDate: '2019-08-24',
        status: 'ENABLE',
        warehouseId: 1,
    },
];
const blocks = [
    {
        code: 'B_1',
        createDate: '2019-08-24',
        id: 1,
        name: 'Block A',
        shelfs: [{
            capacity: 100,
            name: 'Shelf A1',
            high: 50,
            id: 1,
            medium: 10,
            status: 'ENABLE',
            totalRack: 100,
            wareHouseId: 0,
            productCategory: {
                id: 1,
                name: 'Mỹ phẩm',
            },
        }],
        status: 'ENABLE',
        totalShelf: 4,
        wareHouseId: 1,
    },

];

export async function zoneList(payload) {
    if (environment.isMockData) {
        return Promise.resolve(zones);
    }
    return request(environment.api.zones + 'get-all', payload, 'GET');
}

export async function zoneCreate(payload) {
    if (environment.isMockData) {
        const newZone = {
            id: zones.length + 1,
            code: `Z-${zones.length + 1}`,
            name: payload.name,
            capacity: payload.capacity,
            createDate: '2019-08-24',
            status: 'ENABLE',
            warehouseId: 1,
        };
        zones.push(newZone);
        return Promise.resolve('OK');
    }
    return request(environment.api.zones + 'create', payload, 'POST');
}
export async function zoneUpdate(value, zoneSelected) {
    if (environment.isMockData) {
        zones.map(zone => {
            if (zone.id === zoneSelected.id) {
                Object.assign(zone, value);
            }
            return zone;
        });
        return Promise.resolve('OK');
    }
    const zoneUpdate = {
        ...value,
        id: zoneSelected.id,
        status: zoneSelected.status,
        warehouseId: zoneSelected.warehouseId,
    };
    return request(environment.api.zones + 'update', zoneUpdate, 'PUT');
}

export async function zoneDelete(id) {
    if (environment.isMockData) {
        zones.splice(id - 1, 1);
        return Promise.resolve('ok');
    }
    return request(environment.api.zones + `delete/${id}`, {}, 'DELETE');
}
export async function blockList(payload) {
    if (environment.isMockData) {
        return Promise.resolve(blocks);
    }
    return request(environment.api.blocks + 'get-all', payload, 'GET');
}
export async function blockCreate(payload) {
    if (environment.isMockData) {
        const newData = {
            code: `B_${blocks.length + 1}`,
            createDate: '2019-08-24',
            id: blocks.length + 1,
            name: payload.name,
            shelfs: [],
            status: 'ENABLE',
            totalShelf: payload.totalShelf,
            wareHouseId: 1,
        };
        blocks.push(newData);
        return Promise.resolve('OK');
    }

    return request(environment.api.blocks + 'create', payload, 'POST');
}
export async function blockUpdate(value, item) {
    if (environment.isMockData) {
        blocks.map(block => {
            if (block.id === item.id) { Object.assign(block, value); }
            return block;
        });
        return Promise.resolve('OK');
    }
    const blockUpdate = {
        ...value,
        id: item.id,
        status: item.status,
        warehouseId: item.warehouseId,
    };
    return request(environment.api.blocks + 'update', blockUpdate, 'PUT');
}
export async function blockDelete(id) {
    if (environment.isMockData) {
        blocks.splice(id - 1, 1);
        return Promise.resolve('ok');
    }
    return request(environment.api.blocks + `delete/${id}`, {}, 'DELETE');
}

export async function shelfDetail(id) {
    return request(environment.api.shelves + `get-one/${id}`, {}, 'GET');
}
export async function shelfCreate(values, item) {
    if (environment.isMockData) {
        blocks.map(block => {
            if (block.id === values.blockId) {
                block.shelfs.push({
                    capacity: values.capacity,
                    name: values.name,
                    high: values.high,
                    id: block.shelfs.length + 1,
                    medium: values.medium,
                    status: 'ENABLE',
                    totalRack: values.totalRack,
                    wareHouseId: 1,
                    productCategory: {
                        id: 1,
                        name: 'Mỹ phẩm',
                    },
                });
            }
            return block;
        });
        return Promise.resolve('OK');
    }
    delete values.blockName;
    Object.assign(values,{position:item.position});
    return request(environment.api.shelves + 'create', values, 'POST');
}
export async function shelfUpdate(value, item) {
    delete value.blockName;
    delete value.blockId;
    if (environment.isMockData) {
        blocks.map(block => {
            if (block.id === item.block.id) {
                block.shelfs.map(shelf => {
                    if (shelf.id === item.id) {
                        Object.assign(shelf, value);
                    }
                    return shelf;
                });
            }
            return block;
        });
        return Promise.resolve('OK');
    }
    delete value.blockName;
    const shelfUpdate = {
        ...value,
        id: item.id,
        status: item.status,
        warehouseId: item.warehouseId,
    };
    return request(environment.api.shelves + 'update', shelfUpdate, 'PUT');
}
export async function shelfDelete(id) {
    if (environment.isMockData) {
       blocks.map(block => {
            if (block.id === id.blockId) {
                const dataShelf = block.shelfs.filter(shelf => shelf.id !== id.shelfId);
                block.shelfs = dataShelf;
            }
            return block;
        });
        return Promise.resolve(blocks);
    }
    return request(environment.api.shelves + `delete/${id.shelfId}`, {}, 'DELETE');
}
