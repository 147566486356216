import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Button, Layout, PageHeader, Tabs, Form, Image, message, Card, Row, Col, Pagination, Spin } from 'antd';
import { ProFormSelect, ProFormDateRangePicker, ProFormText, ModalForm } from '@ant-design/pro-form';
import './style.less';
import TabPane from '@ant-design/pro-card/lib/components/TabPane';
import { blockList, productcategoryList, productsList, receiptList, zoneList } from './product-management.service';
import { error, hashStatusInbound, hashStatusPending, hasStatusLost, lost } from './product-management.constants';
import iconGrid from 'assets/icons/icon-grid.svg';
import iconList from 'assets/icons/icon-list.svg';
import { pickBy } from 'lodash';
import { useSelector } from 'react-redux';
import { defaultPage, formatDate, formatDateFull } from 'utils/constants';
import Helper from 'utils/helpers';
import ProTableCusTom from 'components/TableCustom/Protable';
import ProductModalForm from './productModalForm';
import moment from 'moment-timezone';
const defaultSort = {
    // sortDirection: 'desc',
    // sortBy: 'createDate',
};

const breadcrumbItemRender = (route, params, routes, paths) => {
    const last = routes.indexOf(route) === routes.length - 1;
    return (last || route.path !== '') ? (
        <span>{route.breadcrumbName}</span>
    ) : (
        <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
    );
};

const ProductManagement = () => {
    // const dispatch = useDispatch();
    const { t } = useTranslation();
    const lang = getLanguages(t);
    const [tab, setTab] = useState('2');
    const [items, setItems] = useState([]);
    const [isList, setIslist] = useState(true);
    const [visible, setVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState();
    const [visiblePrint, setVisiblePrint] = useState(false);
    const [form] = Form.useForm();
    const [productCategory, setProductCategory] = useState([]);
    const [listZone, setListZone] = useState([]);
    const [listBlock, setListBlock] = useState([]);
    const [totalItems, setTotalItems] = useState();
    const [loading, setLoading] = useState(false);
    const { currentWarehouse } = useSelector((state) => state.auth);
    //Pagination
    const [params, setParams] = useState({ multipleStatus: 'TEMPORARY,MOVING,STORED,REALLOCATE,TEMPORARY_OUT', ...defaultPage });
    // const [pageSize, SetPageSize] = useState(10);
    // const [currentPage, setCurrentPage] = useState(1);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [isReload, setIsReload] = useState(false);
    const [loadingExport,setLoadingExport] = useState(false);
    const onSelectChange = selectedRowKeys => {
        setSelectedRowKeys(selectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,

    };
    useEffect(() => {
        productcategoryList({ warehouseId: currentWarehouse.id, status: 'ENABLE' }).then(result => {
            setProductCategory(result.data);
        });
        zoneList({ warehouseId: currentWarehouse.id }).then(result => {
            setListZone(result.data);
        });
        blockList({ warehouseId: currentWarehouse.id }).then(result => {
            setListBlock(result.data);
        });
    }, [currentWarehouse]);
    const columns = [
        {
            title: lang.defaultCode,
            dataIndex: 'masterProduct',
            key: 'barcode',
            width: '10%',
            render: value => value?.barCode,
            fixed: true,
        },
        {
            title: lang.productCode,
            dataIndex: 'code',
            key: 'productcode',
            width: '10%',
            render: (text, record) => {
                return (
                    <Button size="small" type="link" onClick={() => {
                        setSelectedItem(record);
                        setVisible(true);
                    }
                    }>
                        {text}
                    </Button>
                );
            },
            fixed: true,
        },
        {
            title: lang.productCategory,
            key: 'productCategory',
            width: '15%',
            render: (record) => {
                return record?.masterProduct?.productCategory?.name;
            },
        },
        {
            title: lang.productName,
            key: 'productname',
            width: '20%',
            render: (record) => {
                return record?.masterProduct?.name;
            },
        },
        {
            title: lang.status,
            dataIndex: 'status',
            width: '10%',
            key: 'status',
            render: (record) => {
                return lang[record];
            },
        },
        {
            title: lang.expectedQuantity,
            dataIndex: 'expectedQuantity',
            width: '12%',
            key: 'expectedQuantity',
            render: value => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        },
        {
            title: lang.totalQuantity,
            dataIndex: 'totalQuantity',
            width: '8%',
            key: 'totalQuantity',
            render: value => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        },
        {
            title: lang.block,
            key: 'block',
            dataIndex: 'block',
            width: '8%',
            render: value => value?.name,
        },
        {
            title: lang.shelf,
            key: 'shelf',
            dataIndex: 'rack',
            width: '8%',
            render: value => value?.shelf?.name,
        },
        {
            title: lang.rack,
            key: 'rack',
            dataIndex: 'rack',
            width: '10%',
            render: value => value?.code,
        },
        {
            title: lang.reportContent,
            hideInTable: tab !== '1',
            key: 'reportContent',
            dataIndex: 'description',
            width: '10%',
        },

    ];
    const fetchProduct = (params, currentWarehouse) => {
        setLoading(true);
        productsList({ ...params, ...defaultSort, warehouseId: currentWarehouse.id }).then(result => {
            if (result.data.length === 0 && tab === '3' && isList === false) {
                message.error(`${lang.noRecordsFound}`);
                setLoading(false);
            } else {
                setItems(result.data);
                setTotalItems(result.totalItem);
                setLoading(false);
            }
        }).catch(error => { message.log(error.message); setLoading(false); });
    };
    useEffect(() => {
        fetchProduct(params, currentWarehouse);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, currentWarehouse, isReload]);

    const routes = [
        {
            path: '',
            breadcrumbName: 'Home',
        },
        {
            path: '',
            breadcrumbName: lang.inventory,
        },
        {
            path: '/productmanagement',
            breadcrumbName: lang.productManagement,
        },
    ];
    return (
        <Layout className="layoutContent">
            <PageHeader
                ghost={false}
                title={lang.productManagement}
                breadcrumb={{
                    routes,
                    itemRender: breadcrumbItemRender,
                }}
                extra={[
                    tab === '2' && <Button key='0' loading={loadingExport} style={{ position: 'absolute', right: 107 }} type='primary' onClick={async() => {
                        setLoadingExport(true);
                        const response = await productsList({ warehouseId: currentWarehouse.id, multipleStatus: 'TEMPORARY,MOVING,STORED,REALLOCATE,TEMPORARY_OUT' });
                        const dataRemp = response?.data?.map(product => {
                            return {
                                'Mã lô': product.code,
                                'Barcode': product?.masterProduct?.barCode,
                                'Tên': product?.masterProduct.name,
                                'Ngày nhận': product?.receipt?.receiptDate && moment(product?.receipt?.receiptDate, formatDateFull).format(formatDate),
                                'Số PO': product?.receipt?.code,
                                'Số lượng tồn': product.totalQuantity,
                                'Block': product?.block?.name,
                                'Shelf': product?.rack?.shelf?.name,
                                'Rack': product?.rack?.code,
                                'Ngày hết hạn': product?.expireDate && moment(product?.expireDate, formatDateFull).format(formatDate),
                                'Status': product?.status,
                            }
                        })
                        Helper.dowloadXLSX(dataRemp, 'Product');
                        setLoadingExport(false);
                    }}>{lang.exportCSV}</Button>,
                    <Button style={{ lineHeight: 1, border: '0' }} className={isList === false ? 'selected' : null} icon={<img alt="" src={iconGrid} />} key="2" onClick={() => setIslist(false)}></Button>,
                    <Button style={{ lineHeight: 1, border: '0' }} className={isList === true ? 'selected' : null} icon={<img alt="" src={iconList} />} key="1" onClick={() => setIslist(true)}></Button>,
                ]}
                footer={
                    <Tabs onTabClick={(value) => {
                        if (value === '1') {
                            setParams({ status: 'ERROR', ...defaultPage });
                        } else if (value === '3') {
                            setItems([]);
                            setTotalItems(0);
                            form.resetFields();
                            setTab(value);
                            setIslist(false);
                            return true;
                        } else {
                            setParams({ multipleStatus: 'TEMPORARY,MOVING,STORED,REALLOCATE,TEMPORARY_OUT', ...defaultPage });
                        }
                        setTab(value);
                        // setIslist(true);
                        form.resetFields();
                    }}
                        defaultActiveKey={tab}
                        tabBarExtraContent={false}
                        className="tabCustom"
                    >
                        <TabPane tab={lang.inboundState} key="2" />
                        <TabPane tab={lang.pendingState} key="1" />
                        <TabPane tab={lang.lostState} key="3" />
                    </Tabs>
                }
                className="customPageHeader"
            />
            <Layout.Content>
                <Card size="small" className="cardSearch">
                    <Form form={form}
                        onFinish={(value) => {
                            const cleanValue = pickBy(value, v => v !== undefined && v !== '' && v !== null);
                            if (cleanValue?.reportDate) {
                                cleanValue.startReportDate = cleanValue.reportDate[0].startOf('date').format(formatDateFull);
                                cleanValue.endReportDate = cleanValue.reportDate[1].endOf('date').format(formatDateFull);
                                delete cleanValue.reportDate;
                            }
                            if (cleanValue?.lostDate) {
                                cleanValue.startLostDate = cleanValue.lostDate[0].startOf('date').format(formatDateFull);
                                cleanValue.endLostDate = cleanValue.lostDate[1].endOf('date').format(formatDateFull);
                                delete cleanValue.lostDate;
                            }
                            if (cleanValue?.importDate) {
                                cleanValue.startDate = cleanValue.importDate[0].startOf('date').format(formatDateFull);
                                cleanValue.endDate = cleanValue.importDate[1].endOf('date').format(formatDateFull);
                                delete cleanValue.importDate;
                            }
                            if (tab === '3') {
                                if (Object.keys(cleanValue).length !== 0) {
                                    cleanValue.status = lost;
                                    setParams({ ...cleanValue, page: 1, limit: params.limit });
                                }
                                return true;
                            }
                            if (!cleanValue?.status) {
                                if (tab === '1') {
                                    cleanValue.status = error;
                                } else {
                                    cleanValue.multipleStatus = 'TEMPORARY,MOVING,STORED,REALLOCATE,TEMPORARY_OUT';
                                }
                            }
                            setParams({ ...cleanValue, page: 1, limit: params.limit });
                        }}
                        className="customFormSearch"
                    >
                        {tab !== '3' ? <Row gutter={16}>
                            <Col xxl={5} xl={6} sm={10} xs={24} className="customItem">
                                <ProFormSelect label={lang.productCategory} name="productCategoryId" placeholder={lang.productCategory}
                                    options={productCategory?.map(cate => {
                                        return {
                                            value: cate.id,
                                            label: cate.name,
                                        };
                                    })}
                                />
                            </Col>
                            <Col xxl={4} xl={4} sm={6} xs={24} className="customItem">
                                <ProFormSelect label={lang.receipt} name="receiptId" placeholder={lang.receipt} request={async () => {
                                    try {
                                        const receipts = await receiptList({ warehouseId: currentWarehouse.id, status: 'RECEIVE' });
                                        return receipts.data?.map(receipt => {
                                            return {
                                                label: receipt.code,
                                                value: receipt.id,
                                                key: receipt.id
                                            };
                                        })
                                    } catch (error) {
                                        return []
                                    }
                                }}
                                    fieldProps={{
                                        showSearch: true
                                    }}
                                />
                            </Col>
                            <Col xxl={3} xl={4} sm={6} xs={24} className="customItem">
                                <ProFormSelect label={lang.status} name="status" placeholder={lang.status}
                                    options={tab === '1' ? Object.values(hashStatusPending).map(value => { return { value: value.text, label: lang[value.text] } }) : Object.values(hashStatusInbound).map(value => { return { value: value.text, label: lang[value.text] } })} />
                            </Col>
                            <Col xxl={5} xl={6} sm={8} xs={24} className="customItem">
                                {tab === '2' ? <ProFormDateRangePicker label={lang.receiveDate} name="importDate" placeholder={[lang.startDate, lang.endDate]}
                                    fieldProps={{
                                        format: formatDate,
                                    }}
                                />
                                    : <ProFormDateRangePicker label={lang.reportDate} name="reportDate" placeholder={[lang.startDate, lang.endDate]}
                                        fieldProps={{
                                            format: formatDate,
                                        }}
                                    />}
                            </Col>
                            <Col xxl={3} xl={4} sm={8} xs={14}>
                                <ProFormText name="keyword" placeholder={lang.keyword} />
                            </Col>
                            <Col >
                                <Button type={'primary'} style={{ borderRadius: '4px', width: '111px' }} htmlType="submit" ghost >{lang.btnSearch}</Button>
                            </Col>
                        </Row> : <Row gutter={16}>
                            <Col xxl={5} md={6} xs={24} className="customItem">
                                <ProFormSelect label={lang.productCategory} name="productCategoryId" placeholder={lang.productCategory}
                                    options={productCategory?.map(cate => {
                                        return {
                                            value: cate.id,
                                            label: cate.name,
                                        };
                                    })}
                                />
                            </Col>
                            <Col xxl={5} md={6} xs={24} className="customItem">
                                <ProFormText label={lang.productCode} name="keyword" placeholder={lang.productCode} />
                            </Col>
                            <Col xxl={5} md={6} xs={16} className="customItem">
                                <ProFormDateRangePicker label={lang.lostDate} name="lostDate" placeholder={[lang.startDate, lang.endDate]}
                                    fieldProps={{
                                        format: formatDate,
                                    }}
                                />
                            </Col>
                            <Col >
                                <Button type={'primary'} style={{ borderRadius: '4px', width: '111px' }} onClick={() => form?.submit()} ghost >{lang.btnSearch} </Button>
                            </Col>
                        </Row>}
                    </Form>
                </Card>
                {isList === true ? <ProTableCusTom
                    rowKey="id"
                    rowSelection={tab === '2' ? rowSelection : false}
                    headerTitle={lang.productList}
                    columns={columns}
                    loading={loading}
                    scroll={{ x: 1500 }}
                    dataSource={items || null}
                    search={false}
                    options={{
                        search: false,
                        reload: false,
                        density: false,
                    }}
                    onChange={(pagination) => {
                        if (pagination.pageSize !== params.limit) {
                            params.page = 1;
                        } else {
                            params.page = pagination.current;
                        }
                        params.limit = pagination.pageSize;
                        setParams({ ...params });
                    }}
                    pagination={{
                        total: totalItems,
                        showSizeChanger: true,
                        pageSize: params?.limit,
                        current: params?.page,
                        showTotal: false,
                    }}
                    tableAlertRender={false}
                    toolbar={{
                        actions: [
                            selectedRowKeys.length > 0 && tab === '2' && <Button
                                onClick={() => {
                                    setVisiblePrint(true);
                                }}
                                type={'primary'}
                                style={{ width: 110, borderRadius: 4 }}>{lang.print}</Button>,
                            tab !== '2' && <Button
                                onClick={() => {
                                    const itemCSV = items?.map(item => {
                                        const { id, code, expireDate, expectedQuantity, description, totalQuantity } = item;
                                        const { DVT, VAT, barCode, itemCode, height, width, length, method, name, packing, purchasePrice, retailPrice, salePrice } = item?.masterProduct;
                                        return {
                                            id,
                                            code,
                                            expireDate,
                                            expectedQuantity,
                                            description,
                                            DVT,
                                            VAT,
                                            barCode,
                                            totalQuantity,
                                            itemCode,
                                            height,
                                            width,
                                            length,
                                            method,
                                            name,
                                            packing,
                                            purchasePrice,
                                            retailPrice,
                                            salePrice,
                                            zoneName: item?.zone?.name
                                        }
                                    })
                                    Helper.dowloadXLSX(itemCSV, 'ErrorData');
                                }}
                                type={'primary'}
                                style={{ width: 110, borderRadius: 4 }}>{lang.exportCSV}</Button>,
                        ],
                    }}
                /> : <Spin spinning={loading}><Row gutter={[24, 16]}>
                    {items.map(item => {
                        return <Col key={item.id} xxl={6} xl={8} lg={12} md={12} sm={12}>
                            <Card key={item.id} style={{ cursor: 'pointer', borderRadius: 4 }}
                                bodyStyle={{ padding: '12px 12px' }}
                                onClick={() => { setSelectedItem(item); setVisible(true); }}>
                                <Row gutter={24}>
                                    <Col flex='118px'>
                                        <Image
                                            width={118}
                                            height={124}
                                            src={item?.masterProduct?.image}
                                            preview={false}
                                            style={{ objectFit: 'cover' }}
                                        />
                                    </Col>
                                    <Col span={13}>
                                        <Row gutter={16}>
                                            <Col span={24}>{`${lang.productCode}: ${item.code}`}</Col>
                                            <Col style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} span={24}>{`${lang.name}: ${item?.masterProduct?.name}`}</Col>
                                            <Col span={24}>{`${lang.salePrice}: ${Helper.numberWithCommas(item.salePrice)}`}</Col>
                                            <Col span={24}>{`${lang.total}: ${item.totalQuantity}`}</Col>
                                            <Col span={24}>{`${lang.status}: ${hashStatusInbound[item.status]?.text || hashStatusPending[item.status]?.text || hasStatusLost[item.status]?.text}`}</Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>;
                    })}
                </Row>
                    {totalItems > 0 && <Row justify='end'>
                        <Col style={{ paddingLeft: 0, paddingRight: 0, marginTop: 15 }}>
                            <Pagination
                                size="small"
                                current={params.page}
                                total={totalItems}
                                showSizeChanger={true}
                                onChange={(page, size) => {
                                    if (size !== params.limit) {
                                        params.page = 1;
                                    } else {
                                        params.page = page;
                                    }
                                    params.limit = size;
                                    setParams({ ...params });
                                }}
                                pageSize={params.limit}
                            />
                        </Col>
                    </Row>}
                </Spin>}
                {/* <ConfigProvider locale={enUSIntl}> */}
                <ProductModalForm
                    visible={visible}
                    setVisible={setVisible}
                    selectedItem={selectedItem}
                    listZone={listZone}
                    listBlock={listBlock}
                    productCategory={productCategory}
                    setIsReload={setIsReload}
                    isReload={isReload}
                />
                {/* </ConfigProvider> */}
                <ModalForm
                    title={lang.print}
                    visible={visiblePrint}
                    width="1122px"
                    submitter={{
                        render: ({ form }) => {
                            return [
                                <Button
                                    style={{ width: '120px', height: '32px' }}
                                    key="extra-reset"
                                    onClick={() => {
                                        setVisiblePrint(false);
                                    }}
                                >
                                    {lang.btnCancel}
                                </Button>,
                                <Button
                                    style={{ width: '120px', height: '32px' }}
                                    type="primary"
                                    key="extra-submit"
                                    onClick={() => {
                                        Helper.dowloadXLSX(items?.filter(item => selectedRowKeys?.includes(item.id)).map(item => {
                                            return {
                                                code: item.code,
                                                name: item.masterProduct.name,
                                                receiptDate: item?.receipt?.receiptDate && moment(item?.receipt?.receiptDate, formatDateFull).format(formatDate),
                                            }
                                        }), 'productCode');
                                        setVisiblePrint(false);
                                    }}
                                >
                                    {lang.print}
                                </Button>,
                            ];
                        },
                    }}
                    onVisibleChange={setVisiblePrint}
                    modalProps={{
                        closable: false,
                        centered: true,
                        destroyOnClose: true
                    }}
                >
                    <Row gutter={[16, 16]} className="scrollYStyle">
                        {items?.filter(item => selectedRowKeys.indexOf(item.id) > -1)?.map((value, index) => {
                            return (
                                <Col key={index} span={6}>
                                    <Card bodyStyle={{ padding: 0, textAlign: 'center' }}>
                                        {/* <QRCode value={value.code} renderAs={'svg'} style={{ margin: 'auto', width: '100%' }} />
                                        <Barcode value={value.code} height={70} background="#EFF1F1" width={2} /> */}
                                        {Helper.genarateQRcode([value.code])}
                                        {Helper.genarateBarcode([value.code])}
                                        <div style={{ textAlign: 'center' }}>{`${lang.code}: ${value.code}`}</div>
                                        <div style={{ textAlign: 'center' }}>{`${lang.product}: ${value?.masterProduct?.name}`}</div>
                                        <div style={{ textAlign: 'center' }}>{`Ngày nhập: ${moment(value?.receipt?.receiptDate, formatDateFull).format(formatDate)}`}</div>
                                    </Card>
                                </Col>
                            );
                        })}
                    </Row>
                </ModalForm>
            </Layout.Content>
        </Layout>
    );
};

export default ProductManagement;
