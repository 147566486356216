import React, { useState } from 'react';
import { Card, Input, Col, Row, Button, Tag, Modal, Form, InputNumber } from 'antd';
import { TweenOneGroup } from 'rc-tween-one';
import PropTypes from 'prop-types';
import { ScanOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { scanReturnProductCodeAdd, scanStoringProductCodeAdd, scanInboundControlProductCodeAdd } from 'providers/InboundProvider/actions';
import { scanPickingProductCodeAdd, scanPackagingProductCodeAdd, scanSplitProduct } from 'providers/OutboundProvider/actions';
import { ProFormSelect } from '@ant-design/pro-form';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import { scanReallocationProduct } from 'providers/InventoryProvider/actions';
// import soundOn from '../../assets/audio/SpeechOn.wav';
// import soundOff from '../../assets/audio/SpeechOff.wav';
import { BASE64_NO, BASE64_YES } from 'utils/constants';
const ScanProductForm = ({ type, selectOrderProduct, pickingOrder, setSelectedOrder, selectedOrder }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [countModal, setCountModal] = useState(false);
  const { scanReturnForProductCodes, scanStoringForProductCodes, scanInboundControlForProductCodes } = useSelector((state) => state.inbound);
  const { scanPickingForProductCodes, scanPackagingForProductCodes, scanSplitProductCode } = useSelector((state) => state.outbound);
  const { scanReallocationProductCode } = useSelector((state) => state.inventory);
  const audioOn = new Audio("data:audio/wav;base64," + BASE64_YES);
  const audioOff = new Audio("data:audio/wav;base64," + BASE64_NO);
  const [barCode, setBarCode] = useState();
  //Product code
  //['AP1001', 'AP1002', 'AP1003', 'AP1004', 'AP1005', 'AP1006', 'AP1007']
  let initValue = [];
  if (type === 'SCAN_RETURN') {
    initValue = scanReturnForProductCodes;
  }

  if (type === 'STORING') {
    initValue = scanStoringForProductCodes;
  }

  if (type === 'INBOUND_CONTROL') {
    initValue = scanInboundControlForProductCodes
  }

  if (type === 'PICKING') {
    initValue = scanPickingForProductCodes;
  }

  if (type === 'PACKAGING') {
    initValue = scanPackagingForProductCodes || [];
  }
  if (type === 'INVENTORY') {
    initValue = scanReallocationProductCode || [];
  }
  if (type === 'SPLIT') {
    initValue = scanSplitProductCode || [];
  }
  const [productTags, setProductTags] = useState(initValue);
  const [productInputValue, setProductInputValue] = useState();
  const forMapProduct = tag => {
    const tagElem = (
      <Tag
        style={{ marginBottom: 10 }}
        closable
        onClose={e => {
          e.preventDefault();
          handleCloseProduct(tag);
        }}
      >
        {tag}
      </Tag>
    );
    return (
      <span key={tag} style={{ display: 'inline-block' }}>
        {tagElem}
      </span>
    );
  };

  const handleCloseProduct = removedTag => {
    const tagsHasRemoved = ['PICKING', 'PACKAGING'].includes(type) ? productTags.filter(tag => tag?.code !== removedTag) : productTags.filter(tag => tag !== removedTag);

    if (type === 'SCAN_RETURN') {
      dispatch(scanReturnProductCodeAdd(tagsHasRemoved));
    }

    if (type === 'STORING') {
      dispatch(scanStoringProductCodeAdd(tagsHasRemoved));
    }

    if (type === 'INBOUND_CONTROL') {
      dispatch(scanInboundControlProductCodeAdd(tagsHasRemoved));
    }

    if (type === 'PICKING') {
      // dispatch(scanPickingProductCodeAdd(tagsHasRemoved));
      dispatch(scanPickingProductCodeAdd(tagsHasRemoved));
    }

    if (type === 'PACKAGING') {
      dispatch(scanPackagingProductCodeAdd(tagsHasRemoved));
    }
    if (type === 'INVENTORY') {
      dispatch(scanReallocationProduct(tagsHasRemoved));
    }
    if (type === 'SPLIT') {
      dispatch(scanSplitProduct(tagsHasRemoved));
    }

    setProductTags(tagsHasRemoved);
  };

  const handleInputConfirmProduct = () => {
    let tagsHasInput = productTags;
    if (type !== 'PICKING' && type !== 'PACKAGING') {
      if (productInputValue && productTags.indexOf(productInputValue) === -1) {
        tagsHasInput = [...productTags, productInputValue];
      }

      if (type === 'SCAN_RETURN') {
        dispatch(scanReturnProductCodeAdd(tagsHasInput));
      }

      if (type === 'STORING') {
        dispatch(scanStoringProductCodeAdd(tagsHasInput));
      }

      if (type === 'INBOUND_CONTROL') {
        dispatch(scanInboundControlProductCodeAdd(tagsHasInput));
      }

      if (type === 'SPLIT') {
        dispatch(scanSplitProduct(tagsHasInput));
      }
      if (type === 'INVENTORY') {
        dispatch(scanReallocationProduct(tagsHasInput));
      }
    }
    else if (productInputValue && type === 'PACKAGING') {
      const product = pickingOrder?.pickingProducts?.find(product => product.masterProduct.barCode === productInputValue);
      if (product) {
        audioOn.play();
        // tagsHasInput = [...productTags, productInputValue];
        // dispatch(scanPackagingProductCodeAdd(tagsHasInput));
        setCountModal(true);
        return true;
      }
      else {
        audioOff.play();
        Modal.confirm({
          title: 'Bạn có muốn thêm sản phẩm?',
          icon: <ExclamationCircleOutlined />,
          content: 'Mã sản phẩm bạn vừa nhập không có trong danh sách sản phẩm đã lấy',
          okText: lang.okText,
          cancelText: lang.cancelText,
          onOk() {
            // tagsHasInput = [...productTags, productInputValue];
            // setProductTags(tagsHasInput);
            // setProductInputValue('');
            // dispatch(scanPackagingProductCodeAdd(tagsHasInput));
            setCountModal(true);
          },
        });
        return true;
      }
    }
    else {
      if (productInputValue && selectOrderProduct.length > 0) {
        const product = selectOrderProduct?.find(product => product.code === productInputValue && product?.masterProduct?.barCode === barCode);
        if (product) {
          audioOn.play();
          setCountModal(true);
          return true;
          // tagsHasInput = [...productTags, productInputValue];
          // dispatch(scanPickingProductCodeAdd(tagsHasInput));
        }
        else {
          audioOff.play();
          Modal.confirm({
            title: 'Bạn có muốn thêm sản phẩm?',
            icon: <ExclamationCircleOutlined />,
            content: 'Mã sản phẩm bạn vừa nhập không có trong danh sách sản phẩm gợi ý',
            okText: lang.okText,
            cancelText: lang.cancelText,
            onOk() {
              // tagsHasInput = [...productTags, productInputValue];
              // setProductTags(tagsHasInput);
              // setProductInputValue('');
              // dispatch(scanPickingProductCodeAdd(tagsHasInput));
              setCountModal(true);
            },
          });
          return true;
        }
      }
    }
    setProductTags(tagsHasInput);
    setProductInputValue('');
  };

  const productTagChild = ['PICKING', 'PACKAGING'].includes(type) ? productTags.map(item => forMapProduct(item.code)) : productTags.map(item => forMapProduct(item));

  return (
    <>
      <Card title={type === 'PACKAGING' ? <>
        <Row>
          <Col>
            <ProFormSelect label={lang.orderCode} required={true} placeholder={lang.orderCode}
              options={pickingOrder?.orders?.map(order => {
                return {
                  label: order.code,
                  value: order.id
                }
              })}
              fieldProps={{
                onChange: async (value) => {
                  const order = pickingOrder?.orders.find(order => order.id === value);
                  setSelectedOrder(order);
                }
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            {lang.defaultCode}
          </Col>
        </Row>
      </> : lang.productCode
      }>
        <Row>
          <Col><label>{lang.inputCode}</label></Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: 10 }}>
          <Col col={12} style={{ flexDirection: 'row', display: 'flex' }}>
            <Input
              type="text"
              size="small"
              value={productInputValue}
              onChange={(item) => setProductInputValue(item.target.value)}
              // onBlur={handleInputConfirmProduct}
              // onPressEnter={handleInputConfirmProduct}
              placeholder={type === 'PACKAGING' ? lang.defaultCode : lang.productCode}
            />
            {type === 'PICKING' && <Input
              type="text"
              size="small"
              value={barCode}
              onChange={(item) => setBarCode(item.target.value)}
              onBlur={handleInputConfirmProduct}
              onPressEnter={handleInputConfirmProduct}
              placeholder={lang.defaultCode}
              style={{ marginLeft: '10px' }}
            />}
          </Col>
          <Col col={6}>
            <Button type={'primary'} ghost icon={<ScanOutlined />} />
          </Col>
          <Col col={6}>
            <Button key="btn-add-product" onClick={handleInputConfirmProduct} type={'primary'} icon={<PlusOutlined />}>{lang.btnAdd}</Button>
          </Col>
        </Row>
        <Row style={{ marginTop: 24 }}>
          <Col span={24}>
            <div style={{ marginBottom: 16 }}>
              <TweenOneGroup
                enter={{
                  scale: 0.8,
                  opacity: 0,
                  type: 'from',
                  duration: 100,
                  onComplete: e => {
                    e.target.style = '';
                  },
                }}
                leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
                appear={false}
              >
                {productTagChild}
              </TweenOneGroup>
            </div>
          </Col>
        </Row>
      </Card>
      {/* <Modal
        visible={modalScan}
        title={'Scan mã sản phẩm'}
        onCancel={() => { setModalScan(false); setProductInputValue(''); }}
        destroyOnClose={true}
        width={570}
        centered
        footer={null}
        wrapClassName="temporary-inbound-product-report-modal"
      >
        <Form onFinish={(values) => {
          try {
            const { barCode } = values;
            const product = selectOrderProduct?.find(product => product.code === productInputValue);
            if (product?.masterProduct?.barCode === barCode) {
              setCountModal(true);
              return true;
            }
            throw new Error('Mã sản phẩm không chính xác');
          } catch (error) {
            message.error(error.message);
          }

        }}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item name='barCode' label={lang.defaultCode} rules={[
                {
                  required: true,
                }
              ]}>
                <Input type="text" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={4} offset={16} style={{ textAlign: 'right' }}>
              <Button style={{ width: '100%' }} type="primary" onClick={() => { setModalScan(false); setProductInputValue(''); }}>{lang.btnCancel}</Button>
            </Col>
            <Col span={4} style={{ textAlign: 'right' }}>
              <Button style={{ width: '100%' }} type="primary" htmlType="submit">{lang.btnSubmit}</Button>

            </Col>
          </Row>

        </Form>
      </Modal> */}
      <Modal
        visible={countModal}
        title={'Số lượng sản phẩm'}
        onCancel={() => setCountModal(false)}
        destroyOnClose={true}
        width={570}
        centered
        footer={null}
        wrapClassName="temporary-inbound-product-report-modal"
      >
        <Form onFinish={(values) => {
          if (type === 'PACKAGING') {
            const product = selectedOrder?.productOrders?.find(product => product?.masterProduct?.barCode === productInputValue);
            if (product?.total < values?.count) {
              Modal.confirm({
                title: 'Bạn có muốn tiếp tục',
                content: 'Mặt hàng số lượng đang lấy lớn hơn đơn hàng. Bạn có muốn hoàn tất không?',
                icon: <ExclamationCircleOutlined />,
                onOk() {
                  const tagsHasInput = [...productTags, { code: productInputValue, count: values?.count }];
                  setProductTags(tagsHasInput);
                  setProductInputValue('');
                  setBarCode();
                  dispatch(scanPackagingProductCodeAdd(tagsHasInput));
                  setCountModal(false);
                },
                onCancel() {
                },
              });
              return true;
            }
            else {
              const tagsHasInput = [...productTags, { code: productInputValue, count: values?.count }];
              setProductTags(tagsHasInput);
              setProductInputValue('');
              setBarCode();
              dispatch(scanPackagingProductCodeAdd(tagsHasInput));
              setCountModal(false);
              return true;
            }
          }
          const product = selectOrderProduct?.find(product => product.code === productInputValue && product?.masterProduct?.barCode === barCode);
          if (product?.total < values?.count) {
            Modal.confirm({
              title: 'Bạn có muốn tiếp tục',
              content: 'Mặt hàng số lượng đang lấy hơn. Bạn có muốn hoàn tất không?',
              icon: <ExclamationCircleOutlined />,
              onOk() {
                const tagsHasInput = [...productTags, { code: productInputValue, count: values?.count }];
                setProductTags(tagsHasInput);
                setProductInputValue('');
                setBarCode();
                dispatch(scanPickingProductCodeAdd(tagsHasInput));
                setCountModal(false);
              },
              onCancel() {
              },
            });
          }
          else {
            const tagsHasInput = [...productTags, { code: productInputValue, count: values?.count }];
            setProductTags(tagsHasInput);
            setProductInputValue('');
            setBarCode();
            dispatch(scanPickingProductCodeAdd(tagsHasInput));
            setCountModal(false);
          }
          // dispatch(scanPickingProductCodeAdd(tagsHasInput));

        }}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item name='count' label="Số lượng" rules={[
                {
                  required: true,
                }
              ]}>
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={4} offset={16} style={{ textAlign: 'right' }}>
              <Button style={{ width: '100%' }} type="primary" onClick={() => setCountModal(false)}>{lang.btnCancel}</Button>
            </Col>
            <Col span={4} style={{ textAlign: 'right' }}>
              <Button style={{ width: '100%' }} type="primary" htmlType="submit">{lang.btnSubmit}</Button>

            </Col>
          </Row>

        </Form>
      </Modal>
    </>
  );
};

ScanProductForm.propTypes = {
  type: PropTypes.string,
};

export default ScanProductForm;
