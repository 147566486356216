import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, message, Row, Col, Modal, Form } from 'antd';

import { ScanOutlined, DeleteOutlined, CheckOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { ProFormSelect } from '@ant-design/pro-form';

import { productSplit, productUpdateStatus } from '../picking/picking.service';
import { zoneList } from 'pages/dashboard/inventory/product-management/product-management.service';
import PackagingForm from './packaging-print.form';

import './style.less';
import { useDispatch, useSelector } from 'react-redux';
import { orderConfirm, packingCreate } from './packaging.service';
import { scanPackagingProductCodeAdd } from 'providers/OutboundProvider/actions';
import TableCustom from 'components/TableCustom/table';
import Helper from 'utils/helpers';

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const PackagingCreateScanComplete = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const lang = getLanguages(t);

  const [pageSize, SetPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [item, setItem] = useState();
  const [items, setItems] = useState([]);

  const [isPrintModal, setIsPrintModal] = useState(false);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const { state } = useLocation();

  const [form] = Form.useForm();

  // const { packagingOrder } = useSelector((state) => state.outbound);

  const { currentWarehouse } = useSelector((state) => state.auth);
  const onSelectChange = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: lang.defaultCode,
      render: record => record?.masterProduct?.code,
      key: 'productCode',
      width: '10%',
    },
    {
      title: lang.productCode,
      key: 'code',
      width: '10%',
      dataIndex: 'code',
    },
    {
      title: lang.productCategory,
      dataIndex: 'masterProduct',
      key: 'productCategory',
      width: '15%',
      render: value => value?.productCategory?.name,
    },
    {
      title: lang.productName,
      key: 'name',
      width: '20%',
      render: value => value?.masterProduct?.name,
    },
    {
      title: lang.quantity,
      key: 'totalQuantity',
      // dataIndex: 'totalQuantity',
      width: '15%',
      render: value => value?.split ? value.split : value.totalQuantity,
    },
    {
      title: lang.block,
      key: 'block',
      dataIndex: 'block',
      width: '10%',
      render: value => value?.name,
    },
    {
      title: lang.shelf,
      key: 'shelf',
      dataIndex: 'rack',
      width: '10%',
      render: value => value?.shelf?.name,
    },
    {
      title: lang.rack,
      key: 'rack',
      dataIndex: 'rack',
      width: '10%',
      render: value => value?.code,
    },
  ];

  const extraButton = [
    <Button
      key="btn-cancel"
      type="primary"
      ghost
      onClick={() => {
        navigate('/dashboard/outbound/packaging/create/scan');
      }}
    >
      {lang.btnCancel}
    </Button>,
    <Button
      key="btn-complete"
      type="primary"
      onClick={async () => {
        try {
          const zoneId = await form.validateFields().then(values => values.zone)
            .catch(() => { throw new Error('Vui lòng chọn Zone'); });
          const splitProducts = items?.filter(item => item?.split).map(item => {
            return {
              id: item.id,
              quantity: item.split,
            }
          });
          const xyz = state.selectedOrder?.productOrders?.some(product => {
            const fill = items?.filter(item => item?.masterProduct?.barCode === product?.masterProduct?.barCode)
            if (!!fill) {
              const totalPick = fill?.reduce((prev, curr) => {
                if (curr?.split) {
                  return prev += curr.split;
                }
                return prev += curr?.totalQuantity;
              }, 0);
              if (totalPick === product?.total) {
                return false;
              }
              return true;
            }
            return true;
          })
          if (xyz) {
            Modal.confirm({
              title: 'Bạn có muốn tiếp tục',
              content: 'Mặt hàng số lượng đang lấy thiếu. Bạn có muốn hoàn tất không?',
              icon: <ExclamationCircleOutlined />,
              onOk: async () => {
                const response = await Promise.all(splitProducts?.map(splitProduct => productSplit(splitProduct)));
                const ids = [...items, ...response]?.filter(item => !item?.split)?.map(item => item.id);
                const result = await packingCreate({ orderId: state?.selectedOrder?.id, zoneId: zoneId, warehouseId: items[0]?.warehouseId });
                setItem({ id: result.id, code: result.code });
                // eslint-disable-next-line
                const productAwait = await productUpdateStatus({ ids: ids, status: 'PACKAGE', packageCode: result.code });
                // eslint-disable-next-line
                const orderAwait = await orderConfirm({ id: state?.selectedOrder?.id });
                dispatch(scanPackagingProductCodeAdd([]));
                // setItem({ id: 1, code: 'OD15476453456454' });
                setIsPrintModal(true);
              },
              onCancel() {
              },
            });
          }
          else {
            const response = await Promise.all(splitProducts?.map(splitProduct => productSplit(splitProduct)));
            const ids = [...items, ...response]?.filter(item => !item?.split)?.map(item => item.id);
            const result = await packingCreate({ orderId: state?.selectedOrder?.id, zoneId: zoneId, warehouseId: items[0]?.warehouseId });
            setItem({ id: result.id, code: result.code });
            // eslint-disable-next-line
            const productAwait = await productUpdateStatus({ ids: ids, status: 'PACKAGE', packageCode: result.code });
            // eslint-disable-next-line
            const orderAwait = await orderConfirm({ id: state?.selectedOrder?.id });
            dispatch(scanPackagingProductCodeAdd([]));
            // setItem({ id: 1, code: 'OD15476453456454' });
            setIsPrintModal(true);
          }
        } catch (error) {
          message.error(error.message);
        }
      }}
      disabled={!(items?.length > 0)}
    >
      {lang.btnComplete}
      <CheckOutlined />
    </Button>,
  ];

  const routes = [
    {
      path: '',
      breadcrumbName: 'Home',
    },
    {
      path: '',
      breadcrumbName: lang.outbound,
    },
    {
      path: '/packaging',
      breadcrumbName: lang.packaging,
    },
    {
      path: '/create',
      breadcrumbName: lang.pickupList,
    },
    {
      path: '/scan',
      breadcrumbName: lang.scanCode,
    },
    {
      path: '',
      breadcrumbName: lang.generatePackageCode,
    },
  ];
  useEffect(() => {
    if (state?.errList?.length > 0) {
      message.warning(`${lang.invalidCode}: ${state?.errList?.toString()}. ${lang.tryAgain}`);
    }
    setItems(state?.successList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);
  return (
    <Layout className="receipt-detail">
      <PageHeader
        ghost={false}
        title={<div>
          {lang.generatePackageCode}<br />
          <span style={{ fontSize: 15 }}>{`${lang.orderNumber}: `}<strong>{state?.selectedOrder?.code}</strong></span>
          <Form form={form} style={{ float: 'right', padding: '2px', marginLeft: '10px' }}>
            <ProFormSelect style={{ fontWeight: 600, marginBottom: 0 }}
              name="zone" label={lang.zone} rules={[
                {
                  required: true,
                  message: lang.requiredZone,
                },
              ]}
              placeholder={lang.zone}
              params={{ warehouseId: currentWarehouse.id }}
              request={async (params) => {
                const response = await zoneList(params);
                return response?.data?.map(zone => {
                  return {
                    value: zone.id,
                    label: zone.name,
                  };
                });
              }}
            />
          </Form>
        </div>}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      //onBack={onBack}
      />
      <Layout.Content style={{ margin: '12px 24px' }}>
        <TableCustom
          title={() => <Row>
            <Col span={12}><h3>{lang.productList}</h3></Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <Button
                type="primary"
                onClick={() => {
                  navigate('/dashboard/outbound/packaging/create/scan');
                }}
              >
                {lang.scanMore}
                <ScanOutlined />
              </Button>
              {selectedRowKeys.length > 0 && (
                <Button
                  type="primary"
                  onClick={() => {
                    Modal.confirm({
                      title: lang.doYouWantToDeleteTheseItems,
                      icon: <ExclamationCircleOutlined />,
                      //content: 'Some descriptions',
                      onOk() {
                        const filterlist = items?.filter(item => selectedRowKeys.indexOf(item.id) === -1);
                        setItems(filterlist);
                        setSelectedRowKeys([]);
                      },
                      onCancel() {

                      },
                    });
                  }}
                  style={{ marginLeft: 8 }}
                >
                  {lang.btnDelete}
                  <DeleteOutlined />
                </Button>
              )}
            </Col>
          </Row>}
          rowKey="id"
          rowSelection={rowSelection}
          bordered
          columns={columns}
          dataSource={items}
          onChange={(pagination) => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setCurrentPage(pagination.current);
          }}
          pagination={{
            total: items?.length,
            showSizeChanger: true,
            pageSize: pageSize,
            current: currentPage,
            onShowSizeChange: (current, size) => {
              SetPageSize(size);
            },
          }}
          scroll={{ x: 1300 }}
        />
      </Layout.Content>
      <Modal
        visible={isPrintModal}
        title={'Print'}
        onCancel={() => {
          setIsPrintModal(false);
          navigate('/dashboard/outbound/packaging');
        }}
        destroyOnClose={true}
        width={320}
        centered
        footer={null}
        wrapClassName="packaging-products-print-modal"
      >
        <PackagingForm
          item={item}
          onCallback={(value) => {
            var content = document.getElementById("print");
            var myWindow = window.open('', '');
            myWindow.document.write(content.innerHTML);
            myWindow.document.close(); //missing code
            myWindow.focus();
            myWindow.print();
            setIsPrintModal(false);
            navigate('/dashboard/outbound/packaging');
          }}
          onEXportCSV={(value) => {
            Helper.dowloadXLSX(value, 'Package');
          }}
          onCancel={() => {
            setIsPrintModal(false);
            navigate('/dashboard/outbound/packaging');
          }}
        />
      </Modal>

    </Layout>
  );
};

export default PackagingCreateScanComplete;
