export const error = 'ERROR';
export const temporaryIn = 'TEMPORARY';
export const moving = 'MOVING';
export const stored = 'STORED';
export const reallocation = 'REALLOCATE';
export const lost = 'LOST';
export const temporaryOut = 'TEMPORARY_OUT';

export const hashStatusPending = {
    [error]: {
      text: 'ERROR',
    },
};
export const hasStatusLost = {
  [lost]: {
    text: 'LOST',
  },
};
export const hashStatusInbound = {
    [temporaryIn]: {
      text: 'TEMPORARY',
    },
    [moving]: {
      text: 'MOVING',
    },
    [stored]: {
      text: 'STORED',
    },
    [reallocation]: {
      text: 'REALLOCATE',
    },
    [temporaryOut]: {
      text: 'TEMPORARY_OUT',
    },
};
