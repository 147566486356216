//Temporary Inbound
export const SCAN_RETURN_FOR_PRODUCT_CODE_ADD = 'SCAN_RETURN_FOR_PRODUCT_CODE_ADD';
export const SCAN_RETURN_FOR_PACKAGE_CODE_ADD = 'SCAN_RETURN_FOR_PACKAGE_CODE_ADD';
export const SCAN_RETURN_COMPLETED = 'SCAN_RETURN_COMPLETED';

//Storing
export const SCAN_STORING_FOR_PRODUCT_CODE_ADD = 'SCAN_STORING_FOR_PRODUCT_CODE_ADD';
export const SCAN_STORING_FOR_PACKAGE_CODE_ADD = 'SCAN_STORING_FOR_PACKAGE_CODE_ADD';
export const SCAN_STORING_COMPLETED = 'SCAN_STORING_COMPLETED';

//Inbound control
export const SCAN_INBOUND_CONTROL_FOR_PRODUCT_CODE_ADD = 'SCAN_INBOUND_CONTROL_FOR_PRODUCT_CODE_ADD';
export const SCAN_INBOUND_CONTROL_FOR_LOCATION_CODE_ADD = 'SCAN_INBOUND_CONTROL_FOR_LOCATION_CODE_ADD';
export const SCAN_INBOUND_CONTROL_COMPLETED = 'SCAN_INBOUND_CONTROL_COMPLETED';
