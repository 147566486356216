import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Modal, Card, Row, Col, DatePicker, Input, Tag, message } from 'antd';
import { PlusOutlined, DeleteOutlined, SearchOutlined, ExclamationCircleOutlined, EditOutlined } from '@ant-design/icons';

import WarehouseEditForm from './warehouse-edit.form';
import { warehouseDelete, warehouseList } from './warehouse.service';

import './style.less';
import moment from 'moment';
import { defaultPage, formatDate, formatDateFull, formatDatetime } from 'utils/constants';
import TableCustom from 'components/TableCustom/table';

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const WarehouseList = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);

  const [items, setItems] = useState([]);
  const [totalItem, setTotalItem] = useState(0);

  const [params, setParams] = useState({ ...defaultPage });
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [sortBy] = useState('id');
  const [sortDirection] = useState('desc');
  const [keyword, setKeyword] = useState('');

  const [item, setItem] = useState({});
  const [isEditModal, setIsEditModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const getWarehouses = (params, sortBy, sortDirection) => {
    setLoading(true);

    // const params = {
    //   page,
    //   limit,
    //   sortBy,
    //   sortDirection,
    //   keyword,
    // };
    // if (startDate) {
    //   params.startDate = startDate.startOf('date').format(formatDateFull);
    // }
    // if (endDate) {
    //   params.endDate = endDate.endOf('date').format(formatDateFull);
    // }

    warehouseList({ ...params, sortBy, sortDirection })
      .then(result => {
        setItems(result.data);
        setTotalItem(result.totalItem);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  };
  useEffect(() => {
    getWarehouses(params, sortBy, sortDirection);
  }, [params, sortBy, sortDirection]);

  const columns = [
    {
      title: lang.warehouseCode,
      dataIndex: 'code',
      key: 'code',
      width: '15%',
    },
    {
      title: lang.warehouseName,
      dataIndex: 'name',
      key: 'name',
      width: '20%',
    },
    {
      title: lang.status,
      key: 'status',
      dataIndex: 'status',
      width: '10%',
      render: (record) => {
        if (record === 'DISABLE') {
          return (
            <Tag color="red">{record}</Tag>
          );
        } else {
          return (
            <Tag color="green">{record}</Tag>
          );
        }
      },
    },
    {
      title: lang.address,
      dataIndex: 'address',
      key: 'address',
      width: '25%',
    },
    {
      title: lang.acreage,
      dataIndex: 'acreage',
      key: 'acreage',
      width: '10%',
    },
    {
      title: lang.createDate,
      dataIndex: 'createDate',
      key: 'createDate',
      width: '20%',
      render: value => moment(value, formatDateFull).format(formatDatetime),
    },
    {
      title: lang.action,
      align: 'center',
      width: '10%',
      fixed: 'right',
      render: (record) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            size="small"
            type="link"
            icon={<EditOutlined />}
            onClick={() => {
              const userDetail = items.find((item) => item.id === record.id);
              setItem(userDetail);
              setIsEditModal(true);
            }}
          />
        </div>
      ),
    },
  ];

  const extraButton = [
    <Button
      key="btn-complete"
      type="primary"
      onClick={() => {
        setItem();
        setIsEditModal(true);
      }}
    >
      {lang.btnCreate}
      <PlusOutlined />
    </Button>,
  ];

  const routes = [
    {
      path: '/admin',
      breadcrumbName: lang.admin,
    },
    {
      path: '/admin/warehouse',
      breadcrumbName: lang.warehouse,
    },
  ];

  return (
    <Layout className="layoutContent">
      <PageHeader
        ghost={false}
        title={lang.warehouse}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      />
      <Layout.Content>
        <Card size="small" className="cardSearch">
          <Row gutter={[16,16]}>
            <Col style={{ marginTop: 4 }}>
              <label>{lang.createDate}</label>
            </Col>
            <Col xxl={{ span: 4 }} sm={6} xs={16}>
              <DatePicker.RangePicker
                placeholder={[lang.from, lang.to]}
                onChange={(dates) => {
                  if (dates) {
                    setStartDate(dates[0]);
                    setEndDate(dates[1]);
                  } else {
                    setStartDate();
                    setEndDate();
                  }
                }}
                style={{ width: '100%' }}
                format={formatDate}
              />
            </Col>
            <Col xxl={{ span: 4 }} sm={6}>
              <Input placeholder={lang.keyword} onChange={(value) => setKeyword(value.target.value)} />
            </Col>
            <Col sm={8}>
              <Button
                type={'primary'}
                ghost
                icon={<SearchOutlined />}
                onClick={() => {
                  if (startDate) {
                    params.startDate = startDate.startOf('date').format(formatDateFull);
                  } else {
                    delete params.startDate;
                  }
                  if (endDate) {
                    params.endDate = endDate.endOf('date').format(formatDateFull);
                  } else {
                    delete params.endDate;
                  }
                  params.page = 1;
                  setParams({ ...params, keyword });
                }}
              >{lang.search}</Button>
            </Col>
          </Row>
        </Card>
        <TableCustom
          title={() => <Row>
            <Col span={12}><h3>{lang.warehouses}</h3></Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              {selectedRowKeys.length > 0 && (
                <Button
                  type="primary"
                  onClick={() => {
                    Modal.confirm({
                      title: lang.doYouWantToDeleteTheseItems,
                      icon: <ExclamationCircleOutlined />,
                      onOk() {
                        const successList = [];
                        for (let i = 0; i < selectedRowKeys.length; i++) {
                          warehouseDelete({
                            id: selectedRowKeys[i],
                          })
                            .then(result => {
                              successList.push(selectedRowKeys[i]);
                            })
                            .catch((e) => message.error(e.message));
                        }

                        setParams({ ...defaultPage });
                        getWarehouses(1);
                        setSelectedRowKeys([]);
                        Modal.success({
                          title: lang.deleteSuccessfully,
                          content: `${lang.idsHasBeenDeleted} ${JSON.stringify(selectedRowKeys)}`,
                        });
                      },
                      onCancel() {

                      },
                    });
                  }}
                  style={{ marginLeft: 8 }}
                >
                  {lang.btnDelete}
                  <DeleteOutlined />
                </Button>
              )}
            </Col>
          </Row>}
          rowKey="id"
          rowSelection={rowSelection}
          loading={loading}
          bordered
          columns={columns}
          dataSource={items}
          onChange={(pagination, filters, sorter) => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            if (pagination.pageSize !== params.limit) {
              params.page = 1;
            } else {
              params.page = pagination.current;
            }
            params.limit = pagination.pageSize;
            setParams({ ...params });
          }}
          pagination={{
            total: totalItem,
            showSizeChanger: true,
            pageSize: params.limit,
            current: params.page,
          }}
          scroll={{ x: 1200 }}
        />
      </Layout.Content>
      <WarehouseEditForm
        item={item}
        onCallback={(value) => {
          setParams({ ...defaultPage });
          setIsEditModal(false);
        }}
        setIsEditModal={setIsEditModal}
        isEditModal={isEditModal}
      />
    </Layout>
  );
};

export default WarehouseList;
