import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';

import { Layout, PageHeader, Button, Row, Col, Modal, message } from 'antd';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import { useSelector } from 'react-redux';

import ProductEditForm from '../picking/product-edit.form';

import TableCustom from 'components/TableCustom/table';
import { productScan } from '../picking/picking.service';

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const SplitScanComplete = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);

  const [pageSize, SetPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [item, setItem] = useState();
  const [items, setItems] = useState([]);

  const [isEditModal, setIsEditModal] = useState(false);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { scanSplitProductCode } = useSelector((state) => state.outbound);
  const { currentWarehouse } = useSelector((state) => state.auth);
  const onSelectChange = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: lang.productCode,
      key: 'code',
      width: '10%',
      render: (record) => {
        return <Button
          type="link"
          size="small"
          onClick={() => {
            const detail = items?.find((item) => item.id === record.id);
            setItem(detail);
            setIsEditModal(true);
          }}
        >{record?.code}</Button>;
      },
    },
    {
      title: lang.productCategory,
      dataIndex: 'masterProduct',
      key: 'productCategory',
      width: '15%',
      render: value => value?.productCategory?.name,
    },
    {
      title: lang.productName,
      key: 'name',
      width: '20%',
      render: value => value?.masterProduct?.name,
    },
    {
      title: lang.quantity,
      key: 'totalQuantity',
      dataIndex: 'totalQuantity',
      width: '15%',
    },
    {
      title: lang.block,
      key: 'block',
      dataIndex: 'block',
      width: '10%',
      render: value => value?.name,
    },
    {
      title: lang.shelf,
      key: 'shelf',
      dataIndex: 'rack',
      width: '10%',
      render: value => value?.shelf?.name,
    },
    {
      title: lang.rack,
      key: 'rack',
      dataIndex: 'rack',
      width: '10%',
      render: value => value?.code,
    },
  ];

  const extraButton = [
    <Button
      key="btn-cancel"
      type="primary"
      ghost
      onClick={() => {
        navigate('/dashboard/outbound/split-product/scan');
      }}
    >
      {lang.btnCancel}
    </Button>
  ];

  const routes = [
    {
      path: '',
      breadcrumbName: 'Home',
    },
    {
      path: '',
      breadcrumbName: lang.outbound,
    },
    {
      path: '/split-product',
      breadcrumbName: lang.splitproduct,
    },
    {
      path: '/scan',
      breadcrumbName: lang.scan,
    },
    {
      path: '',
      breadcrumbName: lang.complete,
    },
  ];
  useEffect(() => {
    setLoading(true)
    productScan({ productCodes: scanSplitProductCode, type: 'PICKING', warehouseId: currentWarehouse.id }).then(result => {
      if (result?.errList.length > 0) {
        message.warning(`${lang.invalidCode}: ${result?.errList?.toString()}. ${lang.tryAgain}`);
      }
      if (result?.successList.length !== 0) {
        setItems(result?.successList);
      }
      setLoading(false);
    })
      .catch(() => setLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scanSplitProductCode]);

  return (
    <Layout className="receipt-detail">
      <PageHeader
        ghost={false}
        title={lang.splitProductList}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      //onBack={onBack}
      />
      <Layout.Content style={{ margin: '12px 24px' }}>
        <TableCustom
          title={() => <Row>
            <Col span={12}><h3>{lang.productList}</h3></Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              {selectedRowKeys.length > 0 && (
                <Button
                  type="primary"
                  onClick={() => {
                    Modal.confirm({
                      title: lang.doYouWantToDeleteTheseItems,
                      icon: <ExclamationCircleOutlined />,
                      //content: 'Some descriptions',
                      onOk() {
                        const filterlist = items?.filter(item => selectedRowKeys.indexOf(item.id) === -1);
                        setItems(filterlist);
                        setSelectedRowKeys([]);
                      },
                      onCancel() {

                      },
                      okText: lang.okText,
                      cancelText: lang.cancelText,
                    });
                  }}
                  style={{ marginLeft: 8 }}
                >
                  {lang.btnDelete}
                  <DeleteOutlined />
                </Button>
              )}
            </Col>
          </Row>}
          rowKey="id"
          rowSelection={rowSelection}
          loading={loading}
          bordered
          columns={columns}
          dataSource={items}
          scroll={{ x: 1300 }}
          onChange={(pagination) => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setCurrentPage(pagination.current);
          }}
          pagination={{
            total: items?.length,
            showSizeChanger: true,
            pageSize: pageSize,
            current: currentPage,
            onShowSizeChange: (current, size) => {
              SetPageSize(size);
            },
          }}
        />
      </Layout.Content>
      <Modal
        visible={isEditModal}
        title={lang.titleModalPslitProduct}
        onCancel={() => setIsEditModal(false)}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
        wrapClassName="receipt-product-edit-modal"
      >
        <ProductEditForm
          isOnlyQuantityAllowForUpdate={true}
          item={item}
          onCallback={(value) => {
            const listItems = items.map(item => {
              if (item.masterProduct.id === value.masterProduct.id) {
                Object.assign(item, { totalQuantity: item.totalQuantity - value.totalQuantity });
              }
              return item;
            })
            setItems([...listItems, value]);
            setIsEditModal(false);
          }}
          onCancel={() => setIsEditModal(false)}
        />
      </Modal>
    </Layout>
  );
};

export default SplitScanComplete;
