
import environment from 'environments/environment';
import request from 'utils/request';

export async function productcategoryList(payload) {
  return request(environment.api.productCategoryList, payload, 'GET');
}

export async function productsList(payload) {
  return request(environment.api.productTrxList, payload, 'GET');
}
export async function zoneList(payload) {
  return request(environment.api.zones + 'get-all', payload, 'GET');
}
export async function blockList(payload) {
  return request(environment.api.blocks + 'get-all', payload, 'GET');
}
export async function blockDetail(id) {
  return request(environment.api.blocks + `get-one/${id}`, {}, 'GET');
}

export async function shelfDetail(id) {
  return request(environment.api.shelves + `get-one/${id}`, {}, 'GET');
}
export async function productUpdate(payload) {
  return request(environment.api.products + 'update', payload, 'PUT');
}
export async function receiptList(payload) {
  return request(environment.api.receiptList, payload, 'GET');
}