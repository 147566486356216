import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Row, Col } from 'antd';
import { LoginOutlined } from '@ant-design/icons';

import { useSelector } from 'react-redux';

import ScanPackageForm from 'components/ScanPackageForm/scan-package.form';
import ScanProductForm from 'components/ScanProductForm/scan-product.form';

import helpers from 'utils/helpers';
import './style.less';

const StoringScan = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const lang = getLanguages(t);

  const { scanStoringForProductCodes, scanStoringForPackageCodes } = useSelector((state) => state.inbound);

  const extraButton = [
    <Button
      key="btn-cancel"
      type="primary"
      ghost
      onClick={() => {
        navigate('/dashboard/inbound/storing/');
      }}
    >
      {lang.btnCancel}
    </Button>,
    <Button
      disabled={scanStoringForProductCodes.length === 0 && scanStoringForPackageCodes.length === 0}
      key="btn-complete"
      type="primary"
      onClick={() => {
        navigate('complete');
      }}
    >
      {lang.btnSubmit}
      <LoginOutlined />
    </Button>,
  ];

  const routes = [
    {
      path: '/dashboard/inbound',
      breadcrumbName: lang.inbound,
    },
    {
      path: '/dashboard/inbound/storing',
      breadcrumbName: lang.storing,
    },
    {
      path: '/dashboard/inbound/storing/storing-scan',
      breadcrumbName: lang.scanProduct,
    },
  ];

  return (
    <Layout className="storing-scan layoutContent">
      <PageHeader
        ghost={false}
        title={lang.storingScanProduct}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: helpers.breadcrumbItemRender,
        }}
      />
      <Layout.Content>
        <Row gutter={[16,16]}>
          <Col sm={24} lg={12} xs={24}>
            <ScanPackageForm type={'STORING'} />
          </Col>
          <Col sm={24} lg={12} xs={24}>
            <ScanProductForm type={'STORING'} />
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  );
};

export default StoringScan;
