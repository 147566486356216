import React from 'react';
import ProForm, { ProFormSelect } from '@ant-design/pro-form';

import { blockDetail } from '../product-management/product-management.service';

const selectShelf = ({ value, onChange, blockId }) => {
    return (
        <ProForm.Item noStyle shouldUpdate>
            {(form) => <ProFormSelect name="shelf"
                fieldProps={{
                    defaultValue: value,
                    onChange: (value) => {
                        form.setFieldsValue({ rack: undefined });
                        return onChange?.(value);
                    },
                    labelInValue: true,
                }}
                initialValue={value}
                params={{ id: blockId }}
                request={async (params) => {
                    if (typeof (params?.id) === 'number') {
                        const response = await blockDetail(params?.id);
                        if (response) {
                            return response?.shelfs?.map(shelf => {
                                return {
                                    value: shelf.id,
                                    label: shelf.name,
                                };
                            });
                        }
                        return [];
                    }
                    return [];
                }
                } />
            }
        </ProForm.Item>
    );
};

export default selectShelf;
