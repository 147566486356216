import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Layout, PageHeader, Button, Modal, Form, Select, Card, Row, Col, DatePicker, message as Alert, Input, message } from 'antd';

import { CheckOutlined, PlusOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined, InboxOutlined } from '@ant-design/icons';
import { receiptCreate } from './receipt.service';
import ReceiptCreateProductEditForm from './receipt-create-product-edit.form';
import { formatDate, formatDateFull, formatDatetime, INBOUND_KINDS } from 'utils/constants';

import './style.less';
import { productList } from '../../configuration/product/product.service';
import ProTableCusTom from 'components/TableCustom/Protable';
import Helper from 'utils/helpers';
import Dragger from 'antd/lib/upload/Dragger';
import * as XLSX from 'xlsx';
import moment from 'moment-timezone';
const { Option } = Select;

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const ReceiptCreate = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const { currentWarehouse, user } = useSelector((state) => state.auth);
  const [isEditModal, setIsEditModal] = useState(false);

  const [product, setProduct] = useState();
  const [products, setProducts] = useState([]);
  const [productMasters, setProductMasters] = useState([]);
  const [uploadModal, setUploadModal] = useState(false);
  const [file, setFile] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const columns = [
    // {
    //   title: lang.productCode,
    //   dataIndex: 'productCode',
    //   key: 'productCode',
    //   width: '12%',
    // },
    {
      title: lang.defaultCode,
      dataIndex: 'barCode',
      key: 'barCode',
      width: '12%',
    },
    {
      title: lang.productName,
      dataIndex: 'productName',
      key: 'productName',
      width: '15%',
    },
    {
      title: lang.productCategory,
      dataIndex: 'productCategory',
      key: 'productCategory',
      width: '10%',
    },
    {
      title: lang.expectedQuantity,
      key: 'expectedQuantity',
      dataIndex: 'expectedQuantity',
      width: '10%',
    },
    {
      title: lang.supplier,
      key: 'supplier',
      dataIndex: 'supplier',
      width: '12%',
    },
    {
      title: lang.DVT,
      key: 'DVT',
      dataIndex: 'DVT',
      width: '7%',
    },
    {
      title: lang.cost,
      dataIndex: 'purchasePrice',
      key: 'cost',
      width: '8%',
      render: value => value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    },
    {
      title: lang.salePrice,
      dataIndex: 'salePrice',
      key: 'salePrice',
      width: '8%',
      render: value => value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    },
    {
      title: lang.retailPrice,
      dataIndex: 'retailPrice',
      key: 'retailPrice',
      width: '8%',
      render: value => value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    },
    {
      title: lang.expireDate,
      dataIndex: 'expireDate',
      key: 'expireDate',
      width: '15%',
      render: (record) => record !== '-' ? record?.format(formatDatetime) : undefined,
    },
    {
      title: lang.action,
      align: 'center',
      width: '8%',
      render: (record) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            type="link"
            icon={<EditOutlined />}
            size="small"
            onClick={() => {
              // const detail = products.find((item) => item.masterProductId === record.masterProductId);
              setProduct(record);
              setIsEditModal(true);
            }}
          />
        </div>
      ),
    },
  ];

  const extraButton = [
    <Button
      key="btn-cancel"
      type="primary"
      ghost
      onClick={() => {
        navigate('/dashboard/inbound/receipt');
      }}
    >
      {lang.btnCancel}
    </Button>,
    <Button
      key="btn-complete"
      type="primary"
      disabled={products.length === 0}
      onClick={() => {
        form.submit();
      }}
    >
      {lang.btnComplete}
      <CheckOutlined />
    </Button>,
  ];

  const routes = [
    {
      path: '/dashboard/inbound',
      breadcrumbName: lang.inbound,
    },
    {
      path: '/receipt',
      breadcrumbName: lang.receipt,
    },
    {
      path: '',
      breadcrumbName: lang.createReceipt,
    },
  ];

  useEffect(() => {
    setLoading(true);
    productList({ warehouseId: currentWarehouse.id })
      .then(result => {
        setProductMasters(result.data);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  }, [currentWarehouse]);

  return (
    <Layout className="receipt-list layoutContent">
      <PageHeader
        ghost={false}
        title={lang.createReceipt}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      />
      <Layout.Content>
        <Card size="small" className="cardSearch">
          <Form className="customFormSearch"
            colon={false}
            form={form}
            layout="vertical"
            name="receiptCreateForm"
            onFinish={async (values) => {
              try {
                values.creatorId = user?.userInfo?.sub;
                values.creatorName = user?.userInfo?.name;
                values.warehouseId = currentWarehouse?.id;
                values.receiptDate = values.receiptDate.format(formatDateFull);
                values.products = products?.map(product => {
                  delete product.slLo;
                  return product;
                });
                setLoading(true);
                receiptCreate(values)
                  .then(result => {
                    setLoading(false);
                    Modal.success({
                      title: lang.createReceiptSuccessfully,
                      content: `${lang.receiptCode}: ${result?.code}`,
                      icon: <CheckOutlined />,
                      onOk() {
                        navigate('/dashboard/inbound/receipt');
                      },
                    });
                  })
                  .catch((error) => {
                    Alert.error(error?.message);
                    setLoading(false);
                  });
              } catch (error) {
                Alert.error(error?.message);
                setLoading(false);
              }
            }}
            initialValues={{}}
          >
            <Row gutter={24} type="flex">
              <Col lg={{ span: 6 }} xs={{ span: 8 }}>
                <Form.Item
                  name="inboundkind"
                  label={lang.inboundKind}
                  rules={[
                    {
                      required: true,
                      message: lang.pleaseSelectAInboundKind,
                    },
                  ]}
                >
                  <Select placeholder={lang.inboundKind}>
                    {INBOUND_KINDS.map(item => (
                      <Option key={item.value} value={item.value}>{item.label}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={{ span: 6 }} xs={{ span: 8 }}>
                <Form.Item
                  name="receiptDate"
                  label={lang.receiveDate}
                  rules={[
                    {
                      required: true,
                      message: lang.messageReceiveDate,
                    },
                  ]}
                >
                  <DatePicker style={{ width: '100%' }} format={formatDate} />
                </Form.Item>
              </Col>
              <Col lg={{ span: 6 }} xs={{ span: 8 }}>
                <Form.Item shouldUpdate>
                  {({ getFieldValue }) =>
                    getFieldValue('inboundkind') === 'INTERNAL' &&
                    <Form.Item
                      name="boothCode"
                      label={lang.vansCode}
                    >
                      <Input style={{ width: '100%' }} placeholder={lang.vansCode} />
                    </Form.Item>

                  }
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
        <ProTableCusTom
          title={() => <Row>
            <Col sm={12} xs={10}><h3>{lang.productAdded}</h3></Col>
            <Col sm={12} xs={14} style={{ textAlign: 'right' }}>
              <Button
                type="primary"
                onClick={() => {
                  setIsEditModal(true);
                  setProduct();
                }}
              >
                {lang.btnAdd}
                <PlusOutlined />
              </Button>
              <Button
                type="primary"
                ghost
                onClick={() => setUploadModal(true)}
                style={{ marginLeft: 8 }}
              >
                {lang.importCSV}
              </Button>
              {selectedRowKeys.length > 0 && (
                <Button
                  type="primary"
                  onClick={() => {
                    Modal.confirm({
                      title: lang.doYouWantToDeleteTheseItems,
                      icon: <ExclamationCircleOutlined />,
                      onOk() {
                        setProducts(products.filter(item => !selectedRowKeys.includes(item.key)));
                        setSelectedRowKeys([]);
                      },
                      onCancel() {
                      },
                    });
                  }}
                  style={{ marginLeft: 8 }}
                >
                  {lang.btnDelete}
                  <DeleteOutlined />
                </Button>
              )}
            </Col>
          </Row>}
          rowKey="key"
          rowSelection={rowSelection}
          loading={loading}
          bordered
          columns={columns}
          dataSource={products}
          scroll={{ x: 1800 }}
          search={false}
          options={{
            search: false,
            reload: false,
            density: false,
          }}
        // pagination={false}
        />
      </Layout.Content>
      <ReceiptCreateProductEditForm
        item={product}
        products={productMasters}
        productsSelected={products}
        warehouseId={currentWarehouse?.id}
        onCallback={(values) => {
          // const productsRest = products.filter(item => item.productCode !== value.productCode);
          const productUpdate = products?.find(product => product.key === values[0].key);
          if (productUpdate) {
            productUpdate.expectedQuantity = values[0].expectedQuantity;
            productUpdate.supplier = values[0].supplier;
            productUpdate.supplierId = values[0].supplierId;
            setProducts([...products]);
            setIsEditModal(false);
            return true
          }
          setProducts([...products, ...values]);
          setIsEditModal(false);
        }}
        setIsEditModal={setIsEditModal}
        isEditModal={isEditModal}
      />
      <Modal visible={uploadModal} onCancel={() => { setUploadModal(false); setFile(); }}
        bodyStyle={{ minHeight: 340 }} onOk={async () => {
          try {
            if (file) {

              const fileReader = new FileReader();
              fileReader.readAsArrayBuffer(file?.originFileObj);
              fileReader.onload = (e) => {
                const bufferArray = e.target.result;
                const wb = XLSX.read(bufferArray, { type: 'binary' });
                const sheetName = wb.SheetNames[0];
                const sheet = wb.Sheets[sheetName];
                const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                if (!!data) {
                  const result = data?.reduce((prev, curr, index) => {
                    if(index>0 && !!curr){
                      const finded = productMasters?.find(master => master.barCode === curr[0]?.toString());
                      if(finded){
                        return [...prev,{
                          key: index-1,
                          barCode: finded.barCode,
                          productName: finded?.name,
                          productCategory: finded?.productCategory?.name,
                          expectedQuantity: curr[1],
                          supplier: finded?.suppliers?.find(supp => supp?.id === curr[2])?.name,
                          purchasePrice: finded?.purchasePrice,
                          salePrice: finded?.salePrice,
                          retailPrice: finded?.retailPrice,
                          expireDate: curr[3] && moment(curr[3], formatDateFull),
                          masterProductId: finded?.id,
                          productCategoryId: finded?.productCategory?.id,
                          productCode: finded?.code,
                          warehouseId: finded?.warehouseId,
                          DVT: finded?.DVT
                        }]
                      }
                      return prev;
                    }
                    return prev;
                  },[])
                  setProducts([...products, ...result]);
                }
              }
              setFile();
            }
            message.success(lang.importSuccess);
            setUploadModal(false);
          } catch (error) {
            message.error(error.message);
            setUploadModal(false);
            setFile();
          }
        }}
        okText={lang.okText} cancelText={lang.cancelText}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Button onClick={() => Helper.dowloadXLSX([{ barCode: '', expectedQuantity: 0, supplierId: 0, expireDate: moment().format(formatDate) }], 'TemplateDataPO')}>{lang.dowloadTemplate}</Button>
          </Col>
          <Col span={24}>
            <Dragger onChange={(info) => {
              setFile(info.file);
            }}
              maxCount={1}
              // defaultFileList={file}
              showUploadList={true}
              customRequest={async (options) => {
                const { onSuccess } = options;
                onSuccess('OK');
              }}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                band files
              </p>
            </Dragger>
          </Col>
        </Row>
      </Modal>
    </Layout>
  );
};

export default ReceiptCreate;
