import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, message, Row, Col, Modal } from 'antd';
import { ScanOutlined, DeleteOutlined, CheckOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { scanInboundControlCompleted } from 'providers/InboundProvider/actions';
import moment from 'moment';
import { formatDateFull, formatDatetime } from 'utils/constants';
import { productScan, productUpdates } from '../product.service';
import './style.less';
import TableCustom from 'components/TableCustom/table';

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const InboundControlScanComplete = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const { scanInboundControlForProductCodes, scanInboundControlForLocationCode } = useSelector((state) => state.inbound);
  const [rackId, setRackId] = useState();
  const { currentWarehouse } = useSelector((state) => state.auth);
  const [items, setItems] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: lang.defaultCode,
      dataIndex: 'masterProduct',
      key: 'barcode',
      // width: '8%',
      render: value => value?.barCode
    },
    {
      title: lang.productCode,
      dataIndex: 'code',
      key: 'code',
      // width: '6%',
    },
    {
      title: lang.productCategory,
      dataIndex: 'masterProduct',
      key: 'productCategory',
      width: '10%',
      render: value => value?.productCategory?.name,
    },
    {
      title: lang.packageCode,
      dataIndex: 'packageCode',
      key: 'packageCode',
      // width: '10%',
    },
    {
      title: lang.productName,
      dataIndex: 'masterProduct',
      key: 'productName',
      width: '12%',
      render: value => value?.name,
    },
    {
      title: lang.totalQuantity,
      key: 'totalQuantity',
      dataIndex: 'totalQuantity',
      // width: '6%',
    },
    {
      title: lang.zone,
      key: 'zone',
      dataIndex: 'zone',
      // width: '6%',
      render: value => value?.name,
    },
    {
      title: lang.block,
      key: 'block',
      dataIndex: 'block',
      // width: '6%',
      render: value => value?.name,
    },
    {
      title: lang.shelf,
      key: 'shelf',
      dataIndex: 'rack',
      // width: '6%',
      render: value => value?.shelf?.name,
    },
    {
      title: lang.rack,
      key: 'rack',
      dataIndex: 'rack',
      width: '6%',
      render: value => value?.code,
    },
    {
      title: lang.supplier,
      key: 'supplier',
      dataIndex: 'supplier',
      width: '15%',
      render: (value) => value?.name,
    },
    {
      title: lang.cost,
      dataIndex: 'masterProduct',
      key: 'cost',
      // width: '8%',
      render: value => value?.purchasePrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    },
    {
      title: lang.salePrice,
      dataIndex: 'masterProduct',
      key: 'salePrice',
      // width: '8%',
      render: value => value?.salePrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    },
    {
      title: lang.retailPrice,
      dataIndex: 'masterProduct',
      key: 'retailPrice',
      // width: '8%',
      render: value => value?.retailPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    },
    {
      title: lang.expireDate,
      dataIndex: 'expireDate',
      key: 'expireDate',
      render: value => value? moment(value, formatDateFull).format(formatDatetime): undefined,
    },
  ];

  const extraButton = [
    <Button
      key="btn-cancel"
      type="primary"
      ghost
      onClick={() => {
        navigate('/dashboard/inbound/inbound-control/inbound-control-scan');
      }}
    >
      {lang.btnCancel}
    </Button>,
    <Button
      key="btn-complete"
      type="primary"
      disabled={items?.length === 0}
      onClick={() => {
        productUpdates({
          ids: items.map(item => item.id),
          rackId: rackId,
          status: 'STORED',
        })
          .then(result => {
            dispatch(scanInboundControlCompleted());
            Modal.success({
              title: lang.completeInboundControlScanSuccessfully,
              icon: <CheckOutlined />,
              onOk() {
                navigate('/dashboard/inbound/inbound-control/');
              },
            });
          })
          .catch((e) => message.error(e.message));
      }}
    >
      {lang.btnComplete}
      <CheckOutlined />
    </Button>,
  ];

  const routes = [
    {
      path: '/dashboard/inbound',
      breadcrumbName: lang.inbound,
    },
    {
      path: '/dashboard/inbound/inbound-control',
      breadcrumbName: lang.inboundControl,
    },
    {
      path: '/dashboard/inbound/inbound-control/inbound-control-scan',
      breadcrumbName: lang.scanProduct,
    },
    {
      path: '',
      breadcrumbName: lang.btnComplete,
    },
  ];

  //STATUS = INBOUND_CONTROL
  useEffect(() => {
    setLoading(true);
    productScan({
      rackCode: scanInboundControlForLocationCode[0],
      productCodes: scanInboundControlForProductCodes,
      type: 'INBOUND_CONTROL',
      warehouseId: currentWarehouse.id,
    })
      .then(result => {
        if (result?.rack) {
          setRackId(result?.rack?.id);
        }
        if (result?.errList.length > 0) {
          message.warning(`${lang.invalidCode}: ${result?.errList?.toString()}. ${lang.tryAgain}`);
        }

        if (result?.successList.length !== 0) {
          const addLocation = result?.successList?.map(product => {
            return Object.assign(product, { block: result?.rack?.shelf?.block, rack: result?.rack });
          });
          setItems(addLocation);
        }
        setLoading(false);
      })
      .catch((e) => { setLoading(false); message.error(e.message); });
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [currentWarehouse]);

return (
  <Layout className="layoutContent">
    <PageHeader
      ghost={false}
      title={lang.inboundControlScanProductComplete}
      extra={extraButton}
      breadcrumb={{
        routes,
        itemRender: breadcrumbItemRender,
      }}
      className="customPageHeader"
    />
    <Layout.Content>
      <TableCustom
        title={() => <Row>
          <Col sm={12} xs={10}><span style={{ fontWeight: '600' }}>{lang.locationCode}:</span> {scanInboundControlForLocationCode}</Col>
          <Col sm={12} xs={14} style={{ textAlign: 'right' }}>
            <Button
              type="primary"
              onClick={() => {
                navigate('/dashboard/inbound/inbound-control/inbound-control-scan');
              }}
            >
              {lang.scanMore}
              <ScanOutlined />
            </Button>
            {selectedRowKeys.length > 0 && (
              <Button
                type="primary"
                onClick={() => {
                  Modal.confirm({
                    title: lang.doYouWantToDeleteTheseItems,
                    icon: <ExclamationCircleOutlined />,
                    onOk() {
                      const itemsDeleted = items.filter(item => selectedRowKeys.includes(item.id) === false);
                      setItems(itemsDeleted);
                      setSelectedRowKeys([]);
                    },
                    onCancel() {

                    },
                  });
                }}
                style={{ marginLeft: 8 }}
              >
                {lang.btnDelete}
                <DeleteOutlined />
              </Button>
            )}
          </Col>
        </Row>}
        rowKey="id"
        rowSelection={rowSelection}
        loading={loading}
        bordered
        columns={columns}
        dataSource={items}
        scroll={{ x: 2500 }}
      />
    </Layout.Content>
  </Layout>
);
};

export default InboundControlScanComplete;
