import React, { useState, useEffect } from 'react';
import { Form, Input, Col, Row, Button, Select, InputNumber } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import { productList } from '../../configuration/product/product.service';
import './style.less';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';

const { Option } = Select;

const ReceiptProductEditForm = ({ item, onCancel, onCallback, isOnlyQuantityAllowForUpdate = false, items }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const { currentWarehouse } = useSelector((state) => state.auth);
  // const [productListFind, setProductListFind] = useState([]);
  const [products, setProducts] = useState([]);
  const [productFind, setProductFind] = useState();
  useEffect(() => {
    setLoading(true);
    productList({ warehouseId: currentWarehouse.id, status: 'ENABLE' })
      .then(result => {
        setProducts(result.data);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  }, [currentWarehouse]);

  return (
    <Form
      colon={false}
      form={form}
      layout="vertical"
      requiredMark={false}
      name="receiptProductEditForm"
      onFinish={(values) => {
        setLoading(true);
        onCallback({
          ...productFind,
          total: values.total,
        });
      }}
      initialValues={{
        packageCode: item ? item?.packageCode : '',
        productCode: item ? item?.productCode : '',
        totalQuantity: item ? item?.totalQuantity : undefined,
        cost: item ? item?.cost : undefined,
        salesPrice: item ? item?.salesPrice : undefined,
        expiratinDate: moment(),
      }}
    >
      <Row gutter={24} type="flex">
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item name="productcode" label={lang.product}
            rules={[
              {
                required: true,
                message: lang.requiredProduct,
              },
            ]}
          >
            <Select
              placeholder={lang.placeholderSelectProduct}
              onChange={(value) => {
                const itemFind = products.find(product => product.id === value);
                setProductFind(itemFind);
                // productsList({ warehouseId: currentWarehouse.id, keyword: itemFind.name, status: 'STORED' }).then(result => setProductListFind(result.data));
                // setSupplier(itemFind?.supplier);
                // setProductCategory(itemFind?.productCategory);
              }}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => {
                return option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              }
            >
              {products?.map(item => (
                <Option key={item.id} value={item.id}>{item.name}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item label={lang.supplier}>
            <Input disabled value={productFind?.suppliers.map(supp => supp.name).join(', ')} />
          </Form.Item>
        </Col>
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item label={lang.productCategory}>
            <Input disabled value={productFind?.productCategory?.name} />
          </Form.Item>
        </Col>
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item shouldUpdate>
            {() => {
              if (productFind) {
                // const masterProduct = productListFind[0]?.masterProduct;
                const totalQuantity = productFind?.availableQuantity - items?.filter(item => item.id === productFind.id)?.reduce((prev, curr) => prev + curr.total,0);
                return <Form.Item
                  name="total"
                  label={totalQuantity > 0 ? `${lang.totalQuantity}(<=${totalQuantity})` : `${lang.totalQuantity}(=${totalQuantity})`}
                  rules={[
                    {
                      required: true,
                      message: lang.requiredQuantity,
                    },
                    {
                      validator: (_, value)=>value<=totalQuantity?Promise.resolve():Promise.reject('Nhỏ hơn số lượng tồn kho')
                    }
                  ]}
                >
                  <InputNumber disabled={totalQuantity === 0} style={{ width: '100%' }} min={1} placeholder={lang.placeholderQuantity} />
                </Form.Item>;
              }
              return <Form.Item
                name="total"
                label={lang.totalQuantity}
                rules={[
                  {
                    required: true,
                    message: lang.requiredQuantity,
                  },
                ]}
              >
                <InputNumber style={{ width: '100%' }} min={1} max={productFind?.capacity} placeholder={lang.placeholderQuantity} disabled={true} />
              </Form.Item>;
            }}
          </Form.Item>
        </Col>
      </Row>

      <div
        className="ant-modal-footer"
        style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
      >
        <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
          <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
            <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
              {lang.btnCancel}
            </Button>
            {loading === false ? (
              <Button type="primary" htmlType="submit" style={{ fontWeight: 'bold' }}>
                {lang.btnSave}
              </Button>
            ) : (
              <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                {lang.loading}
              </Button>
            )}
          </Col>
        </Row>
      </div>
    </Form>
  );
};

ReceiptProductEditForm.propTypes = {
  item: PropTypes.object,
  onCancel: PropTypes.func,
  onCallback: PropTypes.func,
  isOnlyQuantityAllowForUpdate: PropTypes.bool,
  items: PropTypes.array,
};

export default ReceiptProductEditForm;
