import {
  SCAN_RETURN_FOR_PRODUCT_CODE_ADD,
  SCAN_RETURN_FOR_PACKAGE_CODE_ADD,
  SCAN_RETURN_COMPLETED,

  SCAN_STORING_FOR_PRODUCT_CODE_ADD,
  SCAN_STORING_FOR_PACKAGE_CODE_ADD,
  SCAN_STORING_COMPLETED,

  SCAN_INBOUND_CONTROL_FOR_LOCATION_CODE_ADD,
  SCAN_INBOUND_CONTROL_FOR_PRODUCT_CODE_ADD,
  SCAN_INBOUND_CONTROL_COMPLETED,
} from './types';

//Temporary Inbound
export function scanReturnProductCodeAdd(payload = {}) {
  return { type: SCAN_RETURN_FOR_PRODUCT_CODE_ADD, payload };
}
export function scanReturnPackageCodeAdd(payload = {}) {
  return { type: SCAN_RETURN_FOR_PACKAGE_CODE_ADD, payload };
}
export function scanReturnCompleted(payload = {}) {
  return { type: SCAN_RETURN_COMPLETED, payload, meta: { thunk: true } };
}

//Storing
export function scanStoringProductCodeAdd(payload = {}) {
  return { type: SCAN_STORING_FOR_PRODUCT_CODE_ADD, payload };
}
export function scanStoringPackageCodeAdd(payload = {}) {
  return { type: SCAN_STORING_FOR_PACKAGE_CODE_ADD, payload };
}
export function scanStoringCompleted(payload = {}) {
  return { type: SCAN_STORING_COMPLETED, payload, meta: { thunk: true } };
}

//Inbound control
export function scanInboundControlLocationCodeAdd(payload = {}) {
  return { type: SCAN_INBOUND_CONTROL_FOR_LOCATION_CODE_ADD, payload };
}
export function scanInboundControlProductCodeAdd(payload = {}) {
  return { type: SCAN_INBOUND_CONTROL_FOR_PRODUCT_CODE_ADD, payload };
}
export function scanInboundControlCompleted(payload = {}) {
  return { type: SCAN_INBOUND_CONTROL_COMPLETED, payload, meta: { thunk: true } };
}
