import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Row, Col, Dropdown, Menu, message } from 'antd';
import { LockOutlined, EyeInvisibleOutlined, EyeOutlined, DownOutlined, UserOutlined } from '@ant-design/icons';
import './styles.less';
// import bgImg from 'assets/background.png';
// import bgImg from 'assets/images/cloudWMSBrief.png';
// import cloudWMSBrief from 'assets/images/cloudWMSBrief.png';

import warehouseBg from 'assets/images/warehouse_bg_convert.jpg';

import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _get from 'lodash/get';

import { setLanguage } from 'providers/I18nProvider/actions';
import { login } from 'providers/AuthProvider/actions';

import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';

import logo from 'assets/PinNow-07.png';
import iconFlagEn from 'assets/icons/united-states-flag.png';
import iconFlagVi from 'assets/icons/vietnam-flag.png';
import iconFlagJa from 'assets/icons/japan-flag-icon.png';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  
  let locale = useSelector((state) => state.i18n.locale);

  const { t } = useTranslation();

  let lang = getLanguages(t);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (locale === null) {
      dispatch(setLanguage({ locale: 'en' }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onFinish = (values) => {
    setLoading(true);
    dispatch(login(values))
      .then((result) => {
        const isAdmin = _get(result, 'roles', '').includes('admin');
        setLanguage('en');
        setLoading(false);
        if (isAdmin) {
          navigate('/admin');
        } else {
          navigate('/dashboard');
        }
      })
      .catch(err => {
        message.error(err);
        setLoading(false);
      });
  };

  const changeLanguage = (locale) => {
    dispatch(setLanguage({ locale }));
  };

  const languages = (
    <Menu>
      <Menu.Item onClick={() => changeLanguage('en')}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div>
            <img src={iconFlagEn} width={24} alt="English Language" />
          </div>
          <div style={{ marginLeft: 8, marginTop: 2 }}>English</div>
        </div>
      </Menu.Item>
      <Menu.Item onClick={() => changeLanguage('ja')}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div>
            <img src={iconFlagJa} width={24} alt="Japan Language" />
          </div>
          <div style={{ marginLeft: 8, marginTop: 2 }}>Japan</div>
        </div>
      </Menu.Item>
      <Menu.Item onClick={() => changeLanguage('vi')}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div>
            <img src={iconFlagVi} width={24} alt="Vietnam Language" />
          </div>
          <div style={{ marginLeft: 8, marginTop: 2 }}>Vietnam</div>
        </div>
      </Menu.Item>
    </Menu>
  );

  const languageSelected = (locale) => {
    switch (locale) {
      case 'en':
        return <img src={iconFlagEn} width={30} alt="English Language" />;    
      case 'ja':
          return <img src={iconFlagJa} width={30} alt="Japan Language" />;  
      case 'vi':
            return <img src={iconFlagVi} width={30} alt="Vietnam Language" />;
      default:
        return <img src={iconFlagEn} width={30} alt="English Language" />;
    }
  };

  return (
    <div className="login-page">
      <Row justify="space-between" style={{ height: window.innerHeight }}>
      <Col
          xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}
          style={{
            backgroundImage: `url(${warehouseBg})`,
            // background: `#FFFFFF`,
            width: '100%',
            width: '100%',
            backgroundSize: 'cover',
            overflow: 'hidden',
          }}
        > 
          <div style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#FFFFFF', marginTop: window.innerHeight / 2 - 250 }}>
            <div style={{ margin: window.innerWidth / 25 }}>
              <div style={{ fontSize: 30, fontWeight: 300, color: '#000000', paddingBottom: 0 }}>{lang.weAre}</div>
              <div style={{ fontSize: 84, fontWeight: 900, color: '#e27a3c', textShadowColor: '#cccccc', textShadowOffset: {width: -5, height: 5}, textShadowRadius: 10 }}>{lang.fptCloudWms}</div>
              <div style={{ fontSize: 18, fontWeight: 400, color: '#000000' }}>
                <strong style={{ color: '#e27a3c' }}>{lang.fptCloudWms}</strong> {lang.fptCloudWmsDescription}</div>
            </div>
          </div>
          
        </Col>

        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} style={{ alignSelf: 'center' }} className="paddingCol">
          <div className="login-logo">
            <img src={logo} alt="logo" style={{ height: 46 }} />
            <h3 className="login-title" style={{ marginTop: 20, marginBottom: 20 }}>
              {lang.login}
            </h3>
            <p>{lang.slogan}</p>
          </div>
          <div>
            <Form
              name="loginForm"
              className="login-form"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              layout="vertical"
            >
              <Form.Item
                label={lang.username}
                name="username"
                rules={[
                  { required: true, message: lang.messageUsername },
                ]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder={lang.placeholderUsername}
                />
              </Form.Item>
              <Form.Item
                label={lang.password}
                name="password"
                rules={[{ required: true, message: lang.messagePassword }]}
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder={lang.placeholderPassword}
                  iconRender={(visible) =>
                    visible ? (
                      <EyeOutlined style={{ color: 'black' }} />
                    ) : (
                      <EyeInvisibleOutlined style={{ color: 'black' }} />
                    )
                  }
                />
              </Form.Item>
              <Form.Item>
                {loading === false ? (
                  <Button type="primary" htmlType="submit" block className="login-form-button">
                    {lang.login}
                  </Button>
                ) : (
                  <Button type="primary" loading={loading} block className="login-form-button">
                    {lang.loading}
                  </Button>
                )}
              </Form.Item>
            </Form>
          </div>
          <div style={{ position: 'relative' }}>
            <Dropdown overlay={languages} placement="bottom">
              <div
                style={{
                  height: '40px',
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: 16,
                  width: 100
                }}
              >
                {languageSelected(locale)}
                <DownOutlined style={{ fontSize: 10, marginLeft: 8 }} />
              </div>
            </Dropdown>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
