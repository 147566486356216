import environment from 'environments/environment';
import request from 'utils/request';

export async function divisonList(payload) {
  return request(environment.api.divison + '/get-all', payload, 'GET');
}

export async function divisonDetail(payload) {
  return request(`${environment.api.divison}/get-one/${payload?.divisonId}`, {}, 'GET');
}

export async function divisonCreate(payload) {
  return request(environment.api.divison + '/create', payload, 'POST');
}

export async function divisonUpdate(payload) {
  return request(environment.api.divison + '/update', payload, 'PUT');
}
export async function divisonDelete(id) {
  return request(environment.api.divison + `/delete/${id}`, {}, 'DELETE');
}
export async function importExel(warehouseId, formData) {
  return request(environment.api.divison + `/create/excel/${warehouseId}`, formData , 'POST');
}
