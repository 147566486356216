import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Modal, Row, Col, message } from 'antd';

import { CheckOutlined, PlusOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { saleorderCreate } from './sale-order.service';
import ReceiptCreateProductEditForm from './sale-order-create-product-edit.form';

import './style.less';
import { useSelector } from 'react-redux';
import TableCustom from 'components/TableCustom/table';

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const ReceiptCreate = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [isEditModal, setIsEditModal] = useState(false);

  const [item, setItem] = useState();
  const [items, setItems] = useState([]);
  const [loadingBtn, setLoadingBtn]= useState(false);
  const [pageSize, SetPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys);
  };
  const { currentWarehouse, user } = useSelector((state) => state.auth);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: lang.productCategory,
      dataIndex: 'productCategory',
      key: 'productCategory',
      render: (value) => value.name,
    },
    {
      title: lang.productCode,
      dataIndex: 'code',
      key: 'productCode',
    },
    {
      title: lang.productName,
      dataIndex: 'name',
      key: 'productName',
    },
    {
      title: lang.supplier,
      key: 'supplier',
      dataIndex: 'suppliers',
      render: (values) => values.map(value => value.name).join(', '),
    },
    {
      title: lang.total,
      key: 'total',
      dataIndex: 'total',
    },
  ];

  const extraButton = [
    <Button
      key="btn-cancel"
      type="primary"
      ghost
      onClick={() => {
        navigate('/dashboard/configuration/sale-order');
      }}
    >
      {lang.btnCancel}
    </Button>,
    <Button
      key="btn-complete"
      type="primary"
      loading={loadingBtn}
      onClick={async () => {
        try {
          setLoadingBtn(true);
          const payload = items.map(item => {
            return {
              total: item.total,
              boothCode: currentWarehouse.code,
              sku: item?.barCode,
            };
          });
          await saleorderCreate({ nameCustomer: user.userInfo.name, productOrders: payload, boothCode: currentWarehouse.code, warehouseId: currentWarehouse?.id });
          message.success(lang.createSuccessfully);
          setLoadingBtn(false);
          navigate('/dashboard/configuration/sale-order');
        } catch (error) {
          message.error(error.message);
          setLoadingBtn(false);
        }
      }
      }
      disabled={!(items?.length > 0)}
    >
      {lang.btnComplete}
      <CheckOutlined />
    </Button>,
  ];

  const routes = [
    {
      path: '',
      breadcrumbName: 'Home',
    },
    {
      path: '',
      breadcrumbName: lang.configuration,
    },
    {
      path: '/sale-order',
      breadcrumbName: lang.saleOrder,
    },
    {
      path: '',
      breadcrumbName: lang.createSaleOrder,
    },
  ];

  return (
    <Layout className="Sale-order-list layoutContent">
      <PageHeader
        ghost={false}
        title={lang.createSaleOrder}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      //onBack={onBack}
      />
      <Layout.Content>
        {/* <Card>
          <Form
            colon={false}
            layout="vertical"
            name="productEditForm"
            initialValues={{}}
          >
            <Row gutter={24} type="flex">
              <Col lg={{ span: 6 }} xs={{ span: 12 }}>
                <Form.Item
                  name="inboundKind"
                  label={'Inbound kind'}
                  rules={[
                    {
                      required: true,
                      message: 'Please select a inbound kind',
                    },
                  ]}
                >
                  <Select placeholder={'Select a inbound kind'}>
                    {INBOUND_KINDS.map(item => (
                      <Option key={item.value} value={item.value}>{item.label}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={{ span: 6 }} xs={{ span: 12 }}>
                <Form.Item
                  name="receiptDate"
                  label={'Receipt date'}
                  rules={[
                    {
                      required: true,
                      message: lang.messageInput,
                    },
                  ]}
                >
                  <DatePicker style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card> */}
        <TableCustom
          title={() => <Row>
            <Col span={12}><h3>{lang.productAdded}</h3></Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <Button
                type="primary"
                onClick={() => {
                  setIsEditModal(true);
                  setItem();
                }}
              >
                {lang.btnAdd}
                <PlusOutlined />
              </Button>
              <Button
                type="primary"
                ghost
                style={{ marginLeft: 8 }}
              >
                {lang.importCSV}
              </Button>
              {selectedRowKeys.length > 0 && (
                <Button
                  type="primary"
                  onClick={() => {
                    Modal.confirm({
                      title: lang.doYouWantToDeleteTheseItems,
                      icon: <ExclamationCircleOutlined />,
                      //content: 'Some descriptions',
                      onOk() {
                        const dataFilter = items.filter(item => selectedRowKeys.indexOf(item.id) === -1);
                        setItems(dataFilter);
                        setSelectedRowKeys([]);
                      },
                      onCancel() {

                      },
                    });
                  }}
                  style={{ marginLeft: 8 }}
                >
                  {lang.btnDelete}
                  <DeleteOutlined />
                </Button>
              )}
            </Col>
          </Row>}
          rowKey="id"
          rowSelection={rowSelection}
          bordered
          columns={columns}
          dataSource={items}
          pagination={{
            showSizeChanger: true,
            total: items.length,
            onShowSizeChange: (current, size) => {
              SetPageSize(size);
              setCurrentPage(1);
            },
            pageSize: pageSize,
            current: currentPage,
          }}
          scroll={{ x: 467 }}
        />
      </Layout.Content>

      <Modal
        visible={isEditModal}
        title={item ? lang.editProduct : lang.addProduct}
        onCancel={() => setIsEditModal(false)}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
        wrapClassName="receipt-product-edit-modal"
      >
        <ReceiptCreateProductEditForm
          item={item}
          items={items}
          onCallback={(value) => {
            setItems([...items, value]);
            setIsEditModal(false);
          }}
          onCancel={() => setIsEditModal(false)}
        />
      </Modal>
    </Layout>
  );
};

export default ReceiptCreate;
