import React, { useState } from 'react';
import { Card, Col, Row, Button } from 'antd';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import Helper from 'utils/helpers';
import moment from 'moment-timezone';
import { formatDate, formatDateFull } from 'utils/constants';

const RequestReceiptProductPrint = ({ items, onCancel, onCallback }) => {
  const [loading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  return (
    <>
      <Row gutter={16} className="scrollYStyle">
        {items.map((value) => {
          return (
            <Col key={value.id} span={6}>
              <Card bodyStyle={{ padding: 0, textAlign: 'center' }}>
              {Helper.genarateQRcode([value.code])}
                {Helper.genarateBarcode([value.code])}
                <div style={{ textAlign: 'center' }}>{`${lang.code}:${value.code}`}</div>
                <div style={{ textAlign: 'center' }}>{`${lang.productName}: ${value?.masterProduct?.name}`}</div>
                <div style={{ textAlign: 'center' }}>{`Ngày nhập: ${moment(value?.receipt?.receiptDate, formatDateFull).format(formatDate)}`}</div>
              </Card>
            </Col>
          );
        })}
      </Row>
      <div
        className="ant-modal-footer"
        style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
      >
        <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
          <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
            <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
              {lang.btnCancel}
            </Button>
            {loading === false ? (
              <Button type="primary" onClick={() => onCallback(items)} style={{ fontWeight: 'bold' }}>
                {lang.print}
              </Button>
            ) : (
              <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                {lang.loading}
              </Button>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

RequestReceiptProductPrint.propTypes = {
  items: PropTypes.array,
  onCancel: PropTypes.func,
  onCallback: PropTypes.func,
};

export default RequestReceiptProductPrint;
