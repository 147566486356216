import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Layout, PageHeader, Button, Modal, Card, Row, Col, DatePicker, Input, Tag } from 'antd';
import { ScanOutlined, SearchOutlined } from '@ant-design/icons';
import { productList } from '../product.service';
import ProductReportForm from 'components/ProductReportForm/product-report.form';
import './style.less';

import moment from 'moment';
import _get from 'lodash/get';
import { defaultPage, formatDate, formatDateFull, formatDatetime } from 'utils/constants';
import TableCustom from 'components/TableCustom/table';

// const tableScrollHeightArea = window.innerHeight - 360; // px

// const VIEW_MODE = {
//   CARD_VIEW: 0,
//   LIST_VIEW: 1,
// };
const defaultParams = {
  ...defaultPage,
  status: 'MOVING',
};

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const StoringList = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);

  //Roles
  const { user, currentWarehouse } = useSelector((state) => state.auth);
  const isStaff = _get(user, 'roles', '').includes('staff');

  const [isReportModal, setIsReportModal] = useState(false);
  const [item, setItem] = useState();
  const [items, setItems] = useState([]);
  const [totalItem, setTotalItem] = useState(0);

  //Pagination
  const [params, setParams] = useState({ ...defaultParams });
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [sortBy] = useState('id');
  const [sortDirection] = useState('desc');
  const [keyword, setKeyword] = useState('');

  const getProducts = (params, sortBy, sortDirection, currentWarehouse) => {
    setLoading(true);

    // const params = {
    //   page,
    //   limit,
    //   sortBy,
    //   sortDirection,
    //   keyword,
    //   status: 'MOVING',
    // };
    // if (startDate) {
    //   params.startDate = startDate.startOf('date').format(formatDateFull);
    // }
    // if (endDate) {
    //   params.endDate = endDate.endOf('date').format(formatDateFull);
    // }
    // params.warehouseId = currentWarehouse.id;

    productList({ ...params, sortBy, sortDirection, warehouseId: currentWarehouse.id })
      .then(result => {
        setItems(result.data);
        setTotalItem(result.totalItem);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  };

  useEffect(() => {
    getProducts(params, sortBy, sortDirection, currentWarehouse);
  }, [params, sortBy, sortDirection, currentWarehouse]);

  const columns = [
    {
      title: lang.defaultCode,
      dataIndex: 'masterProduct',
      key: 'barcode',
      // width: '8%',
      render: value => value?.barCode
    },
    {
      title: lang.productCode,
      dataIndex: 'code',
      key: 'code',
      // width: '8%',
    },
    {
      title: lang.productName,
      dataIndex: 'masterProduct',
      key: 'name',
      width: '12%',
      render: value => value?.name,
    },
    {
      title: lang.supplier,
      dataIndex: 'supplier',
      key: 'supplier',
      width: '15%',
      render: value => value?.name,
    },
    {
      title: lang.receipt,
      dataIndex: 'receipt',
      key: 'receipt',
      // width: '8%',
      render: value => value?.code,
    },
    {
      title: lang.importDate,
      dataIndex: 'importDate',
      key: 'confirmDate',
      // width: '8%',
      render: value => moment(value, formatDateFull).format(formatDatetime),
    },
    {
      title: lang.expectedQuantity,
      key: 'expectedQuantity',
      dataIndex: 'expectedQuantity',
      // width: '6%',
    },
    {
      title: lang.totalQuantity,
      key: 'totalQuantity',
      dataIndex: 'totalQuantity',
      // width: '6%',
    },
    {
      title: lang.zone,
      key: 'zone',
      dataIndex: 'zone',
      width: '5%',
      render: value => value?.name,
    },
    {
      title: lang.block,
      key: 'block',
      dataIndex: 'block',
      width: '5%',
      render: value => value?.name,
    },
    {
      title: lang.shelf,
      key: 'shelf',
      dataIndex: 'rack',
      width: '5%',
      render: value => value?.shelf.name,
    },
    {
      title: lang.rack,
      key: 'rack',
      dataIndex: 'rack',
      // width: '6%',
      render: value => value?.code,
    },
    {
      title: lang.status,
      key: 'status',
      dataIndex: 'status',
      width: '5%',
      render: (record) => {
        return (
          <Tag>{lang[record]}</Tag>
        );
      },
    },
    {
      title: lang.action,
      align: 'center',
      width: '8%',
      fixed: 'right',
      render: (record) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            size="small"
            type="link"
            onClick={() => {
              //report
              const detail = {
                id: record?.id,
                productCode: record?.code,
                productName: record?.masterProduct?.name,
                totalQuantity: record?.totalQuantity,
              };

              setItem(detail);
              setIsReportModal(true);
            }}
          >{lang.report}</Button>
        </div>
      ),
    },
  ];

  const extraButton = [
    // <Radio.Group
    //   key="segment-control-view-mode"
    //   buttonStyle="solid"
    //   value={mode}
    //   onChange={(val) => setMode(val?.target?.value)}
    // >
    //   <Radio.Button value={VIEW_MODE.CARD_VIEW}>
    //     <AppstoreOutlined />
    //   </Radio.Button>
    //   <Radio.Button value={VIEW_MODE.LIST_VIEW}>
    //     <BarsOutlined />
    //   </Radio.Button>
    // </Radio.Group>,
    <Button
      key="btn-complete"
      disabled={isStaff}
      type="primary"
      onClick={() => {
        navigate('storing-scan');
      }}
    >
      {lang.scan}
      <ScanOutlined />
    </Button>,
  ];

  const routes = [
    {
      path: '/dashboard/inbound',
      breadcrumbName: lang.inbound,
    },
    {
      path: '/dashboard/inbound/storing',
      breadcrumbName: lang.storing,
    },
  ];

  return (
    <Layout className="layoutContent">
      <PageHeader
        ghost={false}
        title={lang.storing}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
        subTitle={<span className="spanCustomHead">{`*${lang.subTitleStoringNotify}`}</span>}
      />
      <Layout.Content>
        <Card size="small" className="cardSearch">
          <Row gutter={[16,16]}>
            <Col style={{ marginTop: 4 }}>
              <label>{lang.storingDate}</label>
            </Col>
            <Col  xxl={{span: 4}} sm={6} xs={16}>
              <DatePicker.RangePicker
                placeholder={[lang.from, lang.to]}
                onChange={(dates) => {
                  if (dates) {
                    setStartDate(dates[0]);
                    setEndDate(dates[1]);
                  } else {
                    setStartDate();
                    setEndDate();
                  }
                }}
                style={{ width: '100%' }}
                format={formatDate}
              />
            </Col>
            <Col xxl={{span: 4}} sm={6}>
            <Input
                placeholder={lang.keyword}
                onChange={(value) => setKeyword(value.target.value)}
              />
            </Col>
            <Col sm={3}>
              <Button
                type={'primary'}
                ghost
                icon={<SearchOutlined />}
                onClick={() => {
                  if (startDate) {
                    params.startDate = startDate.startOf('date').format(formatDateFull);
                  } else {
                    delete params.startDate;
                  }
                  if (endDate) {
                    params.endDate = endDate.endOf('date').format(formatDateFull);
                  } else {
                    delete params.endDate;
                  }
                  params.page = 1;
                  setParams({ ...params, keyword });
                }}
              >{lang.search}</Button>
            </Col>
          </Row>
        </Card>
        <TableCustom
          title={() => <Row>
            <Col span={12}><h3>{lang.productList}</h3></Col>
          </Row>}
          rowKey="id"
          //rowSelection={rowSelection}
          loading={loading}
          bordered
          columns={columns}
          dataSource={items}
          onChange={(pagination, filters, sorter) => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            // if (sorter) {
            //   setSortedInfo({
            //     columnKey: sorter.columnKey,
            //     order: sorter.order,
            //   });
            // }
            if (pagination.pageSize !== params.limit) {
              params.page = 1;
            } else {
              params.page = pagination.current;
            }
            params.limit = pagination.pageSize;
            setParams({ ...params });
          }}
          pagination={{
            total: totalItem,
            showSizeChanger: true,
            pageSize: params.limit,
            current: params.page,
          }}
          scroll={{ x: 2500 }}
        />
      </Layout.Content>
      <Modal
        visible={isReportModal}
        title={lang.reportErrorProduct}
        onCancel={() => setIsReportModal(false)}
        destroyOnClose={true}
        width={570}
        centered
        footer={null}
        wrapClassName="temporary-inbound-product-report-modal"
      >
        <ProductReportForm
          type={'storing'}
          item={item}
          onCallback={(value) => {
            setParams({ ...defaultParams });
            setIsReportModal(false);
          }}
          onCancel={() => setIsReportModal(false)}
        />
      </Modal>
    </Layout>
  );
};

export default StoringList;
