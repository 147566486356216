import axios from 'axios';
import _ from 'lodash';
import environment from 'environment';

const getAxiosInstance = (baseURL) => {
  const token = localStorage.getItem('__a_token');

  const headers = {
    ...(token ? { Authorization: 'Bearer ' + token } : {}),
  };

  const axiosInstance = axios.create({
    baseURL: baseURL,
    headers,
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      if ([200, 201].includes(response.status)) {
        const result = response.data;
        if (_.isObject(result.isObject)) {
          result.statusCode = response.status;
        }
        return response.data;
      }
      return Promise.reject(response);
    },
    (error) => {
      if (error.response?.status === 413) {
        return Promise.reject(new Error('updateImageLess'));
      }
      const { statusCode } = error.response.data;
      if (statusCode) {
        if (statusCode === 401 && window.location.pathname !== '/') {
          localStorage.removeItem('__a_token');
          window.location = '/';
        }
        return Promise.reject(error.response.data);
      }
      return Promise.reject(error.response.data);
    },
  );

  return axiosInstance;
};

const request = (url, data = {}, method = 'POST') => {
  try {
    const API = getAxiosInstance(environment.baseURL);
    switch (method) {
      case 'GET':
        return API.get(url, { params: data });
      case 'PUT':
        return API.put(url, data);
      case 'DELETE':
        return API.delete(url, data);
      default:
        return API.post(url, data);
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export default request;
