import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Button, Layout, PageHeader, Form, DatePicker, Card, Row, Col } from 'antd';

import { ProFormSelect } from '@ant-design/pro-form';
import IconExport from 'assets/icons/icon-Export.svg';
import moment from 'moment-timezone';
import { Area, Column, Pie } from '@ant-design/charts';
import * as numeral from 'numeral';
import { fetchReports, productMasterList } from './performance.service';
import { formatDateFull } from 'utils/constants';
import { useSelector } from 'react-redux';
import { hashMonth } from './hashMonth';

const breadcrumbItemRender = (route, params, routes, paths) => {
    const last = routes.indexOf(route) === routes.length - 1;
    return (last || route.path !== '') ? (
        <span>{route.breadcrumbName}</span>
    ) : (
        <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
    );
};

const InventoryWarning = () => {
    const { t } = useTranslation();
    const lang = getLanguages(t);
    const [listProductCate, setListProductCate] = useState([]);
    const [listInbound, setListInbound] = useState([]);
    const [listProduct, setListProduct] = useState([]);
    const [productCatefill, setProductCatefill] = useState({});
    const [productfill, setProductfill] = useState({});
    const [inboundFill, setInboundFill] = useState({});
    const { currentWarehouse } = useSelector((state) => state.auth);
    const [productMasters, setProductMasters] = useState([]);
    const [form] = Form.useForm();
    const rempDataProductCate = (productsCate) => {
        return productsCate.map((cate, index) => {
            return {
                id: index,
                productCategory: `${cate?.productCategory.code}-${cate?.productCategory.name}`,
                value: cate?.valueCategory,
            };
        });
    };

    const rempDataProduct = (products) => {
        const initValues = Array.from({ length: 12 }, (_, i) => i + 1).map(month => {
            return {
                month: lang[hashMonth[`${month}`]],
                value: 0,
            };
        });
        const result = products.reduce((previousValue, currentValue) => {
            if (currentValue?.valueProduct) {
                Object.assign(previousValue[currentValue?.month - 1], { value: currentValue?.valueProduct });
            }
            return previousValue;
        }, initValues);
        return [...result];
    };
    useEffect(() => {
        productMasterList({ warehouseId: currentWarehouse.id, status: 'ENABLE' }).then((value) => {
            setProductMasters(value.data);
            setProductfill({ year: moment(), masterProductId: value.data[0]?.id })
            return form?.setFieldsValue({ masterProductId: value.data[0]?.id });
        });
        // eslint-disable-next-line
    }, [currentWarehouse]);

    useEffect(() => {
        const { month } = productCatefill;
        const dataformat = moment(month).format(formatDateFull);
        fetchReports({ date: dataformat, type: 'INVENTORY', warehouseId: currentWarehouse.id }).then(result => {
            if (result) {
                setListProductCate(result.data);
            } else {
                setListProductCate([]);
            }
        });
    }, [productCatefill, currentWarehouse]);
    useEffect(() => {
        const { year } = productfill;
        const dataformat = moment(year).format(formatDateFull);
        delete productfill.year;
        fetchReports({ date: dataformat, type: 'PRODUCT', warehouseId: currentWarehouse.id, ...productfill }).then(result => {
            if (result) {
                setListProduct(result.data);
            } else {
                setListProduct([]);
            }
        });
    }, [productfill, currentWarehouse]);
    useEffect(() => {
        const { month } = inboundFill;
        const dataformat = moment(month).format(formatDateFull);
        fetchReports({ date: dataformat, type: 'INBOUND', warehouseId: currentWarehouse.id }).then(result => {
            if (result) {
                setListInbound(result.data);
            } else {
                setListInbound([]);
            }
        });
    }, [inboundFill, currentWarehouse]);

    const configColumn = useMemo(() => {
        return {
            data: rempDataProductCate(listProductCate),
            xField: 'productCategory',
            yField: 'value',
            columnStyle: {
                radius: [20, 20, 20, 20],
            },
            seriesField: 'productCategory',
            // color: (id) => {
            //     // console.log(id)
            //     // if(id%2===0){
            //     //     return '#FCB8B9'
            //     // }
            //     return '#E4595C';
            // },
            yAxis: {
                label: {
                    formatter: function formatter(value) {
                        return numeral(value).format('0 a');
                    },
                },
                title: {
                    text: lang.total,
                    style: { fontSize: 16 },
                    // position:'end',
                },

            },
            minColumnWidth: 20,
            maxColumnWidth: 20,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listProductCate]);

    const configPie = useMemo(() => {
        return {
            appendPadding: 10,
            data: listInbound.map(inbound => {
                return {
                    type: inbound.typeName,
                    value: inbound.valueProduct,
                };
            }),
            angleField: 'value',
            colorField: 'type',
            color: ['#E4595C', '#F0AA40', '#ECD71B', '#005522', '#006622', '#007722', '#008822'],
            radius: 1,
            innerRadius: 0.8,
            label: {
                type: 'inner',
                offset: '-50%',
                content: '{value}',
                style: {
                    textAlign: 'center',
                    fontSize: 14,
                },
            },
            meta: {
                value: {
                    formatter: function formatter(v) {
                        return ''.concat(numeral(v).format('0,0'), ' Units');
                    },
                },
            },
            interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
            statistic: {
                title: false,
                content: {
                    style: {
                        whiteSpace: 'pre-wrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    },
                },
            },
        };
    }, [listInbound]);
    const configArea = useMemo(() => {
        return {
            data: rempDataProduct(listProduct),
            xField: 'month',
            yField: 'value',
            xAxis: {
                range: [0, 1],
            },
            yAxis: {
                label: {
                    formatter: function formatter(value) {
                        return numeral(value).format('0 a');
                    },
                },
            },
            meta: {
                alias: 'Total',
            },
            line: {
                color: 'red',
                size: 4,
            },
            smooth: true,
            areaStyle: function areaStyle() {
                return {
                    fill: 'l(270) 0:#ffffff 0.5:#ff6666 0.7:#ff1a1a',
                };
            },
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listProduct]);

    const routes = [
        {
            path: '',
            breadcrumbName: 'Home',
        },
        {
            path: '',
            breadcrumbName: lang.reporting,
        },
        {
            path: '/performance',
            breadcrumbName: lang.performance,
        },
    ];
    return (
        <Layout className="layoutContent">
            <PageHeader
                ghost={false}
                title={lang.performance}
                breadcrumb={{
                    routes,
                    itemRender: breadcrumbItemRender,
                }}
                extra={[
                    <Button
                        key="btn-complete"
                        type="primary"
                        style={{ width: 154, borderRadius: 4 }}
                    >
                        {lang.exportCSV} <img alt="" style={{ marginLeft: 5, marginTop: '-2px' }} src={IconExport} />
                    </Button>,
                ]}
                className="customPageHeader"
            />
            <Layout.Content>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Card size="small" title={lang.inventorySummary}
                            extra={<Form
                                layout="inline"
                                initialValues={{ month: moment() }}
                                onValuesChange={(value) => setProductCatefill(value)}
                            >
                                <Form.Item name="month">
                                    <DatePicker format="MM/YYYY" picker="month" />
                                </Form.Item>
                            </Form>
                            }
                        >
                            <Column {...configColumn} />
                        </Card>
                    </Col>
                    <Col md={{ span: 10 }} sm={{ span: 24 }} xs={24}>
                        <Card size="small" title={lang.inboundsummary}
                            extra={<Form
                                layout="inline"
                                initialValues={{ month: moment() }}
                                onValuesChange={(value) => setInboundFill(value)}
                            >
                                <Form.Item name="month">
                                    <DatePicker format="MM/YYYY" picker="month" />
                                </Form.Item>
                            </Form>
                            }
                        >
                            <Pie {...configPie} />
                        </Card>
                    </Col>
                    <Col md={{ span: 14 }} sm={24} xs={24}>
                        <Card size="small" title={lang.productSummary}
                            extra={
                                <Form
                                    form={form}
                                    layout="inline"
                                    initialValues={{ year: moment() }}
                                    onValuesChange={(value,allValues) => setProductfill(allValues)}
                                >
                                    <Row>
                                        <Col>
                                            <ProFormSelect label={lang.productName} name="masterProductId" placeholder={lang.productName}
                                                options={productMasters?.map(product => {
                                                    return {
                                                        value: product.id,
                                                        label: product.name,
                                                    }
                                                })}
                                                fieldProps={{
                                                    showSearch: true,
                                                    optionFilterProp: "children",
                                                    filterOption: (input, option) => {
                                                        return option.label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                }}
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Item name="year">
                                                <DatePicker format="YYYY" picker="year" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            }
                        >
                            <Area {...configArea} />
                        </Card>
                    </Col>
                </Row>
            </Layout.Content>
        </Layout>
    );
};

export default InventoryWarning;
