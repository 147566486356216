import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Popconfirm, message, Card, Row, Col } from 'antd';
import './vans-style.less';
import ProCard from '@ant-design/pro-card';
import { useSelector } from 'react-redux';
import { listVans, vanDelete } from './vans.service';
import VanModalForm from './vanModalForm';

const scrollStyle = {
    overflowY: 'scroll',
    height: window.innerHeight - 200,
    margin: 'auto',
    width: '100%',
};
const headeStyleZone = {
    margin: 0,
    border: 0,
    height: 0,
    minHeight: 0,
};

const breadcrumbItemRender = (route, params, routes, paths) => {
    const last = routes.indexOf(route) === routes.length - 1;
    return (last || route.path !== '') ? (
        <span>{route.breadcrumbName}</span>
    ) : (
        <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
    );
};

const Vans = () => {
    // const dispatch = useDispatch();
    const { t } = useTranslation();
    const lang = getLanguages(t);
    const [listblock, setListblock] = useState([]);
    const [visible, setVisible] = useState(false);
    const [vanSelected, setVanSelected] = useState();
    const { currentWarehouse } = useSelector((state) => state.auth);

    const fetchVans = (warehouseId) => {
        listVans({ warehouseId })
            .then(result => {
                setListblock([...result]);
            })
            .catch((e) => message.error(e.message));
    };

    useEffect(() => {
        fetchVans(currentWarehouse.id);
    }, [currentWarehouse]);

    const routes = [
        {
            breadcrumbName: 'Home',
        },
        {
            breadcrumbName: lang.configuration,
        },
        {
            breadcrumbName: lang.vans,
        },
    ];

    const handleDeleteVan = async (van) => {
        try {
            await vanDelete(van);
            fetchVans(currentWarehouse.id);
            message.success(lang.shelfAreDeletedSuccessfully);
            return true;
        } catch (error) {
            message.error(error.message);
            return false;
        }
    };
    return (
        <Layout className="layoutContent">
            <PageHeader
                ghost={false}
                title={lang.vans}
                breadcrumb={{
                    routes,
                    itemRender: breadcrumbItemRender,
                }}
                className="customPageHeader"
            />
            <Layout.Content>
                <ProCard bodyStyle={scrollStyle} >
                    <Row gutter={[16, 16]} /*className="RowMargin"*/>
                        {listblock.map((van) => (
                            <Col key={van.code} xxl={3} md={4} sm={6} xs={8}>
                                <Card
                                    style={{ background: '#EFF1F1', borderRadius: 4, marginTop: 5 }}
                                    headStyle={headeStyleZone}
                                    extra={<Popconfirm title={lang.areYouSureToDeleteThisShelf}
                                        onConfirm={async () => await handleDeleteVan(van)}>
                                        <div className="btnDelete" style={{ right: '-9px' }}>X</div></Popconfirm>}
                                    className="CardVans" bodyStyle={{ padding: '15px', height: 50 }}
                                >
                                    <span style={{ wordWrap: 'break-word' }} onClick={async () => {
                                        setVanSelected(van);
                                        setVisible(true);
                                    }}>{van.name}</span>
                                </Card>
                            </Col>
                        ))}
                        <Col key="btnadd-shelf" xxl={3} md={4} sm={6} xs={8}>
                            <Card
                                className="ButtonVanAdd"
                                style={{ color: '#2f80ed', lineHeight: 1, border: '1px dashed #2f80ed', borderRadius: 4, marginTop: 5 }}
                                onClick={() => {
                                    setVanSelected();
                                    setVisible(true);
                                }}
                                bodyStyle={{ padding: '17px 0' }}
                            >
                                <span>{lang.addVans} +</span>
                            </Card>
                        </Col>
                    </Row>
                </ProCard>
            </Layout.Content>
            <VanModalForm
                item={vanSelected}
                visible={visible}
                setVisible={setVisible}
                fetchVans={fetchVans}
                setVanSelected={setVanSelected}
            />
        </Layout>
    );
};

export default Vans;
