import request from 'utils/request';
import environment from 'environment';
import qs from 'querystring';
import { formatDateFull } from 'utils/constants';

export async function receiptList(payload) {
  const params = qs.stringify(payload);
  return request(`${environment.api.receiptList}?${params}`, {}, 'GET');
}

export async function receiptDetail(payload) {
  return request(`${environment.api.receiptDetail}/${payload?.receiptId}`, {}, 'GET');
}

export async function receiptCreate(payload) {
  const { creatorId, creatorName, inboundkind, receiptDate, warehouseId, products } = payload;
  const receipt = {
    creatorId,
    creatorName,
    inboundkind,
    receiptDate,
    warehouseId,
    supplierId: 0,
    products: products.map(item => {
      if(item?.expireDate){
        return {
          ...item,
          inboundKind: inboundkind,
          expireDate: item.expireDate.format(formatDateFull),
        }
      }
      return {
        ...item,
        inboundKind: inboundkind,
      };
    }),
  };
  return request(environment.api.receiptCreate, receipt, 'POST');
}

export async function receiptUpdate(payload) {
  return request(environment.api.receiptUpdate, payload, 'PUT');
}

export async function receiptConfirm(payload) {
  return request(environment.api.receiptConfirm, payload, 'PUT');
}

export async function receiptDelete(payload) {
  return request(environment.api.receiptDelete, payload, 'POST');
}

export async function packageList(payload) {
  return request(`${environment.api.packageList}`, {}, 'GET');
}
