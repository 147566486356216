import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector, Provider } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import moment from 'moment-timezone';
import _get from 'lodash/get';
import 'moment/locale/vi';

import store, { persistor } from '../src/redux/store';

import detectBrowserLanguage from 'detect-browser-language';
import { useTranslation } from 'react-i18next';
import { setLanguage } from 'providers/I18nProvider/actions';

// Public
import HomeLayout from 'layouts/home/home.layout';
import Login from 'pages/home/login';

// Dashboard
import DashboardLayout from 'layouts/dashboard/dashboard.layout';
import Welcome from 'pages/dashboard/welcome';

// Inbound
import InboundReceiptList from 'pages/dashboard/inbound/receipt/receipt-list.container';
import InboundReceiptCreate from 'pages/dashboard/inbound/receipt/receipt-create.container';
import InboundReceiptDetail from 'pages/dashboard/inbound/receipt/receipt-detail.container';

import InboundTemporaryInboundList from 'pages/dashboard/inbound/temporary-inbound/temporary-inbound-list.container';
import ScanReturnProduct from 'pages/dashboard/inbound/temporary-inbound/scan-return-product.container';
import ScanReturnProductComplete from 'pages/dashboard/inbound/temporary-inbound/scan-return-product-complete.container';

import InboundStoring from 'pages/dashboard/inbound/storing/storing-list.container';
import InboundStoringScan from 'pages/dashboard/inbound/storing/storing-scan.container';
import InboundStoringScanComplete from 'pages/dashboard/inbound/storing/storing-scan-complete.container';

import InboundInboundControl from 'pages/dashboard/inbound/inbound-control/inbound-control-list.container';
import InboundInboundControlScan from 'pages/dashboard/inbound/inbound-control/inbound-control-scan.container';
import InboundInboundControlScanComplete from 'pages/dashboard/inbound/inbound-control/inbound-control-scan-complete.container';

//Configuration
import Supplier from 'pages/dashboard/configuration/supplier/supplier-list.container';
import SupplierDetail from 'pages/dashboard/configuration/supplier/supplier-detail.container';
import ProductCategory from 'pages/dashboard/configuration/product-category/product-category-list.container';
import Product from 'pages/dashboard/configuration/product/product-list.container';
import ProductDetail from 'pages/dashboard/configuration/product/product-detail.container';
import Replenishment from 'pages/dashboard/configuration/replenishment/replenishment-list.container';
import Location from 'pages/dashboard/configuration/location/location-list.container';
import SaleOrder from 'pages/dashboard/configuration/sale-order/sale-order-list.container';
import SaleOrderCreate from 'pages/dashboard/configuration/sale-order/sale-order-create.container';
import SaleOrderDetail from 'pages/dashboard/configuration/sale-order/sale-order-detail.container';
import Vans from 'pages/dashboard/configuration/vans/vans-list.container';
import Divison from 'pages/dashboard/configuration/divison/divison-list.container';
import Category from 'pages/dashboard/configuration/category/category-list.container';

//Inventory
import WarehouseState from 'pages/dashboard/inventory/warehouse-state/warehousestate-list.container';
import WarehouseBlock from 'pages/dashboard/inventory/warehouse-state/warehouseblock.container';
import Reallocation from 'pages/dashboard/inventory/reallocation/reallocation-list.container';
import ReallocationScanCode from 'pages/dashboard/inventory/reallocation/reallocation-scancode.container';
import ProductManagement from 'pages/dashboard/inventory/product-management/product-management.container';
import EditLocation from 'pages/dashboard/inventory/reallocation/reallocation-edit.container';
import VansState from 'pages/dashboard/inventory/vans-state/vans-state-list.container';

//Outbound
import OutboundPickingList from 'pages/dashboard/outbound/picking/picking-list.container';
import OutboundPickingCreate from 'pages/dashboard/outbound/picking/picking-create.container';
import OutboundPickingCreateScan from 'pages/dashboard/outbound/picking/picking-create-scan.container';
import OutboundPickingCreateScanComplete from 'pages/dashboard/outbound/picking/picking-create-scan-complete.container';

import OutboundPackagingList from 'pages/dashboard/outbound/packaging/packaging-list.container';
import OutboundPackagingCreate from 'pages/dashboard/outbound/packaging/packaging-create.container';
import OutboundPackagingCreateScan from 'pages/dashboard/outbound/packaging/packaging-create-scan.container';
import OutboundPackagingCreateScanComplete from 'pages/dashboard/outbound/packaging/packaging-create-scan-complete.container';

import OutboundShippingList from 'pages/dashboard/outbound/shipping/shipping-list.container';
import OutboundShippingScan from 'pages/dashboard/outbound/shipping/shipping-scan.container';
import OutboundShippingScanComplete from 'pages/dashboard/outbound/shipping/shipping-scan-complete.container';

//Reporting
import AuditOrder from 'pages/dashboard/reporting/auditOrder/auditOrderlist.container';
import InventoryWarning from 'pages/dashboard/reporting/inventory-Warning/inventoryWarninglist.container';
import Performance from 'pages/dashboard/reporting/performance/performance.container';

//Admin
import AdminLayout from 'layouts/admin/admin.layout';
import AdminWelcome from 'pages/admin/welcome';
import UserList from 'pages/admin/user/user-list.container';
import WarehouseList from 'pages/admin/warehouse/warehouse-list.container';
import { ConfigProvider } from 'antd';
import enUSIntl from 'antd/lib/locale/en_US';
import viVNIntl from 'antd/lib/locale/vi_VN';
import SplitScanContainer from 'pages/dashboard/outbound/split-product/split-scan.container';
import SplitScanComplete from 'pages/dashboard/outbound/split-product/split-scan-complete';
import VansDetail from 'pages/dashboard/inventory/vans-state/vans-detail.container';
import RequestReceiptList from 'pages/dashboard/inbound/request-receipt/request-receipt-list.container';
import RequestReceiptCreate from 'pages/dashboard/inbound/request-receipt/request-receipt-create.container';
import RequestReceiptDetail from 'pages/dashboard/inbound/request-receipt/request-receipt-detail.container';

const AppWrapper = () => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
};

const App = () => {
  const dispatch = useDispatch();
  moment.tz.setDefault();

  const { i18n } = useTranslation();
  const locale = useSelector((state) => state.i18n.locale);
  const { user } = useSelector((state) => state.auth);

  const isAdmin = _get(user, 'roles', '').includes('admin');
  const isManager = _get(user, 'roles', '').includes('manager');
  const isSupervisor = _get(user, 'roles', '').includes('supervisor');
  const isStaff = _get(user, 'roles', '').includes('staff');

  const setLanguageHandler = useCallback(
    (languageDetectByBrowser) => dispatch(setLanguage({ locale: languageDetectByBrowser })),
    [dispatch],
  );

  useEffect(() => {
    const languageDetectByBrowser = detectBrowserLanguage();
    if (!languageDetectByBrowser) {
      setLanguageHandler(languageDetectByBrowser);
    }
  }, [setLanguageHandler]);

  useEffect(() => {
    if (!locale || i18n.language === locale) return;
    i18n.changeLanguage(locale);
  }, [locale, i18n]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ConfigProvider locale={locale === 'vi' ? viVNIntl : enUSIntl}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <HelmetProvider>
          <Helmet>
            <title>CloudWMS</title>
          </Helmet>
          <Routes>
            <Route path="" element={<HomeLayout />}>
              <Route path="" element={<Login />} />
              <Route path="login" element={<Login />} />
            </Route>
            {isAdmin === true
              ? <Route path="admin" element={<AdminLayout />}>
                  <Route path="" element={<AdminWelcome />} />
                  <Route path="user" element={<UserList />} />
                  <Route path="warehouse" element={<WarehouseList />} />
                </Route>
                : !!user && <Route path="dashboard" element={<DashboardLayout />}>
                  <Route path="" element={<Welcome />} />

                  <Route path="inbound/request-receipt" element={<RequestReceiptList />} />
                  <Route path="inbound/request-receipt/create" element={<RequestReceiptCreate />} />
                  <Route path="inbound/request-receipt/:receiptId" element={<RequestReceiptDetail />} />
                  
                  <Route path="inbound/receipt" element={<InboundReceiptList />} />
                  {(isManager || isSupervisor) && (
                    <>
                    <Route path="inbound/receipt/create" element={<InboundReceiptCreate />} />
                    <Route path="inbound/receipt/:receiptId" element={<InboundReceiptDetail />} />
                    </>
                  )}

                  <Route path="inbound/temporary-inbound" element={<InboundTemporaryInboundList />} />
                  {(isManager || isSupervisor) && (
                    <>
                    <Route path="inbound/temporary-inbound/scan-return-product" element={<ScanReturnProduct />} />
                    <Route path="inbound/temporary-inbound/scan-return-product/complete" element={<ScanReturnProductComplete />} />
                    </>
                  )}

                  <Route path="inbound/storing" element={<InboundStoring />} />
                  {(isManager || isSupervisor) && (
                    <>
                    <Route path="inbound/storing/storing-scan" element={<InboundStoringScan />} />
                    <Route path="inbound/storing/storing-scan/complete" element={<InboundStoringScanComplete />} />
                    </>
                  )}

                  <Route path="inbound/inbound-control" element={<InboundInboundControl />} />
                  {(isManager || isSupervisor) && (
                    <>
                    <Route path="inbound/inbound-control/inbound-control-scan" element={<InboundInboundControlScan />} />
                    <Route path="inbound/inbound-control/inbound-control-scan/complete" element={<InboundInboundControlScanComplete />} />
                    </>
                  )}

                  {isManager && (
                    <>
                    <Route path="configuration/supplier" element={<Supplier />} />
                    <Route path="configuration/supplier/:supplierId" element={<SupplierDetail />} />
                    <Route path="configuration/product-category" element={<ProductCategory />} />
                    <Route path="configuration/category" element={<Category />} />
                    <Route path="configuration/divison" element={<Divison />} />
                    <Route path="configuration/product" element={<Product />} />
                    <Route path="configuration/product/:productId" element={<ProductDetail />} />
                    <Route path="configuration/location" element={<Location />} />
                    <Route path="configuration/replenishment" element={<Replenishment />} />
                    <Route path="configuration/sale-order" element={<SaleOrder />} />
                    <Route path="configuration/sale-order/create" element={<SaleOrderCreate />} />
                    <Route path="configuration/sale-order/:saleorderID" element={<SaleOrderDetail />} />
                    <Route path="configuration/vans" element={<Vans />} />
                    </>
                  )}
                  <Route path="inventory/warehousestate" element={<WarehouseState />} />
                  <Route path="inventory/warehousestate/warehouseblock/:blockId" element={<WarehouseBlock />} />
                  <Route path="inventory/vansstate" element={<VansState />} />
                  <Route path="inventory/vansstate/vansdetail/:vansId" element={<VansDetail />} />
                  {!isStaff && (
                    <>
                    <Route path="inventory/reallocation" element={<Reallocation />} />
                    <Route path="inventory/reallocation/scancode" element={<ReallocationScanCode />} />
                    <Route path="inventory/reallocation/scancode/editlocation" element={<EditLocation />} />
                    <Route path="inventory/productmanagement" element={<ProductManagement />} />
                    </>
                  )}

                  <Route path="outbound/sale-order" element={<OutboundPickingList />} />
                  {!isStaff && (
                    <>
                    <Route path="outbound/sale-order/create" element={<OutboundPickingCreate />} />
                    <Route path="outbound/sale-order/create/scan" element={<OutboundPickingCreateScan />} />
                    <Route path="outbound/sale-order/create/scan/complete" element={<OutboundPickingCreateScanComplete />} />
                    </>
                  )}

                  <Route path="outbound/packaging" element={<OutboundPackagingList />} />
                  {!isStaff && (
                    <>
                    <Route path="outbound/packaging/create" element={<OutboundPackagingCreate />} />
                    <Route path="outbound/packaging/create/scan" element={<OutboundPackagingCreateScan />} />
                    <Route path="outbound/packaging/create/scan/complete" element={<OutboundPackagingCreateScanComplete />} />
                    </>
                  )}

                  <Route path="outbound/shipping" element={<OutboundShippingList />} />
                  {!isStaff && (
                    <>
                    <Route path="outbound/shipping/scan" element={<OutboundShippingScan />} />
                    <Route path="outbound/shipping/scan/complete" element={<OutboundShippingScanComplete />} />
                    </>
                  )}
                  <Route path="outbound/split-product/scan" element={<SplitScanContainer />} />
                  <Route path="outbound/split-product/scan/complete" element={<SplitScanComplete />} />
                  {isManager && (
                    <>
                    <Route path="reporting/auditorder" element={<AuditOrder />} />
                    <Route path="reporting/inventorywarning" element={<InventoryWarning />} />
                    <Route path="reporting/performance" element={<Performance />} />
                    </>
                  )}
                </Route>
              }
          </Routes>
        </HelmetProvider>
      </BrowserRouter>
    </PersistGate>
    </ConfigProvider>
  );
};

export default AppWrapper;
