import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Form, Col, Row, Button, Input, Modal } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';

import { suggestLocationByOrder } from './picking.service';

import './style.less';
import TableCustom from 'components/TableCustom/table';

const tableScrollHeightArea = window.innerHeight - 200; // px

const SuggestLocationForm = ({ item, onCancel, onCallback, productOrders }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);

  const [items, setItems] = useState([]);

  // const filterDataOrder = (productOrder) => {
  //   return productOrder.filter(product => product.status === 'ENABLE');
  // };
  const columnsBookingOrders = [
    {
      title: lang.defaultCode,
      render: record => record?.masterProduct?.code,
      key: 'productCode',
      width: '15%',
    },
    {
      title: lang.productCategory,
      key: 'productCategory',
      width: '20%',
      render: record => record?.masterProduct?.productCategory?.name,
    },
    {
      title: lang.productName,
      key: 'name',
      width: '20%',
      render: record => record?.masterProduct?.name,
    },
    {
      title: lang.orderQuantity,
      dataIndex: 'total',
      key: 'total',
      width: '15%',
    },
  ];
  useEffect(() => {
    setLoading(true);
    const remOrders = productOrders?.reduce((prev,cur)=>{
      const find = prev?.find(prev=>prev.masterProduct.id===cur.masterProduct.id);
      if(find){
        find.total= find.total+ cur.total;
        return prev
      }
      return [...prev,Object.assign({},cur)];
    },[]);
    Promise.all(remOrders?.map(async (product) => {
      const { masterProduct, total, warehouseId } = product;
      const reponse = await suggestLocationByOrder({ masterProductId: masterProduct.id, quantity: total, warehouseId });
      return Promise.resolve(reponse.data);
    })).then((results) => {
      const ctx = results?.reduce((prev, current) => {
        if (current.length > 0 && prev?.find(pre => pre.id === current[0].id)) {
          return prev;
        }

        return prev.concat(current);
      }, []).sort((first,second)=>{
        return first?.block?.id -second?.block?.id;
      }).sort((first,second)=>{
        return first.rack.id -second.rack.id;
      });
      setItems([...ctx]);
      setLoading(false);
    }).catch(() => setLoading(false));
  }, [productOrders]);

  const columns = [
    {
      title: lang.defaultCode,
      render: record => record?.masterProduct?.code,
      key: 'productCode',
      width: '15%',
      fixed: true,
    },
    {
      title: lang.productCode,
      dataIndex: 'code',
      key: 'productCode',
      width: '15%',
      fixed: true,
    },
    {
      title: lang.productCategory,
      key: 'productCategory',
      width: '18%',
      render: record => record?.masterProduct?.productCategory?.name,
    },
    {
      title: lang.productName,
      key: 'productName',
      width: '27%',
      render: record => record?.masterProduct?.name,
    },
    {
      title: lang.block,
      dataIndex: 'block',
      key: 'block',
      width: '14%',
      render: value => value?.name,
    },
    {
      title: lang.shelf,
      key: 'shelf',
      width: '13%',
      render: record => record?.rack?.shelf?.name,
    },
    {
      title: lang.rack,
      dataIndex: 'rack',
      key: 'rack',
      width: '15%',
      render: value => value?.code,
    },
    {
      title: lang.quantity,
      key: 'orderQuantity',
      dataIndex: 'totalQuantity',
      width: '15%',
    },
  ];

  return (
    <Form
      colon={false}
      form={form}
      layout="vertical"
      name="receiptProductEditForm"
      onFinish={() => {
        Modal.success({
          title: lang.titleModalSuccessSuggestLocation,
          icon: <CheckOutlined />,
          onOk() {
            onCallback(items);
          },
          okText: lang.okText,
        });
      }}
      initialValues={{}}
    >
      <Row gutter={24} style={{ marginBottom: 24 }}>
        <Col>
          <h3>{`${lang.labelBooking}`}</h3>
        </Col>
        <Col lg={{ span: 6 }} xs={{ span: 12 }}>
          <Input value={item?.map(ite => ite.code)} disabled />
        </Col>
      </Row>
      <TableCustom
        rowKey="id"
        loading={loading}
        bordered
        columns={columnsBookingOrders}
        dataSource={productOrders?.reduce((prev,cur)=>{
          const find = prev?.find(prev=>prev.masterProduct.id===cur.masterProduct.id);
          if(find){
            find.total= find.total+ cur.total;
            return prev
          }
          return [...prev,Object.assign({},cur)];
        },[])}
        scroll={{ x: 600, y: tableScrollHeightArea }}
        pagination={{
          showSizeChanger: true,
        }}
      />
      <TableCustom
        rowKey="id"
        loading={loading}
        bordered
        columns={columns}
        dataSource={items}
        scroll={{ x: 1300, y: tableScrollHeightArea }}
        pagination={{
          showSizeChanger: true,
        }}
      />
      <div
        className="ant-modal-footer"
        style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
      >
        <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
          <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
            <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
              {lang.btnCancel}
            </Button>
            {loading === false ? (
              <Button type="primary" htmlType='submit' style={{ fontWeight: 'bold' }}>
                {lang.print}
              </Button>
            ) : (
              <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                {lang.loading}
              </Button>
            )}
          </Col>
        </Row>
      </div>
    </Form>
  );
};

SuggestLocationForm.propTypes = {
  item: PropTypes.array,
  onCancel: PropTypes.func,
  onCallback: PropTypes.func,
  productOrders: PropTypes.array,
};

export default SuggestLocationForm;
