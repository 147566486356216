import request from 'utils/request';
import moment from 'moment-timezone';
import environment from 'environments/environment';
import { formatDateFull } from 'utils/constants';

const mockData = [
    {
        id: 1,
        code: 'SL_01',
        name: 'NHÀ PHÂN PHỐI CHINSU',
        cooperationDay: '2021-08-18',
        productCategorys: [{
            code: 'CATE3',
            createDate: '2021-08-30T03:16:09.881Z',
            exportStrategy: 'FEFO',
            id: 3,
            name: 'Home Care 5',
            status: 'ENABLE',
            updateDate: '2021-08-30T03:16:09.881Z',
            warehouseId: 1,
        }],
        phoneNumber: '19008198',
        email: 'chinsu@gmail.com',
        address: '97 Man Thiện,Hiệp Phú,Quận 9',
        createDate: '2021-08-18',
        status: 'ENABLE',
    },
    {
        id: 2,
        code: 'SL_02',
        name: 'NHÀ PHÂN PHỐI SỮA VINAMILK',
        cooperationDay: '2021-08-18',
        productCategorys: [{
            code: 'CATE3',
            createDate: '2021-08-30T03:16:09.881Z',
            exportStrategy: 'FEFO',
            id: 3,
            name: 'Home Care 5',
            status: 'ENABLE',
            updateDate: '2021-08-30T03:16:09.881Z',
            warehouseId: 1,
        }],
        phoneNumber: '19008199',
        email: 'vinamilk@gmail.com',
        address: '98 Man Thiện,Hiệp Phú,Quận 9',
        createDate: '2021-08-18',
        status: 'ENABLE',
    },
    {
        id: 3,
        code: 'SL_03',
        name: 'NHÀ PHÂN PHỐI HÀNG TIÊU DÙNG UNILEVER',
        cooperationDay: '2021-08-18',
        productCategorys: [{
            code: 'CATE3',
            createDate: '2021-08-30T03:16:09.881Z',
            exportStrategy: 'FEFO',
            id: 3,
            name: 'Home Care 5',
            status: 'ENABLE',
            updateDate: '2021-08-30T03:16:09.881Z',
            warehouseId: 1,
        }],
        phoneNumber: '19008200',
        email: 'nhaphanphoi@gmail.com',
        address: '99 Man Thiện,Hiệp Phú,Quận 9',
        createDate: '2021-08-18',
        status: 'ENABLE',
    },
    {
        id: 4,
        code: 'SL_04',
        name: 'NHÀ PHÂN PHỐI TIGER TẠI TPHCM',
        cooperationDay: '2021-08-18',
        productCategorys: [{
            code: 'CATE3',
            createDate: '2021-08-30T03:16:09.881Z',
            exportStrategy: 'FEFO',
            id: 3,
            name: 'Home Care 5',
            status: 'ENABLE',
            updateDate: '2021-08-30T03:16:09.881Z',
            warehouseId: 1,
        }],
        phoneNumber: '19008201',
        email: 'tiger@gmail.com',
        address: '100 Man Thiện,Hiệp Phú,Quận 9',
        createDate: '2021-08-18',
        status: 'ENABLE',
    },
    {
        id: 5,
        code: 'SL_05',
        name: 'CÔNG TY VĂN PHÒNG PHẨM THIÊN LONG',
        cooperationDay: '2021-08-18',
        productCategorys: [{
            code: 'CATE3',
            createDate: '2021-08-30T03:16:09.881Z',
            exportStrategy: 'FEFO',
            id: 3,
            name: 'Home Care 5',
            status: 'ENABLE',
            updateDate: '2021-08-30T03:16:09.881Z',
            warehouseId: 1,
        }],
        phoneNumber: '19008202',
        email: 'thienlong@gmail.com',
        address: '101 Man Thiện,Hiệp Phú,Quận 9',
        createDate: '2021-08-18',
        status: 'ENABLE',
    },
    {
        id: 6,
        code: 'SL_06',
        name: 'NHÀ PHÂN PHỐI SUNHOUSE',
        cooperationDay: '2021-08-18',
        productCategorys: [{
            code: 'CATE3',
            createDate: '2021-08-30T03:16:09.881Z',
            exportStrategy: 'FEFO',
            id: 3,
            name: 'Home Care 5',
            status: 'ENABLE',
            updateDate: '2021-08-30T03:16:09.881Z',
            warehouseId: 1,
        }],
        phoneNumber: '19008203',
        email: 'sunhouse@gmail.com',
        address: '102 Man Thiện,Hiệp Phú,Quận 9',
        createDate: '2021-08-18',
        status: 'ENABLE',
    },
];

export async function supplierList(payload) {
    if (environment.isMockData) {
        return Promise.resolve(mockData);
    }
    return request(environment.api.supplierList, payload, 'GET');
}

export async function supplierDetail(id) {
    if (environment.isMockData) {
        return Promise.resolve(mockData.filter(mock => mock.id.toString() === id)[0]);
    }
    return request(environment.api.supplierDetail + `/${id}`, {}, 'GET');
}
export async function supplierCreate(payload) {
    if (environment.isMockData) {
        delete payload.productCategoryIds;
        const newValue = {
            id: mockData.length + 1,
            code: `SL_0${mockData.length + 1}`,
            name: payload.name,
            cooperationDay: payload.cooperationDay,
            productCategorys: [{
                code: 'CATE3',
                createDate: '2021-08-30T03:16:09.881Z',
                exportStrategy: 'FEFO',
                id: 3,
                name: 'Home Care 5',
                status: 'ENABLE',
                updateDate: '2021-08-30T03:16:09.881Z',
                warehouseId: 1,
            }],
            phoneNumber: payload.phoneNumber,
            email: payload.email,
            address: payload.address,
            createDate: '2021-08-18',
            status: 'ENABLE',
        };
        mockData.push(newValue);
        return Promise.resolve('OK');
    }
    const { cooperationDay } = payload;
    payload.cooperationDay = moment(cooperationDay).format(formatDateFull);
    const newSupplier = {
        ...payload,
    };
    return request(environment.api.supplierCreate, newSupplier, 'POST');
}

export async function supplierUpdate(values, item) {
    if (environment.isMockData) {
        mockData.map(mock => {
            if (mock.id === item.id) {
                Object.assign(mock, values);
            }
            return mock;
        });
        return Promise.resolve('OK');
    }
    const updateValue = {
        ...values,
        id: item.id,
        warehouseId: item.warehouseId,
    };
    return request(environment.api.supplierUpdate, updateValue, 'PUT');
}

export async function supplierDelete(id) {
    if (environment.isMockData) {
        const mockfil = mockData.filter(mock => id.indexOf(mock.id) === -1);
        return Promise.resolve(mockfil);
    }
    return request(environment.api.supplierDelete + `/${id}`, {}, 'DELETE');
}
export async function importExel(warehouseId, formData) {
    return request(environment.api.supplierCreate + `/excel/${warehouseId}`, formData, 'POST');
}
