import React, { useState } from 'react';
import { Form, Input, Col, Row, Button, InputNumber, DatePicker } from 'antd';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';

import './style.less';
import moment from 'moment-timezone';
import { formatDate, formatDateFull } from 'utils/constants';

const ReceiptProductEditForm = ({ item, onCancel, onCallback }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);

  return (
    <Form
      colon={false}
      form={form}
      layout="vertical"
      name="receiptProductEditForm"
      onFinish={(values) => {
        setLoading(true);
        onCallback({
          ...values,
          id: item.id,
        });
      }}
      initialValues={{
        totalQuantity: item ? item?.totalQuantity : undefined,
        expireDate: item?.expireDate ? moment(item.expireDate, formatDateFull) : undefined,
        note: item ? item?.note : undefined,
      }}
    >
      <Row gutter={24} type="flex">
        <Col lg={{ span: 16 }} xs={{ span: 24 }}>
          <Form.Item label={lang.productCode}>
            <Input disabled value={item?.code} />
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item
            label={lang.productName}
          >
            <Input disabled value={item?.masterProduct?.name} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24} type="flex">
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item
            name="totalQuantity"
            label={lang.totalQuantity}
            rules={[
              {
                required: true,
                message: lang.messageTotalQuantity,
              },
            ]}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item label={lang.supplier}>
            <Input disabled value={item?.masterProduct?.suppliers?.map(supp => supp.name).join(', ')} />
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item label={lang.productCategory}>
            <Input disabled value={item?.masterProduct?.productCategory?.name} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24} type="flex">
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item
            label={lang.cost}
          >
            <InputNumber style={{ width: '100%' }} disabled value={item?.masterProduct?.purchasePrice} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} />
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item label={lang.salePrice}>
            <InputNumber style={{ width: '100%' }} disabled value={item?.masterProduct?.salePrice} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} />
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
            <Form.Item label={lang.retailPrice}>
              <InputNumber
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                disabled style={{ width: '100%' }}
                value={item?.masterProduct?.retailPrice}
              />
            </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
            <Form.Item label={lang.defaultCode}>
              <Input
                disabled style={{ width: '100%' }}
                value={item?.masterProduct?.barCode}
              />
            </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
            <Form.Item label={lang.defaultCode}>
              <Input
                disabled style={{ width: '100%' }}
                value={item?.masterProduct?.barCode}
              />
            </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item name="expireDate" label={lang.expireDate}>
            <DatePicker
              style={{ width: '100%' }}
              format={formatDate}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col lg={{ span: 24 }} xs={{ span: 24 }}>
          <Form.Item name="note" label={lang.note}>
            <Input.TextArea/>
          </Form.Item>
        </Col>
      </Row>
      <div
        className="ant-modal-footer"
        style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
      >
        <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
          <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
            <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
              {lang.btnCancel}
            </Button>
            {loading === false ? (
              <Button type="primary" htmlType="submit" style={{ fontWeight: 'bold' }}>
                {lang.btnSave}
              </Button>
            ) : (
              <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                {lang.loading}
              </Button>
            )}
          </Col>
        </Row>
      </div>
    </Form>
  );
};

ReceiptProductEditForm.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object),
  productsSelected: PropTypes.arrayOf(PropTypes.object),
  item: PropTypes.object,
  warehouseId: PropTypes.number,
  onCancel: PropTypes.func,
  onCallback: PropTypes.func,
  isOnlyQuantityAllowForUpdate: PropTypes.bool,
};

export default ReceiptProductEditForm;
