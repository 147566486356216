import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Modal, message as Alert, Space, message } from 'antd';
import ProForm from '@ant-design/pro-form';
import { productScan } from './reallocation.service';
import { EditableProTable } from '@ant-design/pro-table';
import { ExclamationCircleOutlined, DeleteOutlined, CheckOutlined, ScanOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import './reallocattion-style.less';
import SelectShelf from './selectShelf.js';
import SelectRack from './selectRack.js';
import SelectBlock from './selectBlock.js';
import { scanReallocationProduct } from 'providers/InventoryProvider/actions';
import { productUpdate } from '../product-management/product-management.service';

const breadcrumbItemRender = (route, params, routes, paths) => {
    const last = routes.indexOf(route) === routes.length - 1;
    return (last || route.path !== '') ? (
        <span>{route.breadcrumbName}</span>
    ) : (
        <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
    );
};

const ReallocationEdit = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const lang = getLanguages(t);
    const [form] = ProForm.useForm();
    const [items, setItems] = useState([]);
    const [firstItems, setFirstItems] = useState([]);
    const [editableKeys, setEditableRowKeys] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const { scanReallocationProductCode } = useSelector(auth => auth.inventory);
    const { currentWarehouse } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const onSelectChange = selectedRowKeys => {
        setSelectedRowKeys(selectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,

    };
    // const checkDisable = (listitems, firstListItem) => {
    //     if (listitems.length === 0) {
    //         return true;
    //     }
    //     const itemfind = listitems.filter((item) => firstListItem.some((firstItem) => firstItem.id === item.id && firstItem?.rack?.id !== item?.rack?.id));
    //     if (itemfind.length !== listitems.length) {
    //         return true;
    //     }
    //     return false;
    // };
    useEffect(() => {
        if (scanReallocationProductCode.length > 0) {
            productScan({ productCodes: scanReallocationProductCode, type: 'REALLOCATE', warehouseId: currentWarehouse.id }).then(result => {
                setItems(result.successList);
                setFirstItems(result.successList.map(rs => Object.assign({}, rs)));
                if (result?.errList.length > 0) {
                    message.warning(`${lang.invalidCode}: ${result?.errList?.toString()}. ${lang.tryAgain}`);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentWarehouse]);
    const columns = [
        {
            title: 'Seq',
            dataIndex: 'id',
            key: 'id',
            width: '4%',
            editable: false.valueOf,
            hideInTable: true,
        },
        {
            title: lang.defaultCode,
            dataIndex: 'masterProduct',
            key: 'barcode',
            width: '12%',
            render: value => value?.barCode
        },
        {
            title: lang.productCode,
            dataIndex: 'code',
            key: 'code',
            width: '12%',
            editable: false,
        },
        {
            title: lang.productCategory,
            key: 'productCategorys',
            width: '15%',
            render: (record) => {
                return record?.masterProduct?.productCategory?.name;
            },
            editable: false,
        },
        {
            title: lang.productName,
            key: 'productname',
            width: '25%',
            render: (record) => {
                return record?.masterProduct?.name;
            },
            editable: false,
        },
        {
            title: lang.block,
            dataIndex: 'block?.id',
            key: 'block',
            valueType: 'select',
            render: (_, row) => row?.block?.name,
            formItemProps: (form, { rowKey }) => {
                /* eslint eqeqeq: 0 */
                const find = items?.find(item => item.id == rowKey[0]);
                return {
                    initialValue: { value: find?.block?.id, label: find?.block?.name },
                };
            },
            renderFormItem: (_, { record }) => {
                return <SelectBlock warehouseId={currentWarehouse.id} />;
            },
        },
        {
            title: lang.shelf,
            dataIndex: 'rack.shelf.id',
            key: 'shelf',
            valueType: 'select',
            render: (_, row) => row?.rack?.shelf?.name,
            formItemProps: (form, { rowKey }) => {
                /* eslint eqeqeq: 0 */
                const find = items?.find(item => item.id == rowKey[0]);
                return {
                    initialValue: { value: find?.rack?.shelf?.id, label: find?.rack?.shelf?.name },
                };
            },
            renderFormItem: (_, { record }, form) => {
                return <SelectShelf blockId={record?.block.value} />;
            },
        },
        {
            title: lang.rack,
            dataIndex: 'rack.id',
            key: 'rack',
            valueType: 'select',
            render: (_, row) => {
                return row?.rack?.code;
            },
            formItemProps: (form, { rowKey }) => {
                /* eslint eqeqeq: 0 */
                const find = items?.find(item => item.id == rowKey[0]);
                return {
                    initialValue: { value: find?.rack?.id, label: find?.rack?.code },
                };
            },
            renderFormItem: (_, { record }, form) => {
                return <SelectRack shelfId={record?.shelf?.value} />;
            },

        },
        {
            title: lang.action,
            valueType: 'option',
            render: (text, record, _, action) => [
                <Button
                    key="editable"
                    type={'primary'}
                    style={{ width: 78, borderRadius: 4 }}
                    onClick={() => { action.startEditable(record.id); }}
                >
                    {lang.btnEdit}
                </Button>,
            ],
        },
    ];

    const routes = [
        {
            path: '',
            breadcrumbName: 'Home',
        },
        {
            path: '',
            breadcrumbName: lang.inventory,
        },
        {
            path: '/reallocation',
            breadcrumbName: lang.reallocation,
        },
        {
            path: '/scancode',
            breadcrumbName: lang.scanCode,
        },
        {
            path: '/editlocation',
            breadcrumbName: lang.editLocation,
        },
    ];
    return (
        <Layout className="layoutContent">
            <PageHeader
                ghost={false}
                title={lang.editLocation}
                breadcrumb={{
                    routes,
                    itemRender: breadcrumbItemRender,
                }}
                extra={[
                    <Button key="1"
                        type={'primary'}
                        style={{ width: 154, borderRadius: 4 }}
                        onClick={async () => {
                            try {
                                await Promise.all(items?.map(async item => {
                                    const firstItem = firstItems.find(fitem => fitem.id === item.id);
                                    await productUpdate({ id: item.id, blockId: item.block.id, rackId: item.rack.id, status: 'REALLOCATE', idRackReallocate: firstItem?.rack.id });
                                }));
                                dispatch(scanReallocationProduct([]));
                                navigate('/dashboard/inventory/reallocation');
                            } catch (error) {
                                message.error(error.message);
                            }
                        }}
                        // disabled={checkDisable(items, firstItems) || false}
                    >{lang.btnComplete} <CheckOutlined /></Button>,
                ]}
                className="customPageHeader"
            />
            <Layout.Content>
                <EditableProTable
                    rowKey="id"
                    headerTitle={
                        <Space>
                            <span>{lang.productList}</span>
                            <span className="spanCustomHead">{`* ${lang.dotEditLocation}`}</span>
                        </Space>
                    }
                    size="small"
                    columns={columns}
                    className="customSelect"
                    rowSelection={rowSelection}
                    scroll={{ x: 1024 }}
                    tableAlertRender={false}
                    search={false}
                    dataSource={items}
                    recordCreatorProps={false}
                    options={{
                        search: false,
                        reload: false,
                        density: false,
                    }}
                    value={items}
                    // onChange={setItems}
                    editable={{
                        form,
                        editableKeys,
                        onSave: async (rowKey, data, row) => {
                            console.log(data);
                            const dataf = items?.map(item => {
                                if (editableKeys.indexOf(item.id) > -1) {
                                    Object.assign(item, { block: { id: data?.block.value, name: data?.block.label }, rack: { id: data.rack.value, code: data.rack.label, shelf: { id: data?.shelf?.value, name: data?.shelf?.label } } });
                                }
                                return item;
                            });
                            setItems([...dataf]);
                        },
                        onChange: setEditableRowKeys,
                        actionRender: (row, config, dom) => {
                            // const { block, rack, shelf } = config.form.getFieldsValue();
                            // console.log(block, rack, shelf);
                            // console.log(row);
                            // const disable = (!!(block?.value && shelf?.value && rack?.value)) && (block?.value !== row?.block?.id || shelf?.value !== row?.rack?.shelf?.id || rack?.value !== row?.rack?.id);
                            return [<Button
                                key="btn-save"
                                // disabled={!disable}
                                type={'primary'}
                                style={{ width: 78, borderRadius: 4 }}
                                onClick={(block, rack) => {
                                    config.onSave(config.recordKey, config.form.getFieldsValue());
                                    config.cancelEditable(config.recordKey);
                                    config.form.setFieldsValue({
                                        [config.recordKey]: {
                                            block: undefined,
                                            shelf: undefined,
                                            rack: undefined,
                                        },
                                        block: undefined,
                                        shelf: undefined,
                                        rack: undefined,
                                    });
                                }}
                            >
                                {lang.btnSave}
                            </Button>, <Button
                                key="btn-cancle"
                                type={'primary'}
                                style={{ width: 78, borderRadius: 4 }}
                                onClick={() => {
                                    config.cancelEditable(config.recordKey);
                                    config.form.setFieldsValue({
                                        [config.recordKey]: {
                                            block: { value: row?.block?.id, label: row?.block.name, key: row?.block.id },
                                            shelf: { value: row?.rack?.shelf?.id, label: row?.rack?.shelf?.name, key: row?.rack?.shelf?.id },
                                            rack: { value: row?.rack?.code, label: row?.rack?.code, key: row?.rack?.id },
                                        },
                                        block: { value: row?.block?.id, label: row?.block.name, key: row?.block.id },
                                        shelf: { value: row?.rack?.shelf?.id, label: row?.rack?.shelf?.name, key: row?.rack?.shelf?.id },
                                        rack: { value: row?.rack?.id, label: row?.rack?.code, key: row?.rack?.id },
                                    });
                                    // config.form.setFieldsValue({ [config.recordKey]: { rack: undefined, block: undefined, shelf: undefined } });
                                }}
                            >
                                {lang.btnCancel}
                            </Button>];
                        },
                        onlyOneLineEditorAlertMessage: lang.onlyEditLocation,
                    }}
                    pagination={{
                        showTitle: false,
                        showTotal: false,
                        pageSize: 10,
                    }}
                    toolbar={{
                        actions: [
                            selectedRowKeys.length > 0 && <Button
                                key="btn-delete"
                                onClick={() => {
                                    Modal.confirm({
                                        title: lang.doYouWantToDeleteTheseItems,
                                        icon: <ExclamationCircleOutlined />,
                                        okText: lang.okText,
                                        cancelText: lang.cancelText,
                                        onOk() {
                                            const result = items.filter(item => selectedRowKeys.indexOf(item.id) === -1);
                                            setItems([...result]);
                                            setSelectedRowKeys([]);
                                            Alert.success(lang.deleteSuccessfully);
                                        },
                                        onCancel() {

                                        },
                                    });
                                }}
                                type={'primary'}
                                style={{ width: 110, borderRadius: 4 }}>{lang.btnDelete} <DeleteOutlined /></Button>,
                            <Button
                                key="btn-scanmore"
                                type="primary"
                                style={{ borderRadius: 4 }}
                                onClick={() => {
                                    navigate('/dashboard/inventory/reallocation/scancode');
                                }}
                            >
                                {lang.scanMore}
                                <ScanOutlined />
                            </Button>,
                        ],
                    }}
                />
            </Layout.Content>
        </Layout >
    );
};

export default ReallocationEdit;
