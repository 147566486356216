import React, { useEffect } from 'react';
import { Form, Col, Row, Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import ProForm, { ModalForm, ProFormDigit, ProFormText } from '@ant-design/pro-form';
import { CheckCircleOutlined } from '@ant-design/icons';
import './vans-style.less';
import { useSelector } from 'react-redux';
import Helper from 'utils/helpers';
import PropTypes from 'prop-types';
import { vanCreate, vanUpdate } from './vans.service';

const VanModalForm = ({ item, setVisible, visible, fetchVans }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const { currentWarehouse } = useSelector((state) => state.auth);
  useEffect(() => {
    return () => {
      form.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);
  const initValues = {
    name: item ? item?.name : '',
    totalRack: item ? item.totalRack : null,
    licensePlate: item ? item?.licensePlate : '',
    capacity: item ? item.capacity : null,
    medium: item ? item.medium : null,
    high: item ? item.high : null,
  };
  const onCancel = () => {
    const valueForm = form.getFieldsValue();
    const isDefined = Object.values(valueForm).some(value => value !== undefined);
    const isUnChange = JSON.stringify(initValues) === JSON.stringify(valueForm);
    if (!isDefined || isUnChange) {
      setVisible(false);
      return true;
    }
    Helper.conFirm(setVisible);
  };
  const handleAddVans = async (value) => {
    if (item?.code) {
      await vanUpdate(value, item);
      message.success(lang.updateSuccessfully);
      fetchVans(currentWarehouse.id);
      return true;
    }
    await vanCreate(value);
    message.success(lang.createSuccessfully);
    fetchVans(currentWarehouse.id);
    return true;
  };

  return (
    <ModalForm
      title={item?.id ? lang.updateVans : lang.createVans}
      visible={visible}
      form={form}
      onFinish={async (values) => {
        try {
          await handleAddVans({ ...values, warehouseId: currentWarehouse.id });
          return true;
        } catch (error) {
          message.error(error.message);
          return false;
        }
      }}
      requiredMark={false}
      submitter={{
        render: ({ form }) => {
          return [
            <Button
              style={{ maxWidth: '130px', height: '32px', width: '24vw' }}
              key="extra-reset"
              onClick={onCancel}
            // onClick={() => {
            //   setVisible(false);
            //   setShelfSelected(null);
            // }}
            >
              {lang.btnCancel}
            </Button>,
            <Button
              style={{ maxWidth: '130px', height: '32px', width: '24vw' }}
              type="primary"
              key="extra-submit"
              onClick={() => form?.submit?.()}
            >
              {lang.btnSave} <CheckCircleOutlined />
            </Button>,
          ];
        },
      }}
      onVisibleChange={setVisible}
      initialValues={initValues}
      modalProps={{
        closable: false,
        centered: true,
        destroyOnClose: true,
      }}
    >
      <Row gutter={[16, 16]}>
        <Col sm={12} xs={12}>
          <ProFormText name="name" label={lang.vansName} placeholder={lang.placeholderName} rules={[
            {
              required: true,
              message: lang.requiredVansName,
            },
          ]} />
        </Col>
        <Col sm={12} xs={12}>
          <ProFormDigit disabled={item?.id} min={1} max={100} name="totalRack" label={lang.totalRackbetween} placeholder={lang.placeholderTotalRack}
            rules={[
              {
                required: true,
                message: lang.requiredTotalRack,
              },
            ]} />
        </Col>
        <Col sm={12} xs={12}>
          <ProFormText name="licensePlate" label={lang.vansLicensePlate} placeholder={lang.placeholderLicensePlate} rules={[
            {
              required: true,
              message: lang.requiredLicensePlate,
            },
          ]} />
        </Col>
        <Col sm={12} xs={12}>
          <ProFormDigit name="capacity" label={lang.capacityRack} placeholder={lang.placeholderCapacity} rules={[
            {
              required: true,
              message: lang.requiredCapacity,
            },
          ]} />
        </Col>
        <Col sm={12} xs={12}>
          <ProFormDigit
            name="medium"
            label={
              <div className="labelMedium">
                {lang.levelPercent}: {lang.medium}&ensp;<div></div>
              </div>
            }
            placeholder={lang.placeholderLevelPercent}
            rules={[
              {
                required: true,
                message: lang.requiredLevelPercent,
              },
            ]}
            min={1}
            max={100}
          />
        </Col>
        <Col sm={12} xs={12}>
          <ProForm.Item noStyle shouldUpdate >
            {({ getFieldValue }) =>
              <ProFormDigit
                name="high"
                label={
                  <div className="labelHigh">
                    {lang.levelPercent}: {lang.high}&ensp;<div></div>
                  </div>
                }
                placeholder={lang.placeholderLevelPercent}
                rules={[
                  {
                    required: true,
                    message: lang.requiredLevelPercent,
                  },
                ]}
                min={getFieldValue('medium') + 1 > 100 ? getFieldValue('medium') : getFieldValue('medium') + 1}
                max={100}
              />}
          </ProForm.Item>
        </Col>
      </Row>
    </ModalForm>
  );
};
VanModalForm.propTypes = {
  item: PropTypes.object,
  setVisible: PropTypes.func,
  fetchVans: PropTypes.func,
  visible: PropTypes.bool,
};
export default VanModalForm;
