import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Layout, PageHeader, Button, Modal, Card, Row, Col, DatePicker, Input, Tag } from 'antd';
import { ScanOutlined, SearchOutlined } from '@ant-design/icons';
import { productList } from '../product.service';
import ProductReportForm from 'components/ProductReportForm/product-report.form';
import './style.less';
import { ProFormSelect } from '@ant-design/pro-form';
import moment from 'moment';
import _get from 'lodash/get';
import jsPdf from "jspdf";
import { defaultPage, formatDate, formatDateFull, formatDatetime } from 'utils/constants';
import TableCustom from 'components/TableCustom/table';
import { receiptDetail, receiptList } from '../receipt/receipt.service';
import html2canvas from "html2canvas";
//const tableScrollHeightArea = window.innerHeight - 360; // px
// const VIEW_MODE = {
//   CARD_VIEW: 0,
//   LIST_VIEW: 1,
// };
const defaultParams = {
  ...defaultPage,
  status: 'TEMPORARY',
};

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const TemporaryInboundList = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);

  const [isReportModal, setIsReportModal] = useState(false);

  //Roles
  const { user, currentWarehouse } = useSelector((state) => state.auth);
  const isStaff = _get(user, 'roles', '').includes('staff');

  const [item, setItem] = useState();
  const [items, setItems] = useState([]);
  const [totalItem, setTotalItem] = useState(0);
  const [loadingExport, setLoadingExport] = useState(false);
  //Pagination
  const [params, setParams] = useState({ ...defaultParams });
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [sortBy] = useState('id');
  const [sortDirection] = useState('desc');
  const [keyword, setKeyword] = useState('');
  const [selectedReceipt, setSelectedReceipt] = useState();
  const getProducts = (params, sortBy, sortDirection, currentWarehouse) => {
    setLoading(true);

    // const params = {
    //   page,
    //   limit,
    //   sortBy,
    //   sortDirection,
    //   keyword,
    //   status: 'TEMPORARY',
    // };
    // if (startDate) {
    //   params.startDate = startDate.startOf('date').format(formatDateFull);
    // }
    // if (endDate) {
    //   params.endDate = endDate.endOf('date').format(formatDateFull);
    // }
    productList({ ...params, sortBy, sortDirection, warehouseId: currentWarehouse.id })
      .then(result => {
        setItems(result.data);
        setTotalItem(result.totalItem);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  };

  useEffect(() => {
    getProducts(params, sortBy, sortDirection, currentWarehouse);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, sortBy, sortDirection, currentWarehouse]);
  const columns = [
    {
      title: lang.defaultCode,
      dataIndex: 'masterProduct',
      key: 'barcode',
      // width: '8%',
      render: value => value?.barCode
    },
    {
      title: lang.productCode,
      dataIndex: 'code',
      key: 'code',
      // width: '8%',
    },
    {
      title: lang.productName,
      dataIndex: 'masterProduct',
      key: 'name',
      width: '12%',
      render: value => value?.name,
    },
    {
      title: lang.supplier,
      dataIndex: 'supplier',
      key: 'supplier',
      width: '15%',
      render: value => value?.name,
    },
    {
      title: lang.receipt,
      dataIndex: 'receipt',
      key: 'receipt',
      // width: '8%',
      render: value => value?.code,
    },
    {
      title: lang.importDate,
      dataIndex: 'importDate',
      key: 'confirmDate',
      // width: '8%',
      render: value => moment(value, formatDateFull).format(formatDatetime),
    },
    {
      title: lang.expectedQuantity,
      key: 'expectedQuantity',
      dataIndex: 'expectedQuantity',
      // width: '6%',
    },
    {
      title: lang.totalQuantity,
      key: 'totalQuantity',
      dataIndex: 'totalQuantity',
      // width: '6%',
    },
    {
      title: lang.zone,
      key: 'zone',
      dataIndex: 'zone',
      width: '5%',
      render: value => value?.name,
    },
    {
      title: lang.block,
      key: 'block',
      dataIndex: 'block',
      width: '5%',
      render: value => value?.name,
    },
    {
      title: lang.shelf,
      key: 'shelf',
      dataIndex: 'rack',
      width: '5%',
      render: value => value?.shelf.name,
    },
    {
      title: lang.rack,
      key: 'rack',
      dataIndex: 'rack',
      // width: '6%',
      render: value => value?.code,
    },
    {
      title: lang.status,
      key: 'status',
      dataIndex: 'status',
      width: '5%',
      render: (record) => {
        return (
          <Tag>{lang[record]}</Tag>
        );
      },
    },
    {
      title: lang.action,
      align: 'center',
      width: '8%',
      fixed: 'right',
      render: (record) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            size="small"
            type="link"
            onClick={() => {
              //report
              const detail = {
                id: record?.id,
                productCode: record?.code,
                productName: record?.masterProduct?.name,
                totalQuantity: record?.totalQuantity
              };

              setItem(detail);
              setIsReportModal(true);
            }}
          >{lang.report}</Button>
        </div>
      ),
    },
  ];

  const extraButton = [
    // <Radio.Group
    //   key="segment-control-view-mode"
    //   buttonStyle="solid"
    //   value={mode}
    //   onChange={(val) => setMode(val?.target?.value)}
    // >
    //   <Radio.Button value={VIEW_MODE.CARD_VIEW}>
    //     <AppstoreOutlined />
    //   </Radio.Button>
    //   <Radio.Button value={VIEW_MODE.LIST_VIEW}>
    //     <BarsOutlined />
    //   </Radio.Button>
    // </Radio.Group>,
    <Button
      key="btn-export"
      type="primary"
      ghost
      disabled={items.length===0 || !params?.receiptId}
      loading={loadingExport}
      onClick={async() => {
        setLoadingExport(true);
        const receipt= await receiptDetail({receiptId: params?.receiptId});
        const content = `
        <div style="display: grid ;line-height: 0.5; width: 600px; padding: 10px 0; font-family: "Times New Roman", Times, serif;">
        <div style="display: flex; justify-content: space-between;">
          <div>
            <h3>Công ty TNHH PinNow Việt Nam</h3>
            <p>134 Bạch Đằng, Phường 2, Q.Tân Bình,</p>
            <p>0316954732</p>
          </div>
          <div style="text-align: center;">
            <h3>Mẫu số: 01-VT*</h3>
            <p>Ban hành theo thông tư</p>
            <p>số 200/2014/TT-BTC</p>
            <p>ngày 22/12/2014 của Bộ</p>
          </div>
        </div>
        <div style="text-align: center;">
          <h1>PHIẾU NHẬP KHO</h1>
          <p>Ngày ${moment(receipt.receiptDate,formatDateFull).format('DD')} Tháng ${moment(receipt.receiptDate,formatDateFull).format('MM')} Năm ${moment(receipt.receiptDate,formatDateFull).format('YYYY')}</p>
          <p>Số phiếu: ${receipt.code}</p>
        </div>
        <div>
          <p>Họ và tên người giao:</p>
          <p>Nhập tại kho: Công ty TNHH Pinnow Việt Nam</p>
          <p>Nội dung: Nhập hàng ngày 25/01</p>
        </div>
        <div>
          <table border="1" style="width: 100%; border-collapse: collapse; line-height: 1;">
            <thead>
              <tr style="height: 20px;">
                <th rowspan="2"><div style="min-height: 20px; display: flex; align-items: center;">STT</div></th>
                <th rowspan="2"><div style="min-height: 20px; display: flex; align-items: center;">Tên hàng hóa</div></th>
                <th rowspan="2"><div style="min-height: 20px; display: flex; align-items: center;">Mã hàng</div></th>
                <th rowspan="2"><div style="min-height: 20px; display: flex; align-items: center;">Đơn vị tính</div></th>
                <th colspan="2"><div style="min-height: 20px; display: flex; align-items: center;">Số lượng</div></th>
                <th rowspan="2"><div style="min-height: 20px; display: flex; align-items: center;">Ghi chú</div></th>
              </tr>
              <tr style="text-align: center">
                <td><div style="min-height: 20px; display: flex; align-items: center;">Theo CT</div></td>
                <td><div style="min-height: 20px; display: flex; align-items: center;">Thực nhập</div></td>
              </tr>
            </thead>
            <tbody>
              <tr style="text-align: center; height: 20px;">
                <td>A</td>
                <td>B</td>
                <td>C</td>
                <td>D</td>
                <td>1</td>
                <td>2</td>
                <td></td>
              </tr>
              ${receipt?.products?.map((product, index) => {
          return `<tr style="text-align: center; padding: 5px">
                    <td>${index+1}</td>
                    <td>${product.masterProduct.name}</td>
                    <td>${product.masterProduct.barCode}</td>
                    <td>${product.masterProduct.DVT}</td>
                    <td>${product.expectedQuantity}</td>
                    <td>${product.totalQuantity}</td>
                    <td></td>
                </tr>`
        }).join(' \n')}
        <tr style="text-align: center; padding: 5px">
                <td></td>
                <td>Tổng</td>
                <td></td>
                <td></td>
                <td>${receipt?.products?.reduce((prev,curr)=>prev+curr.expectedQuantity,0)}</td>
                <td>${receipt?.products?.reduce((prev,curr)=>prev+curr.totalQuantity,0)}</td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <div style="display: flex; justify-content: space-between; margin-top: 10px">
              <div style="text-align: center;">
                <p style="font-weight: bold;">Người lập phiếu</p>
                <p style="font-style: italic;">(Ký, họ tên)</p>
              </div>
              <div style="text-align: center;">
                <p style="font-weight: bold;">Người giao hàng</p>
                <p style="font-style: italic;">(Ký, họ tên)</p>
              </div>
              <div style="text-align: center;">
                <p style="font-weight: bold;">Thủ kho</p>
                <p style="font-style: italic;">(Ký, họ tên)</p>
              </div>
              <div style="text-align: center;">
                <p style="font-weight: bold;">Giám đốc nhà máy</p>
                <p style="font-style: italic;">(Ký, họ tên)</p>
              </div>
            </div>
        </div>
      </div>`;
        var template = document.createElement('div');
        template.innerHTML = content;
        document.body.appendChild(template);
        await html2canvas(template, {width: 770, scale: 3,}).then((canvas) => {
          let imgWidth = 210;
          let imgHeight = canvas.height * imgWidth / canvas.width;
          var imgData = canvas.toDataURL("image/png");
          const pdf = new jsPdf("p", "mm", "a4");
          pdf.addImage(imgData, "PNG", 25, 10, imgWidth, imgHeight);
          pdf.save(`Receipt${moment().format('DDMMYYYY')}.pdf`);
        });
        document.body.removeChild(template);
        setLoadingExport(false);
      }}
    >
      {lang.exportPDF}
    </Button>,
    <Button
      key="btn-complete"
      disabled={isStaff}
      type="primary"
      onClick={() => {
        navigate('scan-return-product');
      }}
    >
      {lang.scan}
      <ScanOutlined />
    </Button>,
  ];

  const routes = [
    {
      path: '/dashboard/inbound',
      breadcrumbName: lang.inbound,
    },
    {
      path: '/dashboard/inbound/temporary-inbound',
      breadcrumbName: lang.temporaryInbound,
    },
  ];

  return (
    <Layout className="layoutContent">
      <PageHeader
        ghost={false}
        title={lang.temporaryInbound}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      />
      <Layout.Content>
        <Card size="small" className="cardSearch">
          <Row gutter={[16, 16]}>
            <Col style={{ marginTop: 4 }}>
              <label>{lang.importDate}</label>
            </Col>
            <Col xxl={{ span: 4 }} sm={6} xs={16}>
              <DatePicker.RangePicker
                placeholder={[lang.from, lang.to]}
                onChange={(dates) => {
                  if (dates) {
                    setStartDate(dates[0]);
                    setEndDate(dates[1]);
                  } else {
                    setStartDate();
                    setEndDate();
                  }
                }}
                style={{ width: '100%' }}
                format={formatDate}
              />
            </Col>
            <Col xxl={{ span: 4 }} sm={6} xs={16}>
              <ProFormSelect label={lang.receipt} onChange={(value)=>setSelectedReceipt(value)} placeholder={lang.receipt} request={async () => {
                try {
                  const receipts = await receiptList({ warehouseId: currentWarehouse.id });
                  return receipts.data?.map(receipt => {
                    return {
                      label: receipt.code,
                      value: receipt.id,
                      key: receipt.id
                    };
                  })
                } catch (error) {
                  return []
                }
              }}
                fieldProps={{
                  showSearch: true
                }}
              />
            </Col>
            <Col xxl={{ span: 4 }} sm={6}>
              <Input placeholder={lang.keyword} onChange={(value) => setKeyword(value.target.value)} />
            </Col>
            <Col sm={3}>
              <Button
                type={'primary'}
                ghost
                onClick={() => {
                  if (startDate) {
                    params.startDate = startDate.startOf('date').format(formatDateFull);
                  } else {
                    delete params.startDate;
                  }
                  if (endDate) {
                    params.endDate = endDate.endOf('date').format(formatDateFull);
                  } else {
                    delete params.endDate;
                  }
                  if(selectedReceipt){
                    params.receiptId = selectedReceipt;
                  } else {
                    delete params.receiptId;
                  }
                  params.page = 1;
                  setParams({ ...params, keyword });
                }}
                icon={<SearchOutlined />}>{lang.search}</Button>
            </Col>
          </Row>
        </Card>
        <TableCustom
          title={() => <Row>
            <Col span={12}><h3>{lang.productList}</h3></Col>
          </Row>}
          rowKey="id"
          loading={loading}
          bordered
          columns={columns}
          dataSource={items}
          onChange={(pagination, filters, sorter) => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            // if (sorter) {
            //   setSortedInfo({
            //     columnKey: sorter.columnKey,
            //     order: sorter.order,
            //   });
            // }
            if (pagination.pageSize !== params.limit) {
              params.page = 1;
            } else {
              params.page = pagination.current;
            }
            params.limit = pagination.pageSize;
            setParams({ ...params });
          }}
          pagination={{
            total: totalItem,
            showSizeChanger: true,
            pageSize: params.limit,
            current: params.page,
          }}
          scroll={{ x: 2500 }}
        />
      </Layout.Content>
      <Modal
        visible={isReportModal}
        title={lang.reportErrorProduct}
        onCancel={() => setIsReportModal(false)}
        destroyOnClose={true}
        width={768}
        centered
        footer={null}
        wrapClassName="temporary-inbound-product-report-modal"
      >
        <ProductReportForm
          type={'temporary-inbound'}
          item={item}
          onCallback={(value) => {
            // setPage(1);
            // getProducts(1);
            setParams({ ...defaultParams });
            setIsReportModal(false);
          }}
          onCancel={() => setIsReportModal(false)}
        />
      </Modal>
    </Layout>
  );
};

export default TemporaryInboundList;
