import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Descriptions, Card, Row, Col, Select, message } from 'antd';

import './style.less';
import { saleorderDetail } from './sale-order.service';
import { formatDate, formatDateFull } from 'utils/constants';
import moment from 'moment-timezone';
import TableCustom from 'components/TableCustom/table';
import { Option } from 'antd/lib/mentions';
import { orderUpdateStatus } from 'pages/dashboard/outbound/picking/picking.service';

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const ReceiptDetail = () => {
  const { saleorderID } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);

  const [saleorder, setSaleorder] = useState();
  const [pageSize, SetPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState();

  useEffect(() => {
    setLoading(true);
    saleorderDetail(saleorderID)
      .then(result => {
        setSaleorder(result);
        setStatus(result?.status);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  }, [saleorderID]);

  const columns = [
    {
      title: lang.productCategory,
      dataIndex: 'productCategory',
      key: 'productCategory',
      render: (value) => value?.name,
    },
    {
      title: lang.productCode,
      dataIndex: 'code',
      key: 'productCode',
    },
    {
      title: lang.productName,
      dataIndex: 'name',
      key: 'productName',
    },
    {
      title: lang.supplier,
      key: 'suppliers',
      dataIndex: 'suppliers',
      render: (value) => value ? value[0]?.name : undefined,
    },
    {
      title: lang.total,
      key: 'total',
      dataIndex: 'total',
    },
  ];

  const extraButton = [
    <Button
      key="btn-cancel"
      type="primary"
      ghost
      onClick={() => {
        navigate('/dashboard/configuration/sale-order');
      }}
    >
      {lang.btnCancel}
    </Button>,
    <Button
      key="btn-submit"
      type="primary"
      ghost
      onClick={async () => {
        try {
          if (status !== saleorder?.status) {
            await orderUpdateStatus({ ids: [saleorder?.id], status });
            message.success(lang.updateSuccessfully);
          }
        } catch (error) {
          message.error(error?.message);
        }

      }}
    >
      {lang.btnSubmit}
    </Button>,
  ];

  const routes = [
    {
      path: '',
      breadcrumbName: 'Home',
    },
    {
      path: '',
      breadcrumbName: lang.configuration,
    },
    {
      path: '/sale-order',
      breadcrumbName: lang.saleOrder,
    },
    {
      path: '',
      breadcrumbName: lang.saleOrderDetail,
    },
  ];

  return (
    <Layout className="layoutContent">
      <PageHeader
        ghost={false}
        title={lang.saleOrderDetail}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        //onBack={onBack}
        className="customPageHeader"
      />
      <Layout.Content>
        <Card size="small" className="cardSearch">
          <Descriptions column={{ xxl: 4, xl: 4, lg: 3, md: 3, sm: 2, xs: 1 }} >
            <Descriptions.Item label={lang.orderCode}>{saleorder?.code}</Descriptions.Item>
            <Descriptions.Item label={lang.vansCode}>{saleorder?.boothCode}</Descriptions.Item>
            <Descriptions.Item label={lang.userName}>{saleorder?.nameCustomer}</Descriptions.Item>
            <Descriptions.Item label={lang.status}>
              <Select size='small' style={{ width: '60%' }} value={status} onChange={(value) => {
                setStatus(value);
              }}>
                <Option key="1" value="NEW">{lang.NEW}</Option>
                <Option key="2" value="CANCELLED">{lang.CANCELLED}</Option>
                <Option key="3" value="PICKING">{lang.PICKING}</Option>
                <Option key="4" value="PACKAGED">{lang.PACKAGED}</Option>
              </Select>
            </Descriptions.Item>
            <Descriptions.Item label={lang.createDate}>{moment(saleorder?.createDate, formatDateFull).format(formatDate)}</Descriptions.Item>
            <Descriptions.Item label={lang.address}>{saleorder?.deliveryAdress}</Descriptions.Item>
          </Descriptions>
        </Card>
        <TableCustom
          title={() => <Row>
            <Col span={12}><h3>{lang.productList}</h3></Col>
          </Row>}
          rowKey="id"
          loading={loading}
          bordered
          columns={columns}
          dataSource={saleorder?.productOrders?.map(master => {
            return {
              ...master.masterProduct,
              total: master.total,
            };
          })}
          onChange={(pagination, filters, sorter) => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setCurrentPage(pagination.current);
          }}
          pagination={{
            showSizeChanger: true,
            total: saleorder?.productOrders?.length,
            onShowSizeChange: (current, size) => {
              SetPageSize(size);
            },
            pageSize: pageSize,
            current: currentPage,
          }}
          scroll={{ x: 467 }}
        />
      </Layout.Content>
    </Layout>
  );
};

export default ReceiptDetail;
