import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Modal, Card, Row, Col, Input, Tag, message as Alert, Form } from 'antd';

import { PlusOutlined, DeleteOutlined, EditOutlined, SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import { replenishmentDelete, replenishmentList } from './replenishment.service';
import ReplenishmentEditForm from './replenishment-edit.form';

import './style.less';
import { useSelector } from 'react-redux';
import { pickBy } from 'lodash';
import environment from 'environments/environment';
import TableCustom from 'components/TableCustom/table';
import { defaultPage } from 'utils/constants';
const defaultSort = {
  sortBy: 'createDate',
  sortDirection: 'desc',
};

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const ReplenishmentList = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [isEditModal, setIsEditModal] = useState(false);

  const [item, setItem] = useState();
  const [items, setItems] = useState([]);

  //Pagination
  const [params, setParams] = useState({ ...defaultPage });
  const [sortedInfo] = useState(defaultSort);
  const [form] = Form.useForm();

  //
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { currentWarehouse } = useSelector((state) => state.auth);
  const [totalItems, setTotalItems] = useState();

  const fetchReplenishment = async (params, sortedInfo, warehouseId) => {
    setLoading(true);
    replenishmentList({ ...params, ...sortedInfo, warehouseId })
      .then(result => {
        setItems(result.data);
        setTotalItems(result.totalItem);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  };

  useEffect(() => {
    fetchReplenishment(params, sortedInfo, currentWarehouse.id);
  }, [params, sortedInfo, currentWarehouse]);

  const columns = [
    // {
    //   title: 'Seq',
    //   dataIndex: 'id',
    //   key: 'id',
    //   width: '8%',
    // },
    {
      title: lang.productCategory,
      dataIndex: 'productCategory',
      key: 'productCategory',
      width: '20%',
      render: value => value?.name,
    },
    {
      title: lang.productName,
      dataIndex: 'productName',
      key: 'name',
      width: '20%',
    },
    {
      title: lang.inStock,
      dataIndex: 'onHand',
      key: 'onHand',
      width: '10%',
    },
    {
      title: lang.minQuantity,
      dataIndex: 'min',
      key: 'min',
      width: '12%',
    },
    {
      title: lang.maxQuantity,
      dataIndex: 'max',
      key: 'max',
      width: '12%',
    },
    {
      title: lang.toOrder,
      dataIndex: 'toOrder',
      key: 'toOrder',
      width: '12%',
      render: (record) => {
        return <div style={{ color: '#EF4036', fontWeight: '600' }}>{record}</div>;
      },
    },
    {
      title: lang.notification,
      key: 'status',
      dataIndex: 'status',
      width: '10%',
      render: (record) => {
        return (
          <Tag>{lang[record]}</Tag>
        );
      },
    },
    {
      title: lang.action,
      align: 'center',
      width: '8%',
      fixed: 'right',
      render: (record) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            type="link"
            size="small"
            icon={<EditOutlined />}
            onClick={() => {
              const supplierDetail = items.find((item) => item.id === record.id);
              setItem(supplierDetail);
              setIsEditModal(true);
            }}
          />
        </div>
      ),
    },
  ];

  const extraButton = [
    <Button
      key="btn-complete"
      type="primary"
      onClick={() => {
        setIsEditModal(true);
        setItem();
      }}
      disabled={true}
    >
      {lang.btnCreate}
      <PlusOutlined />
    </Button>,
  ];

  const routes = [
    {
      path: '',
      breadcrumbName: 'Home',
    },
    {
      path: '',
      breadcrumbName: lang.configuration,
    },
    {
      path: '/replenishment',
      breadcrumbName: lang.replenishment,
    },
  ];

  return (
    <Layout className="replenishment-list layoutContent">
      <PageHeader
        ghost={false}
        title={lang.replenishment}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        //onBack={onBack}
        className="customPageHeader"
      />
      <Layout.Content>
        <Card size="small" className="cardSearch">
          <Form form={form} className="customFormSearch" onFinish={(value) => {
            const cleanValue = pickBy(value, v => v !== undefined && v !== '');
            setParams({ ...cleanValue, page: 1, limit: params.limit });
          }}>
            <Row gutter={16}>
              <Col xxl={{ span: 6 }} xl={8} md={12} sm={16} xs={16} >
                <Form.Item label={lang.productName} name="productName" className="customItem">
                  <Input placeholder={lang.productName} allowClear={true} />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item>
                  <Button
                    type={'primary'}
                    ghost
                    icon={<SearchOutlined />}
                    htmlType="submit"
                  >{lang.btnSearch}</Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
        <TableCustom
          title={() => <Row>
            <Col span={12}><h3>{lang.replenishmentList}</h3></Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              {selectedRowKeys.length > 0 && (
                <Button
                  type="primary"
                  onClick={() => {
                    Modal.confirm({
                      title: lang.doYouWantToDeleteTheseItems,
                      icon: <ExclamationCircleOutlined />,
                      //content: 'Some descriptions',
                      onOk: async () => {
                        try {
                          if (environment.isMockData) {
                            replenishmentDelete(selectedRowKeys).then(result => {
                              setItems([...result]);
                            });
                            setSelectedRowKeys([]);
                            return true;
                          }
                          await Promise.all(
                            selectedRowKeys.map(key => replenishmentDelete(key)),
                          );
                          fetchReplenishment(params, sortedInfo, currentWarehouse.id);
                          setSelectedRowKeys([]);
                        } catch (error) {
                          Alert.error(error.message);
                          return false;
                        }
                      },
                      onCancel() {
                      },
                      okText: lang.okText,
                      cancelText: lang.cancelText,
                    });
                  }}
                  style={{ marginLeft: 8 }}
                >
                  {lang.btnDelete}
                  <DeleteOutlined />
                </Button>
              )}
            </Col>
          </Row>}
          rowKey="id"
          loading={loading}
          bordered
          columns={columns}
          dataSource={items}
          onChange={(pagination, filters, sorter) => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            if (pagination.pageSize !== params.limit) {
              params.page = 1;
            } else {
              params.page = pagination.current;
            }
            params.limit = pagination.pageSize;
            setParams({ ...params });
          }}
          pagination={{
            total: totalItems,
            showSizeChanger: true,
            pageSize: params.limit,
            current: params.page,
          }}
          scroll={{ x: 1200 }}
        />
      </Layout.Content>
        <ReplenishmentEditForm
          item={item}
          onCallback={async (value) => {
            setParams({ ...defaultPage });
            setIsEditModal(false);
          }}
          isEditModal={isEditModal}
          setIsEditModal= {setIsEditModal}
        />
    </Layout>
  );
};

export default ReplenishmentList;
