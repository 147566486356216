import React, { useState } from 'react';
import { Col, Row, Button, Input } from 'antd';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';

import QRCode from 'qrcode.react';
import Barcode from 'react-barcode';

const ProductPrint = ({ item, onCancel, onCallback }) => {
  const [loading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  return (
    <>
      <Row gutter={16}>
        <Col key={item.id} span={24}>
          <QRCode value={item?.code} renderAs={'svg'} style={{ margin: 'auto', width: '100%' }} />
          <Barcode value={item?.code} height={70} background="#EFF1F1" width={2.3} />
        </Col>
        <Col key={item.id} span={24} style={{ display: 'flex', marginTop: 10, marginBottom: 24 }}>
          <label style={{ lineHeight: 2.5 }}><strong>{`${lang.quantity}:`}</strong></label>
          <Input value={item?.totalQuantity} disabled style={{ marginLeft: 8, width: 80 }} />
        </Col>
      </Row>
      <div
        className="ant-modal-footer"
        style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
      >
        <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
          <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
            <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
              {lang.btnClose}
            </Button>
            {loading === false ? (
              <Button type="primary" htmlType="submit" style={{ fontWeight: 'bold' }}>
                {lang.print}
              </Button>
            ) : (
              <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                {lang.loading}
              </Button>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

ProductPrint.propTypes = {
  item: PropTypes.object,
  onCancel: PropTypes.func,
  onCallback: PropTypes.func,
};

export default ProductPrint;
