import React, { useState } from 'react';
import { Card, Input, Col, Row, Button, Tag } from 'antd';
import { TweenOneGroup } from 'rc-tween-one';
import PropTypes from 'prop-types';
import { ScanOutlined, PlusOutlined } from '@ant-design/icons';

import { useDispatch, useSelector } from 'react-redux';
import { scanInboundControlLocationCodeAdd } from 'providers/InboundProvider/actions';

import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';

const ScanLocationForm = ({ type }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const lang = getLanguages(t);

  const { scanInboundControlForLocationCode } = useSelector((state) => state.inbound);
  //Package code
  const [packageTags, setPackageTags] = useState(scanInboundControlForLocationCode);
  const [packageInputValue, setPackageInputValue] = useState();

  const forMapPackage = tag => {
    const tagElem = (
      <Tag
        style={{ marginBottom: 10 }}
        closable
        onClose={e => {
          e.preventDefault();
          handleClosePackage(tag);
        }}
      >
        {tag}
      </Tag>
    );
    return (
      <span key={tag} style={{ display: 'inline-block' }}>
        {tagElem}
      </span>
    );
  };

  const handleClosePackage = removedTag => {
    const tagsHasRemoved = packageTags.filter(tag => tag !== removedTag);

    if (type === 'INBOUND_CONTROL') {
      dispatch(scanInboundControlLocationCodeAdd(tagsHasRemoved));
    }

    setPackageTags(tagsHasRemoved);
  };

  const handleInputConfirmPackage = () => {
    let tagsHasInput = packageTags;
    if (packageInputValue && packageTags.indexOf(packageInputValue) === -1) {
      //tagsHasInput = [...packageTags, packageInputValue];
      tagsHasInput = [packageInputValue];
    }

    if (type === 'INBOUND_CONTROL') {
      dispatch(scanInboundControlLocationCodeAdd(tagsHasInput));
    }

    setPackageTags(tagsHasInput);
    setPackageInputValue('');
  };

  const packageTagChild = packageTags.map(item => forMapPackage(item));

  return (
    <Card title={lang.locationCode}>
      <Row>
        <Col><label>{lang.inputCode}</label></Col>
      </Row>
      <Row gutter={[16,16]} style={{ marginTop: 10 }}>
        <Col col={12} style={{ flexDirection: 'row', display: 'flex' }}>
          <Input
            type="text"
            size="small"
            value={packageInputValue}
            onChange={(item) => setPackageInputValue(item.target.value)}
            onBlur={handleInputConfirmPackage}
            onPressEnter={handleInputConfirmPackage}
          />
        </Col>
        <Col col={6}>
          <Button type={'primary'} ghost icon={<ScanOutlined />}/>
        </Col>
        <Col col={6}>
          <Button key="btn-add-location" onClick={handleInputConfirmPackage} type={'primary'} icon={<PlusOutlined />}>{lang.btnAdd}</Button>
        </Col>
      </Row>
      <Row style={{ marginTop: 24 }}>
        <Col span={24}>
          <div style={{ marginBottom: 16 }}>
            <TweenOneGroup
              enter={{
                scale: 0.8,
                opacity: 0,
                type: 'from',
                duration: 100,
                onComplete: e => {
                  e.target.style = '';
                },
              }}
              leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
              appear={false}
            >
              {packageTagChild}
            </TweenOneGroup>
          </div>
        </Col>
      </Row>
    </Card>
  );
};

ScanLocationForm.propTypes = {
  type: PropTypes.string,
};

export default ScanLocationForm;
