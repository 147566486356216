import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, message, Card, Row, Col } from 'antd';
import './vans-state-style.less';
import ProCard from '@ant-design/pro-card';
import { useSelector } from 'react-redux';
import { listVans } from 'pages/dashboard/configuration/vans/vans.service';

const scrollStyle = {
    overflowY: 'scroll',
    height: window.innerHeight - 200,
    margin: 'auto',
    width: '100%',
};
const headeStyleZone = {
    margin: 0,
    border: 0,
    height: 0,
    minHeight: 0,
};

const breadcrumbItemRender = (route, params, routes, paths) => {
    const last = routes.indexOf(route) === routes.length - 1;
    return (last || route.path !== '') ? (
        <span>{route.breadcrumbName}</span>
    ) : (
        <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
    );
};

const VansState = () => {
    const { t } = useTranslation();
    const lang = getLanguages(t);
    const [listblock, setListblock] = useState([]);
    const { currentWarehouse } = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const fetchVans = (warehouseId) => {
        listVans({ warehouseId })
            .then(result => {
                setListblock([...result]);
            })
            .catch((e) => message.error(e.message));
    };

    useEffect(() => {
        fetchVans(currentWarehouse.id);
    }, [currentWarehouse]);

    const routes = [
        {
            breadcrumbName: 'Home',
        },
        {
            breadcrumbName: lang.inventory,
        },
        {
            breadcrumbName: lang.vansState,
        },
    ];

    return (
        <Layout className="layoutContent">
            <PageHeader
                ghost={false}
                title={lang.vansState}
                breadcrumb={{
                    routes,
                    itemRender: breadcrumbItemRender,
                }}
                className="customPageHeader"
            />
            <Layout.Content>
                <ProCard bodyStyle={scrollStyle} >
                    <Row gutter={[16, 16]} /*className="RowMargin"*/>
                        <Col span={24}>
                            <Row>
                                <Col lg={3} sm={6} xs={8}>
                                    <div className="statusSummary">
                                        <div style={{ background: '#E4595C' }}></div>
                                        <span>{lang.full}</span>
                                    </div>
                                </Col>
                                <Col lg={3} sm={6} xs={8}>
                                    <div className="statusSummary">
                                        <div style={{ background: '#F2C94C' }}></div>
                                        <span>{lang.Medium}</span>
                                    </div>
                                </Col>
                                <Col lg={3} sm={6} xs={8}>
                                    <div className="statusSummary">
                                        <div style={{ background: '#EFF1F1' }}></div>
                                        <span>{lang.empty}</span>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        {listblock.map((van) => (
                            <Col key={van.code} xxl={3} md={4} sm={6} xs={8}>
                                <Card
                                    style={{ background: '#EFF1F1', borderRadius: 4, marginTop: 5 }}
                                    headStyle={headeStyleZone}
                                    className="CardVans" bodyStyle={{ padding: '15px', height: 50 }}
                                    onClick={async () => {
                                        navigate('/dashboard/inventory/vansstate/vansdetail/' + van.code);
                                    }}
                                >
                                    <span style={{ wordWrap: 'break-word' }} >{van.name}</span>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </ProCard>
            </Layout.Content>
        </Layout>
    );
};

export default VansState;
