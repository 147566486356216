import request from 'utils/request';
import environment from 'environment';

export async function bookingList(payload) {
    return request(environment.api.Orders + 'get-all', payload, 'GET');
}
export async function bookingDetail(id) {
    return request(environment.api.Orders + `get-one/${id}`, {}, 'GET');
}
export async function pickingList(id) {
    return true;
}
export async function suggestLocationByOrder(payload) {
    return request(environment.api.products + 'recommend', payload, 'GET');
}

export async function pickingDetail(payload) {
    return request(environment.api.receiptDetail, payload, 'GET');
}

export async function pickingCreate(payload) {
    return request(environment.api.receiptCreate, payload, 'POST');
}

export async function pickingUpdate(payload) {
    return request(environment.api.receiptUpdate, payload, 'PUT');
}
export async function pickingDelete(payload) {
  //return request(environment.api.productDelete, payload, 'DELETE');
  return new Promise((resolve, reject) => {
      resolve('1');
  });
}
export async function productScan(payload) {
    return request(environment.api.products + 'scan', payload, 'POST');
}
export async function productUpdateStatus(payload) {
    return request(environment.api.products + 'updates', payload, 'POST');
}

export async function orderUpdateStatus(payload) {
    return request(environment.api.Orders + 'updates', payload, 'POST');
}

export async function productSplit(payload) {
    return request(environment.api.products + 'split', payload, 'POST');
}
export async function productsList(payload) {
    return request(environment.api.productTrxList, payload, 'GET');
}
export async function orderCheck(payload) {
    return request(environment.api.Orders + 'check/picking', payload, 'POST');
}

