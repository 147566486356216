import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Popconfirm, message, Card, Row, Col } from 'antd';
import { ModalForm } from '@ant-design/pro-form';
import './location-style.less';
import ProCard from '@ant-design/pro-card';
import { blockDelete, blockList, shelfDelete, shelfDetail, zoneDelete, zoneList } from './location.service';
import { useSelector } from 'react-redux';
import environment from 'environments/environment';
import Helper from 'utils/helpers';
import ZoneModalForm from './zoneModalForm';
import BlockModalForm from './blockModalForm';
import ShelfModalForm from './shelfModalForm';

const scrollStyle = {
    overflow: 'scroll',
    height: '700px',
    margin: 'auto',
    width: '100%',
    marginBottom: 19,
};
const headeStyleZone = {
    margin: 0,
    border: 0,
    height: 0,
    minHeight: 0,
};
const headStyleBlock = {
    borderBottom: 0,
};

const breadcrumbItemRender = (route, params, routes, paths) => {
    const last = routes.indexOf(route) === routes.length - 1;
    return (last || route.path !== '') ? (
        <span>{route.breadcrumbName}</span>
    ) : (
        <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
    );
};

const Location = () => {
    // const dispatch = useDispatch();
    const { t } = useTranslation();
    const lang = getLanguages(t);
    const [listzone, setListzone] = useState([]);
    const [listblock, setListblock] = useState([]);
    const [visible, setVisible] = useState(false);
    const [visiblePrint, setVisiblePrint] = useState(false);
    const [visibleBlock, setVisibleBlock] = useState(false);
    const [visibleZone, setVisibleZone] = useState(false);
    const [visibleprintShelf, setVisibleprintShelf] = useState(false);
    const [zoneSelected, setZoneSelected] = useState();
    const [blockSelected, setBlockSelected] = useState();
    const [shelfSelected, setShelfSelected] = useState();
    const [listRacks, setListRacks] = useState();
    const [position, setPosition] = useState();
    const { currentWarehouse } = useSelector((state) => state.auth);
    const fetchZones = (warehouseId) => {
        zoneList({ warehouseId })
            .then(result => {
                setListzone([...result.data]);
            })
            .catch((e) => message.error(e.message));
    };
    const fetchBlock = (warehouseId) => {
        blockList({ warehouseId })
            .then(result => {
                const dataremp = result.data.reduce((prev, current) => {
                    const positionFind = prev?.find(pre => pre.position === current.position);
                    if (positionFind) {
                        positionFind.blocks.push(current);
                        return prev;
                    }
                    return [...prev, {
                        position: current.position,
                        blocks: [current],
                    }];
                }, []);
                setListblock([...dataremp]);
            })
            .catch((e) => message.error(e.message));
    };

    useEffect(() => {
        fetchZones(currentWarehouse.id);
    }, [currentWarehouse]);
    useEffect(() => {
        fetchBlock(currentWarehouse.id);
    }, [currentWarehouse]);

    const routes = [
        {
            breadcrumbName: 'Home',
        },
        {
            breadcrumbName: lang.configuration,
        },
        {
            breadcrumbName: lang.location,
        },
    ];
    const uploadButton = (
        <div
            className="ButtonCustom"
            onClick={() => {
                setZoneSelected(null);
                setVisibleZone(true);
            }}
        >
            <span>{lang.addZone} +</span>
        </div>
    );

    const handledeleteZone = async (value) => {
        try {
            await zoneDelete(value);
            fetchZones(currentWarehouse.id);
            message.success(lang.zoneAreDeletedSuccessfully);
            return true;
        } catch (error) {
            message.error(error.message);
            return false;
        }
    };
    const handleDeleteBlock = async (value) => {
        try {
            await blockDelete(value);
            await fetchBlock(currentWarehouse.id);
            message.success(lang.blockAreDeletedSuccessfully);
            return true;
        } catch (error) {
            message.error(error.message);
            return false;
        }
    };
    const handleDeleteshelf = async (id) => {
        try {
            if (environment.isMockData) {
                await shelfDelete(id).then(result => {
                    setListblock([...result]);
                });
                return true;
            }
            await shelfDelete(id);
            await fetchBlock(currentWarehouse.id);
            message.success(lang.shelfAreDeletedSuccessfully);
            return true;
        } catch (error) {
            message.error(error.message);
            return false;
        }
    };
    return (
        <Layout className="layoutContent">
            <PageHeader
                ghost={false}
                title={lang.location}
                breadcrumb={{
                    routes,
                    itemRender: breadcrumbItemRender,
                }}
                className="customPageHeader"
            />
            <Layout.Content>
                <ProCard bodyStyle={scrollStyle} >
                    <Row gutter={16} className="RowMargin">
                        <Col flex="102px" className="ColLeft">
                            <Row gutter={[16, 32]}>
                                {listzone?.map((zone) => (
                                    <Col key={zone.id} span={24}>
                                        <Card className="CardStyle" style={{ background: '#dee0e0' }}
                                            headStyle={headeStyleZone}
                                            extra={<Popconfirm title={lang.areYouSureToDeleteThisZone}
                                                onConfirm={async () => await handledeleteZone(zone.id)}>
                                                <div className="btnDelete" style={{ right: '-9px' }}>X</div>
                                            </Popconfirm>}
                                            bodyStyle={{ padding: '24px 0px' }}
                                        >
                                            <p style={{ wordWrap: 'break-word' }} onClick={() => { setZoneSelected(zone); setVisibleZone(true); }}>{zone.name}</p>
                                        </Card>
                                    </Col>
                                ))}
                                <Col key="btn-upload-zone">
                                    {uploadButton}
                                </Col>
                            </Row>
                        </Col>
                        <Col flex="auto">
                            {listblock.map((columnPos) => (
                                <Row gutter={[16, 32]} key={columnPos.position} wrap={false} style={{ marginBottom: 32, minWidth: 1600 }}>
                                    {/* <Col span={24} /> */}
                                    {columnPos?.blocks.map((block) => (
                                        <Col key={block.id} flex="382px">
                                            <Card
                                                title={<span style={{ cursor: 'pointer' }} onClick={() => { setVisibleBlock(true); setBlockSelected(block); }}>{block?.name}</span>}
                                                headStyle={headStyleBlock}
                                                bodyStyle={{ padding: '0 12px', overflowY: 'scroll' }}
                                                className="CardBlock" size="small"
                                                extra={<Popconfirm title={lang.areYouSureToDeleteThisBlock}
                                                    onConfirm={async () => await handleDeleteBlock(block.id)}>
                                                    <div className="btnDelete">X</div>
                                                </Popconfirm>}

                                            >
                                                <Row gutter={[16, 16]} wrap={false} className="scrollXStyle">
                                                    {block?.shelfs?.filter(shelf => shelf.position === 1 || shelf.position === null)?.map((self) => (
                                                        <Col key={self.id}>
                                                            <Card
                                                                style={{ background: '#EFF1F1', borderRadius: 4, marginTop: 5 }}
                                                                headStyle={headeStyleZone}
                                                                extra={<Popconfirm title={lang.areYouSureToDeleteThisShelf}
                                                                    onConfirm={async () => await handleDeleteshelf({ shelfId: self.id, blockId: block.id })}>
                                                                    <div className="btnDelete" style={{ right: '-9px' }}>X</div></Popconfirm>}
                                                                className="CardShelf" bodyStyle={{ padding: '8px' }}
                                                            >
                                                                <span style={{ wordWrap: 'break-word' }} onClick={async () => {
                                                                    if (!environment.isMockData) {
                                                                        const shelfvalue = await shelfDetail(self.id);
                                                                        setShelfSelected({ ...shelfvalue, block: { ...block } });
                                                                        setListRacks(shelfvalue?.racks);
                                                                        setVisible(true);
                                                                    } else {
                                                                        setShelfSelected({ ...self, block: { ...block } });
                                                                        setVisible(true);
                                                                    }
                                                                }}>{self.name}</span>
                                                            </Card>
                                                        </Col>
                                                    ))}
                                                    {block.totalShelf > block.shelfs.length ? (
                                                        <Col key="btnadd-shelf">
                                                            <Card
                                                                className="ButtonShelfAdd"
                                                                style={{ color: '#2f80ed', lineHeight: 1, border: '1px dashed #2f80ed', borderRadius: 4, marginTop: 5 }}
                                                                onClick={() => {
                                                                    setShelfSelected({ block: { ...block }, position: 1 });
                                                                    setVisible(true);
                                                                }}
                                                                bodyStyle={{ padding: '12px 0' }}
                                                            >
                                                                <span>{lang.addShelf} +</span>
                                                            </Card>
                                                        </Col>
                                                    ) : null}
                                                </Row>
                                                <Row gutter={[16, 16]} wrap={false} className="scrollXStyle">
                                                    {block?.shelfs?.filter(shelf => shelf.position === 2)?.map((self) => (
                                                        <Col key={self.id}>
                                                            <Card
                                                                style={{ background: '#EFF1F1', borderRadius: 4, marginTop: 5 }}
                                                                headStyle={headeStyleZone}
                                                                extra={<Popconfirm title={lang.areYouSureToDeleteThisShelf}
                                                                    onConfirm={async () => await handleDeleteshelf({ shelfId: self.id, blockId: block.id })}>
                                                                    <div className="btnDelete" style={{ right: '-9px' }}>X</div></Popconfirm>}
                                                                className="CardShelf" bodyStyle={{ padding: '8px' }}
                                                            >
                                                                <span style={{ wordWrap: 'break-word' }} onClick={async () => {
                                                                    if (!environment.isMockData) {
                                                                        const shelfvalue = await shelfDetail(self.id);
                                                                        setShelfSelected({ ...shelfvalue, block: { ...block } });
                                                                        setListRacks(shelfvalue?.racks);
                                                                        setVisible(true);
                                                                    } else {
                                                                        setShelfSelected({ ...self, block: { ...block } });
                                                                        setVisible(true);
                                                                    }
                                                                }}>{self.name}</span>
                                                            </Card>
                                                        </Col>
                                                    ))}
                                                    {block.totalShelf > block.shelfs.length ? (
                                                        <Col key="btnadd-shelf">
                                                            <Card
                                                                className="ButtonShelfAdd"
                                                                style={{ color: '#2f80ed', lineHeight: 1, border: '1px dashed #2f80ed', borderRadius: 4, marginTop: 5 }}
                                                                onClick={() => {
                                                                    setShelfSelected({ block: { ...block }, position: 2 });
                                                                    setVisible(true);
                                                                }}
                                                                bodyStyle={{ padding: '12px 0' }}
                                                            >
                                                                <span>{lang.addShelf} +</span>
                                                            </Card>
                                                        </Col>
                                                    ) : null}
                                                </Row>
                                            </Card>
                                        </Col>
                                    ))}
                                    <Col key={`btnadd-block-${columnPos.position}`}>
                                        <Card
                                            className="CardBlockAdd"
                                            size="small"
                                            onClick={() => { setVisibleBlock(true); setPosition(columnPos.position); setBlockSelected(undefined); }}
                                            bodyStyle={{ display: 'grid', height: '100%' }}
                                        >
                                            <span>{lang.addBlock} + </span>
                                        </Card>
                                    </Col>
                                </Row>))
                            }
                            <Row gutter={[16, 32]} key={listblock.length + 1} wrap={false}>
                                <Col flex="382px">
                                    <Card
                                        className="CardBlockAdd"
                                        size="small"
                                        onClick={() => { setVisibleBlock(true); setPosition(listblock.length + 1); setBlockSelected(undefined); }}
                                        bodyStyle={{ display: 'grid', height: '100%' }}
                                    >
                                        <span>{lang.addBlock} + </span>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </ProCard>
            </Layout.Content>
            <ZoneModalForm
                item={zoneSelected}
                visible={visibleZone}
                setVisible={setVisibleZone}
                setVisiblePrint={setVisiblePrint}
                fetchZones={fetchZones}
                setZoneSelected={setZoneSelected}
            />
            <BlockModalForm
                item={blockSelected}
                visible={visibleBlock}
                setVisible={setVisibleBlock}
                setVisiblePrint={setVisiblePrint}
                fetchBlock={fetchBlock}
                setBlockSelected={setBlockSelected}
                position={position}
            />

            <ShelfModalForm
                item={shelfSelected}
                visible={visible}
                setVisible={setVisible}
                setVisibleprintShelf={setVisibleprintShelf}
                fetchBlock={fetchBlock}
                setShelfSelected={setShelfSelected}
                setListRacks={setListRacks}
            />
            {/* </ConfigProvider> */}
            <ModalForm
                visible={visiblePrint}
                width="302px"
                submitter={{
                    render: ({ form }) => {
                        return [
                            <Button
                                style={{ maxWidth: '130px', height: '32px', width: '24vw' }}
                                key="extra-reset"
                                onClick={() => {
                                    setVisiblePrint(false);
                                }}
                            >
                                {lang.btnClose}
                            </Button>,
                            <Button
                                style={{ maxWidth: '130px', height: '32px', width: '24vw' }}
                                type="primary"
                                key="extra-submit"
                                onClick={() => {
                                    Helper.dowloadCSV([zoneSelected?.code], 'zondeCode');
                                    setVisiblePrint(false);
                                    setVisibleZone(false);
                                }}
                            >
                                {lang.print}
                            </Button>,
                        ];
                    },
                }}
                onVisibleChange={setVisiblePrint}
                modalProps={{
                    closable: false,
                    centered: true,
                }}
                className="ModalPrint"
            >
                {/* <QRCode value={zoneSelected?.code} renderAs={'svg'} style={{ margin: 'auto', width: '100%' }} /> */}
                {Helper.genarateQRcode([zoneSelected?.code])}
                {Helper.genarateBarcode([zoneSelected?.code])}
                {/* <Barcode value={zoneSelected?.code || ''} height={70} background="#EFF1F1" width={2.3} /> */}
                <div style={{ textAlign: 'center' }}>{`${lang.code}:${zoneSelected?.code}`}</div>
            </ModalForm>
            <ModalForm
                title={lang.print}
                visible={visibleprintShelf}
                width="1122px"
                submitter={{
                    render: ({ form }) => {
                        return [
                            <Button
                                style={{ maxWidth: '130px', height: '32px', width: '24vw' }}
                                key="extra-reset"
                                onClick={() => {
                                    setVisibleprintShelf(false);
                                    fetchBlock(currentWarehouse.id);
                                }}
                            >
                                {lang.btnCancel}
                            </Button>,
                            <Button
                                style={{ maxWidth: '130px', height: '32px', width: '24vw' }}
                                type="primary"
                                key="extra-submit"
                                onClick={() => {
                                    Helper.dowloadCSV(listRacks?.map(rack =>rack.code),'dataRackCode');
                                    setVisibleprintShelf(false);
                                    setVisible(false);
                                }}
                            >
                                {lang.print}
                            </Button>,
                        ];
                    },
                }}
                onVisibleChange={setVisibleprintShelf}
                modalProps={{
                    closable: false,
                    centered: true,
                }}
            >
                <Row gutter={[16, 16]} className="scrollYStyle" id='idRow'>
                    {listRacks?.map((value) => {
                        return (
                            <Col key={value.id} lg={6} sm={8} xs={12}>
                                <Card style={{ background: '#EFF1F1' }} bodyStyle={{ padding: 12, textAlign:'center' }}>
                                    {/* <QRCode value={value.code} renderAs={'svg'} style={{ margin: 'auto', width: '100%' }} />
                                    <Barcode value={value.code} height={70} background="#EFF1F1" width={1.9} /> */}
                                    {Helper.genarateQRcode([value.code])}
                                    {Helper.genarateBarcode([value.code])}
                                    <div style={{ textAlign: 'center' }}>{`${lang.code}:${value.code}`}</div>
                                </Card>
                            </Col>
                        );
                    })}
                </Row>
            </ModalForm>

        </Layout>
    );
};

export default Location;
