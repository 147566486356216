import environment from 'environments/environment';
import request from 'utils/request';

let vans = [
    {
        code: 'van1',
        name: 'Xe 1',
        status: 'ENABLE',
        totalRack: 4,
        capacity: 20,
        medium: 10,
        high: 60,
        wareHouseId: 1,
        licensePlate: '123546',
    },
    {
        code: 'van2',
        name: 'Xe 2',
        status: 'ENABLE',
        totalRack: 4,
        capacity: 20,
        medium: 10,
        high: 60,
        wareHouseId: 1,
        licensePlate: '123546',
    },
    {
        code: 'van3',
        name: 'Xe 3',
        status: 'ENABLE',
        totalRack: 4,
        capacity: 20,
        medium: 10,
        high: 60,
        wareHouseId: 1,
        licensePlate: '123546',
    },
    {
        code: 'van4',
        name: 'Xe 4',
        status: 'ENABLE',
        totalRack: 4,
        capacity: 20,
        medium: 10,
        high: 60,
        wareHouseId: 1,
        licensePlate: '123546',
    },
    {
        code: 'van5',
        name: 'Xe 5',
        status: 'ENABLE',
        totalRack: 4,
        capacity: 20,
        medium: 10,
        high: 60,
        wareHouseId: 1,
        licensePlate: '123546',
    },
    {
        code: 'van6',
        name: 'Xe 6',
        status: 'ENABLE',
        totalRack: 4,
        capacity: 20,
        medium: 10,
        high: 60,
        wareHouseId: 1,
        licensePlate: '123546',
    },
    {
        code: 'van7',
        name: 'Xe 7',
        status: 'ENABLE',
        totalRack: 4,
        capacity: 20,
        medium: 10,
        high: 60,
        wareHouseId: 1,
        licensePlate: '123546',
    },

];

export async function listVans(payload) {
    return Promise.resolve(vans);
}
export async function vanDelete(value) {
    vans = vans.filter(van => van.code !== value.code);
    return Promise.resolve('OK');
}
export async function vanCreate(value) {
    Object.assign(value, { status: 'ENABLE', code: `van${vans.length+1}`});
    vans.push(value)
    return Promise.resolve('OK');
}
export async function vanUpdate(value, item) {
    vans.forEach(van=>{
        if(van.code===item.code){
            Object.assign(van,value);
        }
    })
    return Promise.resolve('OK');
}
export async function vansDetail(code) {
    return Promise.resolve(vans.find(van=>van.code===code));
}
