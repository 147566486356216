import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Button, Layout, PageHeader, Form, Card, Row, Col } from 'antd';

import { ProFormDateRangePicker, ProFormText } from '@ant-design/pro-form';
// import './style.less';
import { pickBy } from 'lodash';
import ProCard from '@ant-design/pro-card';
import IconPrintWhite from 'assets/icons/icon-printWhite.svg';
import { bookingList } from 'pages/dashboard/outbound/picking/picking.service';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { formatDate, formatDateFull, formatDatetime } from 'utils/constants';
import ProTableCusTom from 'components/TableCustom/Protable';

const breadcrumbItemRender = (route, params, routes, paths) => {
    const last = routes.indexOf(route) === routes.length - 1;
    return (last || route.path !== '') ? (
        <span>{route.breadcrumbName}</span>
    ) : (
        <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
    );
};

const AuditOrder = () => {
    // const dispatch = useDispatch();
    const { t } = useTranslation();
    const lang = getLanguages(t);
    const [listProduct, setListProduct] = useState([]);
    const [dataFilter, setDataFilter] = useState();
    const [form] = Form.useForm();
    const { currentWarehouse } = useSelector((state) => state.auth);
    const ref = useRef();

    const columnsHead = [
        {
            title: lang.userName,
            dataIndex: 'nameCustomer',
            key: 'customer',
            width: '12%',
        },
        {
            title: lang.orderCode,
            dataIndex: 'code',
            key: 'orderCode',
            width: '12%',
        },
        {
            title: lang.orderDate,
            dataIndex: 'createDate',
            key: 'orderDate',
            width: '12%',
            render: value => moment(value, formatDateFull).format(formatDatetime),
        },
        {
            title: lang.orderStatus,
            dataIndex: 'status',
            key: 'orderStatus',
            width: '4%',
            // render:value=><Tag style={{width:70,textAlign:'center'}} color={value==="Success"?'green':value==="Move"?'geekblue':'volcano'}>{value}</Tag>
        },
    ];
    const columnDetail = [
        {
            title: lang.productCode,
            key: 'productCode',
            width: '15%',
            render: (record) => record?.masterProduct?.code,
        },
        {
            title: lang.productName,
            key: 'productname',
            width: '25%',
            render: (record) => record?.masterProduct?.name,
        },
        {
            title: lang.orderQuantity,
            dataIndex: 'total',
            key: 'orderQuantity',
        },
        {
            title: lang.outQuantity,
            dataIndex: 'pickingQuantity',
            key: 'outQuantity',
            render: (value) => value === '-' ? 0 : value,
        },
    ];

    const routes = [
        {
            path: '',
            breadcrumbName: 'Home',
        },
        {
            path: '',
            breadcrumbName: lang.reporting,
        },
        {
            path: '/auditorder',
            breadcrumbName: lang.auditOrder,
        },
    ];
    return (
        <Layout className="layoutContent">
            <PageHeader
                ghost={false}
                title={lang.auditOrder}
                breadcrumb={{
                    routes,
                    itemRender: breadcrumbItemRender,
                }}
                extra={[
                    <Button
                        key="btn-complete"
                        type="primary"
                        style={{ width: 154, borderRadius: 4 }}
                        onClick={() => {
                            // navigate('/dashboard/inventory/reallocation/scancode')
                        }}
                    >
                        {lang.print} <img alt="" style={{ marginLeft: 5, marginTop: '-2px' }} src={IconPrintWhite} />
                    </Button>,
                ]}
                className="customPageHeader"
            />
            <Layout.Content>
                <Card size="small" className="cardSearch">
                    <Form form={form} className="customFormSearch"
                        onFinish={(value) => {
                            const cleanValue = pickBy(value, v => v !== undefined && v !== '');
                            if (cleanValue?.orderDate) {
                                cleanValue.startDate = cleanValue.orderDate[0].startOf('date').format(formatDateFull);
                                cleanValue.endDate = cleanValue.orderDate[1].endOf('date').format(formatDateFull);
                                delete cleanValue.orderDate;
                            }
                            ref.current.setPageInfo({ current: 1 });
                            setDataFilter(cleanValue);
                        }}
                    >
                        <Row gutter={[16,16]}>
                            <Col xxl={6} xl={8} md={10}>
                                <ProFormDateRangePicker label={lang.orderDate} name="orderDate" placeholder={[lang.startDate, lang.endDate]}
                                    fieldProps={{
                                        format: formatDate,
                                    }}
                                    width="100%"
                                />
                            </Col>
                            <Col xxl={4} xl={6} md={9}>
                                <ProFormText name="orderCode" placeholder={lang.keyword} />
                            </Col>
                            <Col >
                                <Button type={'primary'} style={{ borderRadius: '4px', width: '111px' }} htmlType="submit" ghost >{lang.btnSearch}</Button>
                            </Col>
                        </Row>
                    </Form>
                </Card>
                <ProCard title={lang.orderInfo} headStyle={{ borderBottom: 0, padding: 12 }} split="vertical">
                    <Row gutter={[16,16]}>
                        <Col span={12}>
                            <ProTableCusTom
                                rowKey="id"
                                actionRef={ref}
                                columns={columnsHead}
                                scroll={{ x: 370 }}
                                // dataSource={listOrder}
                                params={{ warehouseId: currentWarehouse.id, ...dataFilter }}
                                request={async (params) => {
                                    const { current, pageSize } = params;
                                    delete params.current;
                                    delete params.pageSize;
                                    const reponse = await bookingList({ page: current, limit: pageSize, ...params });
                                    return Promise.resolve({
                                        data: reponse.data,
                                        success: true,
                                        total: reponse.totalItem,
                                    });
                                }}
                                className="tableCustom"
                                search={false}
                                options={{
                                    search: false,
                                    reload: false,
                                    density: false,
                                    setting: false,
                                }}
                                pagination={{
                                    pageSize: 10,
                                    showTitle: false,
                                    showTotal: false,
                                }}
                                tableAlertRender={false}
                                onRow={(record) => {
                                    return {
                                        onClick: () => { setListProduct(record.productOrders); },
                                        style: { cursor: 'pointer' },
                                    };
                                }}
                            />
                        </Col>
                        <Col span={12}>
                            <ProTableCusTom
                                rowKey="id"
                                columns={columnDetail}
                                scroll={{ x: 370 }}
                                dataSource={listProduct}
                                search={false}
                                className="tableCustom"
                                options={{
                                    search: false,
                                    reload: false,
                                    density: false,
                                    setting: false,
                                }}
                                pagination={{
                                    showTitle: false,
                                    showTotal: false,
                                    pageSize: 10,
                                }}
                                tableAlertRender={false}
                            />
                        </Col>
                    </Row>
                </ProCard>
            </Layout.Content>
        </Layout>
    );
};

export default AuditOrder;
