import React, { useRef } from 'react';
import { Col, Row, Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import ProForm, { ModalForm, ProFormDigit, ProFormText, ProFormSelect } from '@ant-design/pro-form';
import { CheckCircleOutlined } from '@ant-design/icons';
import './location-style.less';
import { useSelector } from 'react-redux';
import Helper from 'utils/helpers';
import { shelfCreate, shelfUpdate } from './location.service';
import PropTypes from 'prop-types';
// import { categoryList } from '../category/category.service'
import { categoryList } from '../category/category.service';

const ShelfModalForm = ({ item, setVisible, visible, fetchBlock, setListRacks, setVisibleprintShelf }) => {
  // const [form] = Form.useForm();
  const formRef = useRef();
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const { currentWarehouse } = useSelector((state) => state.auth);
  // useEffect(() => {
  //   return () => {
  //     form.resetFields();
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [visible]);
  const initValues = {
    blockName: item?.block?.name,
    name: item ? item?.name : '',
    totalRack: item ? item.totalRack : null,
    capacity: item ? item.capacity : null,
    parentProductCategoryId: item?.parentProductCategory?.status === 'ENABLE' ? item?.parentProductCategory?.id : undefined,
    medium: item?.medium ? item.medium : 50,
    high: item?.height ? item.high : 70,
  };
  const onCancel = () => {
    const valueForm = formRef?.current?.getFieldsValue();
    const isDefined = Object.values(valueForm).some(value => value !== undefined);
    const isUnChange = JSON.stringify(initValues) === JSON.stringify(valueForm);
    if (!isDefined || isUnChange) {
      setVisible(false);
      return true;
    }
    Helper.conFirm(setVisible);
  };
  const handleAddshelf = async (value) => {
    if (item?.id) {
      await shelfUpdate(value, item);
      message.success(lang.updateSuccessfully);
      return true;
    } else {
      const response = await shelfCreate(value, item);
      setListRacks(response?.racks);
    }
    message.success(lang.createSuccessfully);
    fetchBlock(currentWarehouse.id);
    return true;
  };

  return (
    <ModalForm
      title={item?.id ? lang.updateShelf : lang.createShelf}
      visible={visible}
      formRef={formRef}
      onFinish={async (values) => {
        try {
          await handleAddshelf({ ...values, warehouseId: currentWarehouse.id, blockId: item?.block?.id });
          setVisibleprintShelf(true);
          return true;
        } catch (error) {
          message.error(error.message);
          return false;
        }
      }}
      requiredMark={false}
      submitter={{
        render: ({ form }) => {
          return [
            <Button
              style={{ maxWidth: '130px', height: '32px', width: '24vw' }}
              key="extra-reset"
              onClick={onCancel}
            // onClick={() => {
            //   setVisible(false);
            //   setShelfSelected(null);
            // }}
            >
              {lang.btnCancel}
            </Button>,
            item?.id && <Button
              style={{ maxWidth: '130px', height: '32px', width: '24vw' }}
              type="primary"
              key="extra-print"
              onClick={() => setVisibleprintShelf(true)}
            >
              {lang.print} <CheckCircleOutlined />
            </Button>,
            <Button
              style={{ maxWidth: '130px', height: '32px', width: '24vw' }}
              type="primary"
              key="extra-submit"
              onClick={() => form?.submit?.()}
            >
              {lang.btnSave} <CheckCircleOutlined />
            </Button>,
          ];
        },
      }}
      onVisibleChange={setVisible}
      initialValues={initValues}
      modalProps={{
        closable: false,
        centered: true,
        destroyOnClose: true,
      }}
    >
      <Row gutter={16}>
        <Col sm={8} xs={12}>
          <ProFormText name="blockName" label={lang.blockName} placeholder="Please input" disabled={true} />
        </Col>
        <Col sm={8} xs={12}>
          <ProFormText name="name" label={lang.shelfName} placeholder={lang.placeholderName} rules={[
            {
              required: true,
              message: lang.requiredShelfName,
            },
          ]} />
        </Col>
        <Col sm={8} xs={0} />
        <Col sm={{ span: 8 }} xs={12}>
          <ProFormDigit disabled={item?.id} min={1} max={100} name="totalRack" label={lang.totalRackbetween} placeholder={lang.placeholderTotalRack}
            rules={[
              {
                required: true,
                message: lang.requiredTotalRack,
              },
            ]} />
        </Col>
        <Col sm={8} xs={12}>
          <ProFormDigit name="capacity" label={lang.capacityRack} placeholder={lang.placeholderCapacity} rules={[
            {
              required: true,
              message: lang.requiredCapacity,
            },
          ]} />
        </Col>
        <Col sm={8} xs={0}>
        </Col>
        <Col sm={8} xs={12}>
          <ProFormSelect name="parentProductCategoryId" label={lang.Category} placeholder={lang.placeholderSelectProductCate}
            request={async () => {
              const productCate = await categoryList({ warehouseId: currentWarehouse.id, status: 'ENABLE' }).then(result => result.data);
              return productCate.map(cate => {
                return {
                  value: cate.id,
                  label: cate.name,
                };
              });
            }}
            rules={[
              {
                required: true,
                message: lang.requiredCategory,
              },
            ]}
          />
        </Col>
        <Col sm={8} xs={12}>
          <ProForm.Item noStyle shouldUpdate >
            {({ getFieldValue }) =>
              <ProFormDigit
                name="medium"
                label={
                  <div className="labelMedium">
                    {lang.levelPercent}: {lang.medium}&ensp;<div></div>
                  </div>
                }
                placeholder={lang.placeholderLevelPercent}
                rules={[
                  {
                    required: true,
                    message: lang.requiredLevelPercent,
                  },
                ]}
                min={0}
                max={getFieldValue('high') ? getFieldValue('high') - 1 : 99}
              />}
          </ProForm.Item>
        </Col>
        <Col sm={8} xs={12}>
          <ProForm.Item noStyle shouldUpdate >
            {({ getFieldValue }) =>
              <ProFormDigit
                name="high"
                label={
                  <div className="labelHigh">
                    {lang.levelPercent}: {lang.high}&ensp;<div></div>
                  </div>
                }
                placeholder={lang.placeholderLevelPercent}
                rules={[
                  {
                    required: true,
                    message: lang.requiredLevelPercent,
                  },
                ]}
                min={getFieldValue('medium') + 1 > 100 ? getFieldValue('medium') : getFieldValue('medium') + 1}
                max={100}
              />}
          </ProForm.Item>
        </Col>
      </Row>
    </ModalForm>
  );
};
ShelfModalForm.propTypes = {
  item: PropTypes.object,
  setVisible: PropTypes.func,
  fetchBlock: PropTypes.func,
  visible: PropTypes.bool,
  setListRacks: PropTypes.func,
  setVisibleprintShelf: PropTypes.func,
};
export default ShelfModalForm;
