import React, { useEffect, useState } from 'react';
import { Form, Input, Col, Row, Button, Select, message as Alert, Modal } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import { useSelector } from 'react-redux';
import { categoryCreate, categoryUpdate } from './category.service';
import './style.less';
import Helper from 'utils/helpers';
import { divisonList } from '../divison/divison.service';

const { Option } = Select;

const CategoryEditForm = ({ item, onCallback, setIsEditModal, isEditModal }) => {
  const { currentWarehouse } = useSelector((state) => state.auth);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [listDivison, setListDivison] = useState([]);

  const initValues = {
    name: item ? item?.name : '',
    divisonId: item ? item?.divison?.id : undefined,
    exportStrategy: item ? item?.exportStrategy : 'FEFO',
    code: item ? item?.code : undefined,
    status: item ? item?.status : undefined,
  };

  useEffect(() => {
    return () => {
      form.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditModal]);

  useEffect(() => {
    divisonList({ warehouseId: currentWarehouse.id }).then(response => {
      if (response?.data) {
        setListDivison(response?.data);
      }
    })
  }, [currentWarehouse, isEditModal]);

  const onCancel = () => {
    const valueForm = form.getFieldsValue();
    const isDefined = Object.values(valueForm).some(value => value !== undefined);
    const isUnChange = JSON.stringify(initValues) === JSON.stringify(valueForm);
    if (!isDefined || isUnChange) {
      setIsEditModal(false);
      return true;
    }
    Helper.conFirm(setIsEditModal);
  };

  return (
    <Modal
      visible={isEditModal}
      title={item ? lang.updateProductCategoty : lang.createProductCategoty}
      onCancel={onCancel}
      afterClose={() => form.resetFields()}
      width={570}
      centered
      footer={null}
      wrapClassName="product-category-modal"
      forceRender={true}
    >
      <Form
        colon={false}
        form={form}
        layout="vertical"
        name="productCategoryEditForm"
        requiredMark={false}
        onFinish={(values) => {
          console.log(values);
          setLoading(true);
          values.warehouseId = currentWarehouse?.id;
          if (!item) { // Create
            categoryCreate(values)
              .then(result => {
                Alert.success(lang.createSuccessfully);
                setLoading(false);
                onCallback();
              })
              .catch((e) => { setLoading(false); Alert.error(e.message); });
          } else { // Update
            values.id = item?.id;
            categoryUpdate(values)
              .then(result => {
                Alert.success(lang.updateSuccessfully);
                onCallback();
                setLoading(false);
              })
              .catch((e) => { setLoading(false); Alert.error(e.message); });
          }
        }}
        initialValues={initValues}
      >
        <Row gutter={[12,12]} type="flex">
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item
              name="name"
              label={lang.categoryName}
              rules={[
                {
                  required: true,
                  message: lang.requiredCategoryName,
                },
                {
                  max: 100,
                  message: lang.messageLong,
                },
              ]}
            >
              <Input placeholder={lang.placeholderCategoryName} />
            </Form.Item>
          </Col>
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item name="divisonId" label={lang.Divison} rules={[{ required: true, message: lang.requiredDivison }]}>
              <Select placeholder={lang.Divison}>
                {listDivison.map(item => (
                  <Option key={item.id} value={item.id}>{item.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {item && (
          <Row gutter={[12,12]} type="flex">
            <Col lg={{ span: 12 }} xs={{ span: 24 }}>
              <Form.Item
                name="code"
                label={lang.categoryCode}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col lg={{ span: 12 }} xs={{ span: 24 }}>
              <Form.Item name="status" label={lang.status}>
                <Select placeholder={lang.status}>
                  <Option value="ENABLE">{lang.ENABLE}</Option>
                  <Option value="DISABLE">{lang.DISABLE}</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        )}
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item name="exportStrategy" label={lang.methodExport} rules={[{ required: true, message: lang.requiredMethod }]}>
            <Select placeholder={lang.placeholderSelectMethod}>
              <Option value="LIFO">LIFO - Last In, First Out</Option>
              <Option value="FIFO">FIFO - First In, First Out</Option>
              <Option value="FEFO">FEFO - First Expired, First Out</Option>
            </Select>
          </Form.Item>
        </Col>
        <div
          className="ant-modal-footer"
          style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
        >
          <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
            <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
              <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
                {lang.btnCancel}
              </Button>
              {loading === false ? (
                <Button type="primary" onClick={() => form.submit()} style={{ fontWeight: 'bold' }}>
                  {lang.btnSave}
                </Button>
              ) : (
                <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                  {lang.loading}
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </Form>
    </Modal>
  );
};

CategoryEditForm.propTypes = {
  item: PropTypes.object,
  setIsEditModal: PropTypes.func,
  onCallback: PropTypes.func,
  isEditModal: PropTypes.bool,
};

export default CategoryEditForm;
