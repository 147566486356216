import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, message, Row, Col, Modal } from 'antd';

import { ScanOutlined, TableOutlined, DeleteOutlined, CheckOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import { useDispatch, useSelector } from 'react-redux';
import { scanReturnCompleted } from 'providers/InboundProvider/actions';

import moment from 'moment';
import { formatDateFull, formatDatetime } from 'utils/constants';

import { productScan, productUpdates, productUpdateLocation } from '../product.service';
import './style.less';
import TableCustom from 'components/TableCustom/table';

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const ScanReturnProductComplete = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [flag, setFlag] = useState(false);

  const { scanReturnForProductCodes, scanReturnForPackageCodes } = useSelector((state) => state.inbound);
  const { currentWarehouse } = useSelector((state) => state.auth);
  const [items, setItems] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  //STATUS = TEMPORARY_OUT
  useEffect(() => {
    setLoading(true);
    productScan({
      packageCodes: scanReturnForPackageCodes,
      productCodes: scanReturnForProductCodes.length > 0 ? scanReturnForProductCodes : undefined,
      type: 'TEMPORARY_INBOUND',
      warehouseId: currentWarehouse.id,
    })
      .then(result => {
        if (result?.errList.length > 0) {
          message.warning(`${lang.invalidCode}: ${result?.errList?.toString()}. ${lang.tryAgain}`);
        }

        if (result?.successList.length !== 0) {
          setItems(result?.successList);
        }

        setLoading(false);
      })
      .catch((e) => setLoading(false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flag, currentWarehouse]);

  const onSelectChange = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const columns = [
    {
      title: lang.productCode,
      dataIndex: 'code',
      key: 'code',
      width: '5%',
    },
    {
      title: lang.productCategory,
      dataIndex: 'masterProduct',
      key: 'productCategory',
      width: '10%',
      render: value => value?.productCategory?.name,
    },
    {
      title: lang.packageCode,
      dataIndex: 'packageCode',
      key: 'packageCode',
      width: '5%',
    },
    {
      title: lang.productName,
      dataIndex: 'masterProduct',
      key: 'productName',
      width: '12%',
      render: value => value?.name,
    },
    {
      title: lang.totalQuantity,
      key: 'totalQuantity',
      dataIndex: 'totalQuantity',
      width: '5%',
    },
    {
      title: lang.zone,
      key: 'zone',
      dataIndex: 'zone',
      width: '5%',
      render: value => value?.name,
    },
    {
      title: lang.block,
      key: 'block',
      dataIndex: 'block',
      width: '5%',
      render: value => value?.name,
    },
    {
      title: lang.shelf,
      key: 'shelf',
      dataIndex: 'rack',
      width: '5%',
      render: value => value?.shelf?.name,
    },
    {
      title: lang.rack,
      key: 'rack',
      dataIndex: 'rack',
      width: '5%',
      render: value => value?.code,
    },
    {
      title: lang.supplier,
      key: 'supplier',
      dataIndex: 'supplier',
      width: '18%',
      render: (value) => value?.name,
    },
    {
      title: lang.cost,
      dataIndex: 'masterProduct',
      key: 'cost',
      width: '8%',
      render: value => value?.purchasePrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    },
    {
      title: lang.salePrice,
      dataIndex: 'masterProduct',
      key: 'salePrice',
      width: '8%',
      render: value => value?.salePrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    },
    {
      title: lang.retailPrice,
      dataIndex: 'masterProduct',
      key: 'retailPrice',
      width: '8%',
      render: value => value?.retailPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    },
    {
      title: lang.expireDate,
      dataIndex: 'expireDate',
      key: 'expireDate',
      width: '12%',
      render: value => value? moment(value, formatDateFull).format(formatDatetime): undefined,
    },
  ];

  const extraButton = [
    <Button
      key="btn-cancel"
      type="primary"
      ghost
      onClick={() => {
        navigate('/dashboard/inbound/temporary-inbound/scan-return-product');
      }}
    >
      {lang.btnCancel}
    </Button>,
    <Button
      key="btn-complete"
      type="primary"
      disabled={items?.length === 0}
      onClick={() => {
        productUpdates({
          ids: items.map(item => item.id),
          status: 'TEMPORARY',
        })
          .then(result => {
            dispatch(scanReturnCompleted());
            Modal.success({
              title: lang.completeScanReturnProductSuccessfully,
              icon: <CheckOutlined />,
              onOk() {
                navigate('/dashboard/inbound/temporary-inbound/');
              },
            });
          })
          .catch((e) => message.error(e.message));
      }}
    >
      {lang.btnComplete}
      <CheckOutlined />
    </Button>,
  ];

  const routes = [
    {
      path: '/dashboard/inbound',
      breadcrumbName: lang.inbound,
    },
    {
      path: '/dashboard/inbound/temporary-inbound',
      breadcrumbName: lang.temporaryInbound,
    },
    {
      path: '/dashboard/inbound/temporary-inbound/scan-return-product',
      breadcrumbName: lang.scanReturnProduct,
    },
    {
      path: '',
      breadcrumbName: lang.btnComplete,
    },
  ];

  return (
    <Layout className="receipt-detail layoutContent">
      <PageHeader
        ghost={false}
        title={lang.scanReturnProductComplete}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      />
      <Layout.Content>
        <TableCustom
          title={() => <Row>
            <Col sm={12} xs={10}><h3>{lang.productList}</h3></Col>
            <Col sm={12} xs={14} style={{ textAlign: 'right' }}>
              <Button
                type="primary"
                onClick={() => {
                  navigate('/dashboard/inbound/temporary-inbound/scan-return-product');
                }}
              >
                {lang.scanMore}
                <ScanOutlined />
              </Button>
              <Button
                style={{ marginLeft: 8 }}
                type="primary"
                onClick={async () => {
                  // Modal.success({
                  //   title: lang.updateLocationSuccessfully,
                  //   icon: <CheckOutlined />,
                  //   onOk() {
                  //     //Reload
                  //   },
                  // });
                  try {
                    const ids = items?.map(item => item.id);
                    const response = await productUpdateLocation({ productIds: ids });
                    if (response?.length > 0) {
                      setFlag(!flag);
                      message.success(lang.updateLocationSuccessfully);
                    }
                    return true;
                  } catch (error) {
                    message.error(error.message);
                    return false;
                  }
                }}
              >
                {lang.updateLocation}
                <TableOutlined />
              </Button>
              {selectedRowKeys.length > 0 && (
                <Button
                  type="primary"
                  onClick={() => {
                    Modal.confirm({
                      title: lang.doYouWantToDeleteTheseItems,
                      icon: <ExclamationCircleOutlined />,
                      onOk() {
                        const itemsDeleted = items.filter(item => selectedRowKeys.includes(item.id) === false);
                        setItems(itemsDeleted);
                        setSelectedRowKeys([]);
                      },
                    });
                  }}
                  style={{ marginLeft: 8 }}
                >
                  {lang.btnDelete}
                  <DeleteOutlined />
                </Button>
              )}
            </Col>
          </Row>}
          rowKey="id"
          rowSelection={rowSelection}
          loading={loading}
          bordered
          columns={columns}
          dataSource={items}
          scroll={{ x: 1800 }}
        />
      </Layout.Content>
    </Layout>
  );
};

export default ScanReturnProductComplete;
