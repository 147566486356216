import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Form, Select, Card, Row, Col } from 'antd';

import { ScanOutlined } from '@ant-design/icons';
import { bookingList } from '../picking/picking.service';

import './style.less';
import { useDispatch, useSelector } from 'react-redux';
import { productPicking } from './packaging.service';
import TableCustom from 'components/TableCustom/table';
import { packagingOrderSet } from 'providers/OutboundProvider/actions';

const { Option } = Select;

const tableScrollHeightArea = window.innerHeight - 200; // px

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const PackagingCreate = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [bookingOrders, setBookingOrders] = useState([]);

  const [item, setItem] = useState([]);
  const [items, setItems] = useState([]);
  const [orderCode, setOrderCode] = useState();
  const dispatch = useDispatch();
  const { currentWarehouse } = useSelector((state) => state.auth);

  const columnsBookingOrders = [
    {
      title: lang.productOrderCode,
      key: 'orderCode',
      width: '15%',
      dataIndex: 'orderCode',
    },
    {
      title: lang.defaultCode,
      render: record => record?.masterProduct?.code,
      key: 'productCode',
      width: '15%',
    },
    {
      title: lang.productCategory,
      key: 'productCategory',
      width: '20%',
      render: record => record?.masterProduct?.productCategory?.name,
    },
    {
      title: lang.productName,
      key: 'name',
      width: '20%',
      render: record => record?.masterProduct?.name,
    },
    {
      title: lang.orderQuantity,
      dataIndex: 'total',
      key: 'total',
      width: '15%',
    },
  ];

  const columnsPickingList = [
    {
      title: lang.defaultCode,
      render: record => record?.masterProduct?.code,
      key: 'productCode',
      width: '10%',
    },
    {
      title: lang.productCode,
      key: 'code',
      width: '15%',
      dataIndex: 'code',
    },
    {
      title: lang.productCategory,
      dataIndex: 'masterProduct',
      key: 'productCategory',
      width: '15%',
      render: value => value?.productCategory?.name,
    },
    {
      title: lang.productName,
      key: 'name',
      width: '20%',
      render: record => record?.masterProduct?.name,
    },
    {
      title: lang.quantity,
      key: 'totalQuantity',
      dataIndex: 'totalQuantity',
      width: '15%',
    },
    {
      title: lang.block,
      key: 'block',
      dataIndex: 'block',
      width: '10%',
      render: value => value?.name,
    },
    {
      title: lang.shelf,
      key: 'shelf',
      dataIndex: 'rack',
      width: '10%',
      render: value => value?.shelf?.name,
    },
    {
      title: lang.rack,
      key: 'rack',
      dataIndex: 'rack',
      width: '10%',
      render: value => value?.code,
    },
  ];

  const extraButton = [
    <Button
      key="btn-cancel"
      type="primary"
      ghost
      onClick={() => {
        navigate('/dashboard/outbound/packaging');
      }}
    >
      {lang.btnCancel}
    </Button>,
    <Button
      key="btn-scan"
      type="primary"
      onClick={() => {
        dispatch(packagingOrderSet({
          packagingOrder: bookingOrders?.find(order => order.group === orderCode),
          pickingProducts: item,
        }));
        navigate('scan');
      }}
      disabled={item?.length === 0}
    >
      {lang.scan}
      <ScanOutlined />
    </Button>,
  ];

  const routes = [
    {
      path: '',
      breadcrumbName: 'Home',
    },
    {
      path: '',
      breadcrumbName: lang.outbound,
    },
    {
      path: '/packaging',
      breadcrumbName: lang.packaging,
    },
    {
      path: '',
      breadcrumbName: lang.pickupList,
    },
  ];
  useEffect(() => {
    setLoading(true);
    bookingList({ warehouseId: currentWarehouse.id, status: 'PICKING', sortDirection: 'desc', sortBy: 'createDate' })
      .then(result => {
        const rempData = result.data?.reduce((prev, curr) => {
          const group = prev?.find(temp => temp?.group === curr?.group);
          if (group) {
            // eslint-disable-next-line
            const et = group.orders?.push(curr);
          }
          else {
            prev.push({ group: curr.group, orders: [curr] });
          }
          return prev;
        }, [])
        setBookingOrders(rempData);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  }, [currentWarehouse]);
  // useEffect(() => {
  //   if (item) {
  //     setLoading(true);
  //     pickingList()
  //     .then(result => {
  //       setBookingOrders(result[0]?.products);
  //       setLoading(false);
  //     })
  //     .catch((e) => setLoading(false));

  //     suggestLocationByOrder()
  //     .then(result => {
  //       setItems(result);
  //       setLoading(false);
  //     })
  //     .catch((e) => setLoading(false));
  //   }
  // }, [item]);

  return (
    <Layout className="receipt-list">
      <PageHeader
        ghost={false}
        title={lang.pickupList}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      //onBack={onBack}
      />
      <Layout.Content style={{ margin: '12px 24px' }}>
        <Card size="small" className="cardSearch">
          <Form
            colon={false}
            layout="vertical"
            name="productEditForm"
            initialValues={{}}
          >
            <Row gutter={24}>
              <Col>
                <h3 style={{lineHeight: '32px'}}>{`${lang.labelBooking}`}</h3>
              </Col>
              <Col lg={{ span: 8 }} xs={{ span: 12 }}>
                <Select
                  placeholder={lang.orderCode}
                  showSearch
                  onChange={(value) => {
                    const booking = bookingOrders.find(booking => booking.group === value);
                    setOrderCode(booking?.group);
                    const productOrder = booking?.orders?.reduce((prev, curr) => {
                      // eslint-disable-next-line
                      const st = curr?.productOrders?.forEach(product => {
                        prev.push({ ...product, orderCode: curr.code })
                      })
                      return prev;
                    }, [])?.sort((a, b) => a.masterProduct.id - b.masterProduct.id);
                    setItems(productOrder);
                    productPicking({ group: booking?.group, warehouseId: currentWarehouse.id, status: 'PICKING' })
                      .then(result => {
                        setItem(result.data);
                      })
                      .catch((e) => { });
                  }}
                  style={{width: '100%'}}
                >
                  {bookingOrders?.map(booking => <Option key={booking.group} value={booking.group}>{booking?.orders?.map(order => order.code).toString()}</Option>)}
                </Select>
              </Col>
            </Row>
          </Form>
        </Card>
        <TableCustom
          rowKey="id"
          loading={loading}
          bordered
          columns={columnsBookingOrders}
          dataSource={items}
          scroll={{ x: 600, y: tableScrollHeightArea }}
          pagination={{
            showSizeChanger: true,
          }}
        />
        <TableCustom
          title={() => <Row>
            <Col span={24}><h3>{lang.pickingList}</h3></Col>
          </Row>}
          rowKey="id"
          loading={loading}
          bordered
          columns={columnsPickingList}
          dataSource={item}
          pagination={{
            showSizeChanger: true,
          }}
          scroll={{ x: 600, y: tableScrollHeightArea }}
        />
      </Layout.Content>
    </Layout>
  );
};

export default PackagingCreate;
