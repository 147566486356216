import React from 'react';
import { Link } from 'react-router-dom';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import i18n from 'i18next';
import QRCode from 'qrcode.react';
import Barcode from 'react-barcode';
import { Modal } from 'antd';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const Helper = {
  getTimeStamp: (date) => {
    const timePost = moment(date);
    const currentTime = moment();

    if (!date || !timePost.isValid()) {
      return '';
    }
    if (currentTime.diff(timePost, 's') < 60) {
      return i18n.t('justNow');
    }

    if (currentTime.diff(timePost, 'm') < 60) {
      return `${currentTime.diff(timePost, 'm')}${i18n.t('minutesAgo')}`;
    }

    if (currentTime.diff(timePost, 'h') < 24) {
      return `${currentTime.diff(timePost, 'h')}${i18n.t('hoursAgo')}`;
    }

    if (currentTime.diff(timePost, 'd') <= 7) {
      return `${currentTime.diff(timePost, 'd')}${i18n.t('daysAgo')}`;
    }

    return timePost.format('YYYY年MM月DD日');
  },
  breadcrumbItemRender: (route) => {
    if (!route || route.path === '') {
      return <span>{route.breadcrumbName}</span>;
    } else {
      return <Link to={route.path}>{route.breadcrumbName}</Link>;
    }
  },
  genarateQRcode: (arrayCode) => {
    return arrayCode.map(code => <QRCode key={`QR-${code}`} value={code} renderAs={'svg'} style={{ margin: 'auto', width: '100%' }} />);
  },
  genarateBarcode: (arrayCode) => {
    return arrayCode?.map(code => <Barcode key={`Bar-${code}`} value={code || ''} height={70} background="#EFF1F1" width={3} />);
  },
  numberWithCommas: (nStr) => {
    nStr += '';
    var x = nStr.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      // eslint-disable-next-line no-useless-concat
      x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
  },
  conFirm: (setVisible) => {
    return Modal.confirm({
      title: i18n.t('confirmQuestion'),
      icon: <ExclamationCircleOutlined />,
      // content: lang.confirmQuestion,
      onOk() {
        setVisible(false);
      },
      okText: i18n.t('okText'),
      cancelText: i18n.t('cancelText'),
      centered: true,
    });
  },
  dowloadCSV: (arrayData, fileName) => {
    const data = 'code \n' + arrayData.join('\n')
    var uri = "data:text/csv;charset=utf-8," + encodeURIComponent(data);
    var downloadLink = document.createElement('a');
    downloadLink.href = uri;
    downloadLink.download = `${fileName}.csv`;
    downloadLink.click();
  },
  dowloadXLSX: (arrayData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(arrayData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    FileSaver.saveAs(data, fileName + moment().format('YYYYMMDDHHMMSS') + '.xlsx');
  }
};

export default Helper;
