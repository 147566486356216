export const PICKING_ORDER_CREATE = 'PICKING_ORDER_CREATE';

export const BOOKING_ORDER_SET = 'BOOKING_ORDER_SET';
export const BOOKING_ORDER_SET_SUCCESS = 'BOOKING_ORDER_SET_SUCCESS';
export const BOOKING_ORDER_SET_FAIL = 'BOOKING_ORDER_SET_FAIL';

export const PICKING_LIST_SET = 'PICKING_LIST_SET';
export const PICKING_LIST_SET_SUCCESS = 'PICKING_LIST_SET_SUCCESS';
export const PICKING_LIST_SET_FAIL = 'PICKING_LIST_SET_FAIL';

export const PACKAGING_ORDER_SET = 'PACKAGING_ORDER_SET';

//Scan Picking
export const SCAN_PICKING_FOR_PRODUCT_CODE_ADD = 'SCAN_PICKING_FOR_PRODUCT_CODE_ADD';

//Scan Packaging
export const SCAN_PACKAGING_FOR_PRODUCT_CODE_ADD = 'SCAN_PACKAGING_FOR_PRODUCT_CODE_ADD';

//Scan Shipping
export const SCAN_SHIPPING_FOR_PACKAGE_CODE_ADD = 'SCAN_SHIPPING_FOR_PACKAGE_CODE_ADD';

//Scan split
export const SCAN_SPLIT_PRODUCT = 'SCAN_SPLIT_PRODUCT';
