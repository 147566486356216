import React from 'react';
import ProForm, { ProFormSelect } from '@ant-design/pro-form';
import { blockList } from 'pages/dashboard/configuration/location/location.service';

const selectBlock = ({ value, onChange, warehouseId }) => {
    const handleChange = (value, form) => {
        form.setFieldsValue({ rack: undefined, shelf: undefined });
        return onChange?.(value);
    };
    return (
        <ProForm.Item noStyle shouldUpdate>
            {(form) => (<ProFormSelect name="block"
                fieldProps={{
                    defaultValue: value,
                    onChange: (value) => handleChange(value, form),
                    labelInValue: true,
                }}
                initialValue={value}
                params={{ warehouseId: warehouseId }}
                request={async (params) => {
                    const response = await blockList(params);
                    if (response) {
                        return response?.data?.map(block => {
                            return {
                                value: block.id,
                                label: block.name,
                            };
                        });
                    }
                    return [];
                }}
            />)
            }
        </ProForm.Item>
    );
};

export default selectBlock;
