/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { Form, Input, Col, Row, Button, Select, InputNumber, DatePicker, Modal } from 'antd';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';

import './style.less';
import { formatDate } from 'utils/constants';
import Helper from 'utils/helpers';

const { Option } = Select;

const ReceiptProductEditForm = ({ item, products, productsSelected, warehouseId, setIsEditModal, onCallback, isOnlyQuantityAllowForUpdate = false, isEditModal }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);

  const [productName, setProductName] = useState();
  const [supplier, setSupplier] = useState();
  const [productCategory, setProductCategory] = useState();

  useEffect(() => {
    if (item?.productCode) {
      const obj = products.find(x => x.code === item.productCode);
      setProductName(obj.name);
      setSupplier(obj.suppliers);
      setProductCategory(obj.productCategory?.name);
    }
  }, [item, products, isEditModal]);

  useEffect(() => {
    return () => {
      form.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditModal]);

  const initValues = {
    masterProductId: item ? item?.masterProductId : '',
    expectedQuantity: item ? item?.expectedQuantity : null,
    supplierId: item ? item?.supplierId : undefined,
    purchasePrice: item ? item?.purchasePrice : null,
    salePrice: item ? item?.salePrice : null,
    retailPrice: item ? item?.retailPrice : null,
    DVT: item ? item?.DVT : null,
    expireDate: item ? item?.expireDate : null,
    slLo: 1,
  };
  const onCancel = () => {
    const valueForm = form.getFieldsValue();
    const isDefined = Object.values(valueForm).some(value => value !== undefined);
    const isUnChange = JSON.stringify(initValues) === JSON.stringify(valueForm);
    if (!isDefined || isUnChange) {
      setIsEditModal(false);
      return true;
    }
    Helper.conFirm(setIsEditModal);
  };
  return (
    <Modal
      visible={isEditModal}
      title={`${item ? lang.btnEdit : lang.btnAdd} ${lang.product}`}
      onCancel={onCancel}
      width={980}
      centered
      footer={null}
      wrapClassName="receipt-product-edit-modal"
      afterClose={() => {
        setProductName();
        setSupplier();
        setProductCategory();
        form.resetFields();
      }}
      forceRender={true}
    >
      <Form
        colon={false}
        form={form}
        layout="vertical"
        name="receiptProductEditForm"
        onFinish={(values) => {
          setLoading(true);
          const obj = products.find(x => x.id === values.masterProductId);
          const supplier = obj?.suppliers?.find(supp => supp.id === values.supplierId);
          if (!item) { // Create
            const arrProduct = [...Array(values?.slLo).keys()]?.map(key => {
              return {
                ...values,
                warehouseId,
                productName: obj.name,
                supplier: supplier?.name,
                productCategory: obj.productCategory?.name,
                productCode: obj.code,
                // supplierId: obj.supplier?.id,
                productCategoryId: obj.productCategory?.id,
                barCode: obj.barCode,
                key: key + obj.code,
                DVT: obj?.DVT,
              }
            })
            onCallback(arrProduct);
          } else { // Update
            onCallback([{
              ...values,
              warehouseId,
              productName: obj.name,
              supplier: supplier?.name,
              productCategory: obj.productCategory?.name,
              productCode: obj.code,
              // supplierId: obj.supplier?.id,
              productCategoryId: obj.productCategory?.id,
              barCode: obj.barCode,
              key: item.key,
              DVT: obj?.DVT,
            }]);
          }
          setLoading(false);
        }}
        initialValues={initValues}
      >
        <Row gutter={24} type="flex">
          <Col lg={{ span: 16 }} xs={{ span: 24 }}>
            <Form.Item name="masterProductId" label={lang.defaultCode}>
              {item
                //Edit
                ? <Select
                  disabled={item}
                  placeholder={lang.pleaseSelecteAProduct}
                  onChange={(value) => {
                    const obj = products.find(x => x.id === value);
                    form.setFieldsValue({ purchasePrice: obj?.purchasePrice, salePrice: obj?.salePrice, retailPrice: obj?.retailPrice });
                    setProductName(obj.name);
                    setSupplier(obj.supplier?.name);
                    setProductCategory(obj.productCategory?.name);
                  }}
                  showSearch
                >
                  {products.filter(x => x.warehouseId === warehouseId)?.map(item => (
                    <Option key={item.id} value={item.id}>{item.name} - {item.code}</Option>
                  ))}
                </Select>
                // eslint-disable-next-line operator-linebreak
                :
                //Add new
                <Select
                  placeholder={lang.pleaseSelecteAProduct}
                  onChange={(value) => {
                    const obj = products.find(x => x.id === value);
                    form.setFieldsValue({ purchasePrice: obj?.purchasePrice, salePrice: obj?.salePrice, retailPrice: obj?.retailPrice, DVT:obj?.DVT });
                    setProductName(obj.name);
                    setSupplier(obj.suppliers);
                    setProductCategory(obj.productCategory?.name);
                  }}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  }
                >
                  {products.filter(x => x.warehouseId === warehouseId)?.map(product => {
                    // const find = productsSelected.find(y => y.productCode === product.code);
                    return <Option key={product.id} value={product.id}>{product.name} - {product.code}</Option>
                    // if (!find) {
                    //   return (
                    //     <Option key={product.id} value={product.id}>{product.name} - {product.code}</Option>
                    //   );
                    // }
                  })}
                </Select>
              }
            </Form.Item>
          </Col>
          <Col lg={{ span: 8 }} xs={{ span: 24 }}>
            <Form.Item
              label={lang.productName}
            >
              <Input disabled value={productName} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} type="flex">
          <Col lg={{ span: 8 }} xs={{ span: 24 }}>
            <Form.Item
              name="expectedQuantity"
              label={lang.expectedQuantity}
              rules={[
                {
                  required: true,
                  message: lang.messageExpectedQuantity,
                },
              ]}
            >
              <InputNumber
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                style={{ width: '100%' }}
                min={1}
              />
            </Form.Item>
          </Col>
          <Col lg={{ span: 8 }} xs={{ span: 24 }}>
            <Form.Item label={lang.supplier} name="supplierId"
            // rules={[{
            //   required: true,
            //   message: lang.requiredSupplier
            // }]}
            >
              <Select value={supplier?.name}>
                {
                  supplier?.map(sup => <Option value={sup.id} key={sup.id}>{sup.name}</Option>)
                }
              </Select>
            </Form.Item>
          </Col>
          <Col lg={{ span: 8 }} xs={{ span: 24 }}>
            <Form.Item label={lang.productCategory}>
              <Input disabled value={productCategory} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} type="flex">
          <Col lg={{ span: 8 }} xs={{ span: 24 }}>
            <Form.Item
              name="purchasePrice"
              label={lang.cost}
            >
              <InputNumber
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                disabled style={{ width: '100%' }}
                min={1}
              />
            </Form.Item>
          </Col>
          <Col lg={{ span: 8 }} xs={{ span: 24 }}>
            <Form.Item name="salePrice" label={lang.salePrice}>
              <InputNumber
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                disabled
                style={{ width: '100%' }}
                min={1}
              />
            </Form.Item>
          </Col>
          <Col lg={{ span: 8 }} xs={{ span: 24 }}>
            <Form.Item
              name="retailPrice"
              label={lang.retailPrice}
            >
              <InputNumber
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                disabled style={{ width: '100%' }}
                min={1}
              />
            </Form.Item>
          </Col>
          <Col lg={{ span: 8 }} xs={{ span: 24 }}>
            <Form.Item
              name="DVT"
              label={lang.DVT}
            >
              <Input disabled style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col lg={{ span: 8 }} xs={{ span: 24 }}>
            <Form.Item name="expireDate" label={lang.expireDate}>
              <DatePicker
                disabled={isOnlyQuantityAllowForUpdate}
                style={{ width: '100%' }}
                format={formatDate}
              />
            </Form.Item>
          </Col>
          <Col lg={{ span: 8 }} xs={{ span: 24 }}>
            <Form.Item name="slLo" label={lang.lot}
              rules={[
                {
                  required: true,
                  message: lang.requiredLot,
                },
              ]}
            >
              <InputNumber
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                style={{ width: '100%' }}
                min={1}
                disabled={!!item}
              />
            </Form.Item>
          </Col>
        </Row>
        <div
          className="ant-modal-footer"
          style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
        >
          <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
            <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
              <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
                {lang.btnCancel}
              </Button>
              {loading === false ? (
                <Button type="primary" htmlType="submit" style={{ fontWeight: 'bold' }}>
                  {lang.btnSave}
                </Button>
              ) : (
                <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                  {lang.loading}
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </Form>
    </Modal>
  );
};

ReceiptProductEditForm.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object),
  productsSelected: PropTypes.arrayOf(PropTypes.object),
  item: PropTypes.object,
  warehouseId: PropTypes.number,
  setIsEditModal: PropTypes.func,
  onCallback: PropTypes.func,
  isOnlyQuantityAllowForUpdate: PropTypes.bool,
  isEditModal: PropTypes.bool,
};

export default ReceiptProductEditForm;
